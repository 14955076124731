import { createContext, useContext, useEffect } from "react";
import { StateStorage } from "./StateStorage";
import { useProfile } from "../hooks";
import { loginError } from "../constants/responseErrors";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  UseMutationResult,
  UseQueryResult,
} from "react-query";
import type {
  AxiosResponse as Response,
  AxiosResponse,
  AxiosError,
} from "axios";
import type { Auth, LoginData, Tag } from "../models";
import type { SimpleNotification } from "../models/notifications";
import type { LoginErrorCode } from "../constants/responseErrors";
import { logEvent } from "../helpers/logging";
import useAxios from "../hooks/useAxios";
import { useQuery } from "react-query";
import { uncheck_inappropriate_subtags } from "../hooks/useFilters";
import useTrackingToken from "../hooks/useTrackingToken";
import { SettingsGeneral } from "../models/Settings";
import { get_region } from "../cache/coordinates";
import useTranlatedMsg from "../hooks/useTranslatedMsg";

export type UpdateNotificationsPayload = {
  notificationIds: number[];
  seen?: boolean;
};

export type UpdateNotificationsResponseData = {
  count: number;
};

export type Query<T> = UseQueryResult<T, AxiosError<T>>;

type Storage = {
  postLogin: UseMutationResult<Response<Auth>, AxiosError, LoginData>;
  notifications: Query<SimpleNotification[]>;
  tags: Tag[] | undefined;
  refetchTags: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<Tag[], unknown>>;
  settings: SettingsGeneral | undefined;
};

export const QueryStorage = createContext<Storage>({} as Storage);

export const QueryStorageProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { err_connection, err_login } = useTranlatedMsg();
  const {
    setAuth,
    currentUser,
    setMessage,
    setInterestsTags,
    interestsCategories,
    interestsTags,
  } = useContext(StateStorage);
  const { getProfile } = useProfile();
  const { axios } = useAxios();
  const { token: trackingToken } = useTrackingToken();

  const postLogin = useMutation<AxiosResponse<Auth>, AxiosError, LoginData>(
    (payload) => axios.post("/login", payload),
    {
      onSuccess: (data) => {
        setAuth(data.data);
        // TODO: what about the timeout?
        setTimeout(() => getProfile.refetch(), 500);
        logEvent("login", {});
      },
      onError: ({ response }) => {
        const status = response?.status as LoginErrorCode;
        const error = new Error(loginError[status] || err_login);

        setMessage("danger", error.message, error);
      },
    }
  );

  const notifications = useQuery<
    AxiosResponse<SimpleNotification[]>,
    AxiosError<any, any>,
    SimpleNotification[]
  >(
    ["get_notification"],
    () => axios.get<SimpleNotification[]>("/notifications"),
    {
      select: (res) => res.data,
      refetchInterval: currentUser ? 5000 : false,
      enabled: currentUser !== null,
    }
  );

  const { data: tags, refetch: refetchTags } = useQuery(
    ["search_tags"],
    () =>
      axios.get<Tag[]>("/tags/all", {
        params: {
          region: get_region(),
        },
      }),
    {
      select: (res) => res.data,
      refetchOnWindowFocus: false,
      onError: (err) => {
        // TODO: FIX: On 403 when refetching accessToken a popup may show
        setMessage("danger", err_connection, err);
      },
    }
  );

  const { data: settings } = useQuery(
    ["settings", trackingToken, currentUser?.userId],
    () => axios.get<SettingsGeneral>("/settings/general"),
    { select: (res) => res.data, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    setInterestsTags(
      uncheck_inappropriate_subtags(tags, interestsCategories, interestsTags)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interestsCategories, tags]);

  return (
    <QueryStorage.Provider
      value={{
        postLogin,
        notifications,
        tags,
        refetchTags,
        settings,
      }}
    >
      {children}
    </QueryStorage.Provider>
  );
};
