import {
  useIonViewWillEnter,
  IonPage,
  IonBackButton,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
} from "@ionic/react";

import { useQuery } from "react-query";
import { showTabBar } from "../helpers/tabBar";
import useAxios from "../hooks/useAxios";
import { Event } from "../models";
import "./EventDetail.scss";
import { SkeletonEventList } from "../components/Skeleton";
import { EventItem } from "../components/Event";
import { RouteComponentProps } from "react-router";
import { Tip } from "../models/Tips";

interface TipsProps extends RouteComponentProps<{ id: string }> {}

const Tips: React.FC<TipsProps> = ({ match }) => {
  const { axios } = useAxios();

  const { data: tip } = useQuery(
    ["tip", match.params.id],
    () => {
      return axios.get<Tip>(`/tips/${match.params.id}`);
    },
    {
      select: (res) => res.data,
      refetchOnWindowFocus: false,
    }
  );

  const { data: events, isLoading } = useQuery(
    ["tip_events", match.params.id],
    () => {
      return axios.get<Event[]>("/events", {
        params: {
          order_by: "start",
          order_direction: "ASC",
          source: "tips",
          ids: tip?.eventIds.join(","),
        },
      });
    },
    {
      enabled: !!tip,
      select: (res) => res.data,
      refetchOnWindowFocus: false,
    }
  );

  useIonViewWillEnter(() => {
    showTabBar();
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/notifications"
              text=""
              className="back"
            />
          </IonButtons>
          <IonTitle className="title-wide">{tip?.title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList className="events_list" lines="none">
          {isLoading ? (
            <SkeletonEventList size="big" />
          ) : (
            events?.map((event, i) => (
              <EventItem
                key={event.eventId}
                event={event}
                // actions={true}
                order={i + 1}
                source={"tips"}
              />
            ))
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Tips;
