import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonPage,
  ScrollDetail,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useInfiniteQuery, useQueries, useQuery } from "react-query";
import { EventsSlides, HeaderMap } from "../components/HomePage";
import { SkeletonEventList, SkeletonHomePage } from "../components/Skeleton";
import { showTabBar } from "../helpers/tabBar";
import { useNavigation, useStorage } from "../hooks";
import useAxios from "../hooks/useAxios";
import { usePausableState } from "../hooks/usePausableState";
import { Coordinates, Event } from "../models";
import { EventItem } from "../components/Event";
import { SCROLL_STEP } from "../constants/global";
import { useTranslation } from "react-i18next";
// import { Swiper, SwiperSlide } from "swiper/react";

interface TagsforFetching {
  nam: string;
  slug: string;
}

const HomePage: React.FC<{
  bottomMenuSignal: Number;
}> = ({ bottomMenuSignal }) => {
  const { axios } = useAxios();
  const [slugsAndNames, setSlugsAndNames] = useState<TagsforFetching[]>([]);
  const contentEle = useRef<HTMLIonContentElement>(null);
  const [onTop, setOnTop] = useState<Number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const { navigate } = useNavigation();
  const {
    currentUser: user,
    preferredLoc,
    interestsCategories,
    interestsTags,
    tags,
    seenOnboardnig,
  } = useStorage();

  const { t } = useTranslation();
  const pausableinterestsCategories = usePausableState(
    interestsCategories,
    isActive && !(user?.interests?.length && !seenOnboardnig)
  );
  const pausableInterestsTags = usePausableState(
    interestsTags,
    isActive && !(user?.interests?.length && !seenOnboardnig)
  );

  function scrollTop() {
    if (onTop !== 0) contentEle.current?.scrollToTop(400);
  }

  function handleScroll(ev: CustomEvent<ScrollDetail>) {
    setOnTop(ev.detail.scrollTop);
  }

  useIonViewWillEnter(() => {
    setIsActive(true);
    showTabBar();
  });

  useIonViewWillLeave(() => {
    setIsActive(false);
  });

  useEffect(() => {
    scrollTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomMenuSignal]);

  useEffect(() => {
    if (user && !user?.interests?.length && !seenOnboardnig) {
      navigate("/onboarding");
    }
  }, [navigate, seenOnboardnig, user]);

  useEffect(() => {
    if (tags !== undefined) {
      const slAndNam = tags
        .filter((t) => pausableinterestsCategories.has(t.slug))
        .map((tag) => {
          return { nam: tag.name, slug: tag.slug };
        });

      // const unselectedSlAndNam = tags
      //   .filter((t) => !pausableinterestsCategories.has(t.slug))
      //   .map((tag) => {
      //     return { nam: tag.name, slug: tag.slug };
      //   });
      // const specificObj = { nam: "Other", slug: "other" };
      // const index = unselectedSlAndNam.findIndex(
      //   (obj) => obj.nam === specificObj.nam
      // );
      // if (index !== -1) {
      //   unselectedSlAndNam.splice(index, 1);
      //   unselectedSlAndNam.push(specificObj);
      // }
      // setSlugsAndNames([...slAndNam, ...unselectedSlAndNam]);
      setSlugsAndNames(slAndNam);
    }
  }, [tags, pausableinterestsCategories]);

  const getEvents = (slug: string, gps: Coordinates) => {
    return axios.get<Event[]>(`/events`, {
      params: {
        limit: 10,
        source: "homepage_slides_category",
        offset: 0,
        filter_date: "future",
        visibility: "public",
        tags: slug,
        lat: gps.lat,
        lng: gps.lng,
        interests_tags: Array.from(interestsCategories).join(","),
        interests_subtags: Array.from(interestsTags).join(","),
        order_by: "smart_extended",
      },
    });
  };

  const { data: recommendedEvents, isLoading: isLoadingRecommended } = useQuery(
    [
      "recommended_events",
      preferredLoc,
      Array.from(pausableinterestsCategories).join(","),
      Array.from(pausableInterestsTags).join(","),
    ],
    () =>
      axios.get<Event[]>(`/events`, {
        params: {
          limit: 10,
          source: "homepage_slides_recommended",
          offset: 0,
          date_from: moment()
            .startOf("isoWeek")
            // .add(1, "week")
            .format("YYYY-MM-DD"),
          visibility: "public",
          tags: Array.from(pausableinterestsCategories).join(","),
          subtags: Array.from(pausableInterestsTags).join(","),
          lat: preferredLoc.lat,
          lng: preferredLoc.lng,
          interests_tags: Array.from(interestsCategories).join(","),
          interests_subtags: Array.from(interestsTags).join(","),
          order_by: "smart_extended",
        },
      }),
    {
      select: (res) => res.data,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  // const { data: weekEvents, isLoading: isLoadingWeek } = useQuery(
  //   ["week_events", preferredLoc],
  //   () =>
  //     axios.get<Event[]>(`/events`, {
  //       params: {
  //         limit: 10,
  //         source: "homepage_slides_week",
  //         offset: 0,
  //         filter_date: "week",
  //         visibility: "public",
  //         lat: preferredLoc.lat,
  //         lng: preferredLoc.lng,
  //         interests_tags: Array.from(interestsCategories).join(","),
  //         interests_subtags: Array.from(interestsTags).join(","),
  //         order_by: "smart_extended",
  //       },
  //     }),
  //   {
  //     select: (res) => res.data,
  //     cacheTime: 0,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  // const {
  //   data: followingPlacesEvents,
  //   isLoading: isLoadingFollowingPlacesEvents,
  // } = useQuery(
  //   ["following_places_events"],
  //   () =>
  //     axios.get<Event[]>(`/events`, {
  //       params: {
  //         limit: 10,
  //         source: "homepage_slides_following_places_events",
  //         offset: 0,
  //         visibility: "public",
  //         order_by: "start",
  //         filter_date: "future",
  //         following_places: true,
  //       },
  //     }),
  //   {
  //     select: (res) => res.data,
  //     cacheTime: 0,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  const fetchedEvents = useQueries(
    slugsAndNames.map((tag) => {
      return {
        queryKey: ["fetched_category", tag.slug, preferredLoc],
        queryFn: () => getEvents(tag.slug, preferredLoc),
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: !isLoadingRecommended, //&& !isLoadingWeek,
        // && !isLoadingFollowingPlacesEvents,
      };
    })
  );

  const {
    data: displayEvents,
    fetchNextPage,
    isLoading,
  } = useInfiniteQuery(
    [
      "homepage_list",
      Array.from(interestsCategories).join(","),
      Array.from(interestsTags).join(","),
      user?.interests?.length,
      preferredLoc.lat,
      preferredLoc.lng,
    ],
    ({ pageParam = 0 }) =>
      axios.get<Event[]>(`/events`, {
        params: {
          source: "homepage_list",
          offset: pageParam,
          limit: SCROLL_STEP,
          filter_date: "future",
          interests_tags: Array.from(interestsCategories).join(","),
          interests_subtags: Array.from(interestsTags).join(","),
          lat: preferredLoc.lat,
          lng: preferredLoc.lng,
          order_by: "smart_extended",
        },
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length < SCROLL_STEP) return;
        else return pages.flatMap((page) => page.data).length;
      },
      refetchOnWindowFocus: false,
    }
  );

  const loadMore = async (e: any) => {
    await fetchNextPage();
    e.target.complete();
  };

  return (
    <IonPage className="page-homepage">
      <IonContent
        className="padding-base_notch-top"
        fullscreen
        scrollEvents={true}
        onIonScroll={handleScroll}
        ref={contentEle}
      >
        <HeaderMap />
        {/* <Swiper
          slidesPerView={"auto"}
          initialSlide={0}
          speed={400}
          className="hp-slides-boxes"
        >
          <SwiperSlide>
            <div className="slides-box purple">
              <div className="icon">
                <img src="/assets/icon/new/mw.svg" alt="icon mw" />
              </div>
              <div className="title">Trending events</div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slides-box blue">
              <div className="icon">
                <img src="/assets/icon/new/map.svg" alt="icon map" />
              </div>
              <div className="title">My plans</div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slides-box pink">
              <div className="icon">
                <img src="/assets/icon/new/social.svg" alt="icon social" />
              </div>
              <div className="title">My plans</div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slides-box red">
              <div className="icon">
                <img src="/assets/icon/new/mw.svg" alt="icon mw" />
              </div>
              <div className="title">My plans</div>
            </div>
          </SwiperSlide>
        </Swiper> */}

        {/* <HeaderMap /> */}

        {isLoadingRecommended ? (
          <SkeletonHomePage />
        ) : recommendedEvents?.length ? (
          <EventsSlides
            headerText="Your interests"
            slug="recommended"
            events={recommendedEvents}
            source={"homepage_slides_recommended"}
          />
        ) : null}

        {/* {isLoadingWeek ? (
          <SkeletonHomePage />
        ) : weekEvents?.length ? (
          <EventsSlides
            headerText="This week"
            slug="week"
            events={weekEvents}
            source={"homepage_slides_week"}
          />
        ) : null} */}

        {/* {isLoadingFollowingPlacesEvents ? (
          <SkeletonHomePage />
        ) : followingPlacesEvents?.length ? (
          <EventsSlides
            headerText="From places you follow"
            slug="following_places_events"
            events={followingPlacesEvents}
            source={"homepage_slides_following_places"}
          />
        ) : null} */}

        {fetchedEvents.map((events, i) =>
          events.isLoading || isLoadingRecommended /*|| isLoadingWeek*/ ? (
            // || isLoadingFollowingPlacesEvents
            <SkeletonHomePage key={slugsAndNames[i].slug} />
          ) : events.data?.data.length ? (
            <EventsSlides
              headerText={slugsAndNames[i].nam}
              slug={slugsAndNames[i].slug}
              events={events.data?.data}
              key={slugsAndNames[i].slug}
              source={"homepage_slides_category"}
            />
          ) : null
        )}

        <div className="container margin-top">
          <h4 className="header">{t("homepage.events_other")}</h4>
        </div>

        <IonList lines="none" className="events_list">
          {isLoading && <SkeletonEventList size="big" />}
          {displayEvents?.pages
            .flatMap((page) => page.data)
            .map((event, i) => (
              <EventItem
                key={event.eventId}
                event={event}
                // duplicate_event={duplicate_event}
                // actions={true}
                order={i + 1}
                source={"search_list"}
              />
            ))}
        </IonList>
        <IonInfiniteScroll onIonInfinite={loadMore} threshold="600px">
          <IonInfiniteScrollContent loadingSpinner="dots" />
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
