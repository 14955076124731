import { isPlatform } from "@ionic/react";

let api_url = "";
let web_url = "";
let web_url_tickets = "";
let developer = "";
// let device = "default";
let device: "mobile" | "desktop" | "default" = "mobile";
// let device = "desktop";

console.log("ENV");
console.log(process.env.NODE_ENV);
console.log(window.location.hostname);

const isDevelopment = process.env.NODE_ENV === "development";

// API url for local development and production
if (isDevelopment) {
  const env = require("./.env.development");
  developer = env.DEVELOPER;
  device = env.DEVICE ? env.DEVICE : "default";
  api_url = env.API_URL
    ? env.API_URL
    : `http://${window.location.hostname}:8080`;
  web_url = env.WEB_URL ? env.WEB_URL : `https://m-beta.manyways.info`;
  web_url_tickets = env.WEB_URL_TICKETS
    ? env.WEB_URL_TICKETS
    : `https://m-beta.manyways.info`;
} else {
  const env = require("./.env");
  const env_device = require("./.env.device");
  device = env_device.DEVICE ? env_device.DEVICE : "default";
  api_url = env.API_URL ? env.API_URL : `https://api.manyways.info`;
  web_url = env.WEB_URL ? env.WEB_URL : `https://m.manyways.info`;
  web_url_tickets = env.WEB_URL_TICKETS
    ? env.WEB_URL_TICKETS
    : `https://tickets.manyways.info`;
}

const forcedTheme: "light" | "dark" | null = "light";

const isMobileDevelopment =
  isDevelopment && window.location.hostname !== "localhost";

// public keys for app notifications via One Signal
const one_signal_app_id = "3aa3d07f-8606-4f1c-9064-64010cf39831";
// Android requires also this ID for notifications
const firebase_sender_id = "569035896225";

const google_maps_api_key = "AIzaSyDk1KiDgDQZR96Tr41QXfc0DdmW9U9pCzs";

export const firebaseConfig = {
  apiKey: "AIzaSyDk1KiDgDQZR96Tr41QXfc0DdmW9U9pCzs",
  authDomain: "manyways-prod.firebaseapp.com",
  projectId: "manyways-prod",
  storageBucket: "manyways-prod.appspot.com",
  messagingSenderId: "569035896225",
  appId: "1:569035896225:web:c3b5b6d194c32577e887e0",
  measurementId: "G-NVDQLCV5VV",
};

export const smartlookApiKey = "a6f0096567a6e11b3bf9f618c3297865f3a23961";

// const rollbar_access_token = "9de3bf7db32145698c1b3bbeb9290a9d";
const rollbar_access_token = "";

const rollbarConfig = {
  accessToken: rollbar_access_token,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.NODE_ENV,
  disabled: isDevelopment,
  payload: {
    client: {
      javascript: {
        code_version: "1.0.0",
        source_map_enabled: true,
      },
    },
    extra: {
      developer: developer,
    },
  },
};

const auth0_domain = "dev-ri7i8tb9.us.auth0.com";
const auth0_client_id = "jUXJuKfpGBCE1tbucJfJY3vuP3Ja6W0B";
const appId = "info.manyways.main";

// Use `auth0Domain` in string interpolation below so that it doesn't
// get replaced by the quickstart auto-packager
const auth0Domain = auth0_domain;
const isNative = !isPlatform("desktop") && !isPlatform("mobileweb");
const isCapacitor = isPlatform("cordova") || isPlatform("capacitor");

const auth0_callback_uri = isNative
  ? `${appId}://${auth0Domain}/capacitor/${appId}/callback`
  : `${window.location.origin}/#/onboarding`;

const default_locations = {
  praha: {
    name: "Praha",
    lat: 50.075638,
    lng: 14.4379,
  },
  new_york: {
    name: "New York",
    lat: 40.757969,
    lng: -73.985544,
  },
};

const appsFlyerKeys = {
  appID: "id1569619891",
  devKey: "b3TvfAUPywkb5CBhqyhggE",
};

const stripePublishableKey = isDevelopment
  ? "pk_test_51OFcskFd3KazgU1d7Ky2JcWzH8v2T80njcPCuBa3VOO6H6RJFcik7370jWUQCibquinX5zvvdQBQTGGm7GnF0O2000OyBFhjoL"
  : "pk_live_51OFcskFd3KazgU1d7QBO8K8s2q5HB21tQy72dRWx4bpE59EARaUKBMkbhAnZJOnVd50zh37EP4Lpv05ce3uUOHEG00aIzeufEX";

export {
  api_url,
  web_url,
  web_url_tickets,
  device,
  one_signal_app_id,
  firebase_sender_id,
  rollbarConfig,
  auth0_domain,
  auth0_client_id,
  auth0_callback_uri,
  isDevelopment,
  isMobileDevelopment,
  isNative,
  isCapacitor,
  forcedTheme,
  google_maps_api_key,
  default_locations,
  appsFlyerKeys,
  stripePublishableKey,
};
