import { useTranslation } from "react-i18next"


const useTranlatedMsg = ()=>{
    const {t} = useTranslation()
  
    const joined_event = t("messages.joined_event")
    const went_wrong = t("messages.went_wrong")
    const access_denied = t("messages.access_denied")
    const failed_photo = t("messages.failed_photo")
    const fetch_noFriends = t("messages.fetch_noFriends")
    const fail_crt_event = t("messages.fail_crt_event")
    const err_crt_event = t("messages.err_crt_event")
    const interested_event = t("messages.interested_event")
    const gps_reject = t("messages.gps_reject")
    const err_inviting = t("messages.err_inviting")
    const err_crt_group = t("messages.err_crt_group")
    const fail_picture = t("messages.fail_picture")
    const fail_size_picture = t("messages.fail_size_picture")
    const gallery = t("messages.gallery")
    const downloaded = t("message.downloaded")
    const fail_download = t("message.fail_download")
    const no_access = t("message.no_access")
    const invit_sent = t("message.invit_sent")
    const text_copied = t("message.text_copied")
    const err_while_upload = t("message.err_while_upload")
    const err_update_event = t("message.err_update_event")
    const err_edit_group = t("message.err_edit_group")
    const not_interested = t("message.not_interested")
    const left_event = t("message.left_event")
    const adress_copied = t("message.adress_copied")
    const date_copied = t("message.date_copied")
    const feedback_sent = t("message.feedback_sent")
    const err_feedback = t("message.err_feedback")
    const err_cities_load = t("messages.err_cities_load")
    const err_register = t("messages.err_register")
    const err_send_report = t("messages.err_send_report")
    const err_load_users = t("messages.err_load_users")
    const err_connection = t("messages.err_connection")
    const err_login = t("messages.err_login")
    const err_msg_load = t("messages.err_msg_load")
    const err_load_profile = t("messages.err_load_profile")
    const err_updt_setting = t("messages.err_updt_setting")
    const err_updt_name = t("messages.err_updt_name")
    const err_updt_photo = t("messages.err_updt_photo")
    const link_copied = t("messages.link_copied")
    const err_load_fut_events = t("messages.err_load_fut_events")
    const err_load_events = t("messages.err_load_events")
    const user_reported = t("messages.user_reported")
    const admin_leave = t("messages.admin_leave")
    const err_load_notif = t("messages.err_load_notif")
    const err_past_eve = t("messages.err_past_eve")
    const alert_app_cls_op = t("messages.alert_app_cls_op")
  
    return {
      joined_event,
      alert_app_cls_op,
      err_past_eve,
      err_load_notif,
      admin_leave,
      user_reported,
      err_load_events,
      err_load_fut_events,
      link_copied,
      err_updt_photo,
      err_updt_name,
      err_updt_setting,
      err_load_profile,
      err_msg_load,
      err_login,
      err_connection,
      err_load_users,
      err_send_report,
      err_register,
      err_cities_load,
      err_feedback,
      feedback_sent,
      adress_copied,
      date_copied,
      left_event,
      not_interested,
      err_update_event,
      err_edit_group,
      err_while_upload,
      text_copied,
      invit_sent,
      no_access,
      gallery,
      downloaded,
      fail_download,      
      fail_picture,
      fail_size_picture,
      went_wrong,
      err_crt_group,
      access_denied,
      failed_photo,
      fetch_noFriends,
      interested_event,
      fail_crt_event,
      gps_reject,
      err_inviting,
      err_crt_event}
  }

export default useTranlatedMsg