import { IonPopover, IonContent, IonList, IonItem } from "@ionic/react";
import { useState } from "react";
import { useNavigation } from "../hooks";
import { AccountBenefits } from "./UnsignedUser";

export const PopoverActionButtons: React.FC<{
  popoverState: {
    showPopover: boolean;
    event: undefined;
  };
  setShowPopover: React.Dispatch<
    React.SetStateAction<{
      showPopover: boolean;
      event: undefined;
    }>
  >;
}> = ({ popoverState, setShowPopover }) => {
  const { navigate } = useNavigation();
  const [showBenefits, setShowBenefits] = useState<boolean>(false);
  return (
    <>
      <IonPopover
        isOpen={popoverState.showPopover}
        event={popoverState.event}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
        className="popover_options"
      >
        <IonContent scrollY={false}>
          <IonList lines="full">
            <IonItem className="header">
              <strong>Account required</strong>
            </IonItem>
            <IonItem
              type="button"
              onClick={() => {
                setShowBenefits(true);
                setShowPopover({ showPopover: false, event: undefined });
              }}
            >
              Account benefits
            </IonItem>
            <IonItem
              type="button"
              onClick={() => {
                navigate("/registration");
                setShowBenefits(false);
                setShowPopover({ showPopover: false, event: undefined });
              }}
            >
              Register
            </IonItem>
            <IonItem
              type="button"
              onClick={() => {
                navigate("/login");
                setShowBenefits(false);
                setShowPopover({ showPopover: false, event: undefined });
              }}
            >
              Login
            </IonItem>
          </IonList>
        </IonContent>
      </IonPopover>
      <AccountBenefits
        showBenefits={showBenefits}
        setShowBenefits={setShowBenefits}
      />
    </>
  );
};
