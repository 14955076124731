import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { useRollbar } from "@rollbar/react";
import { AxiosError, AxiosResponse } from "axios";
import { useCallback } from "react";
import { useMutation, useQuery } from "react-query";
import { useStorage } from ".";
import { useAuth0Logout } from "../components/auth0";
import { logEvent } from "../helpers/logging";
import { eqSet, sets_to_array } from "../helpers/sets";

import type { Settings, User, UserName } from "../models/User";
import useAxios from "./useAxios";
import { isCapacitor } from "../config";
import useTranlatedMsg from "./useTranslatedMsg";

const useProfile = () => {
  const {
    auth,
    setCurrentUser,
    setMessage,
    interestsCategories,
    interestsTags,
    setInterestsCategories,
    setInterestsTags,
    setSeenOnboarding,
  } = useStorage();
  const { axios } = useAxios();
  const { auth0Logout } = useAuth0Logout();
  const rollbar = useRollbar();
  const {err_load_profile,err_updt_photo,err_updt_name,err_updt_setting,} = useTranlatedMsg()

  const getProfile = useQuery<AxiosResponse<User>, AxiosError, User>(
    ["get_profile"],
    () => axios.get<User>("/profile"),
    {
      select: (res) => res.data,
      onSuccess: (user) => {
        setCurrentUser(user);
        rollbar.configure({ payload: { person: { id: user?.userId } } });
        if (isCapacitor)
          FirebaseAnalytics.setUserId({
            userId: user?.userId?.toString(),
          }).then(() => logEvent("profile_load", {}));
        // this shouldn't be needed, but putting it here just to be sure.
        // We don't want to be loggen in Auth0 whole time, only for our initial login
        // We already call logout in auth0 component after successful /auth0 api call
        auth0Logout();
        if (user.interests.length > 0) {
          let tmpInterestCategories: string[] = [];
          let tmpInterestTags: string[] = [];

          setSeenOnboarding(true);
          user.interests.map((int) =>
            int.level === 1
              ? tmpInterestCategories.push(int.slug)
              : tmpInterestTags.push(int.slug)
          );
          if (
            !eqSet(interestsCategories, new Set(tmpInterestCategories)) ||
            !eqSet(interestsTags, new Set(tmpInterestTags))
          ) {
            setInterestsCategories(new Set(tmpInterestCategories));
            setInterestsTags(new Set(tmpInterestTags));
          }
        } else if (interestsCategories.size) {
          syncInterests({
            tags: sets_to_array(interestsCategories, interestsTags),
          });
        }
      },
      onError: ({ response }) => {
        if (auth.accessToken) {
          setMessage("danger", err_load_profile, response);
        }
        // this shouldn't be needed, but putting it here just to be sure.
        // We don't want to be loggen in Auth0 whole time, only for our initial login
        // We already call logout in auth0 component after successful /auth0 api call
        auth0Logout();
      },
      // TODO: Maybe set this as default and then set retry: true explicitly
      // when needed (eg. for public routes)
      retry: (failureCount, error) => {
        return error.message.includes("403") ? false : true;
      },
      enabled: false,
    }
  );

  const refetchProfile = () => {
    setTimeout(() => getProfile.refetch(), 100);
  };

  const updateSettings = useMutation<never, AxiosError, Settings>(
    (settings) => axios.put("/profile/settings", settings),
    {
      onSuccess: refetchProfile,
      onError: () => {
        setMessage("danger", err_updt_setting);
      },
    }
  );
  const updateName = useMutation<never, AxiosError, UserName>(
    (newName) => axios.put("/profile", newName),
    {
      onSuccess: refetchProfile,
      onError: () => {
        setMessage("danger", err_updt_name);
      },
    }
  );
  const postPhoto = useMutation<never, AxiosError, FormData>(
    (payload) =>
      axios.post("/profile/photo", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    {
      onSuccess: refetchProfile,
      onError: () => {
        setMessage("danger", err_updt_photo);
      },
    }
  );

  const uploadPhoto = useCallback((file: Blob) => {
    const formData = new FormData();
    formData.append("file", file);
    postPhoto.mutate(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: syncInterests } = useMutation<
    AxiosResponse<unknown>,
    AxiosError,
    { tags: string[] }
  >((payload: { tags: string[] }) => axios.put("/profile/interests", payload), {
    onSuccess: () => getProfile.refetch(),
  });

  return {
    updateSettings,
    updateName,
    uploadPhoto,
    syncInterests,
    getProfile,
  };
};

export default useProfile;
