import { useEffect, useContext } from "react";
import { AxiosResponse } from "axios";
import { axios } from "../helpers/axios";
import { Auth } from "../models";
import { StateStorage } from "../contexts/StateStorage";

const useAxios = () => {
  const { auth, setAuth, setCurrentUser } = useContext(StateStorage);

  useEffect(() => {
    let requestIntercept: number | null = null;
    let responseIntercept: number | null = null;

    if (auth.accessToken) {
      requestIntercept = axios.interceptors.request.use(
        ({ headers, ...rest }) => {
          if (headers && !headers["Authorization"]) {
            headers["Authorization"] = `Bearer ${auth?.accessToken}`;
          }

          return { ...rest, headers };
        },
        (error) => Promise.reject(error)
      );
    }

    if (auth.refreshToken) {
      responseIntercept = axios.interceptors.response.use(
        (response) => response,
        async (error) => {
          const prevRequest = error?.config;
          if (
            error?.response?.status === 403 &&
            !prevRequest?.sent &&
            auth.refreshToken
          ) {
            prevRequest.sent = true;
            const tokens: AxiosResponse<Auth> | void = await axios({
              url: "/login/refresh",
              method: "POST",
              data: { refreshToken: auth.refreshToken },
            }).catch((err) => {
              console.error("Refresh token fail", err);
            });

            if (tokens?.data.accessToken && tokens.data.refreshToken) {
              setAuth(tokens.data);
              prevRequest.headers[
                "Authorization"
              ] = `Bearer ${tokens.data.accessToken}`;
              return axios(prevRequest);
            } else {
              setAuth(null);
              setCurrentUser(null);
            }
          }
          return Promise.reject(error);
        }
      );
    }

    return () => {
      if (requestIntercept) axios.interceptors.request.eject(requestIntercept);
      if (responseIntercept)
        axios.interceptors.response.eject(responseIntercept);
    };
  }, [auth, setAuth, setCurrentUser]);

  useEffect(() => {
    let requestIntercept: number | null = null;

    requestIntercept = axios.interceptors.request.use(
      ({ headers, ...rest }) => {
        const token = localStorage.getItem("token");
        if (headers && !headers["TokenMW"] && token) {
          headers["TokenMW"] = token ?? "";
        }

        return { ...rest, headers };
      },
      (error) => Promise.reject(error)
    );

    return () => {
      if (requestIntercept) axios.interceptors.request.eject(requestIntercept);
    };
  }, []);

  return { axios };
};

export default useAxios;
