import { econify } from "./iconsOutline";

export const forumFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M5.4 30.75V6.85q0-.65.55-1.25t1.2-.6h24.9q.65 0 1.225.575.575.575.575 1.275V23.6q0 .65-.575 1.25t-1.225.6h-19.6l-5.8 5.8q-.35.4-.8.2-.45-.2-.45-.7Zm8.85 3.5q-.65 0-1.225-.6-.575-.6-.575-1.25v-3.65H36.5l.6.6V12h3.75q.65 0 1.2.6.55.6.55 1.3v25.6q0 .5-.45.7-.45.2-.8-.2l-5.8-5.75Z"/></svg>`
);
export const notificationsFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M9.65 37.75q-.5 0-.825-.325Q8.5 37.1 8.5 36.6q0-.5.325-.825.325-.325.825-.325h3V20q0-3.95 2.475-7.2t6.525-4V7.35q0-1 .675-1.625T24 5.1q1 0 1.675.625t.675 1.625V8.8q4.05.75 6.55 4t2.5 7.2v15.45h2.95q.5 0 .825.325.325.325.325.825 0 .5-.325.825-.325.325-.825.325ZM24 43.6q-1.45 0-2.525-1.05T20.4 40h7.2q0 1.5-1.05 2.55Q25.5 43.6 24 43.6Z"/></svg>`
);
export const personFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 23.35q-2.9 0-4.775-1.875Q17.35 19.6 17.35 16.7q0-2.9 1.875-4.75T24 10.1q2.9 0 4.775 1.85 1.875 1.85 1.875 4.75t-1.875 4.775Q26.9 23.35 24 23.35ZM11.3 38.6q-.95 0-1.625-.675T9 36.3v-1.5q0-1.6.85-2.8.85-1.2 2.2-1.85 3.2-1.4 6.125-2.1 2.925-.7 5.825-.7 2.9 0 5.825.725 2.925.725 6.075 2.075 1.4.65 2.25 1.85.85 1.2.85 2.8v1.5q0 .95-.675 1.625T36.7 38.6Z"/></svg>`
);
export const mapFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m29.5 40.6-11.9-4.2-8.35 3.3q-.75.4-1.5-.05T7 38.25V12.1q0-.55.325-1.025.325-.475.875-.675l8.45-3q.45-.1.95-.1.5 0 .95.1l11.9 4.15 8.3-3.25q.75-.4 1.5.025Q41 8.75 41 9.6v26.6q0 .5-.35.9t-.85.55l-8.4 2.95q-.45.15-.95.125-.5-.025-.95-.125Zm-.35-2.5V13.35l-10.3-3.5V34.6Z"/></svg>`
);
export const barChartFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M11.65 39.5q-1.35 0-2.25-.925-.9-.925-.9-2.225v-14.7q0-1.3.925-2.225t2.225-.925q1.3 0 2.2.925.9.925.9 2.225v14.7q0 1.3-.9 2.225t-2.2.925Zm12.35 0q-1.3 0-2.225-.925t-.925-2.225v-24.7q0-1.3.925-2.225T24 8.5q1.3 0 2.225.925t.925 2.225v24.7q0 1.3-.925 2.225T24 39.5Zm12.35 0q-1.3 0-2.2-.925-.9-.925-.9-2.225v-6.7q0-1.3.9-2.225t2.2-.925q1.35 0 2.25.925.9.925.9 2.225v6.7q0 1.3-.925 2.225t-2.225.925Z"/></svg>`
);
export const calendarMonthFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 28.15q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.275.5-.525 1.25-.525t1.25.525q.5.525.5 1.275 0 .7-.5 1.225-.5.525-1.25.525Zm-8 0q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.275.5-.525 1.25-.525t1.25.525q.5.525.5 1.275 0 .7-.5 1.225-.5.525-1.25.525Zm16 0q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.275.5-.525 1.25-.525t1.25.525q.5.525.5 1.275 0 .7-.5 1.225-.5.525-1.25.525ZM24 36q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.275.5-.525 1.25-.525t1.25.525q.5.525.5 1.275 0 .7-.5 1.225Q24.75 36 24 36Zm-8 0q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.275.5-.525 1.25-.525t1.25.525q.5.525.5 1.275 0 .7-.5 1.225Q16.75 36 16 36Zm16 0q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.275.5-.525 1.25-.525t1.25.525q.5.525.5 1.275 0 .7-.5 1.225Q32.75 36 32 36ZM9.9 43q-1.2 0-2.05-.85Q7 41.3 7 40.1V11.15q0-1.15.85-2.025T9.9 8.25h3.7V6q0-.5.35-.875t.85-.375q.55 0 .925.375T16.1 6v2.25H32V6q0-.55.325-.9t.875-.35q.5 0 .875.35t.375.9v2.25h3.65q1.2 0 2.05.875Q41 10 41 11.15V40.1q0 1.2-.85 2.05-.85.85-2.05.85Zm0-2.25h28.2q.3 0 .475-.175t.175-.475V20.25H9.25V40.1q0 .3.175.475t.475.175Z"/></svg>`
);
export const paymentsFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M26.75 26.15q2.1 0 3.55-1.45 1.45-1.45 1.45-3.55 0-2.1-1.45-3.55-1.45-1.45-3.55-1.45-2.1 0-3.55 1.45-1.45 1.45-1.45 3.55 0 2.1 1.45 3.55 1.45 1.45 3.55 1.45ZM12.1 33.1q-1.15 0-2-.875-.85-.875-.85-2.025V12.1q0-1.15.85-2 .85-.85 2-.85h29.3q1.2 0 2.05.85.85.85.85 2v18.1q0 1.15-.85 2.025t-2.05.875Zm-5.5 5.5q-1.2 0-2.05-.85-.85-.85-.85-2v-19.4q0-.5.325-.825.325-.325.825-.325.45 0 .775.325t.325.825v19.4q0 .2.2.4t.45.2h30.6q.5 0 .825.325.325.325.325.825 0 .5-.325.8-.325.3-.825.3Zm4.9-22.75q1.8 0 3.075-1.275Q15.85 13.3 15.85 11.5H12.1q-.25 0-.425.175t-.175.425Zm30.55 0V12.1q0-.25-.2-.425t-.45-.175h-3.7q0 1.8 1.275 3.075 1.275 1.275 3.075 1.275ZM12.1 30.8h3.75q0-1.8-1.275-3.075Q13.3 26.45 11.5 26.45v3.75q0 .25.175.425t.425.175Zm25.6 0h3.7q.25 0 .45-.175t.2-.425v-3.75q-1.8 0-3.075 1.275Q37.7 29 37.7 30.8Z"/></svg>`
);
export const lockFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M11.9 43q-1.2 0-2.05-.85Q9 41.3 9 40.1v-20q0-1.2.85-2.05.85-.85 2.05-.85h3.45v-4.55q0-3.6 2.525-6.125T24 4q3.6 0 6.125 2.525t2.525 6.125v4.55h3.45q1.2 0 2.05.85.85.85.85 2.05v20q0 1.2-.85 2.05-.85.85-2.05.85ZM24 33.5q1.4 0 2.4-.975t1-2.375q0-1.3-1-2.375T24 26.7q-1.4 0-2.4 1.075t-1 2.425q0 1.35 1 2.325 1 .975 2.4.975Zm-6.35-16.3h12.7v-4.55q0-2.65-1.85-4.525Q26.65 6.25 24 6.25t-4.5 1.875Q17.65 10 17.65 12.65Z"/></svg>`
);
export const lockOpenFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M11.9 43q-1.2 0-2.05-.85Q9 41.3 9 40.1v-20q0-1.2.85-2.05.85-.85 2.05-.85h18.45v-4.55q0-2.65-1.85-4.525Q26.65 6.25 24 6.25q-2.35 0-4.1 1.475-1.75 1.475-2.15 3.725-.1.55-.45.875-.35.325-.8.325-.5 0-.825-.35-.325-.35-.225-.8.4-3.2 2.8-5.35Q20.65 4 24 4q3.6 0 6.125 2.525t2.525 6.125v4.55h3.45q1.2 0 2.05.85.85.85.85 2.05v20q0 1.2-.85 2.05-.85.85-2.05.85ZM24 33.5q1.4 0 2.4-.975t1-2.375q0-1.3-1-2.375T24 26.7q-1.4 0-2.4 1.075t-1 2.425q0 1.35 1 2.325 1 .975 2.4.975Z"/></svg>`
);
export const groupFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M4.95 38.6q-.6 0-1.025-.4-.425-.4-.425-1.05V34.8q0-1.55.825-2.775Q5.15 30.8 6.6 30.15q3.25-1.4 5.925-2.1 2.675-.7 5.975-.7 3.35 0 6 .7t5.9 2.1q1.45.65 2.275 1.875T33.5 34.8v2.35q0 .65-.4 1.05t-1.05.4Zm30.95 0q.4-.15.65-.525t.25-.975v-2.2q0-2.7-1.25-4.475Q34.3 28.65 32.3 27.5q2.65.4 5.05 1.05t4 1.5q1.45.8 2.3 2.05.85 1.25.85 2.8v2.25q0 .65-.425 1.05t-1.025.4ZM18.5 23.35q-2.85 0-4.725-1.875Q11.9 19.6 11.9 16.7q0-2.9 1.875-4.75T18.5 10.1q2.9 0 4.775 1.85 1.875 1.85 1.875 4.75t-1.875 4.775Q21.4 23.35 18.5 23.35Zm15.8-6.65q0 2.9-1.85 4.775-1.85 1.875-4.75 1.875-.35 0-.725-.05t-.725-.15q1.05-1.2 1.6-2.825.55-1.625.55-3.625 0-1.95-.575-3.5t-1.575-2.9q.3-.1.7-.15.4-.05.75-.05 2.9 0 4.75 1.85t1.85 4.75Z"/></svg>`
);
export const sentimentFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M31.05 21.15q1 0 1.675-.675t.675-1.725q0-1.05-.675-1.725t-1.675-.675q-1.05 0-1.725.675t-.675 1.725q0 1.05.675 1.725t1.725.675Zm-14.1 0q1.05 0 1.725-.675t.675-1.725q0-1.05-.675-1.725t-1.725-.675q-1 0-1.675.675T14.6 18.75q0 1.05.675 1.725t1.675.675ZM24 34.35q2.6 0 4.925-1.2 2.325-1.2 3.775-3.45.3-.5.075-.975-.225-.475-.825-.475-.25 0-.5.15t-.4.35q-1.2 1.7-3.025 2.65T24 32.35q-2.15 0-4-.925T17 28.8q-.15-.25-.4-.4-.25-.15-.5-.15-.55 0-.825.475Q15 29.2 15.4 29.8q1.4 2.15 3.7 3.35 2.3 1.2 4.9 1.2ZM24 43q-3.95 0-7.4-1.5t-6.025-4.075Q8 34.85 6.5 31.4T5 24q0-3.95 1.5-7.425Q8 13.1 10.575 10.55 13.15 8 16.6 6.5T24 5q3.95 0 7.425 1.5Q34.9 8 37.45 10.55 40 13.1 41.5 16.575 43 20.05 43 24q0 3.95-1.5 7.4t-4.05 6.025Q34.9 40 31.425 41.5 27.95 43 24 43Z"/></svg>`
);
export const imageFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M14.85 33.6h18.5q.5 0 .7-.4.2-.4-.1-.8l-5-6.65q-.25-.3-.6-.3t-.55.3l-5.5 6.95-3.5-4.6q-.25-.3-.6-.3t-.55.3l-3.35 4.3q-.25.4-.075.8t.625.4ZM9.9 41q-1.2 0-2.05-.85Q7 39.3 7 38.1V9.9q0-1.2.85-2.05Q8.7 7 9.9 7h28.2q1.2 0 2.05.85.85.85.85 2.05v28.2q0 1.2-.85 2.05-.85.85-2.05.85Z"/></svg>`
);
export const sendFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M9.05 37.6q-.75.3-1.4-.1Q7 37.1 7 36.35V28.3q0-.55.325-.925t.825-.475L20.2 24 8.15 21.05q-.5-.15-.825-.525Q7 20.15 7 19.65v-8q0-.75.65-1.15.65-.4 1.4-.15l29.2 12.3q.85.4.85 1.375t-.85 1.325Z"/></svg>`
);
export const contentCopyFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M16.35 37.05q-1.2 0-2.075-.85-.875-.85-.875-2.05V8.45q0-1.2.875-2.025T16.35 5.6H36q1.15 0 2.025.85t.875 2v25.7q0 1.2-.875 2.05-.875.85-2.025.85Zm-5.6 5.55q-1.15 0-2-.875-.85-.875-.85-2.025V13.9q0-.5.325-.825.325-.325.825-.325.45 0 .775.325t.325.825v25.8q0 .25.2.425t.4.175h19.5q.5 0 .825.325.325.325.325.825 0 .5-.325.825-.325.325-.825.325Z"/></svg>`
);
export const trashFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M13.9 41q-1.15 0-2-.85-.85-.85-.85-2.05V10.9h-.9q-.5 0-.825-.325Q9 10.25 9 9.75q0-.5.325-.8.325-.3.825-.3h7.4q0-.65.425-1.025Q18.4 7.25 19 7.25h10q.6 0 1.025.4.425.4.425 1h7.4q.5 0 .825.325Q39 9.3 39 9.8q0 .45-.325.775t-.825.325h-.9v27.2q0 1.2-.85 2.05-.85.85-2 .85Zm5.15-7.65q0 .5.325.825.325.325.825.325.5 0 .825-.325.325-.325.325-.825V16.2q0-.45-.35-.775t-.8-.325q-.5 0-.825.325-.325.325-.325.775Zm7.6 0q0 .5.35.825.35.325.8.325.5 0 .825-.325.325-.325.325-.825V16.2q0-.45-.325-.775T27.8 15.1q-.5 0-.825.325-.325.325-.325.775Z"/></svg>`
);
export const checkCircleFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m21.05 29-5.1-5.1q-.3-.35-.8-.35t-.85.35q-.4.4-.4.9t.35.85l5.8 5.75q.4.45 1 .45t1-.45L33.7 19.8q.35-.35.35-.85t-.4-.85q-.35-.35-.85-.35t-.9.35ZM24 43q-4 0-7.475-1.45-3.475-1.45-6.05-4.025Q7.9 34.95 6.45 31.475 5 28 5 24q0-3.95 1.45-7.425 1.45-3.475 4.025-6.05 2.575-2.575 6.05-4.05Q20 5 24 5q3.95 0 7.425 1.475 3.475 1.475 6.05 4.05 2.575 2.575 4.05 6.025Q43 20 43 24t-1.475 7.475q-1.475 3.475-4.05 6.05Q34.9 40.1 31.45 41.55 28 43 24 43Z"/></svg>`
);
export const starFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m24 35.4-8.5 5.1q-.35.2-.675.175-.325-.025-.625-.225-.25-.2-.375-.5t-.025-.7L16 29.6l-7.45-6.55q-.35-.25-.425-.575-.075-.325.025-.675.1-.3.375-.5t.625-.25l9.9-.85 3.9-9.15q.15-.35.45-.525.3-.175.6-.175t.6.175q.3.175.45.525l3.9 9.15 9.9.85q.35.05.625.25t.375.5q.1.35.025.675-.075.325-.425.575L32 29.6l2.25 9.65q.05.4-.075.7-.125.3-.375.5-.3.2-.625.225-.325.025-.675-.175Z"/></svg>`
);
export const heartMinusFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M29.75 27.15v-2.3h15v2.3ZM22 40.65l-4.35-3.9q-4.05-3.7-6.85-6.475Q8 27.5 6.25 25.2q-1.75-2.3-2.5-4.325Q3 18.85 3 16.7q0-4.25 2.875-7.1Q8.75 6.75 13 6.75q2.6 0 4.95 1.2t4.05 3.5q1.7-2.3 4.05-3.5 2.35-1.2 4.95-1.2 3.95 0 6.625 2.45t3.175 5.9q-.8-.35-1.675-.5-.875-.15-1.825-.15-4.8 0-8.175 3.35-3.375 3.35-3.375 8.2 0 2.35.9 4.475.9 2.125 2.55 3.675-1 .95-2.4 2.175-1.4 1.225-2.6 2.325Z"/></svg>`
);
export const favoriteFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m21.95 40.2-2.65-2.45Q13.1 32 8.55 26.775T4 15.85q0-4.5 3.025-7.525Q10.05 5.3 14.5 5.3q2.55 0 5.05 1.225T24 10.55q2.2-2.8 4.55-4.025Q30.9 5.3 33.5 5.3q4.45 0 7.475 3.025Q44 11.35 44 15.85q0 5.7-4.55 10.925Q34.9 32 28.7 37.75l-2.65 2.45q-.85.8-2.05.8-1.2 0-2.05-.8Z"/></svg>`
);
export const locationOnFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 23.15q1.3 0 2.225-.925T27.15 20q0-1.3-.925-2.225T24 16.85q-1.3 0-2.225.925T20.85 20q0 1.3.925 2.225T24 23.15Zm.05 19.9q-.35 0-.625-.1t-.475-.3q-7.3-6.45-10.875-12.025T8.5 20.4q0-7.25 4.675-11.575Q17.85 4.5 24 4.5q6.15 0 10.825 4.325Q39.5 13.15 39.5 20.4q0 4.65-3.575 10.225Q32.35 36.2 25.05 42.65q-.2.2-.45.3-.25.1-.55.1Z"/></svg>`
);
export const nearMeFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M21.15 26.8 8.8 21.95q-.4-.1-.625-.475-.225-.375-.225-.775t.25-.75q.25-.35.65-.5l28.9-10.9q.35-.15.725-.05.375.1.625.35.3.3.4.65.1.35-.1.75L28.45 39.1q-.15.45-.5.675-.35.225-.75.225t-.75-.225q-.35-.225-.5-.675Z"/></svg>`
);
export const shareFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M36.25 43.5q-2.2 0-3.725-1.55T31 38.2q0-.35.075-.85t.225-.85l-15.6-9.1q-.7.85-1.75 1.375t-2.15.525q-2.2 0-3.75-1.55Q6.5 26.2 6.5 24t1.55-3.75Q9.6 18.7 11.8 18.7q1.1 0 2.125.475T15.7 20.5l15.6-9q-.15-.4-.225-.85Q31 10.2 31 9.8q0-2.2 1.525-3.75Q34.05 4.5 36.25 4.5T40 6.05q1.55 1.55 1.55 3.7 0 2.25-1.55 3.775t-3.75 1.525q-1.15 0-2.175-.4T32.35 13.4L16.8 22.2q.1.4.175.9.075.5.075.9t-.075.825q-.075.425-.175.825l15.55 8.9q.7-.7 1.675-1.15.975-.45 2.225-.45 2.2 0 3.75 1.525Q41.55 36 41.55 38.2T40 41.95q-1.55 1.55-3.75 1.55Z"/></svg>`
);
export const calendarTodayFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M9.9 43q-1.2 0-2.05-.85Q7 41.3 7 40.1V11.15q0-1.15.85-2.025T9.9 8.25h3.7V6q0-.5.35-.875t.85-.375q.55 0 .925.375T16.1 6v2.25H32v-2.3q0-.5.35-.85t.85-.35q.55 0 .9.35t.35.9v2.25h3.65q1.2 0 2.05.875Q41 10 41 11.15V40.1q0 1.2-.85 2.05-.85.85-2.05.85Zm0-2.25h28.2q.3 0 .475-.175t.175-.475V20.25H9.25V40.1q0 .3.175.475t.475.175Z"/></svg>`
);
export const confirmationNumberFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M7.25 39q-.95 0-1.6-.65Q5 37.7 5 36.75V29.8q1.85-.65 3.025-2.2Q9.2 26.05 9.2 24t-1.175-3.625Q6.85 18.8 5 18.2v-6.95q0-.95.65-1.6Q6.3 9 7.25 9h33.5q.95 0 1.6.65.65.65.65 1.6v6.95q-1.85.6-3.025 2.175Q38.8 21.95 38.8 24t1.175 3.6Q41.15 29.15 43 29.8v6.95q0 .95-.65 1.6-.65.65-1.6.65ZM24 33.2q.45 0 .8-.325.35-.325.35-.825 0-.45-.35-.775t-.8-.325q-.45 0-.8.325-.35.325-.35.775 0 .5.35.825.35.325.8.325Zm0-8.05q.45 0 .8-.35.35-.35.35-.8 0-.45-.35-.8-.35-.35-.8-.35-.45 0-.8.35-.35.35-.35.8 0 .45.35.8.35.35.8.35Zm0-8.1q.45 0 .8-.325.35-.325.35-.775 0-.5-.35-.825-.35-.325-.8-.325-.45 0-.8.325-.35.325-.35.825 0 .45.35.775t.8.325Z"/></svg>`
);
export const callFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M38.75 40.9q-5.6 0-11.25-2.875T17.3 30.6q-4.55-4.55-7.4-10.175Q7.05 14.8 7.05 9.2q0-.9.625-1.55T9.25 7h5.7q.8 0 1.35.5t.75 1.35l1.2 5.35q.1.75-.05 1.35-.15.6-.6 1l-4.7 4.6q2.85 4.8 6.375 8.275Q22.8 32.9 27.35 35.4l4.5-4.7q.5-.55 1.125-.775.625-.225 1.325-.075l4.85 1.1q.8.15 1.3.75t.5 1.45v5.5q0 .95-.625 1.6-.625.65-1.575.65Z"/></svg>`
);
export const visibilityFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 30.8q3.25 0 5.525-2.3Q31.8 26.2 31.8 23q0-3.25-2.3-5.525Q27.2 15.2 24 15.2q-3.25 0-5.525 2.3Q16.2 19.8 16.2 23q0 3.25 2.3 5.525Q20.8 30.8 24 30.8Zm0-2.25q-2.35 0-3.95-1.625T18.45 23q0-2.35 1.625-3.95T24 17.45q2.35 0 3.95 1.625T29.55 23q0 2.35-1.625 3.95T24 28.55ZM24 37q-6.55 0-11.95-3.5-5.4-3.5-8.4-9.35-.15-.2-.2-.5-.05-.3-.05-.65 0-.35.05-.65.05-.3.2-.5 3-5.85 8.4-9.35T24 9q6.55 0 11.95 3.5 5.4 3.5 8.4 9.35.15.2.2.5.05.3.05.65 0 .35-.05.65-.05.3-.2.5-3 5.85-8.4 9.35T24 37Z"/></svg>`
);
export const visibilityOffFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M37.35 32.65 31 26.3q.35-.65.575-1.5.225-.85.225-1.8 0-3.25-2.275-5.525Q27.25 15.2 24 15.2q-.95 0-1.775.2T20.7 16l-5.25-5.3q1.65-.75 4.05-1.225Q21.9 9 24.2 9q6.4 0 11.85 3.475Q41.5 15.95 44.4 21.8q.1.25.15.55.05.3.05.65 0 .35-.05.675-.05.325-.15.525-1.25 2.5-3.025 4.625T37.35 32.65Zm2.2 9.95-7.25-7.15q-1.55.7-3.725 1.125Q26.4 37 24 37q-6.55 0-12.025-3.475Q6.5 30.05 3.6 24.2q-.1-.3-.15-.575Q3.4 23.35 3.4 23t.05-.675Q3.5 22 3.6 21.8q1.15-2.3 2.875-4.425T10.4 13.5L5.05 8.2q-.3-.35-.325-.8-.025-.45.325-.8.35-.35.825-.35t.825.35l34.45 34.45q.3.3.3.725t-.3.775q-.3.4-.775.4-.475 0-.825-.35ZM24 30.8q.75 0 1.65-.175.9-.175 1.4-.475l-10.2-10.2q-.3.6-.475 1.45T16.2 23q0 3.3 2.275 5.55T24 30.8Zm5.25-6.25-6.8-6.8q2.9-1 5.4 1.2 2.5 2.2 1.4 5.6Z"/></svg>`
);
export const helpFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24.2 35q.65 0 1.125-.475t.475-1.175q0-.65-.475-1.125T24.2 31.75q-.65 0-1.125.475T22.6 33.35q0 .7.475 1.175Q23.55 35 24.2 35Zm-.05-19.85q1.75 0 2.825.95 1.075.95 1.075 2.45 0 1-.575 1.95t-1.825 2.05q-1.25 1.15-1.975 2.35-.725 1.2-.775 2.25 0 .4.325.675t.725.275q.5 0 .8-.3.3-.3.35-.75.15-.95.65-1.725.5-.775 1.6-1.675 1.45-1.3 2.1-2.475.65-1.175.65-2.625 0-2.45-1.6-3.975t-4.2-1.525q-1.75 0-3.275.75T18.5 15.95q-.3.4-.25.85.05.45.4.65.45.35.925.225.475-.125.825-.575.65-.85 1.55-1.4.9-.55 2.2-.55ZM24 43q-4 0-7.45-1.45t-6.025-4.025q-2.575-2.575-4.05-6.05Q5 28 5 24q0-4 1.475-7.45t4.05-6.025Q13.1 7.95 16.55 6.475 20 5 24 5q3.95 0 7.4 1.475t6.025 4.05Q40 13.1 41.5 16.55T43 24q0 4-1.5 7.475-1.5 3.475-4.075 6.05Q34.85 40.1 31.4 41.55 27.95 43 24 43Z"/></svg>`
);
export const personAddFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M37.75 27.5q-.5 0-.825-.325-.325-.325-.325-.825v-5.2h-5.2q-.5 0-.825-.325-.325-.325-.325-.825 0-.5.325-.825.325-.325.825-.325h5.2v-5.2q0-.5.325-.825.325-.325.825-.325.5 0 .825.325.325.325.325.825v5.2h5.2q.5 0 .825.325.325.325.325.825 0 .5-.325.825-.325.325-.825.325h-5.2v5.2q0 .5-.325.825-.325.325-.825.325Zm-20-4.15q-2.9 0-4.775-1.875Q11.1 19.6 11.1 16.7q0-2.9 1.875-4.75t4.775-1.85q2.9 0 4.775 1.85Q24.4 13.8 24.4 16.7t-1.875 4.775Q20.65 23.35 17.75 23.35ZM4.2 38.6q-.6 0-1.025-.4-.425-.4-.425-1.05V34.8q0-1.5.825-2.75t2.225-1.9q3.45-1.5 6.275-2.15 2.825-.65 5.675-.65 2.85 0 5.675.65 2.825.65 6.225 2.15 1.45.7 2.275 1.925t.825 2.725v2.35q0 .65-.425 1.05t-1.025.4Z"/></svg>`
);
export const expandLess = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M13.55 29.25q-.4-.35-.4-.8 0-.45.35-.8l9.5-9.5q.2-.25.45-.35.25-.1.55-.1.3 0 .55.1.25.1.45.35l9.55 9.45q.3.3.3.775 0 .475-.35.825t-.8.35q-.45 0-.8-.35L24 20.35l-8.9 8.95q-.3.35-.75.325-.45-.025-.8-.375Z"/></svg>`
);
export const expandMore = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 29.5q-.3 0-.55-.1-.25-.1-.45-.35l-9.5-9.5q-.3-.3-.3-.8t.3-.8q.4-.4.825-.35.425.05.775.35l8.9 8.9 8.9-8.9q.3-.3.8-.325.5-.025.8.325.4.35.35.825-.05.475-.35.825L25 29.05q-.2.25-.45.35-.25.1-.55.1Z"/></svg>`
);
export const scheduleFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M30.7 32.4q.3.35.8.35t.85-.4q.35-.35.35-.85t-.35-.85l-7.1-7.1V14.6q0-.45-.325-.775T24.1 13.5q-.5 0-.8.325-.3.325-.3.825v9.25q0 .3.1.525.1.225.3.475ZM24 43q-3.9 0-7.375-1.5t-6.05-4.075Q8 34.85 6.5 31.375 5 27.9 5 24t1.5-7.375q1.5-3.475 4.075-6.05Q13.15 8 16.625 6.5 20.1 5 24 5t7.375 1.5q3.475 1.5 6.05 4.075Q40 13.15 41.5 16.625 43 20.1 43 24t-1.5 7.375q-1.5 3.475-4.075 6.05Q34.85 40 31.375 41.5 27.9 43 24 43Z"/></svg>`
);
export const infoFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24.1 33.5q.5 0 .825-.325.325-.325.325-.825V23.1q0-.45-.325-.775T24.1 22q-.5 0-.8.325-.3.325-.3.825v9.25q0 .45.325.775t.775.325ZM24 18.8q.55 0 .95-.375.4-.375.4-.975 0-.55-.4-.95-.4-.4-.95-.4-.6 0-.975.4t-.375.95q0 .6.4.975t.95.375ZM24 43q-4 0-7.475-1.45-3.475-1.45-6.05-4.025Q7.9 34.95 6.45 31.475 5 28 5 24q0-4 1.45-7.45t4.025-6.025q2.575-2.575 6.05-4.05Q20 5 24 5q4 0 7.45 1.475t6.025 4.05q2.575 2.575 4.05 6.025Q43 20 43 24t-1.475 7.475q-1.475 3.475-4.05 6.05Q34.9 40.1 31.45 41.55 28 43 24 43Z"/></svg>`
);

export const editFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M38.55 15 33.3 9.8l2.1-2.15q.65-.65 1.625-.625.975.025 1.625.675l2 2q.65.65.65 1.6 0 .95-.65 1.6ZM8.75 41q-.65 0-1.05-.4t-.4-1.05v-3.2q0-.3.1-.525.1-.225.35-.475L31.7 11.4l5.25 5.2-24 23.95q-.25.25-.475.35-.225.1-.525.1Z"/></svg>`
);
export const warningFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M6.25 41q-.85 0-1.275-.725-.425-.725-.025-1.425l17.8-30.7q.45-.7 1.25-.7t1.25.7l17.8 30.7q.4.7-.025 1.425Q42.6 41 41.75 41Zm17.9-21.05q-.5 0-.825.325Q23 20.6 23 21.05v8.65q0 .5.325.825.325.325.825.325.5 0 .825-.325.325-.325.325-.825v-8.65q0-.45-.35-.775t-.8-.325Zm0 15.8q.55 0 .9-.375t.35-.925q0-.5-.375-.85t-.875-.35q-.55 0-.9.35t-.35.9q0 .5.35.875t.9.375Z"/></svg>`
);
export const editSquareFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m42.55 17.9-7.15-7 2.8-2.8q.8-.8 2.025-.8 1.225 0 2.025.9l3 2.95q.8.9.8 2.075 0 1.175-.85 1.975ZM9.9 46.4q-1.2 0-2.05-.85Q7 44.7 7 43.5V15.3q0-1.2.85-2.05.85-.85 2.05-.85h18.8L16.6 24.55q-.6.65-.925 1.425-.325.775-.325 1.625v7.55q0 1.2.85 2.05.85.85 2.05.85h7.5q.85 0 1.65-.325.8-.325 1.45-.975L41 24.6v18.9q0 1.2-.85 2.05-.85.85-2.05.85Zm10.55-12q-.65 0-1.05-.4t-.4-1.05V28.5q0-.55.25-1.125t.65-.975l13.55-13.5 7.1 6.95L27 33.55q-.4.35-.975.6t-1.125.25Z"/></svg>`
);
export const photoCameraFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 34q3.35 0 5.6-2.275 2.25-2.275 2.25-5.575 0-3.35-2.25-5.6-2.25-2.25-5.6-2.25-3.35 0-5.6 2.25-2.25 2.25-2.25 5.6 0 3.3 2.25 5.575Q20.65 34 24 34ZM7.9 41q-1.2 0-2.05-.85Q5 39.3 5 38.1V14.15q0-1.15.85-2.025t2.05-.875h6.85l2.8-3.3q.4-.45.975-.7Q19.1 7 19.75 7h8.5q.65 0 1.225.25.575.25.975.7l2.8 3.3h6.85q1.15 0 2.025.875T43 14.15V38.1q0 1.2-.875 2.05-.875.85-2.025.85Z"/></svg>`
);
export const personRemoveFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M33.55 20.7q-.5 0-.825-.325-.325-.325-.325-.825 0-.5.325-.825.325-.325.825-.325H42q.45 0 .775.35t.325.8q0 .5-.325.825-.325.325-.775.325ZM18 23.35q-2.9 0-4.775-1.875Q11.35 19.6 11.35 16.7q0-2.9 1.875-4.75T18 10.1q2.9 0 4.775 1.85 1.875 1.85 1.875 4.75t-1.875 4.775Q20.9 23.35 18 23.35ZM4.45 38.6q-.6 0-1.025-.4Q3 37.8 3 37.15V34.8q0-1.5.8-2.75t2.25-1.9q3.45-1.5 6.275-2.15 2.825-.65 5.675-.65 2.85 0 5.65.65 2.8.65 6.25 2.15 1.45.7 2.275 1.925T33 34.8v2.35q0 .65-.425 1.05t-1.025.4Z"/></svg>`
);
export const calendarAddFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M35.25 43.5v-6h-6v-2.25h6v-6h2.25v6h6v2.25h-6v6ZM9.9 39q-1.2 0-2.05-.85Q7 37.3 7 36.1V11.15q0-1.15.85-2.025T9.9 8.25h3.7v-3.5h2.5v3.5H28v-3.5h2.45v3.5h3.65q1.2 0 2.05.875Q37 10 37 11.15V25.1q-.55-.05-1.125-.05t-1.125.05v-4.85H9.25V36.1q0 .25.2.45t.45.2H25q0 .55.1 1.125T25.35 39Z"/></svg>`
);
export const chatBubbleFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M5 38.6V7.9q0-1.15.85-2.025T7.9 5h32.2q1.15 0 2.025.875T43 7.9v24.2q0 1.15-.875 2.025T40.1 35h-28l-4.65 4.6q-.7.7-1.575.35T5 38.6Z"/></svg>`
);
export const eventFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M30 36.75q-1.9 0-3.2-1.325-1.3-1.325-1.3-3.225 0-1.85 1.325-3.175Q28.15 27.7 30 27.7q1.9 0 3.2 1.325 1.3 1.325 1.3 3.225 0 1.85-1.3 3.175-1.3 1.325-3.2 1.325ZM9.9 43q-1.2 0-2.05-.85Q7 41.3 7 40.1V11.15q0-1.15.85-2.025T9.9 8.25h3.7v-2.2q0-.55.325-.925t.875-.375q.55 0 .925.375T16.1 6v2.25H32V6q0-.5.35-.875t.85-.375q.5 0 .875.375T34.45 6v2.25h3.65q1.2 0 2.05.875Q41 10 41 11.15V40.1q0 1.2-.85 2.05-.85.85-2.05.85Zm0-2.25h28.2q.3 0 .475-.175t.175-.475V20.25H9.25V40.1q0 .3.175.475t.475.175Z"/></svg>`
);

export const locationFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 44.4q-.5 0-.825-.325-.325-.325-.325-.825V41.1q-6.7-.65-11-4.95t-4.95-11H4.75q-.5 0-.825-.325Q3.6 24.5 3.6 24q0-.5.325-.825.325-.325.825-.325H6.9q.65-6.7 4.95-11t11-5v-2.1q0-.5.325-.825Q23.5 3.6 24 3.6q.5 0 .825.325.325.325.325.825v2.1q6.7.7 11 5t5 11h2.1q.5 0 .825.325.325.325.325.825 0 .5-.325.825-.325.325-.825.325h-2.1q-.7 6.7-5 11t-11 4.95v2.15q0 .5-.325.825-.325.325-.825.325Zm0-5.55q6.15 0 10.5-4.35T38.85 24q0-6.15-4.35-10.5T24 9.15q-6.15 0-10.5 4.35T9.15 24q0 6.15 4.35 10.5T24 38.85Zm0-8.2q-2.8 0-4.725-1.925Q17.35 26.8 17.35 24t1.925-4.725Q21.2 17.35 24 17.35t4.725 1.925Q30.65 21.2 30.65 24t-1.925 4.725Q26.8 30.65 24 30.65Z"/></svg>`
);
export const chatFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M13.65 27.55h12.5q.45 0 .775-.325t.325-.825q0-.5-.325-.825-.325-.325-.825-.325H13.6q-.45 0-.775.325t-.325.825q0 .5.325.825.325.325.825.325Zm0-6.4H34.4q.45 0 .775-.3t.325-.8q0-.5-.325-.825-.325-.325-.825-.325H13.6q-.45 0-.775.325t-.325.825q0 .5.325.8.325.3.825.3Zm0-6.35H34.4q.45 0 .775-.325t.325-.825q0-.5-.325-.8-.325-.3-.825-.3H13.6q-.45 0-.775.325t-.325.775q0 .5.325.825.325.325.825.325ZM5 38.6V7.9q0-1.15.85-2.025T7.9 5h32.2q1.15 0 2.025.875T43 7.9v24.2q0 1.15-.875 2.025T40.1 35h-28l-4.65 4.6q-.7.7-1.575.325Q5 39.55 5 38.6Z"/></svg>`
);
export const homeFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M11 42q-1.25 0-2.125-.875T8 39V19.5q0-.7.325-1.35.325-.65.875-1.05l13-9.75q.4-.3.85-.45.45-.15.95-.15.5 0 .95.15.45.15.85.45l13 9.75q.55.4.875 1.05.325.65.325 1.35V39q0 1.25-.875 2.125T37 42h-9V28h-8v14Z"/></svg>`
);
export const expandCircleDownFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M480 629.616 367.538 517.154q-6.596-6.692-15.759-6.692t-15.855 6.692q-7.077 6.692-6.885 16.308.192 9.615 6.885 16.307l123.845 123.846q8.616 8.615 20.231 8.615 11.615 0 20.231-8.615l124.461-124.461q6.692-6.693 6.577-16-.116-9.308-7.193-16-6.692-6.692-15.999-6.5-9.308.193-16 6.885L480 629.616Zm.067 326.383q-78.221 0-147.397-29.92-69.176-29.92-120.989-81.71-51.814-51.791-81.747-120.936-29.933-69.146-29.933-147.366 0-78.836 29.92-148.204 29.92-69.369 81.71-120.682 51.791-51.314 120.936-81.247 69.146-29.933 147.366-29.933 78.836 0 148.204 29.92 69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173 0 78.221-29.92 147.397-29.92 69.176-81.21 120.989-51.291 51.814-120.629 81.747-69.337 29.933-148.173 29.933Z"/></svg>`
);

export const addFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" style="background-color: #9e35e5;border-radius: 15px;">
  <path d="M450-450H200v-60h250v-250h60v250h250v60H510v250h-60v-250Z" fill="white"/>
  </svg>`
);
export const removeFill = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" style="background-color: #9e35e5;border-radius: 15px;">
  <path d="M200-450v-60h560v60H200Z" fill="white"/>
</svg>`
);


// https://nucleoapp.com/svg-flag-icons - zdroj vlajek
export const czFlag = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M1,24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V15H1v9Z" fill="#c62d25"></path><path d="M27,4H5c-2.209,0-4,1.791-4,4v8H31V8c0-2.209-1.791-4-4-4Z" fill="#fff"></path><path d="M2.316,26.947l13.684-10.947L2.316,5.053c-.803,.732-1.316,1.776-1.316,2.947V24c0,1.172,.513,2.216,1.316,2.947Z" fill="#1e427b"></path><path d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z" opacity=".15"></path><path d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z" fill="#fff" opacity=".2"></path></svg>`
)

export const enFlag = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><rect x="1" y="4" width="30" height="24" rx="4" ry="4" fill="#071b65"></rect><path d="M5.101,4h-.101c-1.981,0-3.615,1.444-3.933,3.334L26.899,28h.101c1.981,0,3.615-1.444,3.933-3.334L5.101,4Z" fill="#fff"></path><path d="M22.25,19h-2.5l9.934,7.947c.387-.353,.704-.777,.929-1.257l-8.363-6.691Z" fill="#b92932"></path><path d="M1.387,6.309l8.363,6.691h2.5L2.316,5.053c-.387,.353-.704,.777-.929,1.257Z" fill="#b92932"></path><path d="M5,28h.101L30.933,7.334c-.318-1.891-1.952-3.334-3.933-3.334h-.101L1.067,24.666c.318,1.891,1.952,3.334,3.933,3.334Z" fill="#fff"></path><rect x="13" y="4" width="6" height="24" fill="#fff"></rect><rect x="1" y="13" width="30" height="6" fill="#fff"></rect><rect x="14" y="4" width="4" height="24" fill="#b92932"></rect><rect x="14" y="1" width="4" height="30" transform="translate(32) rotate(90)" fill="#b92932"></rect><path d="M28.222,4.21l-9.222,7.376v1.414h.75l9.943-7.94c-.419-.384-.918-.671-1.471-.85Z" fill="#b92932"></path><path d="M2.328,26.957c.414,.374,.904,.656,1.447,.832l9.225-7.38v-1.408h-.75L2.328,26.957Z" fill="#b92932"></path><path d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z" opacity=".15"></path><path d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z" fill="#fff" opacity=".2"></path></svg>`
)
