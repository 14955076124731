import useAxios from "./useAxios";
import useStorage from "./useStorage";
import { useMutation } from "react-query";
import { logEvent } from "../helpers/logging";
import { AxiosError, AxiosResponse } from "axios";
import useUserRequests from "./useUserRequests";
import { useInvalidateQueries } from "./useInvalidateQueries";
import useTranlatedMsg from "./useTranslatedMsg";

const useInterestedInEvent = () => {
  const { axios } = useAxios();
  const { setMessage } = useStorage();
  const trigger = useUserRequests();
  const invalidateQueries = useInvalidateQueries();
  const {interested_event,went_wrong} = useTranlatedMsg()

  const { mutate: interestedInEvent, isSuccess } = useMutation<
    AxiosResponse<unknown>,
    AxiosError,
    number
  >(
    (eventId: number) =>
      axios.post(`/events/${eventId}/users`, { state: "MAYBE" }),
    {
      onSuccess: () => {
        logEvent("event_maybe", {});
        invalidateQueries('hp_events');
        trigger("event_join");
        setMessage("primary", interested_event);
      },
      onError: (err) => setMessage("danger", went_wrong, err),
    }
  );
  return { interestedInEvent, isSuccess };
};
export default useInterestedInEvent;
