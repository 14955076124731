import {
  useIonViewWillEnter,
  IonPage,
  IonBackButton,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
} from "@ionic/react";
import { AxiosResponse, AxiosError } from "axios";
import { useState } from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import { showTabBar } from "../helpers/tabBar";
import useAxios from "../hooks/useAxios";
import { PlaceDetail, Event } from "../models";
import { RouteComponentProps } from "react-router";
import { chevroneRight, locationOn, myLocation } from "../icons/iconsOutline";
import { useStorage } from "../hooks";
import "./EventDetail.scss";
import { SCROLL_STEP } from "../constants/global";
import { SkeletonEventList, SkeletonPlaceDetail } from "../components/Skeleton";
import { EventItem } from "../components/Event";
import { PopoverSettings } from "../models/popover";
import {
  PlaceFollowRow,
  PlaceLocationPopover,
} from "../components/PlaceDetailInfo";
import { get_event_photo_url } from "../helpers/event";
import { AboutHeader } from "../components/Detail";
import useTranlatedMsg from "../hooks/useTranslatedMsg";

interface PlaceDetailProps
  extends RouteComponentProps<{
    id: string;
  }> {}

const PlaceDetailInfo: React.FC<PlaceDetailProps> = ({ match }) => {
  const [showMap, setShowMap] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setRerender] = useState<Number>(Math.random());
  const [showLocationPopover, setLocationShowPopover] =
    useState<PopoverSettings>({
      showPopover: false,
      event: undefined,
    });
  const { axios } = useAxios();
  const { setMessage } = useStorage();
  const { err_past_eve } = useTranlatedMsg();

  const {
    data: placeDetail,
    isLoading: placeDetailIsLoading,
    refetch,
  } = useQuery<AxiosResponse<PlaceDetail>, AxiosError, PlaceDetail>(
    ["place_detail", match.params.id],
    () => axios.get<PlaceDetail>(`/places/v2/${match.params.id}`),
    {
      select: (res) => res.data,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: eventsOnPlace,
    isLoading: eventsOnPlaceAreLoading,
    fetchNextPage: fetchEventsOnPlaceNextPage,
  } = useInfiniteQuery(
    ["eventsOnPlace", match.params.id],
    ({ pageParam = 0 }) => {
      return axios.get<Event[]>("/events", {
        params: {
          offset: pageParam,
          source: "place_detail",
          place_id: match.params.id,
          limit: SCROLL_STEP,
          filter_date: "future",
          order_by: "start",
          order_direction: "ASC",
        },
      });
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length < SCROLL_STEP) return;
        else return pages.flatMap((page) => page.data).length;
      },

      onError: (error) => {
        setMessage("danger", err_past_eve, error);
      },
      refetchOnWindowFocus: false,
    }
  );

  useIonViewWillEnter(() => {
    showTabBar();
  });

  const loadMore = async (e: any) => {
    await fetchEventsOnPlaceNextPage();
    e.target.complete();
  };

  return (
    <IonPage className="page-place_detail">
      {placeDetailIsLoading ? (
        <SkeletonPlaceDetail />
      ) : (
        <>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/events" text="" className="back" />
              </IonButtons>
              <IonTitle>{placeDetail?.name}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div className="event_details">
              <AboutHeader
                photo={get_event_photo_url(
                  placeDetail?.photo ? placeDetail.photo : undefined,
                  placeDetail?.tags.length
                    ? placeDetail?.tags[0].slug
                    : undefined
                )}
                // coordinates={placeDetail} // this is ugly but works. coordinates are subset of place detail
                mask={false}
                // showMap={showMap}
                // setShowMap={setShowMap}
                // pin={
                //   placeDetail
                //     ? set_pin_place_detail(
                //         placeDetail?.tags[0].name.toLowerCase()
                //       )
                //     : ""
                // }
              />{" "}
              <PlaceFollowRow
                placeDetailId={placeDetail?.placeId}
                following={placeDetail?.status === "FOLLOWING"}
                onStatusChanged={refetch}
              />
              <IonToolbar className="inner">
                <h5>{placeDetail?.name}</h5>
              </IonToolbar>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <PlaceLocationPopover
                        showPopover={showLocationPopover}
                        setShowPopover={setLocationShowPopover}
                        showMap={showMap}
                        setShowMap={setShowMap}
                        address={placeDetail?.address}
                        name={placeDetail?.name}
                      />
                      <div
                        className="event_info_nice address_nice"
                        onClick={(e: any) =>
                          setLocationShowPopover({
                            showPopover: true,
                            event: e,
                          })
                        }
                      >
                        <div className="icon">
                          <IonIcon icon={locationOn} color="primary" />
                        </div>
                        <div className="texts">
                          <div className="title icon_box">
                            {placeDetail?.address}&nbsp;
                            <IonIcon icon={chevroneRight}></IonIcon>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="event_info_nice address_nice">
                        <div className="icon">
                          <IonIcon icon={myLocation} color="primary" />
                        </div>
                        <div className="texts">
                          <div className="title">
                            {`${placeDetail?.lat}, ${placeDetail?.lng}`}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <IonToolbar className="inner">
                <h5>Program</h5>
              </IonToolbar>
              <IonList lines="none" className="events_list">
                {eventsOnPlaceAreLoading && <SkeletonEventList size="big" />}
                {eventsOnPlace?.pages
                  .flatMap((page) => page.data)
                  .map((event, i) => (
                    <EventItem
                      key={event.eventId}
                      event={event}
                      // duplicate_event={() => {}}
                      // actions={true}
                      order={i + 1}
                      source="place_detail"
                    />
                  ))}
              </IonList>
              <IonInfiniteScroll onIonInfinite={loadMore} threshold="600px">
                <IonInfiniteScrollContent loadingSpinner="dots" />
              </IonInfiniteScroll>
            </div>
          </IonContent>
        </>
      )}
    </IonPage>
  );
};

export default PlaceDetailInfo;
