import { useQueryClient } from "react-query";

const QUERIES: { [key: string]: string[] } = {
  hp_events: ["recommended_events", "fetched_category", "week_events"],
  hp_places_events: ["following_places_events"],
  events: ["myEventsFutureEvents", "myEventsPastEvents"],
};

function useInvalidateQueries() {
  const queryClient = useQueryClient();

  function invalidateQueries(id: string) {
    QUERIES[id].forEach((queryKey: string) => {
      queryClient.invalidateQueries(queryKey);
    });
  }

  return invalidateQueries;
}
export { useInvalidateQueries };
