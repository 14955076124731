export default window.localStorage;

export function store<T>(key: string, value: T) {
  if (value instanceof Set)
    window.localStorage.setItem(
      key,
      `<Set>:${JSON.stringify(Array.from(value))}`
    );
  else window.localStorage.setItem(key, JSON.stringify(value));
}

export function load<T>(key: string, defaultValue: T) {
  // Get from local storage by key
  const item = window.localStorage.getItem(key);
  // custom Set handler
  if (item?.startsWith("<Set>:"))
    return new Set(JSON.parse(item.replace("<Set>:", ""))) as any as T;
  // Parse stored json or if none return initialValue
  return item ? (JSON.parse(item) as T) : defaultValue;
}

export function remove(key: string) {
  window.localStorage.removeItem(key);
}
