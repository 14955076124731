import {
  Camera,
  CameraDirection,
  CameraResultType,
  CameraSource,
} from "@capacitor/camera";
import { Diagnostic } from "@ionic-native/diagnostic";
import { SetMessage } from "../contexts/StateStorage";
import { startSensitive, stopSensitive } from "../hooks/useSmartlook";
import { useTranslation } from "react-i18next";



const takePhoto = async (source: CameraSource, setMessage: SetMessage,messages: {access: string,photo:string}) => {
  await startSensitive();

  const cameraPhoto = await Camera.getPhoto({
    resultType: CameraResultType.DataUrl,
    direction: CameraDirection.Front,
    quality: 100,
    source: source,
    //allowEditing: true,
    //width: 200,
    //height: 200,
  }).catch((err) => {
    if (err.message.includes("denied")) {
      Diagnostic.switchToSettings();
      setMessage("danger", messages.access, err);
    } else if (!err.message.includes("cancelled")) {
      setMessage("danger", err.message);
    } else {
      setMessage("danger", messages.photo, err);
    }
    console.log(err.message);
  });

  await stopSensitive();

  return cameraPhoto && cameraPhoto?.dataUrl ? cameraPhoto.dataUrl : undefined;
};

const takePhotoWithAction = (
  action: (image: string) => void | Promise<void>,
  setMessage: SetMessage,
  access_denied_msg:string,
  failed_photo_msg:string,
  setLoadingImages?: React.Dispatch<React.SetStateAction<boolean>>,
  source = CameraSource.Prompt,

) => {

  if (setLoadingImages) setLoadingImages(true);
  takePhoto(source, setMessage,{access:access_denied_msg,photo: failed_photo_msg})
    .then(async (image) => {
      try {
        if (image) return await action(image);
      } finally {
        if (setLoadingImages) setLoadingImages(false);
      }
    })
    .catch(() => {
      if (setLoadingImages) setLoadingImages(false);
    });
};

export { takePhotoWithAction };
