import { Diagnostic } from "@ionic-native/diagnostic";
import { Geolocation } from "@ionic-native/geolocation";
import { isPlatform } from "@ionic/react";
import { Subscription } from "rxjs";
import { save_coordinates } from "../cache/coordinates";

//type setGpsCoordinates = (n: {lat: number, lng: number}) => null;

//const CHECKING_INTERVAL = 5000;

function load_gps(
  setGpsCoordinates: CallableFunction,
  turnOffGps: () => void,
  setInitialized: (v: boolean) => void,
  setSubscription: (v: Subscription | null) => void
) {
  console.log("loading gps");
  Geolocation.getCurrentPosition({ timeout: 10000 })
    .then((resp) => {
      console.log("gps loaded", resp.coords.latitude, resp.coords.longitude);
      save_coordinates(resp.coords.latitude, resp.coords.longitude);
      setGpsCoordinates({
        lat: resp.coords.latitude,
        lng: resp.coords.longitude,
      });
      watch_gps(setGpsCoordinates, setSubscription);
    })
    .catch((err) => {
      // TODO: this error is not reported to Rollbar
      const isDenied =
        err.message.toLowerCase().includes("denied") ||
        err.message.toLowerCase().includes("illegal");
      console.error("loading gps error", err.message);
      if (!isDenied) {
        setTimeout(
          () =>
            load_gps(
              setGpsCoordinates,
              turnOffGps,
              setInitialized,
              setSubscription
            ),
          5000
        );
      } else {
        turnOffGps();
        setInitialized(false);
      }
    });
}

function init_gps(
  setGpsCoordinates: CallableFunction,
  turnOffGps: () => void,
  setInitialized: (v: boolean) => void,
  setSubscription: (v: Subscription | null) => void
) {
  if (isPlatform("android")) {
    console.log("gps.android");
    Diagnostic.isGpsLocationEnabled()
      .then((data) => {
        console.log("Diagnostics.GSP success", data);
        load_gps(
          setGpsCoordinates,
          turnOffGps,
          setInitialized,
          setSubscription
        );
        setInitialized(true);
      })
      .catch(() => {
        // TODO: this error is not reported to Rollbar
        console.log("Diagnostics.GSP error");
        setTimeout(
          () =>
            init_gps(
              setGpsCoordinates,
              turnOffGps,
              setInitialized,
              setSubscription
            ),
          5000
        );
      });
  } else {
    console.log("gps.other");
    load_gps(setGpsCoordinates, turnOffGps, setInitialized, setSubscription);
    setInitialized(true);
  }
}

function watch_gps(
  setGpsCoordinates: CallableFunction,
  setSubscription: (v: Subscription | null) => void
) {
  console.log("starting to watch gps");
  setSubscription(
    Geolocation.watchPosition().subscribe((position) => {
      if ("coords" in position) {
        console.log(
          "gps changed",
          position.coords.latitude + " " + position.coords.longitude
        );
        save_coordinates(position.coords.latitude, position.coords.longitude);
        setGpsCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      }
    })
  );
}

function destroy_gps(
  subscription: any,
  setGpsCoordinates: CallableFunction,
  setSubscription: (v: Subscription | null) => void,
  setInitialized: (v: boolean) => void
) {
  if (subscription !== null) {
    subscription.unsubscribe();
    setSubscription(null);
    setGpsCoordinates(null);
    setInitialized(false);
  }
}

export { init_gps, destroy_gps };
