import React, { useState } from "react";
import {
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonSelectOption,
  IonSelect,
  IonInput,
  IonIcon,
  isPlatform,
} from "@ionic/react";
import { object, string, ref, InferType } from "yup";
import { Formik } from "formik";
import PhonePrefixes from "../constants/phone_prefixes";

import { ShowPrivacy, ShowTerms } from "./TaC";
import { useNavigation, useStorage } from "../hooks";

import { AxiosError, AxiosResponse } from "axios";
import {
  registrationError,
  RegistrationErrorCode,
} from "../constants/responseErrors";
import { Facebook } from "@awesome-cordova-plugins/facebook";
import { logEvent } from "../helpers/logging";
import { Auth0AppleLoginButton, Auth0GoogleLoginButton } from "./auth0";
import { isNative } from "../config";
import {
  call,
  lock,
  person,
  visibility,
  visibilityOff,
} from "../icons/iconsOutline";
import { useMutation } from "react-query";
import useAxios from "../hooks/useAxios";
import { get_region } from "../cache/coordinates";
import useTranlatedMsg from "../hooks/useTranslatedMsg";
import { useTranslation } from "react-i18next";

const registerInput = object({
  name: string().min(2).required("Required"),
  surname: string().min(2).required("Required"),
  phonePrefix: string().required("Required"),
  phone: string()
    .matches(/^[0-9]{8,10}$/)
    .required("Required"),
  password: string().min(3).required("Required"),
  confirmPassword: string()
    .required("Required")
    .oneOf([ref("password")]),
}).required();

type RegisterInput = Omit<InferType<typeof registerInput>, "confirmPassword">;

const RegisterForm: React.FC<{
  setPhonePrefix: CallableFunction;
  setPhone: CallableFunction;
  setPassword: CallableFunction;
  setUserId: CallableFunction;
  setMissingInvitation: CallableFunction;
}> = ({
  setPhonePrefix,
  setPhone,
  setPassword,
  setUserId,
  setMissingInvitation,
}) => {
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [passwordConfirmIsVisible, setPasswordConfirmIsVisible] =
    useState(false);
  const { setMessage } = useStorage();
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const { axios } = useAxios();
  const { mutate: register } = useMutation<
    AxiosResponse<{
      userId: number;
    }>,
    AxiosError,
    RegisterInput
  >((payload) => axios.post("register", payload));
  const { err_register } = useTranlatedMsg();
  const registerUser = (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const { confirmPassword, ...rest } = values;
    register(rest, {
      onSuccess: ({ data }) => {
        setPhonePrefix(values.phonePrefix);
        setPhone(values.phone);
        setPassword(values.password);
        setUserId(data.userId);
        logEvent("registration", {});
        if (isPlatform("android"))
          Facebook.logEvent(Facebook.EVENTS.EVENT_NAME_COMPLETED_REGISTRATION);
        setSubmitting(false);
      },
      onError: ({ response }: AxiosError) => {
        const status = response?.status as RegistrationErrorCode;

        const error = new Error(registrationError[status] || err_register);

        setMessage("danger", error.message, error);

        if (status === 404) {
          // 404: invitation not found
          setPhonePrefix(values.phonePrefix);
          setPhone(values.phone);
          setMissingInvitation(true);
        }
        setSubmitting(false);
      },
    });
  };

  return (
    <>
      {/* <img
        src={process.env.PUBLIC_URL + "/assets/logomark.png"}
        className="logo"
        alt="logo"
      /> */}
      <IonIcon className="logo" icon="/assets/icon/new/logo.svg" />
      <Formik
        initialValues={{
          name: "",
          surname: "",
          phonePrefix: get_region() === "usa" ? "+1" : "+420",
          phone: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={registerInput}
        onSubmit={(data, { setSubmitting }) => {
          registerUser(data, setSubmitting);
        }}
      >
        {({
          dirty,
          values,
          errors,
          touched,
          isValid,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <IonGrid className="big_form">
            <IonRow className="navigation">
              <IonCol>{t("registration.title")}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="2" className="label">
                <IonIcon className="label_icon" icon={person} />
              </IonCol>
              <IonCol size="5">
                <IonInput
                  aria-label="name"
                  name="name"
                  value={values.name}
                  placeholder="name"
                  autocapitalize="true"
                  className={touched.name && errors.name ? "error" : ""}
                  onIonInput={handleChange}
                  onIonBlur={(e) => {
                    handleChange(e);
                    handleBlur(e);
                  }}
                />
                {touched.name && errors.name ? (
                  <div className="error">{errors.name}</div>
                ) : null}
              </IonCol>
              <IonCol size="5">
                <IonInput
                  aria-label="surname"
                  name="surname"
                  value={values.surname}
                  autocapitalize="true"
                  placeholder="surname"
                  className={touched.surname && errors.surname ? "error" : ""}
                  onIonInput={handleChange}
                  onIonBlur={(e) => {
                    handleChange(e);
                    handleBlur(e);
                  }}
                />
                {touched.surname && errors.surname ? (
                  <div className="error">{errors.surname}</div>
                ) : null}
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="2" className="label">
                <IonLabel>
                  <IonIcon className="label_icon" icon={call} />
                </IonLabel>
              </IonCol>
              <IonCol size="3" className="left_select">
                <IonSelect
                  aria-label="phonePrefix"
                  name="phonePrefix"
                  interfaceOptions={{ header: "Select country code" }}
                  value={values.phonePrefix}
                  placeholder="prefix"
                  className={
                    touched.phonePrefix && errors.phonePrefix ? "error" : ""
                  }
                  onIonChange={handleChange}
                  onIonBlur={(e) => {
                    handleChange(e);
                    handleBlur(e);
                  }}
                  onClick={() => logEvent("register_phonecode_click", {})}
                >
                  {PhonePrefixes?.map(({ dial_code, flag }, _) => {
                    return (
                      <IonSelectOption key={dial_code} value={dial_code}>
                        {flag} {dial_code}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
                {touched.phonePrefix && errors.phonePrefix ? (
                  <div className="error">{errors.phonePrefix}</div>
                ) : null}
              </IonCol>
              <IonCol size="7">
                <IonInput
                  aria-label="phone"
                  name="phone"
                  type="tel"
                  inputMode="tel"
                  value={values.phone}
                  minlength={9}
                  maxlength={10}
                  autocomplete="tel"
                  placeholder="number"
                  className={touched.phone && errors.phone ? "error" : ""}
                  onIonInput={handleChange}
                  onIonBlur={(e) => {
                    handleChange(e);
                    handleBlur(e);
                  }}
                />
                {touched.phone && errors.phone ? (
                  <div className="error">{t("registration.invalid_phone")}</div>
                ) : null}
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="2" className="label">
                <IonLabel>
                  <IonIcon className="label_icon" icon={lock} />
                </IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonInput
                  aria-label="password"
                  name="password"
                  onIonInput={handleChange}
                  value={values.password}
                  placeholder="password"
                  className={touched.password && errors.password ? "error" : ""}
                  type={passwordIsVisible ? "text" : "password"}
                  onIonBlur={(e) => {
                    handleChange(e);
                    handleBlur(e);
                  }}
                />
                {touched.password && errors.password ? (
                  <div className="error">{errors.password}</div>
                ) : null}
              </IonCol>
              <IonCol size="2" className="label">
                <IonButton
                  fill="clear"
                  size="small"
                  onClick={() => {
                    setPasswordIsVisible(!passwordIsVisible);
                  }}
                >
                  <IonIcon
                    icon={passwordIsVisible ? visibilityOff : visibility}
                  />
                </IonButton>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="2" />
              <IonCol size="8">
                <IonInput
                  aria-label="confirmPassword"
                  name="confirmPassword"
                  onIonInput={handleChange}
                  value={values.confirmPassword}
                  placeholder="confirm password"
                  className={
                    touched.confirmPassword && errors.confirmPassword
                      ? "error"
                      : ""
                  }
                  type={passwordConfirmIsVisible ? "text" : "password"}
                  onIonBlur={(e) => {
                    handleChange(e);
                    handleBlur(e);
                  }}
                />
                {touched.confirmPassword && errors.confirmPassword ? (
                  <div className="error">{t("registration.password")}</div>
                ) : null}
              </IonCol>
              <IonCol size="2" className="label">
                <IonButton
                  fill="clear"
                  size="small"
                  onClick={() => {
                    setPasswordConfirmIsVisible(!passwordConfirmIsVisible);
                  }}
                >
                  <IonIcon
                    icon={passwordConfirmIsVisible ? visibilityOff : visibility}
                  />
                </IonButton>
              </IonCol>
            </IonRow>

            <IonRow className="big_button_row center">
              <IonCol className="label">
                <TermsAndPrivacy
                  showTermsModal={showTermsModal}
                  setShowTermsModal={setShowTermsModal}
                  showPrivacyModal={showPrivacyModal}
                  setShowPrivacyModal={setShowPrivacyModal}
                />
                <IonButton
                  type="submit"
                  className="btn-social btn-social-primary"
                  disabled={isSubmitting || !isValid || !dirty}
                  onClick={() => handleSubmit()}
                >
                  {t("registration.register")}
                </IonButton>
              </IonCol>
            </IonRow>

            <IonRow className="alternative_action">
              <IonCol>
                <span> {t("registration.or")}</span>
              </IonCol>
            </IonRow>

            {isNative && (
              <IonRow className="big_button_row">
                <IonCol>
                  <Auth0GoogleLoginButton type="register" />
                </IonCol>
              </IonRow>
            )}

            {isNative && isPlatform("ios") && (
              <IonRow className="big_button_row">
                <IonCol>
                  <Auth0AppleLoginButton type="register" />
                </IonCol>
              </IonRow>
            )}

            <IonRow className="big_button_row">
              <IonCol>
                <IonButton
                  className="big_button"
                  fill="outline"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  {t("registration.login")}
                </IonButton>
              </IonCol>
            </IonRow>

            <IonRow className="navigation_bottom">
              <IonCol>
                <IonButton
                  onClick={() => {
                    navigate("/reset_password");
                  }}
                  fill="clear"
                >
                  {t("registration.reset_pass")}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </Formik>
    </>
  );
};

export default RegisterForm;

export const TermsAndPrivacy: React.FC<{
  showTermsModal: boolean;
  setShowTermsModal: React.Dispatch<React.SetStateAction<boolean>>;
  showPrivacyModal: boolean;
  setShowPrivacyModal: React.Dispatch<React.SetStateAction<boolean>>;
  onboarding?: boolean;
}> = ({
  showTermsModal,
  setShowTermsModal,
  showPrivacyModal,
  setShowPrivacyModal,
  onboarding,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ShowTerms showModal={showTermsModal} setShowModal={setShowTermsModal} />
      <ShowPrivacy
        showModal={showPrivacyModal}
        setShowModal={setShowPrivacyModal}
      />
      <div className="accept_terms_row">
        <div className="text">
          {t("registration.by_click")} {onboarding ? "continue" : "register"}{" "}
          {t("registration.you")} <strong>{t("registration.accept")}</strong>{" "}
          {t("registration.our")}
          <br />
          <span
            className={onboarding ? "onboarding_link" : "link"}
            onClick={() => setShowTermsModal(true)}
          >
            {t("registration.terms")}
          </span>{" "}
          {t("registration.and")}{" "}
          <span
            className={onboarding ? "onboarding_link" : "link"}
            onClick={() => setShowPrivacyModal(true)}
          >
            {t("registration.gdpr")}{" "}
          </span>
        </div>
      </div>
    </>
  );
};
