import { Attendee, Tag, Event, AttendeesFilter } from "../models/Event";
import { api_url } from "../config";
import moment from "moment";

// TODO: I think this module should be somewhere else

function get_attendees_stats(users: Attendee[]) {
  let all_count = 0;
  let going_count = 0;
  let maybe_count = 0;
  let invited_count = 0;
  let declined_count = 0;

  for (let i = 0; i < users.length; i++) {
    const u = users[i];

    all_count += 1;
    if (u.state === "GOING") going_count += 1;
    if (u.state === "MAYBE") maybe_count += 1;
    else if (u.state === "DECLINED") declined_count += 1;
    else if (u.state === "INVITED") invited_count += 1;
  }

  return {
    all_count: all_count,
    going_count: going_count,
    maybe_count: maybe_count,
    invited_count: invited_count,
    declined_count: declined_count,
  };
}

function attendee_filter(user: Attendee, filter: AttendeesFilter) {
  if (filter === "all") return true;
  if (filter === "going" && user.state === "GOING") return true;
  if (filter === "maybe" && user.state === "MAYBE") return true;
  if (filter === "invited" && user.state === "INVITED") return true;
  if (filter === "declined" && user.state === "DECLINED") return true;
}

function friends_filter(user: Attendee, userId: number | undefined) {
  if (user.user.userId === userId) return false;
  if (user.user.relationship === "FRIEND") return true;
  return false;
}

function get_event_photo_url(photo?: string, category?: string) {
  if (photo) return `${api_url}/files/events/${photo}`;
  const slug = category ? category : "other";
  return `/assets/categories/low/${slug}.jpg`;
}

// function get_background_image(tag_slug: string, photo?: string) {
//   return `url("${get_event_photo_url(
//     photo
//   )}"), url("/assets/categories/low/${tag_slug}.jpg")`;
// }

function get_first_event_date_option(event: Event) {
  return event.dateOptions.length > 0 ? event.dateOptions[0] : undefined;
}

function get_start(event: Event) {
  return event.start || get_first_event_date_option(event)?.start || null;
}

// if there is no end, set to the midnight of start
function calc_end_date(event: Event): Date {
  return (
    event.end ?? moment(event.start).add(1, "day").startOf("date").toDate()
  );
}

function get_event_attendee_ids(event: Event | undefined) {
  return event && event.attendees
    ? event.attendees?.map((attendee) => attendee.user.userId)
    : [];
}

function get_primary_tag_slug(tags: Tag[]): string {
  let tags_tmp = tags
    .filter((t) => t.level === 1)
    .map((t) => t.slug)
    .sort();

  return tags_tmp.length ? tags_tmp[0] : "other";
}

function get_primary_tag_slug_default(tags: Tag[]): string {
  return get_primary_tag_slug(tags) || "other";
}

function getPersonalMeetingTitle(
  friendName: string,
  userName: string,
  titleLimit: number
) {
  const title = `Meeting of ${friendName.split(" ")[0]} and ${
    userName.split(" ")[0]
  }`;
  return title.length <= titleLimit ? title : "Let's meet";
}

function getGroupMeetingTitle(groupName: string, titleLimit: number) {
  const title = `Meeting of ${groupName}`;
  return title.length <= titleLimit ? title : "Let's meet";
}

export {
  get_attendees_stats,
  attendee_filter,
  friends_filter,
  get_event_photo_url,
  // get_background_image,
  get_event_attendee_ids,
  get_start,
  calc_end_date,
  get_primary_tag_slug,
  get_primary_tag_slug_default,
  getPersonalMeetingTitle,
  getGroupMeetingTitle,
};
