import React, { useState, useRef, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonButton,
  useIonViewDidEnter,
  useIonViewDidLeave,
  createGesture,
  GestureDetail,
  Gesture,
  CreateAnimation,
  AnimationLifecycle,
  AnimationCallbackOptions,
  useIonViewWillEnter,
  IonProgressBar,
  // IonItem,
} from "@ionic/react";
import "./Search.scss";
import {
  Event,
  AggregatedEvent,
  MarkerContainer,
  AggregatedMarkerContainer,
  PlaceDetail,
} from "../models/Event";
import { search } from "../resources/events";
import {
  dateRangeLabel,
  dayFilterLabel,
  Filters,
  QFCategoriesPopover,
  QFDatePopover,
  resetAllFilters,
} from "../components/Filters";
import { SearchResults } from "../components/SearchResults";
import { EventBoxMap } from "../components/Event";
import {
  DEFAULT_ZOOM,
  JUMP_TO_CITY_ZOOM,
  MAP_STYLES,
  MAP_ZOOM_STEP,
  MIN_ZOOM,
} from "../constants/map";
import {
  clean_unused_markers,
  close_thumbnails,
  get_gps_key,
  handle_pin_loc,
  hide_marker,
  marker_in_container,
  open_thumbmail,
  set_aggregated_pin,
  set_pin,
  show_marker,
} from "../helpers/map";
import { showTabBar } from "../helpers/tabBar";
import { useStorage } from "../hooks";
import useAxios from "../hooks/useAxios";
import { useQuery } from "react-query";
import { logEvent } from "../helpers/logging";
import {
  useBaseFilters,
  useOptimizedLocationFilter,
} from "../hooks/useFilters";
import { useImpreciseGps } from "../hooks/useGps";
import {
  navigateSmall,
  search as searchIcon,
  filterSmall,
  // homeWork,
} from "../icons/iconsOutline";
import { expandLess, expandMore } from "../icons/iconsFill";
import { useLocation } from "react-router";
import { load } from "../cache/engine";
import { isDevelopment } from "../config";
import { useMapCenter } from "../hooks/useMap";
import useValitadeLocation from "../hooks/useValitadeLocation";
import { Swiper, SwiperSlide } from "swiper/react";
import { getLevelOneTags } from "../helpers/tags";
import { useTranslation } from "react-i18next";

let marker_container: MarkerContainer = {};
let aggregated_marker_container: AggregatedMarkerContainer = {};
var activated_markers: google.maps.Marker[] = [];
let gesture: Gesture;

// TODO: Don't use interface, use types everywhere
interface GestureStateProps {
  progressStart: { forceLinearEasing: boolean; step?: number } | undefined;
  progressStep: { step: number } | undefined;
  progressEnd:
    | { playTo: 0 | 1 | undefined; step: number; dur?: number }
    | undefined;
  onFinish:
    | {
        callback: AnimationLifecycle;
        opts?: AnimationCallbackOptions;
      }
    | undefined;
}

const EventThumbnail: React.FC<{
  selectedEvents: Event[];
  // duplicate_event: CallableFunction;
  close: () => void;
}> = ({ selectedEvents, close }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [places, setPlaces] = useState<PlaceDetail[]>([]);
  const [gestureState, setGestureState] = useState<GestureStateProps>({
    progressStart: undefined,
    progressStep: undefined,
    progressEnd: undefined,
    onFinish: undefined,
  });
  const animation: React.RefObject<CreateAnimation> = useRef(null);
  const listRef: React.Ref<HTMLIonListElement> | undefined = useRef(null);

  useEffect(() => {
    setPlaces(
      selectedEvents.reduce((all, ev) => {
        return ev.place &&
          !all.filter((pl) => pl.placeId === ev.place?.placeId).length
          ? [...all, ev.place]
          : all;
      }, [] as PlaceDetail[])
    );
  }, [selectedEvents]);

  useEffect(() => {
    if (!listRef?.current) return;

    let started = false;
    let initialStep = 0;

    const clamp = (min: number, n: number, max: number) => {
      return Math.max(min, Math.min(n, max));
    };

    const getStep = (ev: GestureDetail) => {
      const delta = initialStep + ev.deltaY;
      const max = listRef?.current
        ? Math.floor(listRef.current.clientHeight * 0.5)
        : 0;
      return clamp(0, delta / max, 1);
    };

    const el = Array.from(animation.current!.nodes.values())[0];
    gesture = createGesture({
      el,
      gestureName: "move",
      threshold: 0,
      onMove: (ev) => {
        if (listRef?.current && listRef.current.scrollTop > 0) return;
        if (!started) {
          setGestureState({
            ...gestureState,
            progressStart: { forceLinearEasing: true },
          });
          started = true;
        }
        setGestureState({
          ...gestureState,
          progressStep: { step: getStep(ev) },
        });
      },
      onEnd: (ev) => {
        if (!started) return;
        gesture!.enable(false);

        const step = getStep(ev);
        const shouldComplete = step > 0.5;

        setGestureState({
          ...gestureState,
          progressEnd: { playTo: shouldComplete ? 1 : 0, step },
          onFinish: {
            callback: () => {
              gesture!.enable(true);
              setGestureState({
                ...gestureState,
                progressStart: undefined,
                progressStep: undefined,
                progressEnd: { playTo: 0, step },
              });
              if (shouldComplete) close();
            },
            opts: { oneTimeCallback: true },
          },
        });
        initialStep =
          shouldComplete && listRef?.current ? listRef.current.clientHeight : 0;
        started = false;
      },
    });
    gesture.enable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRef?.current]);

  return (
    <CreateAnimation
      ref={animation}
      duration={400}
      progressStart={gestureState.progressStart}
      progressStep={gestureState.progressStep}
      progressEnd={gestureState.progressEnd}
      onFinish={gestureState.onFinish}
      fromTo={{
        property: "transform",
        fromValue: "translateY(0)",
        toValue: `translateY(100%)`,
      }}
    >
      <div className="appear_animation">
        <Swiper
          slidesPerView={"auto"}
          initialSlide={0}
          speed={400}
          className={`event_boxes-map ${
            selectedEvents.length === 1 && "event_boxes-map-single"
          }`}
        >
          {selectedEvents?.map((event, i) => {
            return (
              <SwiperSlide key={event.eventId}>
                <EventBoxMap
                  event={event}
                  source={"map_thumbnail_multiple"}
                  order={i + 1}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </CreateAnimation>
  );
};

function create_marker(
  marker_container: MarkerContainer,
  event: Event,
  map: google.maps.Map,
  setOpenThumbnailKey: React.Dispatch<React.SetStateAction<string | undefined>>
) {
  console.log("creating marker");
  if (!event.location?.lat || !event.location?.lng) return;
  const key = get_gps_key(event.location?.lat, event.location?.lng);
  let tags = getLevelOneTags(event.tags);

  if (event.location?.lat && event.location.lng) {
    marker_container[key] = {
      marker: new google.maps.Marker({
        position: new google.maps.LatLng(
          event.location.lat,
          event.location.lng
        ),
        map: map,
        title: key,
        icon: {
          url: set_pin({ event, tags }),
        },
      }),
      active: new Set([event.eventId]),
    };
    marker_container[key].marker.addListener("click", () => {
      setOpenThumbnailKey(key);
    });
  }
}

function create_aggregated_marker(
  aggregated_marker_container: AggregatedMarkerContainer,
  key: string,
  aggregatedEvent: AggregatedEvent,
  map: google.maps.Map
) {
  console.log("creating marker");

  aggregated_marker_container[key] = new google.maps.Marker({
    position: new google.maps.LatLng(aggregatedEvent.lat, aggregatedEvent.lng),
    map: map,
    // title: event.title,
    icon: {
      url: set_aggregated_pin(aggregatedEvent.count),
    },
  });

  aggregated_marker_container[key].addListener("click", () => {
    const zoomLevel = map.getZoom();
    if (!zoomLevel) return;
    map.setZoom(Math.min(zoomLevel + MAP_ZOOM_STEP, DEFAULT_ZOOM));
    map?.setCenter(
      new google.maps.LatLng(aggregatedEvent.lat, aggregatedEvent.lng)
    );
  });
}

function redraw_markers(
  events: Event[],
  marker_container: MarkerContainer,
  map: google.maps.Map | undefined,
  bounds: google.maps.LatLngBoundsLiteral,
  selectedEvents: Event[],
  setSelectedEvents: React.Dispatch<React.SetStateAction<Event[]>>,
  setOpenThumbnailKey: React.Dispatch<React.SetStateAction<string | undefined>>,
  eventsVisible: boolean,
  setEventsVisible: React.Dispatch<React.SetStateAction<boolean>>
) {
  if (!map) return;

  let visible_evets = false;

  for (let event of events) {
    if (!event.location?.lat || !event.location?.lng) return;

    if (
      event.location &&
      (event.location.lat > bounds.north ||
        event.location.lat < bounds.south ||
        event.location.lng > bounds.east ||
        event.location.lng < bounds.west)
    ) {
      hide_marker(
        marker_container,
        events,
        event,
        selectedEvents,
        setSelectedEvents
      );
    } else {
      if (!visible_evets) visible_evets = true;
      if (marker_in_container(marker_container, event)) {
        show_marker(marker_container, events, event, map);
      } else {
        create_marker(marker_container, event, map, setOpenThumbnailKey);
      }
    }
  }

  if (eventsVisible !== visible_evets) setEventsVisible(visible_evets);

  clean_unused_markers(
    marker_container,
    events,
    selectedEvents,
    setSelectedEvents
  );
}

function redraw_agregated_markers(
  aggregatedEvents: AggregatedEvent[],
  map: google.maps.Map | undefined,
  bounds: google.maps.LatLngBoundsLiteral,
  aggEventsVisible: boolean,
  setAggEventsVisible: React.Dispatch<React.SetStateAction<boolean>>
) {
  let keys: string[] = [];

  let visible_evets = false;

  for (let event of aggregatedEvents) {
    const key = get_gps_key(event.lat, event.lng);
    keys.push(key);

    if (!map) return;

    if (
      event.lat > bounds.north ||
      event.lat < bounds.south ||
      event.lng > bounds.east ||
      event.lng < bounds.west
    ) {
      // outside of the view
      if (
        key in aggregated_marker_container &&
        aggregated_marker_container[key].getMap()
      )
        // marker exists and is on a map
        // return hide_aggregated_marker(aggregated_marker_container, key);
        aggregated_marker_container[key].setMap(null);
    } else {
      if (!visible_evets) visible_evets = true;
      // inside of the view
      if (key in aggregated_marker_container) {
        // marker exists and it on a map
        if (!aggregated_marker_container[key].getMap())
          // return show_aggregated_marker(aggregated_marker_container, key, map);
          aggregated_marker_container[key].setMap(map);

        aggregated_marker_container[key].setIcon({
          url: set_aggregated_pin(event.count),
        });
      } else {
        // marker doesnt exist
        create_aggregated_marker(aggregated_marker_container, key, event, map);
      }
    }
  }

  if (aggEventsVisible !== visible_evets) setAggEventsVisible(visible_evets);

  // delete markers that no longer exist
  for (const [key, value] of Object.entries(aggregated_marker_container)) {
    if (keys.includes(key)) continue;
    if (value.getMap()) value.setMap(null);
    delete aggregated_marker_container[key];
  }
}

function redraw_event_markers_if_needed(
  map: google.maps.Map | undefined,
  events: Event[],
  selectedEvents: Event[],
  setSelectedEvents: React.Dispatch<React.SetStateAction<Event[]>>,
  setOpenThumbnailKey: React.Dispatch<React.SetStateAction<string | undefined>>,
  eventsVisible: boolean,
  setEventsVisible: React.Dispatch<React.SetStateAction<boolean>>
) {
  const bounds = map?.getBounds()?.toJSON();
  if (bounds) {
    redraw_markers(
      events,
      marker_container,
      map,
      bounds,
      selectedEvents,
      setSelectedEvents,
      setOpenThumbnailKey,
      eventsVisible,
      setEventsVisible
    );
  }
}

function redraw_aggregated_event_markers_if_needed(
  map: google.maps.Map | undefined,
  aggregatedEvents: AggregatedEvent[],
  aggEventsVisible: boolean,
  setAggEventsVisible: React.Dispatch<React.SetStateAction<boolean>>
) {
  const bounds = map?.getBounds()?.toJSON();
  if (bounds) {
    redraw_agregated_markers(
      aggregatedEvents,
      map,
      bounds,
      aggEventsVisible,
      setAggEventsVisible
    );
  }
}

// const mapListSwitch = (value: any) => {
//   if (value === "list") return "map";
//   if (value === "map") return "list";
// };

const MapView: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [aggregatedEvents, setAggregatedEvents] = useState<AggregatedEvent[]>(
    []
  );
  const mapEle = useRef<HTMLDivElement>(null);
  const pageEle = useRef<HTMLIonContentElement>(null);
  const map = useRef<google.maps.Map>();
  const pin_loc = useRef<google.maps.Marker>();
  const [boundChangedSignal, setBoundsChangedSignal] = useState<number>(
    Math.random()
  );
  const [openThumbnailKey, setOpenThumbnailKey] = useState<string>();
  // const [showModal, setShowModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState<Event[]>([]);
  // const [prefetchedEvent, setPrefetchedEvent] = useState<Event>();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [showDatePopover, setShowDatePopover] = useState<boolean>(false);
  const [showCategoriesPopover, setShowCategoriesPopover] =
    useState<boolean>(false);
  const [eventsVisible, setEventsVisible] = useState<boolean>(true);
  const [aggEventsVisible, setAggEventsVisible] = useState<boolean>(true);
  // const [readOnly, setReadOnly] = useState<boolean>(false);
  // const searchBarValue = useRef<any>(null);
  const { gpsCoordinates, customGps, preferredLoc, tags } = useStorage();
  const { t } = useTranslation();

  const { axios } = useAxios();
  const filters = useBaseFilters(tags);
  const [mapInitialised, setMapInitialised] = useState<boolean>(false);
  const { north, south, east, west, aggLevel, gpsChangedSignal } =
    useOptimizedLocationFilter(map.current, mapInitialised);
  const location = useLocation<string>();
  const [tab, setTab] = useState<string | undefined>("map");
  const { centerDelayed, setCenter } = useMapCenter();
  const isNotValid = useValitadeLocation(centerDelayed);

  const [redirectBack, setRedirectBack] = useState<boolean>(false);

  useIonViewDidEnter(() => {
    if (location.state && location.state !== "map") setRedirectBack(true);
    else setRedirectBack(false);
  }, [location.state]);

  useEffect(() => {
    if (location.state === undefined) return;
    resetAllFilters(filters);
    if (location.state === "map") {
      setTab("map");
    } else if (location.state === "recommended") {
      setTab("list");
      filters.tags.setter(load<Set<string>>("interestsCategories", new Set()));
      filters.subtags.setter(load<Set<string>>("interestsTags", new Set()));
    } else if (location.state === "week") {
      setTab("list");
      filters.day.setter("week");
    } else {
      setTab("list");
      filters.tags.setter(new Set([location.state]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const currentLoc = useImpreciseGps();

  const { isLoading } = useQuery(
    [
      "search-map",
      north,
      east,
      south,
      west,
      filters.search.value,
      filters.day.value,
      Array.from(filters.tags.value).join(","),
      Array.from(filters.subtags.value).join(","),
      aggLevel,
      filters.dateTo.value, // only dateTo, not from. Calendar first updates To, when changing range, so we want to trigger change only when both sides of range are set
    ],
    () =>
      search<Event[] | AggregatedEvent[]>(axios, {
        source: "search_map",
        a_lat: north,
        a_lng: east,
        b_lat: south,
        b_lng: west,
        fulltext: filters.search.value,
        filter_date: filters.day.value,
        tags: Array.from(filters.tags.value).join(","),
        subtags: Array.from(filters.subtags.value).join(","),
        lat: centerDelayed?.lat,
        lng: centerDelayed?.lng,
        ref_lat: currentLoc?.lat,
        ref_lng: currentLoc?.lng,
        aggregation_level: aggLevel,
        date_from: filters.dateFrom.value,
        date_to: filters.dateTo.value,
        order_by: "start",
        order_direction: "ASC",
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        if (res.data.length && "count" in res.data[0]) {
          setEvents([]);
          setAggregatedEvents(res.data as AggregatedEvent[]);
        } else {
          setEvents(res.data as Event[]);
          setAggregatedEvents([]);
        }
      },
      enabled: !!north,
    }
  );

  useIonViewDidEnter(() => {
    pageEle.current?.classList.remove("ion-page-invisible");
  });

  useIonViewDidLeave(() => {
    pageEle.current?.classList.add("ion-page-invisible");
  });

  useEffect(() => {
    if (!customGps?.lat) handle_pin_loc(gpsCoordinates, map, mapEle, pin_loc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gpsCoordinates]);

  useEffect(() => {
    if (customGps?.lat) {
      map.current?.panTo(new google.maps.LatLng(customGps.lat, customGps.lng));
      map.current?.setZoom(JUMP_TO_CITY_ZOOM);
    }
  }, [customGps?.lat, customGps?.lng]);

  useEffect(() => {
    const int = setInterval(() => {
      if (isDevelopment) console.log("MAP INIT Interval");
      if (mapEle.current?.offsetWidth && !mapInitialised) {
        setMapInitialised(true);
        clearInterval(int);
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (map.current || !mapEle.current || !mapInitialised) return;

    map.current = new google.maps.Map(mapEle.current, {
      center: preferredLoc,
      zoom: DEFAULT_ZOOM,
      disableDefaultUI: true,
      clickableIcons: true,
      minZoom: MIN_ZOOM,
      styles: MAP_STYLES,
      keyboardShortcuts: false,
    });

    map.current.addListener("click", function (event: any) {
      if ("placeId" in event) event.stop();
    });
    // map.current.addListener("dragstart", () => {
    //   setReadOnly(true);
    // });
    // map.current.addListener("dragend", () => {
    //   setReadOnly(false);
    // });

    map.current.addListener("idle", () => {
      if (mapEle.current) {
        mapEle.current.classList.add("show-map");
      }
    });

    map.current.addListener("bounds_changed", () => {
      if (mapEle.current && map.current) {
        setBoundsChangedSignal(Math.random());

        const center = map.current?.getCenter()?.toJSON();
        if (center) setCenter(center);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map.current, mapEle.current, mapInitialised]);

  // // START TODO: check users on prod if they like this solution more or not
  // // Then move to hook or delete
  // const [listBehavior, setListBehavior] = useState<string>("default");
  // useEffect(() => {
  //   if (listBehavior === "default") {
  //     if (showSearchModal === false && filters.search.value.length > 0) {
  //       setListBehavior("fulltext");
  //       setTab("list");
  //     }
  //     if (showSearchModal === true && filters.search.value.length === 0) {
  //       setListBehavior("button");
  //     }
  //   }

  //   if (listBehavior === "fulltext") {
  //     if (showSearchModal === true && filters.search.value.length === 0) {
  //       setListBehavior("default");
  //       setTab("map");
  //     }
  //     if (showSearchModal === false && filters.search.value.length > 0) {
  //       setListBehavior("button");
  //     }
  //   }

  //   if (listBehavior === "button") {
  //     if (showSearchModal === false && filters.search.value.length === 0) {
  //       setListBehavior("default");
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [showSearchModal, filters.search.value]);
  // // END TODO

  useEffect(() => {
    close_thumbnails(
      marker_container,
      activated_markers,
      selectedEvents,
      setSelectedEvents,
      setOpenThumbnailKey,
      events
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boundChangedSignal]);

  useEffect(() => {
    logEvent("search_list", {
      state: showSearchModal ? "opened" : "closed",
    });
  }, [showSearchModal]);

  useEffect(() => {
    redraw_event_markers_if_needed(
      map.current,
      events,
      selectedEvents,
      setSelectedEvents,
      setOpenThumbnailKey,
      eventsVisible,
      setEventsVisible
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, gpsChangedSignal]);

  useEffect(() => {
    if (!openThumbnailKey) return;
    close_thumbnails(
      marker_container,
      activated_markers,
      selectedEvents,
      setSelectedEvents,
      setOpenThumbnailKey,
      events
    );
    open_thumbmail(
      events,
      marker_container,
      openThumbnailKey,
      activated_markers,
      setSelectedEvents
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openThumbnailKey]);

  useEffect(() => {
    redraw_aggregated_event_markers_if_needed(
      map.current,
      aggregatedEvents,
      aggEventsVisible,
      setAggEventsVisible
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregatedEvents, gpsChangedSignal]);

  useIonViewWillEnter(() => {
    showTabBar();
  });

  // function duplicate_event(eventId: number) {
  //   load_event(axios, eventId)
  //     .then((event) => {
  //       setPrefetchedEvent(event);
  //       setShowModal(true);
  //     })
  //     .catch((err) => setMessage("danger", undefined, err));
  // }

  // const subtags = tags?.map((category: Tag) => category.subtags.map((s) => s));

  useEffect(() => {
    setShowSearchModal(tab === "list" ? true : false);
  }, [tab]);

  return (
    <IonPage>
      {isLoading && <IonProgressBar type="indeterminate"></IonProgressBar>}

      <IonContent id="map-view" ref={pageEle} class="map-page" scrollY={false}>
        <div className="search-controls">
          <div className="search-controls-row">
            <IonIcon
              className="map-icon top-left"
              icon={searchIcon}
              onClick={() => setTab("list")}
            />
            <IonButton
              id="qf-dates-btn"
              size="small"
              fill="clear"
              className="btn-search-quick"
              onClick={() => setShowDatePopover(true)}
            >
              {filters.day.value === "range"
                ? dateRangeLabel(filters.dateFrom.value, filters.dateTo.value)
                : dayFilterLabel(filters.day.value)}
              <IonIcon icon={showDatePopover ? expandLess : expandMore} />
            </IonButton>
            <IonIcon
              className="map-icon top-right"
              icon={filterSmall}
              onClick={() => setShowFilters(true)}
            />
          </div>
          <div className="search-controls-row">
            <IonIcon
              className="map-icon bottom-left"
              icon={navigateSmall}
              onClick={() => {
                map.current?.panTo(preferredLoc);
                map.current?.setZoom(DEFAULT_ZOOM);
              }}
            />
            {/* <IonSegment
              value={tab}
              // onIonChange={(e) => {
              //   setTab(e.detail.value);
              // }}
              className="simple original switch"
              color="primary"
            >
              <IonSegmentButton
                value="list"
                onClick={() => {
                  setTab(mapListSwitch(tab));
                }}
              >
                <IonLabel>
                  <IonIcon icon={list} />
                </IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                value="map"
                onClick={() => {
                  setTab(mapListSwitch(tab));
                }}
              >
                <IonLabel>
                  <IonIcon icon={earth} />
                </IonLabel>
              </IonSegmentButton>
            </IonSegment> */}

            {/* <FulltextSearch
              mapSearchText={filters.search.value}
              setMapSearchText={filters.search.setter}
              setBoundsChangedSignal={setBoundsChangedSignal}
              showSearchModal={showSearchModal}
              highlighted={false}
              searchBarValue={searchBarValue}
              readOnly={readOnly}
            /> */}
          </div>
          {/* <div className="search-controls-row"> */}
          {/* <IonButton
              id="qf-categories-btn"
              size="small"
              fill={filters.tags.value.size === 0 ? "clear" : "outline"}
              className="btn-search-quick"
              onClick={() => setShowCategoriesPopover(true)}
            >
              {get_tags_filter_sum_label(
                tags,
                subtags?.flat(),
                filters.tags.value,
                filters.subtags.value
              )}
              <IonIcon icon={showCategoriesPopover ? expandLess : expandMore} />
            </IonButton> */}

          {/* <IonButton
              size="small"
              fill="clear"
              className="btn-search-quick"
              onClick={() => setShowFilters(true)}
            >
              More filters{" "}
              <IonIcon icon={showFilters ? expandLess : expandMore} />
            </IonButton> */}
          {/* </div> */}
        </div>

        <QFDatePopover
          dateFrom={filters.dateFrom.value}
          dateTo={filters.dateTo.value}
          showPopover={showDatePopover}
          setShowPopover={setShowDatePopover}
          filters={filters}
          setShowFilters={setShowFilters}
        />
        <QFCategoriesPopover
          showPopover={showCategoriesPopover}
          setShowPopover={setShowCategoriesPopover}
          setShowFilters={setShowFilters}
          filters={filters}
          tags={tags}
          value={filters.tags.value}
          setter={filters.tags.setter}
          subSetter={filters.subtags.setter}
          subValue={filters.subtags.value}
        />
        <div ref={mapEle} className="map-canvas"></div>
        {isNotValid && events.length === 0 && aggregatedEvents.length === 0 && (
          <div className="map_no-events">
            <div className="no-events-first-row">{t("map.banner_title")}</div>
            <div className="no-events-second-row">
              {t("map.banner_subtitle")}
            </div>
          </div>
        )}

        <div className="search_extras">
          {/* {currentLoc && (
            <div
              className="map_center"
              onClick={() => {
                map.current?.panTo(load_coordinates());
                map.current?.setZoom(DEFAULT_ZOOM);
                setCustomGps(null);
              }}
            >
              <IonIcon className="map_icon" icon={myLocation} />
            </div>
          )} */}
          <div className="map_placeholder" />

          {selectedEvents.length > 0 && (
            <EventThumbnail
              selectedEvents={selectedEvents}
              // duplicate_event={duplicate_event}
              close={() => {
                close_thumbnails(
                  marker_container,
                  activated_markers,
                  selectedEvents,
                  setSelectedEvents,
                  setOpenThumbnailKey,
                  events
                );
              }}
            />
          )}

          {/* <AddEvent
            showModal={showModal}
            setShowModal={setShowModal}
            map={map.current}
            type="default"
            onEventAdded={() => refetchEvents()}
            prefetchedEvent={prefetchedEvent}
          /> */}
        </div>
        {showSearchModal && (
          <SearchResults
            // duplicate_event={duplicate_event}
            filters={filters}
            setTab={setTab}
            // searchBarValue={searchBarValue}
            centerDelayed={centerDelayed}
            setBoundsChangedSignal={setBoundsChangedSignal}
            // showSearchModal={showSearchModal}
            setShowFilters={setShowFilters}
            redirectBack={redirectBack}
          />
        )}

        <Filters
          showSearchModal={showSearchModal}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          filters={filters}
          tags={tags}
          map={map.current}
        />
      </IonContent>
    </IonPage>
  );
};

export default MapView;
