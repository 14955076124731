import { IonPopover, IonList, IonItem } from "@ionic/react";
import moment, { Moment } from "moment";
import { useState } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import { useHistory } from "react-router";
import { EventSerie } from "../models/Event";

function options_in_event_date_options(
  date_options: EventSerie[],
  date: Moment
): Date[] {
  const filtered_dates = [];
  for (let i = 0; i < date_options.length; i++) {
    if (
      moment(date_options[i].start).format("YYYY-MM-DD") ===
      date.format("YYYY-MM-DD")
    )
      filtered_dates.push(date_options[i].start);
  }
  return filtered_dates;
}

export const DateOptions: React.FC<{
  eventSeries: EventSerie[];
  eventCurrentDate: Date;
}> = ({ eventSeries, eventCurrentDate }) => {
  const history = useHistory();
  const seriesDateStart: Date[] = [];
  const [showPopover, setShowPopover] = useState<{
    show: boolean;
    timeOptions: EventSerie[];
  }>({
    show: false,
    timeOptions: [],
  });
  if (eventSeries) eventSeries.map((date) => seriesDateStart.push(date.start));

  return (
    <>
      <h4>Alternative dates:</h4>

      <IonPopover
        isOpen={showPopover.show === true}
        onDidDismiss={(e) => {
          e.preventDefault();
          setShowPopover({
            show: false,
            timeOptions: [],
          });
        }}
      >
        <IonList lines="none">
          {showPopover.timeOptions.map((option: EventSerie, i: number) => (
            <IonItem
              key={i}
              onClick={(e) => {
                e.preventDefault();
                setShowPopover({
                  show: false,
                  timeOptions: [],
                });
                history.push("/events/" + option.eventId + "/about");
              }}
            >
              {moment(option.start).format("h:mm a")}
            </IonItem>
          ))}
        </IonList>
      </IonPopover>
      <Calendar
        onChange={(dates) => {
          var dates_stringed: string[] = [];

          if (dates instanceof DateObject)
            dates_stringed.push(moment(dates.toDate()).format("YYYY-MM-DD"));

          if (dates instanceof Array) {
            dates_stringed = dates.map((d) =>
              moment(d.toDate()).format("YYYY-MM-DD")
            );
          }
          let inital_dates: string[] = seriesDateStart.map((date) =>
            moment(date).format("YYYY-MM-DD")
          );
          const clickedDate = inital_dates.filter((date) => {
            return !dates_stringed.includes(date);
          });

          const filteredSerie = eventSeries.filter((date) => {
            return clickedDate.includes(
              moment(date.start).format("YYYY-MM-DD")
            );
          });

          if (filteredSerie.length === 1) {
            history.push("/events/" + filteredSerie[0].eventId + "/about");
          } else if (filteredSerie.length > 1) {
            setShowPopover({
              show: true,
              timeOptions: filteredSerie,
            });
          }
        }}
        weekStartDayIndex={1}
        value={seriesDateStart}
        minDate={
          eventCurrentDate > seriesDateStart[0]
            ? seriesDateStart[0]
            : eventCurrentDate
        }
        maxDate={
          seriesDateStart[seriesDateStart.length - 1] > eventCurrentDate
            ? seriesDateStart[seriesDateStart.length - 1]
            : eventCurrentDate
        }
        disableYearPicker={true}
        disableMonthPicker={true}
        shadow={false}
        mapDays={({ date, selectedDate, isSameDate }) => {
          const options = options_in_event_date_options(
            eventSeries,
            moment(date.toDate())
          );

          const currentEventDateOption = moment(date.toDate()).isSame(
            moment(eventCurrentDate),
            "day"
          );

          return {
            className: currentEventDateOption ? "current-event" : "",

            disabled:
              (selectedDate instanceof DateObject &&
                isSameDate(selectedDate, date)) ||
              (selectedDate instanceof Array &&
                selectedDate.some((d) => isSameDate(d, date)))
                ? false
                : true,
            children: (
              <>
                <div
                  className={isSameDate(date, new DateObject()) ? "today" : ""}
                >
                  {date.format("D")}
                </div>

                <div className="note">
                  <div className="text">
                    {options.length === 1 &&
                      moment(options[0]).format("h:mm a")}
                    {options.length > 1 && `${options.length} opt.`}
                  </div>
                </div>
              </>
            ),
          };
        }}
      />
    </>
  );
};
