import React, { useEffect, useState } from "react";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";

import { showTabBar } from "../helpers/tabBar";
import { useStorage } from "../hooks";
import { has_notification_path } from "../components/Notifications";
import { FeedbackForm } from "../components";
import useNotifications from "../hooks/useNotifications";

export const Feedbacks: React.FC = () => {
  // const { axios } = useAxios();
  // const { currentUser: user } = useStorage();
  // const { data: feedbacks, refetch } = useQuery(
  //   ["feedbacks"],
  //   () => axios.get<FeedbackData[]>("/feedback"),
  //   { select: (res) => res.data }
  // );
  const [lastNidsUpdate, setLastNidsUpdate] = useState<number[]>([]);
  const { submit } = useNotifications();

  useIonViewWillEnter(() => {
    showTabBar();
  });

  const { notifications } = useStorage();

  useEffect(() => {
    const nids = has_notification_path(notifications, ["feedback"]);

    const allNidsUpdated = nids.every((val) => lastNidsUpdate.includes(val));

    if (nids.length > 0 && !allNidsUpdated) {
      setLastNidsUpdate([...lastNidsUpdate, ...nids]);
      submit({ notificationIds: nids });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  return (
    <IonPage className="page-profile">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/profile"
              text=""
              className="back"
              icon={"/assets/icon/new/arrow-left.svg"}
            />
          </IonButtons>
          <IonTitle>Feedback</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <FeedbackForm callback={() => {}} />
        {/* <FeedbackForm callback={() => refetch()} /> */}

        {/* <h4 className="header-past-fb"> My past feedbacks</h4>
          <div>
            {feedbacks?.map((data) => (
              <Feedback
                key={`feedback-${data.feedbackId}`}
                {...data}
                callback={() => refetch()}
              />
            ))}
          </div> */}
      </IonContent>
    </IonPage>
  );
};

export default Feedbacks;
