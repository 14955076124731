import {
  IonItem,
  IonAvatar,
  IonSkeletonText,
  IonLabel,
  IonThumbnail,
  IonCard,
  IonHeader,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonContent,
  IonTitle,
  IonList,
} from "@ionic/react";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Skeleton.scss";
import "swiper/scss";
import "@ionic/react/css/ionic-swiper.css";

const skeletonCSS = {
  big: "skeleton skeleton-big",
  default: "skeleton",
};

type SkeletonProps = {
  size?: keyof typeof skeletonCSS;
};

const SkeletonEventList: React.FC<SkeletonProps> = ({ size = "default" }) => {
  return (
    <>
      {Array.from(Array(11)).map((_, index) => {
        return (
          <IonItem key={index} className={skeletonCSS[size]}>
            <IonAvatar>
              <IonSkeletonText animated className="skeleton-avatar" />
            </IonAvatar>
            <IonLabel>
              <span className="skeleton-line">
                <IonSkeletonText animated />
              </span>
              <span className="skeleton-line">
                <IonSkeletonText animated />
              </span>
              <span className="skeleton-line">
                <IonSkeletonText animated />
              </span>
            </IonLabel>
          </IonItem>
        );
      })}
    </>
  );
};

const SkeletonHomePage: React.FC = () => {
  return (
    <div className="skeletom-homepage-container">
      <div className="skeleton-homepage-header-box">
        <IonSkeletonText animated className="skeleton-homepage-title" />
        <IonSkeletonText animated className="skeleton-homepage-btn" />
      </div>
      <Swiper
        slidesPerView={1.3}
        initialSlide={0}
        speed={400}
        className="skeleton-homepage-slides"
      >
        <SwiperSlide className="skeleton-homepage-event-slide">
          <IonCard className="skeleton-homepage-event-card" button={true}>
            <IonThumbnail>
              <IonSkeletonText
                animated
                className="skelton-homepage-thumbnail"
              />
            </IonThumbnail>
          </IonCard>
        </SwiperSlide>
        <SwiperSlide className="skeleton-homepage-event-slide">
          <IonCard className="skeleton-homepage-event-card" button={true}>
            <IonThumbnail>
              <IonSkeletonText
                animated
                className="skelton-homepage-thumbnail"
              />
            </IonThumbnail>
          </IonCard>
        </SwiperSlide>
        <SwiperSlide className="skeleton-homepage-event-slide">
          <IonCard className="skeleton-homepage-event-card" button={true}>
            <IonThumbnail>
              <IonSkeletonText
                animated
                className="skelton-homepage-thumbnail"
              />
            </IonThumbnail>
          </IonCard>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

const SkeletonEvDet: React.FC = () => {
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="skelet-title">
            <IonSkeletonText animated={true}></IonSkeletonText>
          </IonTitle>
        </IonToolbar>
        <IonSegment>
          <IonSegmentButton>
            <IonSkeletonText
              animated={true}
              style={{ width: "30%" }}
            ></IonSkeletonText>
          </IonSegmentButton>
          <IonSegmentButton>
            <IonSkeletonText
              animated={true}
              style={{ width: "30%" }}
            ></IonSkeletonText>
          </IonSegmentButton>
        </IonSegment>
      </IonHeader>
      <IonContent>
        <IonThumbnail className="skeleton_ev_det">
          <IonSkeletonText animated={true}></IonSkeletonText>
        </IonThumbnail>
        <table className="table_skelet">
          <tbody>
            <tr className="skelet_line">
              <td>
                <IonItem lines="none">
                  <IonThumbnail slot="start" className="thumb_icon_skelet">
                    <IonSkeletonText></IonSkeletonText>
                  </IonThumbnail>
                  <IonLabel className="skelet_info_box">
                    <p className="skelet_info">
                      <IonSkeletonText animated={true}></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
              </td>
              <td>
                <IonItem lines="none">
                  <IonThumbnail slot="start" className="thumb_icon_skelet">
                    <IonSkeletonText></IonSkeletonText>
                  </IonThumbnail>
                  <IonLabel className="skelet_info_box">
                    <p className="skelet_info">
                      <IonSkeletonText animated={true}></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
              </td>
            </tr>
            <tr className="skelet_line">
              <td>
                <IonItem lines="none">
                  <IonThumbnail slot="start" className="thumb_icon_skelet">
                    <IonSkeletonText> </IonSkeletonText>
                  </IonThumbnail>
                  <IonLabel className="skelet_info_box">
                    <p className="skelet_info">
                      <IonSkeletonText animated={true}></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
              </td>
              <td>
                <IonItem lines="none">
                  <IonThumbnail slot="start" className="thumb_icon_skelet">
                    <IonSkeletonText></IonSkeletonText>
                  </IonThumbnail>
                  <IonLabel className="skelet_info_box">
                    <p className="skelet_info">
                      <IonSkeletonText animated={true}></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
              </td>
            </tr>
            <tr className="skelet_line">
              <td>
                <IonItem lines="none">
                  <IonThumbnail slot="start" className="thumb_icon_skelet">
                    <IonSkeletonText></IonSkeletonText>
                  </IonThumbnail>
                  <IonLabel className="skelet_info_box">
                    <p className="skelet_info">
                      <IonSkeletonText animated={true}></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="txt-skelet">
          <IonItem lines="none">
            <IonLabel>
              <h3 className="skeleton_head_desc">
                <IonSkeletonText
                  animated={true}
                  style={{ width: "80%" }}
                ></IonSkeletonText>
              </h3>
            </IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>
              <p>
                <IonSkeletonText animated={true}></IonSkeletonText>
              </p>
              <p>
                <IonSkeletonText animated={true}></IonSkeletonText>
              </p>
              <p>
                <IonSkeletonText animated={true}></IonSkeletonText>
              </p>
            </IonLabel>
          </IonItem>
        </div>
      </IonContent>
    </>
  );
};
const SkeletonPlaceDetail: React.FC = () => {
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <IonSkeletonText animated={true}></IonSkeletonText>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonThumbnail className="skeleton_ev_det">
          <IonSkeletonText animated={true}></IonSkeletonText>
        </IonThumbnail>
        <IonTitle className="skelet-title">
          <IonSkeletonText animated={true}></IonSkeletonText>
        </IonTitle>
        <IonItem lines="none">
          <IonLabel>
            <h2 className="skeleton_head_desc">
              <IonSkeletonText
                animated={true}
                style={{ width: "40%" }}
              ></IonSkeletonText>
            </h2>
          </IonLabel>
        </IonItem>
        <table className="table_skelet">
          <tbody>
            <tr className="skelet_line">
              <td>
                <IonItem lines="none">
                  <IonThumbnail slot="start" className="thumb_icon_skelet">
                    <IonSkeletonText animated={true}> </IonSkeletonText>
                  </IonThumbnail>
                  <IonLabel className="skelet_info_box">
                    <p className="skelet_info">
                      <IonSkeletonText animated={true}></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
              </td>
            </tr>
            <tr className="skelet_line">
              <td>
                <IonItem lines="none">
                  <IonThumbnail slot="start" className="thumb_icon_skelet">
                    <IonSkeletonText animated={true}></IonSkeletonText>
                  </IonThumbnail>
                  <IonLabel className="skelet_info_box">
                    <p className="skelet_info">
                      <IonSkeletonText animated={true}></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="txt-skelet">
          <IonItem lines="none">
            <IonLabel>
              <h2 className="skeleton_head_desc">
                <IonSkeletonText
                  animated={true}
                  style={{ width: "40%" }}
                ></IonSkeletonText>
              </h2>
            </IonLabel>
          </IonItem>
        </div>
        <IonList>
          <SkeletonEventList size="big" />
        </IonList>
      </IonContent>
    </>
  );
};

export {
  SkeletonEventList,
  SkeletonHomePage,
  SkeletonEvDet,
  SkeletonPlaceDetail,
};
