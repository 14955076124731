import React, { useRef } from "react";
import {
  IonContent,
  useIonViewWillEnter,
  IonPage,
  IonButton,
  IonIcon,
} from "@ionic/react";
import "./Intro.scss";
import { hideTabBar } from "../helpers/tabBar";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { useNavigation } from "../hooks";

const Intro: React.FC = () => {
  const { navigate } = useNavigation();
  const swiper = useRef<SwiperRef>(null);

  useIonViewWillEnter(() => {
    hideTabBar();
  });

  const pagination = {
    clickable: false,
    shortSwipes: true,
    renderBullet: function (index: number, className: string) {
      return '<div class="' + className + '"></div>';
    },
  };

  function nextSlide() {
    swiper.current?.swiper.slideNext();
  }

  return (
    <IonPage className="page-intro">
      <IonContent fullscreen>
        <div className="welcome">
          <IonIcon icon="/assets/icon/new/mw-white.svg" />
          <div className="text">Welcome to Manyways</div>
        </div>
        <Swiper
          ref={swiper}
          className="fullslides"
          pagination={pagination}
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
            stopOnLastSlide: true,
          }}
        >
          <SwiperSlide className="slide-category-1" onClick={nextSlide}>
            <div className="main">
              <div className="content-padded">
                {/* <div className="label">Music</div> */}
                <h1>Explore the best events</h1>
                <div className="text">
                  Broaden your horizons with our carefully curated experiences –
                  it's time to make some unforgettable memories.
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-category-2" onClick={nextSlide}>
            <div className="main">
              <div className="content-padded">
                {/* <div className="label">Music</div> */}
                <h1>Dive into your passion</h1>
                <div className="text">
                  Whether it's the beat of music, the thrill of sports, or the
                  magic of dance and theater, we've got your interests covered.
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-category-3" onClick={nextSlide}>
            <div className="main">
              <div className="content-padded">
                {/* <div className="label">Music</div> */}
                <h1>Get ready for the action</h1>
                <div className="text">
                  Take a pick from events tailored just for you. Share them with
                  friends, find tickets and make plans together effortlessly.
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-category-4">
            <div className="main">
              <div className="content-padded">
                {/* <div className="label">Music</div> */}
                <h1>Embrace the joy of living!</h1>
                <div className="text">
                  Come and join the fun, be part of special moments, and
                  celebrate life to the fullest. Try Manyways.
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className="actions">
          <IonButton onClick={() => navigate("/guidepost")}>
            Get started
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Intro;
