export const registrationError = {
  400: "Invalid phone number",
  404: "Missing invitation",
  409: "User already exists",
  429: "Too many tries, please, try again later",
  500: "Error, please try again",
};

export const loginError = {
  400: "The phone number or password is incorrect",
  404: "The phone number or password is incorrect",
};

export const passwordResetError = {
  400: "The phone number or password is incorrect",
};

export const verificationCodeError = {
  429: "Invalid code",
};

export type RegistrationErrorCode = keyof typeof registrationError;
export type LoginErrorCode = keyof typeof loginError;
export type PasswordResetErrorCode = keyof typeof passwordResetError;
export type VerificationCodeError = keyof typeof verificationCodeError;
