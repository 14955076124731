import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonFooter,
  IonGrid,
  IonRow,
  IonCol,
  useIonViewWillLeave,
  IonTextarea,
  IonIcon,
  IonPopover,
  isPlatform,
  IonImg,
  IonSpinner,
  IonButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import { useRollbar } from "@rollbar/react";
import { Keyboard } from "@capacitor/keyboard";
import "./Discussion.scss";

import copy from "copy-to-clipboard";
import ImageSlides from "react-imageslides";
import { ImagePicker } from "@ionic-native/image-picker";
import { PhotoLibrary } from "@ionic-native/photo-library";
import { CameraSource } from "@capacitor/camera";
import { Diagnostic } from "@ionic-native/diagnostic";
import "react-imageslides/lib/index.css";
import { ChatType, Chat, Message, ChatFile } from "../models/chat";
import { add_group_members, get_group } from "../resources/groups";
import { get_user } from "../resources/users";
import { format_post_time } from "./Messages";
import { AddMembersModal, Avatar } from "./Users";
import moment from "moment";
import { takePhotoWithAction } from "../helpers/camera";
import { canvasToBlob, resize_image } from "./Cropper";
import { api_url } from "../config";
import { createPortal } from "react-dom";
import { ToastMessage } from "../models/Toast";
import { PopoverSettings } from "../models/popover";
import { SimpleUser } from "../models/User";
import { Event } from "../models/Event";
import { link_replace_config } from "../helpers/textReplaceConfig";
import {
  calc_end_date,
  getGroupMeetingTitle,
  getPersonalMeetingTitle,
  get_event_attendee_ids,
} from "../helpers/event";
import { invite_to_event, load_event } from "../resources/events";
import AddEvent from "./AddEvent";
import { useStorage } from "../hooks";

// TODO: Change later
import { SetMessage as MessageContextType } from "../contexts/StateStorage";
import { TITLE_LENGTH_LIMIT } from "../constants/events";
import { EmptySection } from "./EmptySection";
import { useChat } from "../hooks";
import { long_press } from "../helpers/gestures";
import { SeenBy } from "../hooks/useChat";
import {
  startSensitive,
  stopSensitive,
  useSmartlookSensitive,
} from "../hooks/useSmartlook";
import useNotifications from "../hooks/useNotifications";
import {
  close,
  contentCopy,
  done,
  download,
  download as downloadIcon,
  sentiment,
  trash,
} from "../icons/iconsOutline";
import {
  photoCameraFill,
  imageFill,
  sendFill,
  checkCircleFill,
} from "../icons/iconsFill";
import useAxios from "../hooks/useAxios";
import useTranlatedMsg from "../hooks/useTranslatedMsg";
import { useTranslation } from "react-i18next";

const formatUnicorn = require("format-unicorn/safe");
const processString = require("react-process-string");

const PHOTOLIBRARY_NAME = "Manyways";
const SLIDES_TOAST_TIMEOUT = 1500;
const ANDROID_PHOTOLIBRARY_DOWNLOAD_INTERVAL = 250;

export const ImageSlidesClose: React.FC<{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isOpen, setIsOpen }) => {
  return createPortal(
    <div
      className="slides_close"
      style={{ display: isOpen ? "block" : "none" }}
      onClick={() => setIsOpen(false)}
    >
      <IonIcon icon={close} />
    </div>,
    document.body
  );
};

const ImageSlidesDownload: React.FC<{
  isOpen: boolean;
  images: string[];
  index: number;
  setSlideMessage: MessageContextType;
}> = ({ isOpen, images, index, setSlideMessage }) => {
  const { no_access, downloaded, fail_download } = useTranlatedMsg();
  return createPortal(
    <div
      className="slides_download"
      style={{ display: isOpen ? "block" : "none" }}
      onClick={() =>
        download_images(
          [images[index]],
          setSlideMessage,
          downloaded,
          fail_download,
          no_access
        )
      }
    >
      <IonIcon icon={download} />
    </div>,
    document.body
  );
};

const ImageSlidesToast: React.FC<{
  slideToast: ToastMessage | undefined;
  setSlideToast: React.Dispatch<React.SetStateAction<ToastMessage | undefined>>;
}> = ({ slideToast, setSlideToast }) => {
  useEffect(() => {
    let timer = setTimeout(
      () => setSlideToast(undefined),
      SLIDES_TOAST_TIMEOUT
    );

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideToast]);

  return createPortal(
    <div
      className="slides_toast"
      style={{ display: slideToast !== undefined ? "block" : "none" }}
    >
      <IonIcon icon={slideToast?.type === "danger" ? close : done} />
      <div>{slideToast?.text}</div>
    </div>,
    document.body
  );
};

async function resizeImgFromGallery(image: any) {
  const canvas = await resize_image("data:image/jpeg;base64," + image, 1000);
  return await canvasToBlob(canvas, "image/jpeg", 0.9);
}

async function pick_images_from_gallery(
  files: Blob[],
  setFiles: React.Dispatch<React.SetStateAction<Blob[]>>,
  setLoadingImages: React.Dispatch<React.SetStateAction<boolean>>,
  setMessage: MessageContextType,
  errMsg: string
) {
  await startSensitive();
  setLoadingImages(true);

  let blobs: Blob[] = [];

  let promises = [];

  try {
    await PhotoLibrary.requestAuthorization({ read: true, write: true });

    // put inside try...catch to avoid double request of authorization when first deny
    // on the top of opened app settings
    const images = await ImagePicker.getPictures({ outputType: 1 });

    if (!images || images === "OK") {
      setLoadingImages(false);
      return;
    }

    for (let i = 0; i < images.length; i++) {
      promises.push(resizeImgFromGallery(images[i]));
    }
  } catch (err) {
    setLoadingImages(false);
    setMessage("danger", errMsg, err);
    // setMessage("danger", "error getting images", err);
    if (
      isPlatform("android") &&
      typeof err === "string" &&
      err.startsWith("Permission")
    ) {
      // open settings for Manyways app
      Diagnostic.switchToSettings();
    }
  }

  Promise.allSettled(promises).then((results: any) => {
    for (let i = 0; i < promises.length; i++) {
      blobs.push(results[i].value);
    }
    setFiles(files.concat(blobs));
    setLoadingImages(false);
  });
  await stopSensitive();
}

async function download_images(
  filenames: string[],
  setMessage: MessageContextType,
  succesMsg: string,
  errMsg: string,
  errAccess: string,
  closePopover?: () => void
) {
  PhotoLibrary.requestAuthorization({
    read: true,
    write: true,
  })
    .then(() => {
      if (isPlatform("android")) {
        const timeout = setTimeout(() => {
          setMessage("primary", succesMsg);
          closePopover && closePopover();
        }, 500 + filenames.length * ANDROID_PHOTOLIBRARY_DOWNLOAD_INTERVAL);

        for (let i = 0; i < filenames.length; i++) {
          setTimeout(() => {
            PhotoLibrary.saveImage(filenames[i], PHOTOLIBRARY_NAME).catch(
              (err) => {
                clearTimeout(timeout);
                setMessage("danger", errMsg, err);
              }
            );
          }, i * ANDROID_PHOTOLIBRARY_DOWNLOAD_INTERVAL);
        }
      } else {
        let promises: any[] = [];

        for (let i = 0; i < filenames.length; i++) {
          promises.push(
            PhotoLibrary.saveImage(filenames[i], PHOTOLIBRARY_NAME)
          );

          Promise.allSettled(promises)
            .then(() => {
              setMessage("primary", succesMsg);
              closePopover && closePopover();
            })
            .catch((err) => setMessage("danger", errMsg, err));
        }
      }
    })
    .catch((err) => setMessage("danger", errAccess, err));
}

function get_chat_member(members: SimpleUser[], user_id: number) {
  for (let i = 0; i < members.length; i++) {
    if (members[i].userId === user_id) return members[i];
  }
}

const SeenByNames: React.FC<{ userIds: number[]; chat: Chat | null }> = ({
  userIds,
  chat,
}) => {
  const [userNames, setUserNames] = useState("");
  const { axios } = useAxios();
  useEffect(() => {
    let tempNames: string[] = [];
    async function getNames() {
      for (let id of userIds) {
        if (chat) {
          // TODO: prefetch members when composing lastSeenMessages
          const member = get_chat_member(chat.members, id);
          if (member) {
            tempNames.push(member.name);
          } else {
            try {
              let res = await get_user(id, axios);
              tempNames.push(res.name);
            } catch (err) {
              console.error(err);
            }
          }
        }
      }
    }
    getNames().then(() => setUserNames(tempNames.sort().join(", ")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIds, chat]);

  return <>Seen by: {userNames.length ? userNames : ""}</>;
};

interface SeenByAvatar {
  photo: string | undefined;
  id: number;
  name: string;
}

const SeenByAvatars: React.FC<{ userIds: number[]; chat: Chat | null }> = ({
  userIds,
  chat,
}) => {
  const [users, setUsers] = useState<SeenByAvatar[]>([]);
  const { axios } = useAxios();

  useEffect(() => {
    const tempUsers: SeenByAvatar[] = [];
    async function getPhotos() {
      for (let id of userIds) {
        if (chat) {
          // TODO: prefetch members when composing lastSeenMessages
          const member = get_chat_member(chat.members, id);
          if (member) {
            tempUsers.push({ photo: member.photo, id, name: member.name });
          } else {
            try {
              let res = await get_user(id, axios);
              tempUsers.push({ photo: res.photo, id, name: res.name });
            } catch (err) {
              console.error(err);
            }
          }
        }
      }
    }
    getPhotos().then(() =>
      setUsers(tempUsers.sort((a, b) => (a.name > b.name ? 1 : -1)))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat, userIds]);

  return (
    <>
      {users.map((user) => (
        <Avatar key={user.id} photo={user.photo} />
      ))}
    </>
  );
};

const NoMessages: React.FC<{ chat: Chat; chatType: ChatType; id: number }> = ({
  chat,
  chatType,
  id,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [event, setEvent] = useState<Event>();
  const [message, setStateMessage] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [invitees, setInvitees] = useState<number[]>([]);
  const { currentUser: user, setMessage } = useStorage();
  const { axios } = useAxios();
  const { invit_sent } = useTranlatedMsg();

  useEffect(() => {
    if (!user) return;
    if (chatType === "event") {
      load_event(axios, id)
        .then((res) => {
          setEvent(res);
          setStateMessage("Post a message to event participants or");
          setButtonText("Invite friends");
        })
        .catch((err) => setMessage("danger", undefined, err));
    } else if (chatType === "user") {
      const friend = chat.members.find(
        (member) => member.userId !== user.userId
      );
      const friendName = friend!.name.split(" ")[0];
      const userName = user.name.split(" ")[0];
      setInvitees([friend!.userId]);
      setStateMessage(`Send a message to ${friendName} or`);
      setEventTitle(
        getPersonalMeetingTitle(friendName, userName, TITLE_LENGTH_LIMIT)
      );
      setButtonText("Meet in person");
    } else if (chatType === "group") {
      get_group(id, axios)
        .then((res) => {
          setStateMessage(`Send a message to ${res.name} group or`);
          setEventTitle(getGroupMeetingTitle(res.name, TITLE_LENGTH_LIMIT));
          setButtonText("Add friends");
        })
        .catch((err) => setMessage("danger", undefined, err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat, chatType, user, id]);

  const onChange = (selectedUsers: number[]) => {
    if (chatType === "event") {
      invite_to_event(id, selectedUsers, axios)
        .then(() => setMessage("primary", invit_sent))
        .catch((err) => setMessage("danger", undefined, err));
    } else if (chatType === "group") {
      add_group_members(id, selectedUsers, axios).catch((err) =>
        setMessage("danger", undefined, err)
      );
    }
  };

  return (
    <>
      {buttonText.length ? (
        <>
          <EmptySection
            icon={sentiment}
            iconColor={
              event && moment(calc_end_date(event)) < moment()
                ? "dark"
                : "primary"
            }
            title={"Nothing here yet"}
            description={message}
          >
            <IonButton
              className="button-primary-light"
              fill="clear"
              size="small"
              color={
                event && moment(calc_end_date(event)) < moment()
                  ? "dark"
                  : undefined
              }
              onClick={() => setShowModal(true)}
              disabled={event && moment(calc_end_date(event)) < moment()}
            >
              {buttonText}
            </IonButton>
          </EmptySection>

          {chatType === "user" ? (
            <AddEvent
              showModal={showModal}
              setShowModal={setShowModal}
              type="personal"
              prefilledEventTitle={eventTitle}
              prefilledEventInvitees={invitees}
            />
          ) : (
            <AddMembersModal
              presentUsers={get_event_attendee_ids(event)}
              showModal={showModal}
              setShowModal={setShowModal}
              onChange={onChange}
              typeOfModal={
                chatType === "event" ? "invite_friends" : "add_friends"
              }
            />
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

const DiscussionFooter: React.FC<{
  submit_message: CallableFunction;
  chat_content: React.RefObject<HTMLIonContentElement>;
}> = ({ submit_message, chat_content }) => {
  const [files, setFiles] = useState<Blob[]>([]);
  const [loadingImagesGallery, setLoadingImagesGallery] =
    useState<boolean>(false);
  const [loadingImagesCamera, setLoadingImagesCamera] =
    useState<boolean>(false);
  const [message, setStateMessage] = useState<string>("");
  const chatInputRef = useRef<HTMLIonTextareaElement>(null);
  const { setMessage } = useStorage();

  const { access_denied, failed_photo, gallery } = useTranlatedMsg();

  const onClick = () => {
    const trimmed_msg = message.trim();
    if (!trimmed_msg && files.length === 0) return;
    chatInputRef.current?.setFocus();
    submit_message(trimmed_msg, files);
    setStateMessage("");
    setFiles([]);
  };

  const resize = async (image: string) => {
    return await resize_image(image, 1000).then((canvas: HTMLCanvasElement) => {
      canvasToBlob(canvas, "image/jpeg", 0.9).then((blob) =>
        setFiles([...files, blob])
      );
    });
  };

  return (
    <IonFooter className="discussion_form">
      <IonGrid>
        <IonRow>
          <IonCol size="10">
            {!message && !files.length && (
              <>
                {loadingImagesCamera ? (
                  <IonSpinner
                    color="primary"
                    name="crescent"
                    className="select_images from_camera"
                  />
                ) : (
                  <IonIcon
                    color="primary"
                    icon={photoCameraFill}
                    size="large"
                    className="select_photo"
                    onClick={() =>
                      takePhotoWithAction(
                        resize,
                        setMessage,
                        access_denied,
                        failed_photo,
                        setLoadingImagesCamera,
                        CameraSource.Camera
                      )
                    }
                  />
                )}
                {loadingImagesGallery ? (
                  <IonSpinner
                    color="primary"
                    name="crescent"
                    className="select_images"
                  />
                ) : (
                  <IonIcon
                    color="primary"
                    icon={imageFill}
                    size="large"
                    className="select_images"
                    onClick={() => {
                      pick_images_from_gallery(
                        files,
                        setFiles,
                        setLoadingImagesGallery,
                        setMessage,
                        gallery
                      );
                    }}
                  />
                )}
              </>
            )}

            {files.length ? (
              files.map((file, i) => {
                return (
                  <div key={i} className="chat_file_to_upload">
                    <IonIcon
                      icon={close}
                      onClick={() => {
                        setFiles(files.filter((_, fi) => fi !== i));
                      }}
                    />
                    <img
                      src={URL.createObjectURL(file)}
                      alt="file"
                      className="smartlook-hide"
                      data-sl="exclude"
                    />
                  </div>
                );
              })
            ) : (
              <IonItem lines="none">
                <IonTextarea
                  aria-label="discussionMessage"
                  ref={chatInputRef}
                  className={[
                    "discussion_input",
                    "smartlook-hide",
                    message
                      ? "discussion_input_full"
                      : "discussion_input_empty",
                  ].join(" ")}
                  data-sl="exclude"
                  rows={1}
                  autoGrow={true}
                  value={message}
                  placeholder="Write something"
                  autocapitalize="true"
                  onIonInput={(e) => setStateMessage(e.detail.value!)}
                  onIonFocus={() => {
                    chat_content.current?.scrollToBottom();
                  }}
                ></IonTextarea>
              </IonItem>
            )}
          </IonCol>
          <IonCol size="2">
            <IonIcon
              color="primary"
              icon={sendFill}
              size="large"
              className="submit"
              onClick={onClick}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonFooter>
  );
};

const MessagePopover: React.FC<{
  activeMessage: Message | null;
  filename: string | null;
  popoverState: PopoverSettings;
  closePopover: () => void;
  deleteMessage: () => void;
}> = ({
  activeMessage,
  filename,
  popoverState,
  closePopover,
  deleteMessage,
}) => {
  const { currentUser: user, setMessage } = useStorage();
  const { message, user: userMessage, File } = activeMessage || {};
  const { downloaded, text_copied, fail_download, access_denied } =
    useTranlatedMsg();
  const { t } = useTranslation();

  return (
    <IonPopover
      className="popover_options"
      event={popoverState.event}
      isOpen={popoverState.showPopover}
      onDidDismiss={() => closePopover()}
    >
      <IonList>
        <IonItem
          button
          detail={false}
          onClick={() => {
            if (message) {
              copy(message);
            }
            closePopover();
            setMessage("primary", text_copied);
          }}
        >
          <IonIcon icon={contentCopy} size="default"></IonIcon> Copy text
        </IonItem>
        {filename && (
          <IonItem
            button
            detail={false}
            onClick={() => {
              if (activeMessage && setMessage !== undefined)
                download_images(
                  [`${api_url}/files/chats/${filename}`],
                  setMessage,
                  downloaded,
                  fail_download,
                  access_denied,
                  closePopover
                );
            }}
          >
            <IonIcon icon={downloadIcon} size="default"></IonIcon>
            {t("discussion.donwload_image")}
          </IonItem>
        )}
        {filename && File && File.length > 1 && (
          <IonItem
            button
            detail={false}
            onClick={() => {
              if (activeMessage && setMessage !== undefined) {
                download_images(
                  File
                    ? File.map((img) => `${api_url}/files/chats/${img.name}`)
                    : [],
                  setMessage,
                  downloaded,
                  fail_download,
                  access_denied,
                  closePopover
                );
              }
            }}
          >
            <IonIcon icon={downloadIcon} size="default"></IonIcon>{" "}
            {t("discussion.download_all")} {File && File.length}{" "}
            {t("discussion.images")}
          </IonItem>
        )}
        {userMessage?.userId === user?.userId ? (
          <IonItem button detail={false} onClick={deleteMessage}>
            <IonIcon icon={trash} size="default"></IonIcon>{" "}
            {t("discussion.erase")}
          </IonItem>
        ) : null}
      </IonList>
    </IonPopover>
  );
};

const MessageImages: React.FC<{
  messageId: number;
  messageUserId: number | undefined;
  files: ChatFile[];
  onClick: (i: number, files: ChatFile[]) => void;
}> = ({ messageId, messageUserId, files, onClick }) => {
  const { currentUser: user } = useStorage();

  return (
    <>
      {files.map((file, i) => {
        return (
          <IonImg
            key={i}
            className={[
              "chat_file",
              files.length === 1 ? "chat_file_single" : "",
            ].join(" ")}
            src={`${api_url}/files/chats/${file.name}`}
            alt="file"
            data-id={messageId}
            data-owner={messageUserId === user?.userId ? "me" : "them"}
            data-filename={file.name}
            onClick={() => onClick(i, files)}
          />
        );
      })}
    </>
  );
};

const MessageText: React.FC<{
  post: Message;
}> = ({ post }) => {
  const { currentUser: user, setMessage } = useStorage();

  return (
    <span
      className="message_text"
      data-id={post.messageId}
      data-owner={
        post.user && post.user.userId === user?.userId ? "me" : "them"
      }
    >
      {post.variables
        ? formatUnicorn(
            processString(link_replace_config(setMessage))(post.message),
            post.variables
          )
        : processString(link_replace_config(setMessage))(post.message)}
    </span>
  );
};

const MessagesShell: React.FC<{
  post: Message;
  chat: Chat;
  setSlideImages: React.Dispatch<React.SetStateAction<string[]>>;
  setSlideIndex: React.Dispatch<React.SetStateAction<number>>;
  setSlideIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  seenBy: SeenBy | null;
  activeSeenBy: Number | undefined;
  setActiveSeenBy: React.Dispatch<React.SetStateAction<Number | undefined>>;
  unreadThreshold: Date | null;
  index: number;
  messages: Message[];
}> = ({
  post,
  chat,
  setSlideImages,
  setSlideIndex,
  setSlideIsOpen,
  seenBy,
  activeSeenBy,
  setActiveSeenBy,
  unreadThreshold,
  index,
  messages,
}) => {
  const { currentUser: user } = useStorage();

  let item_classes = [
    post.user
      ? post.user.userId === user?.userId
        ? "mine"
        : "their"
      : "system",
  ];

  if (
    index < messages.length - 1 &&
    post.user?.userId === messages[index + 1].user?.userId
  ) {
    item_classes.push("message_same_user");
    if (moment(post.posted).isSame(messages[index + 1].posted, "minute"))
      item_classes.push("message_same_time");
  }

  if (
    unreadThreshold &&
    index > 0 &&
    unreadThreshold >= post.posted &&
    unreadThreshold < messages[index - 1].posted
  )
    item_classes.push("message_last_seen");
  return (
    <IonItem key={post.messageId} className={item_classes.join(" ")}>
      {post.user && post.user.userId !== user?.userId && (
        <Avatar photo={post.user.photo} />
      )}
      <IonLabel>
        {post.user && (
          <h3>
            {post.user.userId === user?.userId ||
            chat === null ||
            (chat?.members && chat.members.length <= 2)
              ? ""
              : post.user.name}
            <span className="time">{format_post_time(post.posted)}</span>
          </h3>
        )}
        {post.File.length ? (
          <MessageImages
            messageId={post.messageId}
            messageUserId={post.user?.userId}
            files={post.File}
            onClick={(i, files) => {
              setSlideImages(
                files.map((file) => `${api_url}/files/chats/${file.name}`)
              );
              setSlideIndex(i);
              setSlideIsOpen(true);
            }}
          />
        ) : (
          <MessageText post={post} />
        )}
        {chat && seenBy?.[post.messageId] && (
          <div
            className="chat_seen_by"
            onClick={() => {
              if (activeSeenBy === post.messageId) setActiveSeenBy(undefined);
              else setActiveSeenBy(post.messageId);
            }}
          >
            {chat.members.length <= 2 ? (
              <IonIcon icon={checkCircleFill} color="primary" />
            ) : activeSeenBy === post.messageId ? (
              <SeenByNames userIds={seenBy[post.messageId]} chat={chat} />
            ) : (
              <SeenByAvatars userIds={seenBy[post.messageId]} chat={chat} />
            )}
          </div>
        )}
      </IonLabel>
    </IonItem>
  );
};

export const Discussion: React.FC<{
  type: ChatType;
  id: number;
  chat: Chat;
  isActive: boolean;
  groupId?: number | null;
}> = ({ type, id, chat, groupId, isActive }) => {
  const { lastVisit } = chat;
  const rollbar = useRollbar();
  const { currentUser: user } = useStorage();
  const chat_content = useRef<HTMLIonContentElement>(null);

  const [slideImages, setSlideImages] = useState<string[]>([]);
  const [slideIsOpen, setSlideIsOpen] = useState<boolean>(false);
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [slideToast, setSlideToast] = useState<ToastMessage | undefined>(
    undefined
  );

  const [activeSeenBy, setActiveSeenBy] = useState<Number | undefined>();
  const [filename, setFilename] = useState<string | null>(null);
  const [popoverState, setShowPopover] = useState<PopoverSettings>({
    showPopover: false,
    event: undefined,
  });
  const { updatePathsAsSeen } = useNotifications();

  useSmartlookSensitive(slideIsOpen);

  const closePopover = () => {
    setShowPopover({ showPopover: false, event: undefined });
  };

  const {
    updateLastVisit,
    sendMessage,
    activeMessage,
    setActiveMessage,
    deleteMessage,
    unreadThreshold,
    seenBy,
    loadMore,
    postMessage,
    postFile,
    allMessages,
    newMessages,
    previousMessagesFetched,
  } = useChat({
    id: chat.chatId,
    groupId,
    lastVisit,
    isActive: true,
    userId: user?.userId,
  });

  function setSlideMessage(
    type: "danger" | "primary",
    text?: string,
    error?: any
  ) {
    if (text)
      setSlideToast({
        type: type,
        text: text,
      });
    if (error) {
      console.error(error);
      if (type === "danger") rollbar.error(text, error);
    }
  }

  const handleDelete = () => {
    deleteMessage(activeMessage, filename);
    closePopover();
  };

  useEffect(() => {
    updatePathsAsSeen([[type, id, "chat"]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (chat && isActive && groupId && ["user", "group"].includes(type)) {
      updateLastVisit(groupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat.chatId, isActive, newMessages.data]);

  function submit_message(trimmed_msg: string, files: Blob[]) {
    if (user && chat) {
      sendMessage(trimmed_msg, files);
    }
    chat_content.current?.scrollToBottom();
  }

  useEffect(() => {
    const timer = setTimeout(() => chat_content.current?.scrollToBottom(), 500);
    return () => clearTimeout(timer);
  }, [newMessages.data]);

  if (isPlatform("capacitor")) {
    Keyboard.addListener("keyboardDidShow", (info) => {
      chat_content.current?.scrollToBottom();
    });

    Keyboard.addListener("keyboardDidHide", () => {
      chat_content.current?.scrollToBottom();
    });
  }

  useEffect(() => {
    const messages = document.querySelectorAll(".message_text, .chat_file");
    if (messages) {
      for (let i = 0; i < messages.length; i++) {
        const message = messages[i] as HTMLElement;

        // eslint-disable-next-line no-loop-func
        long_press(message, (e) => {
          let active_message = undefined;

          if (allMessages && message.dataset.id) {
            for (let i = allMessages.length - 1; i >= 0; i--) {
              if (allMessages[i].messageId === parseInt(message.dataset.id)) {
                active_message = allMessages[i];
                break;
              }
            }
          }

          if (active_message) {
            setActiveMessage(active_message);
            setFilename(message.dataset.filename || null);
            setShowPopover({ showPopover: true, event: e.event });
          } else {
            setActiveMessage(null);
            setFilename(null);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMessages]);

  useIonViewWillLeave(() => {
    // setActive(false);
    if (groupId) updateLastVisit(groupId);
  }, [groupId, chat]);

  const handleSlideChange = useCallback((newIndex: number) => {
    setSlideIndex(newIndex);
  }, []);

  return (
    <>
      <IonContent
        ref={chat_content}
        className="discussion"
        onLoadedData={(e) => {
          console.log(e);
        }}
      >
        {chat &&
        newMessages.isFetched &&
        previousMessagesFetched &&
        allMessages !== undefined &&
        !allMessages?.length ? (
          <NoMessages chat={chat} chatType={type} id={id} />
        ) : (
          chat && (
            <MessagePopover
              activeMessage={activeMessage}
              filename={filename}
              closePopover={closePopover}
              deleteMessage={handleDelete}
              popoverState={popoverState}
            />
          )
        )}
        <ImageSlides
          images={slideImages}
          isOpen={slideIsOpen}
          index={slideIndex}
          onChange={handleSlideChange}
        />
        <ImageSlidesClose isOpen={slideIsOpen} setIsOpen={setSlideIsOpen} />
        <ImageSlidesDownload
          isOpen={slideIsOpen}
          images={slideImages}
          index={slideIndex}
          setSlideMessage={setSlideMessage}
        />
        <ImageSlidesToast
          slideToast={slideToast}
          setSlideToast={setSlideToast}
        />
        <IonInfiniteScroll
          position="top"
          onIonInfinite={loadMore}
          threshold="200px"
        >
          <IonInfiniteScrollContent loadingSpinner="dots" />
        </IonInfiniteScroll>
        <IonList
          lines="full"
          className="messages smartlook-hide"
          data-sl="exclude"
        >
          {allMessages?.map((post, i) => {
            return (
              <MessagesShell
                post={post}
                chat={chat}
                setSlideImages={setSlideImages}
                setSlideIndex={setSlideIndex}
                setSlideIsOpen={setSlideIsOpen}
                seenBy={seenBy}
                activeSeenBy={activeSeenBy}
                setActiveSeenBy={setActiveSeenBy}
                key={post.messageId}
                index={i}
                messages={allMessages}
                unreadThreshold={unreadThreshold}
              />
            );
          })}
        </IonList>

        {postMessage.isLoading || postFile.isLoading ? (
          <div className="spinner">
            <IonSpinner color="primary" name="crescent" />
          </div>
        ) : (
          ""
        )}
      </IonContent>

      <DiscussionFooter
        submit_message={submit_message}
        chat_content={chat_content}
      />
    </>
  );
};
