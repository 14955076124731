import { RefObject } from "react";
import { IonReactHashRouter } from "@ionic/react-router";

import {
  SimpleNotification,
  OneSignalNotificationAdditionalData,
} from "../models/notifications";
import { Query } from "../contexts/QueryStorage";

function has_object_notification(
  notifications: Query<SimpleNotification[]>,
  unnoticed: boolean,
  objects: string[],
  id?: number
) {
  const notif = notifications.data || [];

  for (let i = 0; i < notif.length; i++) {
    const n = notif[i];
    if (
      typeof n.data.path[0] === "string" &&
      objects.includes(n.data.path[0]) &&
      (!unnoticed || !n.noticed)
    ) {
      if (id === undefined) return true;
      if (n.data.path[1] === id) return true;
    }
  }

  return false;
}

function has_notification_path(
  notifications: Query<SimpleNotification[]>,
  path: Array<string | number>
) {
  var notification_ids: number[] = [];
  const notif = notifications.data || [];

  for (let i = 0; i < notif.length; i++) {
    const n = notif[i];
    if (JSON.stringify(n.data.path) === JSON.stringify(path))
      notification_ids.push(n.pushNotificationId);
  }

  return notification_ids;
}

function resolve_notification_path(data: OneSignalNotificationAdditionalData) {
  if (data.path[0] === "event") {
    if (data.object === "message") return `/events/${data.path[1]}/discussion`;
    else return `/events/${data.path[1]}/about`;
  } else if (data.path[0] === "user") {
    return `/friends/friend/${data.path[1]}`;
  } else if (data.path[0] === "events") {
    return `/events`;
  } else if (data.path[0] === "group") {
    return `/friends/group/${data.path[1]}`;
  } else if (data.path[0] === "announcement") {
    return `/announcements/${data.path[1]}`;
  } else if (data.path[0] === "feedback") {
    return `/profiles/feedbacks`;
  } else if (data.path[0] === "tips" && data.path.length > 1) {
    return `/notifications/tips/${data.path[1]}`;
  }
}

function handle_open_notification(
  data: OneSignalNotificationAdditionalData,
  routerRef: RefObject<IonReactHashRouter>
) {
  var path = resolve_notification_path(data);
  if (path) routerRef.current?.history.push(path);
}

function has_unnoticed_notifications(
  notifications: Query<SimpleNotification[]>
) {
  return (notifications.data ?? []).filter((n) => !n.noticed).length > 0;
}

function get_unnoticed_notifications(
  notifications: Query<SimpleNotification[]>
) {
  return (notifications.data ?? [])
    .filter((n) => !n.noticed)
    .map((n) => n.pushNotificationId);
}

export {
  has_object_notification,
  handle_open_notification,
  has_notification_path,
  resolve_notification_path,
  has_unnoticed_notifications,
  get_unnoticed_notifications,
};
