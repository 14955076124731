import React, { useState, useEffect, useMemo } from "react";
import {
  IonContent,
  IonModal,
  IonButton,
  IonGrid,
  IonAlert,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import "./CUEvent.scss";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { Location, Event, SimpleEvent } from "../models/Event";
import { invite_to_event } from "../resources/events";
import {
  EventCategoryFormRow,
  EventDatetimeFormRows,
  EventDescriptionFormRow,
  EventFeeFormRow,
  EventLocationFormRows,
  EventTicketsFormRow,
  EventTitleFormRow,
  EventVisibilityFormRow,
  EventWebFormRow,
  EventTagsFormRow,
} from "./CUEvent";
import { ModalHeader } from "./Headers";
import { useStorage } from "../hooks";
import { InferType, Schema } from "yup";
import { TITLE_LENGTH_LIMIT, URL_REGEX } from "../constants/events";
import { logEvent } from "../helpers/logging";
import { get_primary_tag_slug } from "../helpers/event";
import useAxios from "../hooks/useAxios";
import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { get_region } from "../cache/coordinates";
import currencies from "../constants/currencies";
import { useTranslation } from "react-i18next";
import useTranlatedMsg from "../hooks/useTranslatedMsg";

const validationRules = Yup.object({
  eventTitle: Yup.string()
    .required("Required")
    .max(
      TITLE_LENGTH_LIMIT,
      `Title must be at most ${TITLE_LENGTH_LIMIT} characters`
    ),
  eventDescription: Yup.string(),
  eventStartDatetime: Yup.date().required(),
  eventEndDatetime: Yup.date()
    .when("eventStartDatetime", (eventStartDatetime, schema) => {
      return eventStartDatetime
        ? schema.min(eventStartDatetime, "End time cannot be before start time")
        : schema;
    })
    .required(),
  eventDateOptions: Yup.array()
    .of(
      Yup.object().shape({
        start: Yup.date().required(),
        end: Yup.date().required(),
      })
    )
    .required(),
  eventLocationOptions: Yup.array()
    .of(
      Yup.string()
        .required("Required")
        .test(
          "is-unique",
          "Locations must be unique",
          function (value): boolean {
            let duplicates = this.parent.filter(
              (eventLocationId: string | undefined) => {
                return eventLocationId && eventLocationId === value;
              }
            );
            return duplicates.length === 1;
          }
        )
    )
    .required(),
  eventSourceUrl: Yup.string().matches(URL_REGEX, "Enter correct url"),
  eventTicketsUrl: Yup.string().matches(URL_REGEX, "Enter correct url"),
  eventEntryFeeAmount: Yup.number(),
  eventEntryFeeCurrency: Yup.string().oneOf(currencies),
  eventLanguages: Yup.array().of(Yup.string()),
  eventCategory: Yup.string().when("eventVisibility", {
    is: (eventVisibility: string) => eventVisibility === "public",
    then: (schema: Schema) => schema.required("Category is required"),
  }), // TODO: allow only tags as value
  eventTags: Yup.array().of(Yup.string().required()).required(),
  eventInvitees: Yup.array().of(Yup.number().required()).required(),
  eventVisibility: Yup.string().oneOf(["private", "public"]),
  associatedGroups: Yup.array().of(Yup.number()),
});

type AddEventFormFields = InferType<typeof validationRules>;

export type AddEventProps = FormikProps<AddEventFormFields>;

const AddEvent: React.FC<{
  showModal: boolean;
  setShowModal: CallableFunction;
  map?: google.maps.Map | undefined;
  type: "default" | "group" | "personal" | "XXX";
  group_id?: number;
  prefilledEventInvitees?: number[];
  prefilledEventTitle?: string;
  onEventAdded?: CallableFunction;
  prefetchedEvent?: Event | undefined;
}> = ({
  showModal,
  setShowModal,
  map,
  group_id,
  prefilledEventInvitees = [],
  prefilledEventTitle = "",
  onEventAdded,
  prefetchedEvent,
}) => {
  const [imagePreview, setImagePreview] = useState<any | undefined>(undefined);
  const [showCancelAlert, setShowCancelAlert] = useState(false);

  const [placeResults, setPlaceResults] = useState<(Location | undefined)[]>([
    undefined,
  ]);
  const history = useHistory();
  const { axios } = useAxios();
  const { currentUser: user, setMessage } = useStorage();
  const { t } = useTranslation();
  const { fail_crt_event, err_crt_event } = useTranlatedMsg();

  const { mutate: submit } = useMutation<
    AxiosResponse<SimpleEvent>,
    AxiosError,
    InferType<typeof validationRules>
  >((payload) => axios.post("/events", payload));

  const { mutateAsync: uploadPhoto } = useMutation<
    AxiosResponse<unknown>,
    AxiosError,
    { payload: FormData; id: Number }
  >(["events_new"], (data) =>
    axios.post(`/events/${data.id}/photo`, data.payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  );

  useEffect(() => {
    if (prefetchedEvent) setPlaceResults([prefetchedEvent.location]);
    if (
      prefetchedEvent &&
      !prefetchedEvent.location &&
      prefetchedEvent.locationOptions
    )
      setPlaceResults(
        prefetchedEvent.locationOptions.map((location) => {
          return {
            id: location.id,
            name: location.name,
            formattedAddress: location.formattedAddress,
            lat: location.lat,
            lng: location.lng,
          };
        })
      );
    if (prefetchedEvent?.photo) {
      axios
        .get(`/files/profiles/${prefetchedEvent.photo}`, {
          validateStatus: (status) => {
            return status >= 200 && status < 300;
          },
          responseType: "blob",
        })
        .then((r) => {
          setImagePreview(r);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefetchedEvent]);

  const initial_values: AddEventFormFields = useMemo(() => {
    const getPrefetchedDate = (option: "start" | "end", hoursToAdd: number) =>
      prefetchedEvent
        ? (prefetchedEvent[option] && moment(prefetchedEvent[option])) ||
          (prefetchedEvent.dateOptions[0] &&
            moment(prefetchedEvent.dateOptions[0][option]))
        : moment().add(hoursToAdd, "hour").startOf("hour");

    const getStringDate = (
      daysFromToday: number,
      hoursToSet: number,
      minutesToSet: number
    ) =>
      moment()
        .add(daysFromToday, "days")
        .set("hour", hoursToSet)
        .set("minute", minutesToSet)
        .startOf("minute")
        .toDate();

    const startDate = getPrefetchedDate("start", 1);
    const endDate = getPrefetchedDate("end", 2);

    // if startDate hour is less then now, add 1 day
    const daysToAdd = startDate.hour() - moment().hour() <= 0 ? 1 : 0;

    const eventStartDatetime = getStringDate(
      daysToAdd,
      startDate.hour(),
      startDate.minute()
    );
    const eventEndDatetime = getStringDate(
      endDate.date() - startDate.date() + daysToAdd,
      endDate.hour(),
      endDate.minute()
    );

    return {
      eventTitle: prefetchedEvent?.title
        ? prefetchedEvent.title
        : prefilledEventTitle,
      eventDescription: prefetchedEvent?.description
        ? prefetchedEvent.description
        : "",
      eventLocationOptions: prefetchedEvent?.location
        ? [prefetchedEvent.location.id]
        : prefetchedEvent?.locationOptions
        ? prefetchedEvent?.locationOptions.map((l) => l.id)
        : [""],
      eventStartDatetime,
      eventEndDatetime,
      eventDateOptions: [],
      eventSourceUrl: "",
      eventTicketsUrl: "",
      eventEntryFeeAmount: 0,
      eventEntryFeeCurrency: get_region() === "usa" ? "USD" : "CZK",
      eventLanguages: ["english"],
      eventInvitees: prefetchedEvent?.attendees
        ? prefetchedEvent?.attendees
            .map((a) => {
              return a.user.userId;
            })
            .filter((i) => i !== user?.userId)
        : prefilledEventInvitees,
      // eventVisibility: prefetchedEvent?.public ? "public" : "private",
      eventVisibility: "public",
      eventCategory: prefetchedEvent?.tags
        ? get_primary_tag_slug(prefetchedEvent.tags)
        : undefined,
      eventTags: prefetchedEvent?.tags
        ? prefetchedEvent.tags
            .filter((tag) => tag.level !== 1)
            .map((tag) => tag.slug)
        : [],
      associatedGroups: prefetchedEvent?.associatedGroups
        ? prefetchedEvent.associatedGroups
        : group_id
        ? [group_id]
        : [],
    };
  }, [
    group_id,
    prefetchedEvent,
    prefilledEventInvitees,
    prefilledEventTitle,
    user?.userId,
  ]);

  function save(setSubmitting: CallableFunction, values: AddEventFormFields) {
    if (placeResults[0] === undefined || placeResults[0].id === undefined) {
      // TODO: show error
      console.error("saving event, error", placeResults[0]);
      return;
    }

    // FIXME: transform place results to output

    const event_payload: any = {
      title: values.eventTitle,
      description:
        values.eventDescription === "" ? undefined : values.eventDescription,
      public: values.eventVisibility === "public",
      start:
        values.eventDateOptions.length >= 1
          ? undefined
          : values.eventStartDatetime,
      end:
        values.eventDateOptions.length >= 1
          ? undefined
          : values.eventEndDatetime,
      dates:
        values.eventDateOptions.length >= 2
          ? values.eventDateOptions
          : undefined,
      ticketsUrl:
        values.eventVisibility === "public" && values.eventTicketsUrl
          ? values.eventTicketsUrl
          : null,
      sourceUrl:
        values.eventVisibility === "public" && values.eventSourceUrl
          ? values.eventSourceUrl
          : null,
      entryFeeAmounts:
        values.eventEntryFeeAmount !== undefined &&
        values.eventVisibility === "public"
          ? [values.eventEntryFeeAmount]
          : undefined,
      entryFeeCurrency: values.eventEntryFeeCurrency || "CZK",
      languages: values.eventLanguages,
      location: placeResults.length === 1 ? placeResults[0] : undefined,
      locations: placeResults.length > 1 ? placeResults : undefined,
      associatedGroups: values.associatedGroups,
      tags:
        values.eventCategory && values.eventVisibility === "public"
          ? [values.eventCategory, ...values.eventTags]
          : undefined,
    };

    submit(event_payload, {
      onSuccess: (result) => {
        setPlaceResults([undefined]);
        setSubmitting(false);
        setShowModal(false);
        let promises: any[] = [];
        if (imagePreview) {
          const formData = new FormData();
          formData.append("file", imagePreview, "file");
          promises.push(
            uploadPhoto({ payload: formData, id: result.data.eventId })
          );
        }
        if (values.eventInvitees)
          promises.push(
            invite_to_event(result.data.eventId, values.eventInvitees, axios)
          );
        Promise.allSettled(promises)
          .then(() => {
            setImagePreview(undefined);
            history.push(`/events/${result.data.eventId}/about`);
          })
          .catch((err) => setMessage("danger", fail_crt_event, err));
        logEvent("event_created", {});
        if (onEventAdded) onEventAdded();
      },
      onError: (err) => {
        setMessage("danger", err_crt_event, err);
        setSubmitting(false);
      },
    });
  }

  return (
    <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
      <Formik
        initialValues={initial_values}
        validationSchema={validationRules}
        onSubmit={(values, formikActions) => {
          save(formikActions.setSubmitting, values);
        }}
        onReset={(values, formikActions) => {
          setShowModal(false);
          setImagePreview(undefined);
        }}
      >
        {(props) => (
          <>
            <ModalHeader
              title="New event"
              isSubmitting={props.isSubmitting}
              onClick={() => {
                if (props.dirty) {
                  setShowCancelAlert(true);
                } else {
                  setPlaceResults([undefined]);
                  props.handleReset();
                }
              }}
            />

            <IonContent className="form add_event_table with_icons">
              <IonGrid>
                <EventVisibilityFormRow props={props} disabled={true} />

                <EventTitleFormRow
                  props={props}
                  imagePreview={imagePreview}
                  setImagePreview={setImagePreview}
                />

                <EventDescriptionFormRow props={props} />

                <EventCategoryFormRow props={props} />
                <EventTagsFormRow props={props} />

                <EventLocationFormRows
                  props={props}
                  placeResults={placeResults}
                  setPlaceResults={setPlaceResults}
                  map={map}
                  prefetchedLocationOptions={prefetchedEvent?.locationOptions}
                />

                {/* <EventInviteesFormRow props={props} /> */}

                <EventDatetimeFormRows props={props} />

                <EventWebFormRow props={props} />

                <EventFeeFormRow props={props} />

                <EventTicketsFormRow props={props} />
              </IonGrid>
            </IonContent>

            <IonButton
              className="modal_big_button"
              disabled={
                props.isSubmitting ||
                !props.isValid ||
                !(
                  props.dirty ||
                  prefetchedEvent ||
                  (prefilledEventTitle.length &&
                    props.values.eventLocationOptions?.length &&
                    props.values.eventLocationOptions[0].length)
                )
              }
              onClick={() => {
                if (
                  !props.values.eventLocationOptions[0] &&
                  placeResults.length > 0 &&
                  placeResults[0]
                ) {
                  props.values.eventLocationOptions[0] = placeResults[0].id;
                }
                props.handleSubmit();
              }}
            >
              {t("add_event.create")}
            </IonButton>

            <IonAlert
              isOpen={showCancelAlert}
              onDidDismiss={() => setShowCancelAlert(false)}
              header={t("add_event.discard_chngs")}
              buttons={[
                {
                  text: t("add_event.cancel"),
                  role: "cancel",
                  cssClass: "secondary",
                  handler: () => {},
                },
                {
                  text: t("add_event.confirm"),
                  cssClass: "primary",
                  handler: () => {
                    setPlaceResults([undefined]);
                    props.handleReset();
                  },
                },
              ]}
            />
          </>
        )}
      </Formik>
    </IonModal>
  );
};

export default AddEvent;
