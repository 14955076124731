import { useEffect, useState } from "react";

function usePausableState<T>(value: T, enabled: boolean) {
  const [localValue, setLocalValue] = useState<T>(value);

  useEffect(() => {
    if (enabled && value !== localValue) setLocalValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, enabled]);

  return localValue;
}

export { usePausableState };
