import React, { useEffect, useState } from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";

import "./Profile.scss";
import { showTabBar } from "../helpers/tabBar";
import { useStorage } from "../hooks";
import {
  Announcement as AnnouncementType,
  InitialValues,
  HandleChangeAnnouncement as HandleChange,
  HandleBlurAnnouncement as HandleBlur,
  Quality,
  Options,
  TextArea,
  AnnouncementPayload as Payload,
  MultipleOptions,
  SetFieldValue,
  FiledTouched,
} from "../models/Announcement";
import { RouteComponentProps } from "react-router";
import { logEvent } from "../helpers/logging";
import useAxios from "../hooks/useAxios";
import { useMutation, useQuery } from "react-query";
import { Formik, FormikErrors, FormikTouched, FormikValues } from "formik";
import * as Yup from "yup";
import "./Announcement.scss";
import { AxiosError, AxiosResponse } from "axios";
import { EmptySection } from "../components/EmptySection";
import useNotifications from "../hooks/useNotifications";
import { sentiment } from "../icons/iconsOutline";
import useTranlatedMsg from "../hooks/useTranslatedMsg";

const AnnouncementText: React.FC<{
  showLogo: boolean;
  text: string;
}> = ({ showLogo, text }) => {
  return (
    <div className="main_text">
      {showLogo && (
        <img
          src={process.env.PUBLIC_URL + "/assets/logomark.png"}
          className="logo"
          alt="logo"
        />
      )}

      {text && (
        <div
          className="header-announc"
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      )}
    </div>
  );
};

interface AnnouncementlProps extends RouteComponentProps<{ id: string }> {}

const Announcement: React.FC<AnnouncementlProps> = ({ match }) => {
  // TODO: turn off refresh.. this shouldnt change.. its ok to load only once
  const { axios } = useAxios();
  const { setMessage } = useStorage();
  const [sendedSurvey, setSendedSurvey] = useState<boolean>(false);
  const { updatePathsAsSeen } = useNotifications();
  const {went_wrong} = useTranlatedMsg()

  const { data: announcement } = useQuery(
    ["announcement", match.params.id],
    () => axios.get<AnnouncementType>(`/announcements/${match.params.id}`),
    { select: (res) => res.data }
  );

  const { mutate: submit } = useMutation<
    AxiosResponse<unknown>,
    AxiosError,
    Payload
  >((payload) => axios.post("/feedback/dynamic", payload), {
    onSuccess: () => setSendedSurvey(true),
    onError: (err) => setMessage("danger", went_wrong, err),
  });

  useIonViewWillEnter(() => {
    showTabBar();
  });

  function initialValues(
    questions:
      | Array<Quality | Options | TextArea | MultipleOptions>
      | null
      | undefined
  ) {
    if (questions === null || questions === undefined) return {};
    return questions.reduce((prev, item) => {
      if (item.type === "textarea") prev[item.name] = "";
      else if (item.type === "options") prev[item?.name] = undefined;
      else if (item.type === "options_multiple") prev[item?.name] = [];
      else if (item.type === "quality") prev[item?.name] = undefined;
      return prev;
    }, {} as InitialValues);
  }
  function validationRules(
    questions:
      | Array<Quality | Options | TextArea | MultipleOptions>
      | null
      | undefined
  ) {
    return questions?.reduce((prev, item) => {
      if (item.type === "textarea")
        prev[item.name] = item.mandatory
          ? Yup.string().required("Required")
          : Yup.string();
      else if (item.type === "options")
        prev[item.name] = item.mandatory
          ? Yup.string().required("Required")
          : Yup.string();
      else if (item.type === "options_multiple")
        prev[item.name] = item.mandatory
          ? Yup.array().of(Yup.string()).min(1, "Required").required("Required")
          : Yup.array().of(Yup.string());
      else if (item.type === "quality")
        prev[item.name] = item.mandatory
          ? Yup.number().required("Required")
          : Yup.number();
      return prev;
    }, {} as any);
  }

  useIonViewDidEnter(() => {
    logEvent("annoucement_opened", {
      id: match.params.id,
    });
  });

  useIonViewWillLeave(() => {
    logEvent("annoucement_left", {
      id: match.params.id,
    });
  });

  useEffect(() => {
    updatePathsAsSeen([["announcement", parseInt(match.params.id)]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  return (
    <IonPage className="page-announcements">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/search" text="" className="back" />
          </IonButtons>
          <IonTitle>Announcement</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {announcement?.questions ? (
          <Formik
            initialValues={initialValues(announcement.questions)}
            validationSchema={Yup.object(
              validationRules(announcement.questions)
            )}
            onSubmit={(values, formikActions) => {
              submit({
                feedbackFormId: announcement?.feedbackFormId,
                data: values,
              });
              formikActions.resetForm();
            }}
          >
            {({
              validateField,
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <>
                {sendedSurvey ? (
                  <EmptySection
                    icon={sentiment}
                    title={"Feedback has been sent"}
                    description={
                      "Thank you for taking a time and help us to improve ourselves"
                    }
                  >
                    <IonButton
                      className="button-primary-light"
                      fill="clear"
                      size="small"
                      routerLink={"/search"}
                      onClick={() => setSendedSurvey(false)}
                    >
                      Back to the main page
                    </IonButton>
                  </EmptySection>
                ) : (
                  <>
                    <AnnouncementText
                      showLogo={announcement.showLogo}
                      text={announcement.text}
                    />

                    {announcement.questions && (
                      <IonGrid>
                        {announcement.questions.map((question) => {
                          if (question.type === "textarea")
                            return (
                              <AnnouncementFeedback
                                placeholder={question.placeholder}
                                name={question.name}
                                key={question.name}
                                values={values}
                                touched={touched}
                                errors={errors}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                              />
                            );
                          else if (question.type === "quality")
                            return (
                              <SatisfactionSurvey
                                title={question.title}
                                titleMin={question.titleMin}
                                titleMax={question.titleMax}
                                name={question.name}
                                key={question.name}
                                values={values}
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}
                                validateField={validateField}
                                setFieldTouched={setFieldTouched}
                              />
                            );
                          else if (question.type === "options")
                            return (
                              <ContentSurvey
                                title={question.title}
                                options={question.options}
                                name={question.name}
                                key={question.name}
                                values={values}
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}
                                validateField={validateField}
                                setFieldTouched={setFieldTouched}
                              />
                            );
                          else if (question.type === "options_multiple")
                            return (
                              <ContentSurveyCheckbox
                                title={question.title}
                                options={question.options}
                                name={question.name}
                                key={question.name}
                                values={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                validateField={validateField}
                                setFieldTouched={setFieldTouched}
                              />
                            );
                          else return null;
                        })}
                      </IonGrid>
                    )}

                    {announcement.questions && (
                      <IonGrid className="announcement_btns">
                        <IonButton fill="solid" color="medium" href="#/search">
                          Dissmiss
                        </IonButton>
                        <IonButton fill="solid" onClick={() => handleSubmit()}>
                          Submit
                        </IonButton>
                      </IonGrid>
                    )}
                  </>
                )}
              </>
            )}
          </Formik>
        ) : announcement ? (
          <AnnouncementText
            showLogo={announcement.showLogo}
            text={announcement.text}
          />
        ) : null}
      </IonContent>
    </IonPage>
  );
};

export default Announcement;

const AnnouncementFeedback: React.FC<{
  placeholder: string;
  name: string;
  values: FormikValues;
  touched: FormikTouched<FormikValues>;
  errors: FormikErrors<FormikValues>;
  handleBlur: HandleBlur;
  handleChange: HandleChange;
}> = ({
  placeholder,
  name,
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
}) => {
  return (
    <IonRow className="form-feedback">
      <IonCol>
        <IonTextarea
          aria-label="feedbackAnnouncement"
          name={name}
          inputMode="text"
          value={values[name]}
          placeholder={placeholder}
          autocapitalize="true"
          onIonInput={handleChange}
          onIonBlur={handleBlur}
        />
        {touched[name] && errors[name] ? (
          <div className="error"><>{errors[name]}</></div>
        ) : null}
      </IonCol>
    </IonRow>
  );
};
const SatisfactionSurvey: React.FC<{
  title: string;
  titleMin: string;
  titleMax: string;
  name: string;
  values: FormikValues;
  handleChange: HandleChange;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  validateField: (field: string) => void;
  setFieldTouched: FiledTouched;
}> = ({
  titleMin,
  titleMax,
  title,
  name,
  values,
  handleChange,
  errors,
  touched,
  validateField,
  setFieldTouched,
}) => {
  return (
    <>
      <IonRow className="survey_block question">
        <IonCol>
          <IonLabel className="survey_title">{title}</IonLabel>
        </IonCol>
      </IonRow>
      <IonRow className="survey_block">
        <IonCol>
          <IonRadioGroup
            allowEmptySelection={true}
            className="radio_sat_survey"
            name={name}
            value={values[name]}
            onIonChange={handleChange}
            onClick={() => {
              validateField(name);
              setFieldTouched(name);
            }}
          >
            <IonRadio
              mode="md"
              aria-label="SatisfactionSurvey"
              value={1}
            ></IonRadio>
            <IonRadio
              mode="md"
              aria-label="SatisfactionSurvey"
              value={2}
            ></IonRadio>
            <IonRadio
              mode="md"
              aria-label="SatisfactionSurvey"
              value={3}
            ></IonRadio>
            <IonRadio
              mode="md"
              aria-label="SatisfactionSurvey"
              value={4}
            ></IonRadio>
            <IonRadio
              mode="md"
              aria-label="SatisfactionSurvey"
              value={5}
            ></IonRadio>
          </IonRadioGroup>
        </IonCol>
      </IonRow>
      <IonRow className="survey_block">
        <IonCol className="levels_of_satisfaction">
          <IonLabel>{titleMin}</IonLabel>
          <IonLabel> {titleMax}</IonLabel>
        </IonCol>
      </IonRow>
      {touched[name] && errors[name] ? (
        <div className="error">
          <>{errors[name]}</>
        </div>
      ) : null}
    </>
  );
};
const ContentSurvey: React.FC<{
  title: string;
  options: string[];
  name: string;
  values: FormikValues;
  handleChange: HandleChange;
  touched: FormikTouched<FormikValues>;
  errors: FormikErrors<FormikValues>;
  validateField: (field: string) => void;
  setFieldTouched: FiledTouched;
}> = ({
  title,
  options,
  name,
  values,
  handleChange,
  touched,
  errors,
  validateField,
  setFieldTouched,
}) => {
  return (
    <IonRow className="question">
      <IonCol>
        <IonLabel className="survey_title">{title}</IonLabel>
        <IonList lines="none" className="content_survey">
          <IonRadioGroup
            allowEmptySelection={true}
            name={name}
            value={values[name]}
            onIonChange={handleChange}
            onClick={() => {
              validateField(name);
              setFieldTouched(name);
            }}
          >
            {options?.map((option) => (
              <IonItem key={option}>
                <IonRadio value={option} mode="md" aria-label="ContentSurvey">
                  {option}
                </IonRadio>
              </IonItem>
            ))}
          </IonRadioGroup>
        </IonList>

        {touched[name] && errors[name] ? (
          <div className="error"><>{errors[name]}</></div>
        ) : null}
      </IonCol>
    </IonRow>
  );
};
const ContentSurveyCheckbox: React.FC<{
  title: string;
  options: string[];
  name: string;
  values: FormikValues;
  setFieldValue: SetFieldValue;
  touched: FormikTouched<FormikValues>;
  errors: FormikErrors<FormikValues>;
  validateField: (field: string) => void;
  setFieldTouched: FiledTouched;
}> = ({
  title,
  options,
  name,
  values,
  setFieldValue,
  touched,
  errors,
  validateField,
  setFieldTouched,
}) => {
  return (
    <IonRow className="question">
      <IonCol>
        <IonLabel className="survey_title">{title}</IonLabel>
        <IonList lines="none" className="content_survey">
          <IonItemGroup>
            {values[name] &&
              options?.map((option) => (
                <IonItem key={option}>
                  <IonCheckbox
                    aria-label="ContentSurveyCheckbox"
                    name={name}
                    value={values[name]}
                    checked={values[name].includes(option)}
                    onBlur={(e) => {
                      setFieldTouched(name);
                    }}
                    onIonChange={() => {
                      // validateField(name);
                      setFieldValue(
                        name,
                        values[name].includes(option)
                          ? values[name].filter((o: string) => o !== option)
                          : [...values[name], option]
                      );
                    }}
                  >
                    {option}
                  </IonCheckbox>
                </IonItem>
              ))}
          </IonItemGroup>
        </IonList>

        {touched[name] && errors[name] ? (
          <div className="error"><>{errors[name]}</></div>
        ) : null}
      </IonCol>
    </IonRow>
  );
};
