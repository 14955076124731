import { IonIcon, IonPopover, IonList, IonItem, IonButton } from "@ionic/react";
import copy from "copy-to-clipboard";
import { useStorage } from "../hooks";
import { image, contentCopy, map, done } from "../icons/iconsOutline";
import { PopoverSettings } from "../models/popover";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import useAxios from "../hooks/useAxios";
import { logEvent } from "../helpers/logging";
import useAnalytics from "../hooks/useAnalytics";
import { useInvalidateQueries } from "../hooks/useInvalidateQueries";
import { PopoverActionButtons } from "./Unregistered";
import { useState } from "react";
import useTranlatedMsg from "../hooks/useTranslatedMsg";
import { useTranslation } from "react-i18next";

export const PlaceLocationPopover: React.FC<{
  address: string | undefined;
  name: string | undefined;
  showPopover: PopoverSettings;
  setShowPopover: React.Dispatch<React.SetStateAction<PopoverSettings>>;
  showMap: boolean;
  setShowMap: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ name, showPopover, setShowPopover, showMap, setShowMap, address }) => {
  const { setMessage } = useStorage();
  const { adress_copied } = useTranlatedMsg();
  const { t } = useTranslation();

  return (
    <IonPopover
      className="popover_options"
      event={showPopover.event}
      isOpen={showPopover.showPopover}
      onDidDismiss={() =>
        setShowPopover({
          showPopover: false,
          event: undefined,
        })
      }
    >
      <IonList>
        <IonItem
          button
          detail={false}
          onClick={() => {
            if (name && address) {
              copy(`${name},${address}`);
              setMessage("primary", adress_copied);
              setShowPopover({
                showPopover: false,
                event: undefined,
              });
            }
          }}
        >
          <IonIcon icon={contentCopy} size="default"></IonIcon>{" "}
          {t("place_detail_info.copy_adress")}
        </IonItem>
        <IonItem
          button
          detail={false}
          onClick={() => {
            setShowMap(!showMap);
            setShowPopover({
              showPopover: false,
              event: undefined,
            });
          }}
        >
          <IonIcon icon={showMap ? image : map} size="default"></IonIcon>{" "}
          {showMap ? "Hide map" : "Show on a map"}
        </IonItem>
      </IonList>
    </IonPopover>
  );
};
export const PlaceFollowButton: React.FC<{
  placeDetailId: number | undefined;
  following: boolean | undefined;
  onStatusChanged: CallableFunction;
}> = ({ placeDetailId, following, onStatusChanged }) => {
  const { currentUser: user, setMessage } = useStorage();
  const { axios } = useAxios();
  const { sendMetric } = useAnalytics();
  const invalidateQueries = useInvalidateQueries();
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const { went_wrong } = useTranlatedMsg();

  const { mutate: follow } = useMutation<never, AxiosError, {}>((payload) =>
    axios.post(`/places/v2/${placeDetailId}/follow`, payload)
  );
  const { mutate: unFollow } = useMutation<never, AxiosError, {}>((payload) =>
    axios.delete(`/places/v2/${placeDetailId}/follow`, payload)
  );

  return (
    <>
      <PopoverActionButtons
        popoverState={popoverState}
        setShowPopover={setShowPopover}
      />
      <IonButton
        className="join"
        size="small"
        color={following ? "primary" : "secondary"}
        fill="solid"
        onClick={(e: any) => {
          if (!user) {
            setShowPopover({ showPopover: true, event: e });
            return;
          }

          if (following === true) {
            unFollow(
              {},
              {
                onSuccess: () => {
                  onStatusChanged();
                  logEvent("place_unfollow", {});
                  sendMetric({
                    name: "place_unfollow",
                    data: {
                      event_id: placeDetailId,
                    },
                  });
                  invalidateQueries("hp_places_events");
                },
                onError: (err) => setMessage("danger", went_wrong, err),
              }
            );
          } else
            follow(
              {},
              {
                onSuccess: () => {
                  onStatusChanged();
                  logEvent("place_follow", {});
                  sendMetric({
                    name: "place_follow",
                    data: {
                      event_id: placeDetailId,
                    },
                  });
                  invalidateQueries("hp_places_events");
                },
                onError: (err) => setMessage("danger", went_wrong, err),
              }
            );
        }}
      >
        {following && (
          <IonIcon icon={done} size="large" slot="icon-only"></IonIcon>
        )}
        {following ? "Following" : "Follow place"}
      </IonButton>
    </>
  );
};

export const PlaceFollowRow: React.FC<{
  placeDetailId: number | undefined;
  following: boolean;
  onStatusChanged: CallableFunction;
}> = ({ placeDetailId, following, onStatusChanged }) => {
  return (
    <div
      className={[
        "event_attendance_options",
        "event_attendance_invited",
        "placeDetail_follow_btn",
      ].join(" ")}
    >
      <div className="text"></div>
      <div className="buttons">
        <PlaceFollowButton
          placeDetailId={placeDetailId}
          following={following}
          onStatusChanged={onStatusChanged}
        />
      </div>
    </div>
  );
};
