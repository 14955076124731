import { useQuery } from "react-query";
import "./Order.scss";
import useAxios from "../hooks/useAxios";
import { RouteComponentProps } from "react-router";
import { Order, Ticket } from "../models/orders";
import {
  IonButton,
  IonContent,
  IonPage,
  IonSpinner,
  useIonViewWillEnter,
} from "@ionic/react";
import { showTabBar } from "../helpers/tabBar";
import { api_url } from "../config";
import QRCode from "react-qr-code";

interface OrderDetailProps
  extends RouteComponentProps<{ id: string; code: string }> {}

const OrderDetail: React.FC<OrderDetailProps> = ({ match }) => {
  const { axios } = useAxios();
  const orderId = Number(match.params.id);

  useIonViewWillEnter(() => {
    showTabBar();
  });

  const { data: order } = useQuery(
    ["order", orderId, match.params.code],
    () =>
      axios.get<Order>(`/orders/${orderId}`, {
        params: {
          code: match.params.code,
        },
      }),
    {
      select: (res) => res.data,
      refetchInterval: (data) =>
        data?.state === "COMPLETED" || data?.state === "EXPIRED" ? false : 1000,
    }
  );

  const { data: tickets } = useQuery(
    ["order", "tickets", orderId, match.params.code],
    () =>
      axios.get<Ticket[]>(`/orders/tickets/${orderId}`, {
        params: {
          code: match.params.code,
        },
      }),
    {
      select: (res) => res.data,
      refetchInterval: (data) =>
        (data && data.length > 0) ||
        (order && (order.state === "EXPIRED" || order.state === "OPEN"))
          ? false
          : 1000,
    }
  );

  return (
    <IonPage>
      <IonContent>
        <div className="container">
          {order?.state === "NEW" && (
            <IonSpinner color="primary" name="crescent" />
          )}
          {order?.state === "EXPIRED" ? (
            <h1>
              Your order has not been successfully completed. It has been
              cancelled or expired.
            </h1>
          ) : (
            <h1>Your order</h1>
          )}

          {order && order.state && (
            <>
              {order?.state && order.state === "COMPLETED" && (
                <p>You will receive tickets on your e-mail soon</p>
              )}

              {order.state === "COMPLETED" && <h1>Your tickets</h1>}
              {tickets &&
                tickets.length > 0 &&
                tickets.map((t, i) => (
                  <div key={`${i}-${t.code}`} className="ticket">
                    <h3>Ticket {i + 1}</h3>
                    <QRCode
                      key={t.ticketId}
                      size={192}
                      value={t.code}
                      viewBox={`0 0 192 192`}
                    />
                    <div key={t.file}>
                      <a
                        key={t.ticketId}
                        href={`${api_url}/files/tickets/${t.file}`}
                        target="_blank"
                        rel="noreferrer"
                        download
                      >
                        Download
                      </a>
                    </div>
                  </div>
                ))}
              <div className="center button-back">
                <IonButton href={`#/events/${order.items[0].eventId}/about`}>
                  Back to event
                </IonButton>
              </div>
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default OrderDetail;
