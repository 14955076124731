import useAxios from "./useAxios";
import { UseMutateFunction, useMutation } from "react-query";
import { AxiosResponse } from "axios";

export type SendMetric = UseMutateFunction<
  AxiosResponse<any, any>,
  unknown,
  {
    name: string;
    data: object;
  },
  unknown
>;

const useAnalytics = () => {
  const { axios } = useAxios();
  const { mutate: sendMetric } = useMutation(
    (payload: { name: string; data: object }) => axios.post("/a", payload)
  );

  return { sendMetric };
};

export default useAnalytics;
