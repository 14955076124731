import { default as fromAxios } from "axios";
import { api_url as API_URL, isDevelopment } from "../config";

export const axios = fromAxios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosApiAuth = fromAxios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axios.interceptors.request.use(
  ({ method, url, params, data, ...rest }) => {
    if (isDevelopment) console.log(method, "|", url, params, data);

    return { method, url, params, data, ...rest };
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  ({ data, ...rest }) => {
    if (isDevelopment) console.log(data);
    return { data, ...rest };
  },
  (error) => Promise.reject(error)
);

axiosApiAuth.interceptors.request.use(
  ({ method, url, params, data, ...rest }) => {
    if (isDevelopment) console.log(method, "|Auth0", url, params, data);

    return { method, url, params, data, ...rest };
  },
  (error) => Promise.reject(error)
);

axiosApiAuth.interceptors.response.use(
  ({ data, ...rest }) => {
    if (isDevelopment) console.log(data);
    return { data, ...rest };
  },
  (error) => Promise.reject(error)
);
