import React, { useState } from "react";
import { Formik } from "formik";
import {
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonSelectOption,
  IonSelect,
  IonInput,
  IonIcon,
} from "@ionic/react";
import PhonePrefixes from "../constants/phone_prefixes";
import { useStorage } from "../hooks";
import { resetPasswordSchema } from "../models";
import {
  passwordResetError,
  PasswordResetErrorCode,
} from "../constants/responseErrors";

import type { User, ResetPasswordData as Payload } from "../models";
import { useMutation } from "react-query";
import useAxios from "../hooks/useAxios";
import { AxiosError, AxiosResponse } from "axios";
import { call, lock, visibility, visibilityOff } from "../icons/iconsOutline";
import { get_region } from "../cache/coordinates";

const ResetPassword: React.FC<{
  setPhonePrefix: CallableFunction;
  setPhone: CallableFunction;
  setPassword: CallableFunction;
  setUserId: CallableFunction;
}> = ({ setPhonePrefix, setPhone, setPassword, setUserId }) => {
  const { setMessage } = useStorage();
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [passwordConfirmIsVisible, setPasswordConfirmIsVisible] =
    useState(false);
  const { axios } = useAxios();

  type Data = Pick<User, "userId">;

  const { mutate: resetPassword } = useMutation<
    AxiosResponse<Data>,
    AxiosError,
    Payload
  >((payload) => axios.post("/password/reset", payload));

  const resPass = (
    formData: any,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const { confirmPassword, ...payload } = formData;
    resetPassword(payload, {
      onSuccess: ({ data }) => {
        const { phonePrefix, phone, password } = payload;
        setPhonePrefix(phonePrefix);
        setPhone(phone);
        setPassword(password);
        setUserId(data.userId);
        setSubmitting(false);
      },
      // TODO: move to useQuery hook
      onError: ({ response }) => {
        const status = response?.status as PasswordResetErrorCode;
        const error = new Error(
          passwordResetError[status] || "Unknown error, please try again"
        );
        setSubmitting(false);
        return setMessage("danger", error.message, error);
      },
    });
  };

  return (
    <>
      <IonIcon className="logo" icon="/assets/icon/new/logo.svg" />
      <Formik
        initialValues={{
          phonePrefix: get_region() === "usa" ? "+1" : "+420",
          phone: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={resetPasswordSchema}
        onSubmit={(data, { setSubmitting }) => {
          resPass(data, setSubmitting);
        }}
      >
        {({
          dirty,
          values,
          errors,
          touched,
          isValid,
          isSubmitting,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <IonGrid className="big_form">
            <IonRow className="navigation">
              <IonCol>Reset password</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="2" className="label">
                <IonLabel>
                  <IonIcon className="label_icon" icon={call} />
                </IonLabel>
              </IonCol>
              <IonCol size="3" className="left_select">
                <IonSelect
                  aria-label="phonePrefix"
                  name="phonePrefix"
                  interfaceOptions={{ header: "Select country code" }}
                  value={values.phonePrefix}
                  placeholder="prefix"
                  className={
                    touched.phonePrefix && errors.phonePrefix ? "error" : ""
                  }
                  onIonChange={handleChange}
                  onIonBlur={(e) => {
                    handleChange(e);
                    handleBlur(e);
                  }}
                >
                  {PhonePrefixes?.map(({ dial_code, flag }) => {
                    return (
                      <IonSelectOption key={dial_code} value={dial_code}>
                        {flag} {dial_code}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
                {touched.phonePrefix && errors.phonePrefix ? (
                  <div className="error">{errors.phonePrefix}</div>
                ) : null}
              </IonCol>
              <IonCol size="7">
                <IonInput
                  aria-label="phone"
                  name="phone"
                  type="tel"
                  inputMode="tel"
                  value={values.phone}
                  minlength={9}
                  maxlength={10}
                  autocomplete="tel"
                  placeholder="number"
                  className={touched.phone && errors.phone ? "error" : ""}
                  onIonInput={handleChange}
                  onIonBlur={(e) => {
                    handleChange(e);
                    handleBlur(e);
                  }}
                />
                {touched.phone && errors.phone ? (
                  <div className="error">Invalid phone</div>
                ) : null}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="2" className="label">
                <IonLabel>
                  <IonIcon className="label_icon" icon={lock} />
                </IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonInput
                  aria-label="password"
                  name="password"
                  type={passwordIsVisible ? "text" : "password"}
                  value={values.password}
                  placeholder="new password"
                  className={touched.password && errors.password ? "error" : ""}
                  onIonInput={handleChange}
                  onIonBlur={(e) => {
                    handleChange(e);
                    handleBlur(e);
                  }}
                />
                {touched.password && errors.password ? (
                  <div className="error">Enter password</div>
                ) : null}
              </IonCol>
              <IonCol size="2" className="label">
                <IonButton
                  fill="clear"
                  size="small"
                  // TODO: :))
                  onClick={(e: any) => {
                    setPasswordIsVisible(!passwordIsVisible);
                  }}
                >
                  <IonIcon
                    icon={passwordIsVisible ? visibilityOff : visibility}
                  />
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="2" />
              <IonCol size="8">
                <IonInput
                  aria-label="confirmPassword"
                  name="confirmPassword"
                  type={passwordConfirmIsVisible ? "text" : "password"}
                  value={values.confirmPassword}
                  placeholder="confirm password"
                  className={
                    touched.confirmPassword && errors.confirmPassword
                      ? "error"
                      : ""
                  }
                  onIonInput={handleChange}
                  onIonBlur={(e) => {
                    handleChange(e);
                    handleBlur(e);
                  }}
                />
                {touched.confirmPassword && errors.confirmPassword ? (
                  <div className="error">Enter password</div>
                ) : null}
              </IonCol>
              <IonCol size="2" className="label">
                <IonButton
                  fill="clear"
                  size="small"
                  // TODO: :))
                  onClick={(e: any) => {
                    setPasswordConfirmIsVisible(!passwordConfirmIsVisible);
                  }}
                >
                  <IonIcon
                    icon={passwordConfirmIsVisible ? visibilityOff : visibility}
                  />
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="label" style={{ textAlign: "center" }}>
                <IonButton
                  type="submit"
                  className="btn-social btn-social-primary"
                  disabled={isSubmitting || !isValid || !dirty}
                  onClick={() => handleSubmit()}
                >
                  Request password reset
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </Formik>
    </>
  );
};

export default ResetPassword;
