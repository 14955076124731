import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import React from "react";

export const ModalHeader: React.FC<{
  onClick: React.MouseEventHandler<HTMLIonButtonElement>;
  title: string;
  isSubmitting?: boolean;
}> = ({ onClick, title, isSubmitting = false }) => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton disabled={isSubmitting} onClick={onClick}>
            <IonIcon icon={"/assets/icon/new/arrow-left.svg"} />
          </IonButton>
        </IonButtons>
        <IonTitle>{title}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};
