import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonToggle,
} from "@ionic/react";
import { useState } from "react";
import { removeFill, addFill } from "../icons/iconsFill";
import "./Payment.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import { InferType } from "yup";
import { useMutation } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import useAxios from "../hooks/useAxios";
import { TicketOffer } from "../models/Event";
import PhonePrefixes from "../constants/phone_prefixes";
import { get_region } from "../cache/coordinates";
import { ModalHeader } from "./Headers";
import { useTranslation } from "react-i18next";

const currencyMap: {
  [key: string]: string;
} = {
  CZK: "Kč",
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationRules = Yup.object({
  name: Yup.string().min(3).required(),
  surname: Yup.string().min(3).required(),
  email: Yup.string().email().required(),
  phonePrefix: Yup.string().required(),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required(),
  accepted_terms_conditions: Yup.boolean().oneOf(
    [true],
    "You must accept the terms and conditions"
  ),
});

type FormFields = InferType<typeof validationRules>;

export const PaymentForm: React.FC<{
  ticketOffers: TicketOffer[];
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ ticketOffers, setShowModal }) => {
  const [orderedTickets, setOrderedTickets] = useState<
    {
      ticketOfferId: number | undefined;
      quantity: number;
      totalPrice: number;
    }[]
  >([{ ticketOfferId: undefined, quantity: 0, totalPrice: 0 }]);
  const { axios } = useAxios();
  const { t } = useTranslation();

  function handleAddItem(id: number, ticketPrice: number) {
    const filteredTickets = orderedTickets.filter(
      (ticket) => ticket.ticketOfferId !== undefined
    );

    const updatedOrders = filteredTickets.map((orderItem) => {
      if (orderItem.ticketOfferId === id) {
        if (orderItem.quantity === 0 && ticketPrice < 0) return orderItem;
        return {
          ...orderItem,
          quantity:
            ticketPrice < 0 ? orderItem.quantity - 1 : orderItem.quantity + 1,
          totalPrice: orderItem.totalPrice + ticketPrice,
        };
      }
      return orderItem;
    });

    const itemFound = updatedOrders.some(
      (orderItem) => orderItem.ticketOfferId === id
    );

    if (!itemFound) {
      updatedOrders.push({
        ticketOfferId: id,
        quantity: 1,
        totalPrice: ticketPrice,
      });
    }

    setOrderedTickets(updatedOrders);
  }

  const { mutateAsync } = useMutation<AxiosResponse<any>, AxiosError>(
    (payload) => axios.post("/orders", payload)
  );

  const initial_values = {
    name: "",
    surname: "",
    email: "",
    phone: "",
    phonePrefix: get_region() === "usa" ? "+1" : "+420",
    accepted_terms_conditions: false,
  };

  async function save(values: FormFields) {
    if (!orderedTickets[0] === undefined) return;

    const payload: any = {
      price: orderedTickets.reduce((acc, item) => (acc += item.totalPrice), 0),
      currency: ticketOffers[0].currency,
      items: orderedTickets,
      contact: {
        name: values.name,
        surname: values.surname,
        email: values.email,
        phone: values.phone.replace(" ", ""),
      },
    };

    return mutateAsync(payload, {
      onSuccess: (result: any) => {
        console.log("---OK---", result.data.redirectUrl, result);
        window.location.href = result.data.redirectUrl;
      },
      onError: (err: any) => {
        console.log("---NOK---", err);
      },
    });
  }

  if (!ticketOffers[0]) return null;

  return (
    <Formik
      initialValues={initial_values}
      validationSchema={validationRules}
      onSubmit={(values) => save(values)}
      // onReset={(values, formikActions) => {}}
    >
      {(props) => (
        <>
          <ModalHeader
            title={t("event_detail.buy_tickets")}
            onClick={() => setShowModal(false)}
          />

          <IonContent className="form payment-form add_event_table">
            <div className="container">
              {ticketOffers.length >= 1
                ? ticketOffers.map((ticket) => (
                    <IonRow
                      style={{
                        border: "1px solid rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        marginBottom: "1rem",
                        alignItems: "center",
                        padding: "10px",
                      }}
                    >
                      <IonCol className="label" size="6">
                        <div className="row-description">
                          {ticket.description}
                        </div>
                        <div>
                          {ticket.price} {ticket.currency}
                        </div>
                      </IonCol>
                      <IonCol className="item-column">
                        <IonButton
                          onClick={() =>
                            handleAddItem(ticket.ticketOfferId, -ticket.price)
                          }
                          fill="clear"
                          className="button-changeNumber"
                          disabled={ticket.count < 2}
                        >
                          <IonIcon icon={removeFill} />
                        </IonButton>
                      </IonCol>

                      <IonCol className="item-column">
                        <div className="row-price">
                          <div className="price-number">
                            {orderedTickets.find(
                              (orderItem) =>
                                orderItem.ticketOfferId === ticket.ticketOfferId
                            )
                              ? orderedTickets.find(
                                  (orderItem) =>
                                    orderItem.ticketOfferId ===
                                    ticket.ticketOfferId
                                )?.quantity
                              : 0}
                          </div>
                        </div>
                      </IonCol>
                      <IonCol className="item-column">
                        <IonButton
                          onClick={() =>
                            handleAddItem(ticket.ticketOfferId, ticket.price)
                          }
                          fill="clear"
                          className="button-changeNumber"
                        >
                          <IonIcon icon={addFill} />
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  ))
                : ""}

              <IonGrid>
                <IonRow>
                  <IonCol className="label" size="5">
                    <div>{t("payment.sum")}:</div>
                  </IonCol>
                  <IonCol>
                    <div className="row-price">
                      <div className="price-number">
                        {orderedTickets.reduce(
                          (acc, item) => (acc += item.totalPrice),
                          0
                        )}
                      </div>
                      <div className="price-currency">
                        {currencyMap[ticketOffers[0].currency]}
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <h3>{t("payment.contact")}</h3>
              <IonGrid>
                <IonRow>
                  <IonCol className="label" size="5">
                    <div>{t("payment.name")}</div>
                  </IonCol>
                  <IonCol>
                    <IonInput
                      aria-label="name"
                      name="name"
                      onIonInput={(e: any) => {
                        props.handleChange(e);
                      }}
                      onIonBlur={(e: any) => {
                        props.handleBlur(e);
                      }}
                      value={props.values.name}
                    />
                    {props.touched.name && props.errors.name ? (
                      <div className="error">{props.errors.name}</div>
                    ) : null}
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol className="label" size="5">
                    <div>{t("payment.surname")}</div>
                  </IonCol>
                  <IonCol>
                    <IonInput
                      aria-label="surname"
                      name="surname"
                      onIonInput={(e: any) => {
                        props.handleChange(e);
                      }}
                      onIonBlur={(e: any) => {
                        props.handleBlur(e);
                      }}
                      value={props.values.surname}
                    />
                    {props.touched.surname && props.errors.surname ? (
                      <div className="error">{props.errors.surname}</div>
                    ) : null}
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol className="label" size="5">
                    <div>{t("payment.email")}</div>
                  </IonCol>
                  <IonCol>
                    <IonInput
                      aria-label="email"
                      name="email"
                      onIonInput={(e: any) => {
                        props.handleChange(e);
                      }}
                      onIonBlur={(e: any) => {
                        props.handleBlur(e);
                      }}
                      value={props.values.email}
                    />
                    {props.touched.email && props.errors.email ? (
                      <div className="error">{props.errors.email}</div>
                    ) : null}
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol className="label" size="5">
                    <div>{t("payment.phone")}</div>
                  </IonCol>
                  <IonCol size="3">
                    <IonSelect
                      aria-label="phonePrefix"
                      name="phonePrefix"
                      interfaceOptions={{ header: "Select country code" }}
                      value={props.values.phonePrefix}
                      onIonChange={props.handleChange}
                      onIonBlur={(e) => {
                        props.handleChange(e);
                        props.handleBlur(e);
                      }}
                    >
                      {PhonePrefixes?.map(({ dial_code, flag }) => {
                        return (
                          <IonSelectOption key={dial_code} value={dial_code}>
                            {flag} {dial_code}
                          </IonSelectOption>
                        );
                      })}
                    </IonSelect>
                  </IonCol>
                  <IonCol>
                    <IonInput
                      aria-label="phone"
                      name="phone"
                      onIonInput={(e: any) => {
                        props.handleChange(e);
                      }}
                      onIonBlur={(e: any) => {
                        props.handleBlur(e);
                      }}
                      value={props.values.phone}
                    />
                    {props.touched.phone && props.errors.phone ? (
                      <div className="error">{props.errors.phone}</div>
                    ) : null}
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="12">
                    {t("payment.agree_with")}{" "}
                    <a href="/pdf/obchodni-podminky.pdf" target="_blank">
                      {t("payment.conditions")}
                    </a>
                    &nbsp;
                    <a
                      href="/pdf/zasady-ochrany-osobnich-udaju.pdf"
                      target="_blank"
                    >
                      {t("payment.gdpr")}
                    </a>
                    &nbsp;a&nbsp;
                    <a href="/pdf/zpracovatelske-podminky.pdf" target="_blank">
                      {t("payment.service_terms")}
                    </a>
                  </IonCol>
                  <IonCol size="5">
                    <IonToggle
                      name="accepted_terms_conditions"
                      aria-label={"accepted_terms_conditions"}
                      checked={props.values.accepted_terms_conditions}
                      onIonChange={(e) => {
                        props.setFieldValue(
                          "accepted_terms_conditions",
                          !props.values.accepted_terms_conditions
                        );
                      }}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </IonContent>

          <IonButton
            className="modal_big_button"
            disabled={props.isSubmitting || !props.isValid || !props.dirty}
            onClick={() => {
              props.handleSubmit();
            }}
          >
            {t("payment.payment_continue")}
          </IonButton>
        </>
      )}
    </Formik>
  );
};

export const PaymentModal: React.FC<{
  ticketOffers: TicketOffer[];
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ ticketOffers, showModal, setShowModal }) => {
  return (
    <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
      <PaymentForm ticketOffers={ticketOffers} setShowModal={setShowModal} />
    </IonModal>
  );
};
