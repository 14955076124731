import { useEffect } from "react";
import { IonButton } from "@ionic/react";
import { Browser } from "@capacitor/browser";
import { useAuth0, Auth0Provider } from "@auth0/auth0-react";
import { App as CApp } from "@capacitor/app";
import "./auth0.scss";
import { Auth } from "../models";
import { useProfile, useStorage } from "../hooks";
import { axiosApiAuth } from "../helpers/axios";
import { logEvent } from "../helpers/logging";
import {
  auth0_callback_uri,
  auth0_client_id,
  auth0_domain,
  isMobileDevelopment,
} from "../config";

const Auth0GoogleLoginButton: React.FC<{
  type: "login" | "register";
}> = ({ type }) => {
  const { loginWithRedirect } = useAuth0();

  const login = async () => {
    await loginWithRedirect({
      authorizationParams: {
        connection: "google-oauth2",
      },
      async openUrl(url) {
        await Browser.open({ url: url });
      },
    });
  };

  return (
    <IonButton className="btn-social btn-google" onClick={login} color="white">
      <img src="/assets/logos/google.png" alt="Google" />
      Continue with Google
    </IonButton>
  );
};

const Auth0AppleLoginButton: React.FC<{
  type: "login" | "register";
}> = ({ type }) => {
  const { loginWithRedirect } = useAuth0();

  const login = async () => {
    await loginWithRedirect({
      authorizationParams: {
        connection: "apple",
      },
      async openUrl(url) {
        await Browser.open({ url: url });
      },
    });
  };

  return (
    <IonButton className="btn-social btn-apple" onClick={login} color="white">
      <img src="/assets/logos/apple.png" alt="Apple" />
      Continue with Apple
    </IonButton>
  );
};

const Auth0Listener: React.FC<{
  isLoadingCallback: (isLoading: boolean) => void;
}> = ({ isLoadingCallback }) => {
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    handleRedirectCallback,
    logout,
  } = useAuth0();
  const { setAuth } = useStorage();
  const { getProfile } = useProfile();

  useEffect(() => {
    if (isAuthenticated) {
      isLoadingCallback(true);
      getAccessTokenSilently().then((x) => {
        axiosApiAuth
          .post<Auth>("/auth0", undefined, {
            headers: { authorization: `Bearer ${x}` },
          })
          .then((res) => {
            // TODO: DRY it
            setAuth(res.data);
            // we are logging out Auth0 and continue with our login
            // there were problems when logged on Android after reload, Auth0 was timeouting (isLoading variable and 400 on authorize Auth0 api call)
            logout({
              openUrl: false,
            });
            setTimeout(() => getProfile.refetch(), 500);
            logEvent("login", {});
            isLoadingCallback(false);
          })
          .catch((e) => {
            console.error(e);
            isLoadingCallback(false);
          });
      });
    } else if (!isLoading) {
      isLoadingCallback(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
    CApp.addListener("appUrlOpen", async ({ url }) => {
      if (
        url.includes("state") &&
        (url.includes("code") || url.includes("error"))
      ) {
        await handleRedirectCallback(url);
      }
      // No-op on Android
      await Browser.close();
    });
  }, [handleRedirectCallback]);

  return null;
};

const useAuth0Logout = () => {
  const { logout, isAuthenticated } = useAuth0();

  const auth0Logout = () => {
    if (isAuthenticated)
      logout({
        openUrl: false,
      });
  };

  return { auth0Logout };
};

const Auth0Context: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // NOTE: Auth0 is broken on mobile dev env because of https => we ignore Auth0 here
  if (isMobileDevelopment) return <>{children}</>;
  else
    return (
      <Auth0Provider
        domain={auth0_domain}
        clientId={auth0_client_id}
        authorizationParams={{
          redirect_uri: auth0_callback_uri,
        }}
      >
        {children}
      </Auth0Provider>
    );
};

export {
  Auth0Context,
  Auth0Listener,
  Auth0GoogleLoginButton,
  Auth0AppleLoginButton,
  useAuth0Logout,
};
