import { StateStorageProvider } from "./StateStorage";
import { QueryStorageProvider } from "./QueryStorage";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const GlobalContext: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <StateStorageProvider>
      <QueryClientProvider client={queryClient}>
        <QueryStorageProvider>{children}</QueryStorageProvider>
      </QueryClientProvider>
    </StateStorageProvider>
  );
};

export default GlobalContext;
