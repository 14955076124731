import { isPlatform } from "@ionic/react";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { TrackingToken, PayloadTrackingToken } from "../models/TrackingToken";
import useAxios from "./useAxios";
import useStorage from "./useStorage";

const useTrackingToken = () => {
  const { setMessage } = useStorage();
  const { axios } = useAxios();
  const [token, setToken] = useState<string>();
  const { mutate: uploadToGetToken } = useMutation<
    AxiosResponse<TrackingToken>,
    AxiosError,
    PayloadTrackingToken
  >((payload) => axios.post("/tokens", payload), {
    onSuccess: (data) => {
      localStorage.setItem("token", data.data.token);
      setToken(data.data.token);
    },
    onError: (err) => setMessage("danger", undefined, err),
  });

  const checkDevice = () => {
    if (isPlatform("mobile")) return "mobile";
    else if (isPlatform("tablet")) return "tablet";
    else return "phablet";
  };
  const checkView = () => {
    if (isPlatform("mobile")) return "mobile";
    else if (isPlatform("mobileweb")) return "mobileweb";
    else return "desktop";
  };
  const checkPlatform = () => {
    if (isPlatform("ios")) return "ios";
    else if (isPlatform("android")) return "android";
    else return null;
  };

  useEffect(() => {
    const trackingTokenData: PayloadTrackingToken = {
      device: checkDevice(),
      view: checkView(),
      platform: checkPlatform(),
      data: {
        ipad: isPlatform("ipad"),
        iphone: isPlatform("iphone"),
        ios: isPlatform("ios"),
        android: isPlatform("android"),
        phablet: isPlatform("phablet"),
        tablet: isPlatform("tablet"),
        cordova: isPlatform("cordova"),
        capacitor: isPlatform("capacitor"),
        electron: isPlatform("electron"),
        pwa: isPlatform("pwa"),
        mobile: isPlatform("mobile"),
        mobileweb: isPlatform("mobileweb"),
        desktop: isPlatform("desktop"),
        hybrid: isPlatform("hybrid"),
      },
    };

    const trackingToken = localStorage.getItem("token");

    if (trackingToken === null) {
      uploadToGetToken(trackingTokenData);
    } else {
      setToken(trackingToken);
    }
  }, [uploadToGetToken]);

  return { token };
};

export default useTrackingToken;
