import React from "react";
import { IonContent, IonModal, IonButton, IonIcon } from "@ionic/react";
import "./CUGroup.scss";
import { ModalHeader } from "./Headers";
import { useTranslation } from "react-i18next";
import { czFlag, enFlag } from "../icons/iconsFill";
import { useStorage } from "../hooks";

const LanguageChange: React.FC<{
  showModal: boolean;
  setShowModal: CallableFunction;
}> = ({ showModal, setShowModal }) => {
  const { t, i18n } = useTranslation();
  const { setCurrLanguage } = useStorage();
  const closeModal = () => {
    return setShowModal(false);
  };

  return (
    <IonModal isOpen={showModal} onDidDismiss={closeModal}>
      <>
        <ModalHeader
          title={t("setting.language")}
          onClick={() => {
            closeModal();
          }}
        />

        <IonContent className="form">
          <div className="content-padded">
            <h3>{t("setting.language")}</h3>
            <div className="text-14 margin-bottom">
              {t("setting.language_subtitle")}
            </div>
            <div>
              <IonIcon
                icon={czFlag}
                className="icon_medium"
                style={{ fontSize: "64px" }}
                onClick={() => {
                  i18n.changeLanguage("cs");
                  setCurrLanguage("cs");
                }}
              />
              <IonIcon
                icon={enFlag}
                className="icon_medium"
                style={{ fontSize: "64px" }}
                onClick={() => {
                  i18n.changeLanguage("en");
                  setCurrLanguage("en");
                }}
              />
            </div>
          </div>
        </IonContent>

        <IonButton
          type="submit"
          className="modal_big_button"
          onClick={() => closeModal()}
        >
          {t("setting.close")}
        </IonButton>
      </>
    </IonModal>
  );
};

export default LanguageChange;
