import React, { LegacyRef, useEffect, useRef, useState } from "react";
import {
  IonItem,
  IonAvatar,
  IonLabel,
  IonButton,
  IonBadge,
  IonIcon,
  IonItemOption,
  IonItemOptions,
  IonPopover,
  IonList,
  IonAccordion,
  IonAccordionGroup,
  IonCard,
  isPlatform,
  //isPlatform,
} from "@ionic/react";
import copy from "copy-to-clipboard";
import moment from "moment";
import {
  Event,
  Location,
  Currency,
  AttendanceState,
  Attendee,
} from "../models/Event";
import {
  attendanceSlideIcons,
  attendanceStateColors,
  attendanceStateIcons,
} from "../constants/events";
import "./Event.scss";
import { DirectionsPath } from "../models/directions";

import {
  calc_end_date,
  get_event_photo_url,
  get_primary_tag_slug_default,
} from "../helpers/event";
import { PopoverSettings } from "../models/popover";
import { link_replace_config } from "../helpers/textReplaceConfig";
import { gps_distance } from "../helpers/map";
import { useStorage } from "../hooks";
import { logEvent } from "../helpers/logging";
import useLeaveEvent from "../hooks/useLeaveEvent";
import useInterestedInEvent from "../hooks/useInterestedInEvent";
import { PARTNERS } from "../constants/partners";
import { DateOptions } from "./DateOptions";
import { atcb_action } from "add-to-calendar-button";
import {
  calendarMonth,
  close,
  done,
  heartMinus,
  nearMe,
  contentCopy,
  locationOn,
  remove,
  favorite,
} from "../icons/iconsOutline";
import { expandMore, starFill } from "../icons/iconsFill";
import { useInvalidateQueries } from "../hooks/useInvalidateQueries";
import useAxios from "../hooks/useAxios";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { useSmartlookSensitive } from "../hooks/useSmartlook";
import { ImageSlidesClose } from "./Discussion";
import ImageSlides from "react-imageslides";
import useAnalytics from "../hooks/useAnalytics";
import useJoinEvent from "../hooks/useJoinEvent";
import { PopoverActionButtons } from "./Unregistered";
import { PaymentModal } from "./Payment";
import { device, web_url, web_url_tickets } from "../config";
import { useTranslation } from "react-i18next";
import useTranlatedMsg from "../hooks/useTranslatedMsg";

const processString = require("react-process-string");

export const getEventPublicUrl = (eventId: number, hash?: string) => {
  if (hash) return `${web_url}/events/${eventId}/${hash}/about`;
  return `${web_url}/events/${eventId}/about`;
};
export const getEventPublicUrlTickets = (eventId: number, hash?: string) => {
  if (hash) return `${web_url_tickets}/events/${eventId}/${hash}/about`;
  return `${web_url_tickets}/events/${eventId}/about`;
};

// ok, 2-3 functions below should be unified, its shit
export function get_location_name(
  location: Location | undefined,
  short: boolean
) {
  // TODO: make it prettier
  if (!location) return <span className="active">Vote on the location</span>;
  else if (location.name) {
    if (short) return location.name;
    else if (location.formattedAddress) {
      if (location.formattedAddress.startsWith(location.name))
        return location.formattedAddress;
      else return `${location.name}, ${location.formattedAddress}`;
    } else return `${location.name}`;
  } else return location.formattedAddress;
}

export function get_location_name_suffix(location: Location) {
  if (location.formattedAddress) {
    let short = location.formattedAddress.replace(location.name, "");
    if (short.length && short[0] === ",") short = short.substring(1);
    return short.trim();
  } else return "";
}

export function get_place_name(
  place: google.maps.places.PlaceResult,
  short: boolean
) {
  if (place.name) {
    if (short) return place.name;
    else if (place.formatted_address) {
      if (place.formatted_address.startsWith(place.name))
        return place.formatted_address;
      else return `${place.name}, ${place.formatted_address}`;
    } else return `${place.name}`;
  } else return place.formatted_address;
}

export function get_event_attendance_class(
  isHost: boolean,
  state: AttendanceState | null
) {
  if (isHost) return "danger";
  else if (state !== null) return attendanceStateColors[state];
  else return "dark";
}

export const UserAttendanceBadge: React.FC<{
  state: AttendanceState;
  hostId: number | undefined;
  userId: number | undefined;
}> = ({ hostId, userId, state }) => {
  if (userId === hostId)
    return (
      <IonBadge color="danger" className={"badge-organiser"}>
        <IonIcon icon={starFill} color="light" />
      </IonBadge>
    );
  else
    return (
      <IonBadge
        color={attendanceStateColors[state]}
        className={"badge-" + state}
      >
        <IonIcon
          icon={attendanceStateIcons[state]}
          color={["INVITED", "MAYBE"].includes(state) ? "dark" : "light"}
        />
      </IonBadge>
    );
};

export const EventTime: React.FC<{
  start: Date | null;
  end: Date | null;
  isThumbnail?: boolean;
}> = ({ start, end, isThumbnail = false }) => {
  let c_start = "";
  let c_end = "";
  let c_className = "";

  const now = moment();

  if (start === null) {
    c_start = "Vote on the date";
    c_end = "";
    c_className = "active";
  } else if (moment(start).subtract(1, "hour") < now && moment(start) > now) {
    const diff = Math.ceil(
      moment.duration(moment(start).diff(now)).asMinutes()
    );
    c_start = `Starts in ${diff} ${diff > 1 ? "minutes" : "minute"}`;
    c_end = "";
    c_className = "active";
  } else if (moment(start) < now && moment(end) > now) {
    c_start = "Ongoing";
    c_end = moment(end).isSame(moment(), "year")
      ? `ends ${moment(end).format("MMM DD")}`
      : `ends ${moment(end).format("MMM YYYY")}`;
    c_className = "active";
  } else {
    const tempStart = format_date_first(start, end);
    const tempEnd = end ? format_date_second(start, end) : "";
    c_start = tempStart;
    c_end = tempEnd;
    c_className = "";
    if (
      moment(start).format("HH:mm") === "00:00" &&
      moment(end).diff(moment(start), "hours") === 24
    )
      c_end = "Whole day";
  }

  return isThumbnail ? (
    <>
      <span className={c_className}>{c_start}</span>
      {isThumbnail && c_end.length ? ": " : ""}
      <span className={c_className}>{c_end}</span>
    </>
  ) : (
    <>
      <div className="main">{c_start}</div>
      <div className="text">{c_end}</div>
    </>
  );
};

export const EventDirections: React.FC<{
  directionsPath: DirectionsPath;
  start: Date;
}> = ({ directionsPath, start }) => {
  const { t } = useTranslation();

  const diff = Math.ceil(
    moment.duration(moment(start).diff(moment())).asSeconds()
  );

  return (
    <>
      ·{" "}
      <span
        className={diff >= directionsPath.time_seconds ? "success" : "danger"}
      >
        {t("event_detail.walk")} {directionsPath.distance}{" "}
        {t("event_detail.in")} {directionsPath.time}
      </span>
    </>
  );
};

export const EventItemOptions: React.FC<{
  event: Event;
  setRerender: CallableFunction;
  // duplicate_event?: CallableFunction;
  userId: number | undefined;
  // overview: boolean;
  order: number;
  source: string;
}> = ({
  event,
  setRerender,
  // duplicate_event,
  userId,
  // overview,
  order,
  source,
}) => {
  const { setMessage } = useStorage();
  const { interestedInEvent } = useInterestedInEvent();
  const leaveEvent = useLeaveEvent();
  const invalidateQueries = useInvalidateQueries();
  const { axios } = useAxios();
  const { sendMetric } = useAnalytics();
  const { joinEvent } = useJoinEvent(event.eventId);
  const { not_interested, left_event } = useTranlatedMsg();

  const { mutate: declineInvitation } = useMutation<
    never,
    AxiosError,
    { state: "DECLINED" }
  >((payload_state) =>
    axios.put(`/events/${event.eventId}/invitation`, payload_state)
  );

  return (
    <IonItemOptions side="end">
      {event.attends === "MAYBE" && (
        <IonItemOption
          className="wide"
          color="medium"
          onClick={() => {
            leaveEvent(event.eventId, {
              onSuccess: () => {
                event.attends = null;
                setRerender(Math.random());
                setMessage("primary", not_interested);
                sendMetric({
                  name: "event_leave",
                  data: {
                    event_id: event.eventId,
                    source: source,
                    order: order,
                    old_status: "interested",
                  },
                });
              },
            });
          }}
        >
          <IonIcon
            size="large"
            slot="icon-only"
            color="light"
            icon={heartMinus}
          />
        </IonItemOption>
      )}
      {moment(calc_end_date(event)) > moment() &&
        event.attends !== "MAYBE" &&
        event.public &&
        !event.isHost && (
          <IonItemOption
            className="wide"
            color="secondary"
            onClick={() => {
              interestedInEvent(event.eventId, {
                onSuccess: () => {
                  event.attends = "MAYBE";
                  setRerender(Math.random());
                  sendMetric({
                    name: "event_interested",
                    data: {
                      event_id: event.eventId,
                      source: source,
                      order: order,
                    },
                  });
                },
              });
            }}
          >
            <IonIcon
              size="large"
              slot="icon-only"
              color="light"
              icon={favorite}
            />
          </IonItemOption>
        )}

      {(event.attends === null ||
        event.attends === "MAYBE" ||
        event.attends === "DECLINED") && (
        <IonItemOption
          className="wide"
          color="primary"
          onClick={() => {
            joinEvent(
              {},
              {
                onSuccess: () => {
                  event.attends = "GOING";
                  setRerender(Math.random());
                  sendMetric({
                    name: "event_join",
                    data: {
                      event_id: event.eventId,
                      source: source,
                      order: order,
                    },
                  });
                },
              }
            );
          }}
        >
          <IonIcon
            size="large"
            slot="icon-only"
            color="light"
            icon={done}
          ></IonIcon>
        </IonItemOption>
      )}
      {/* {event.hostId === userId && (
        <IonItemOption
          className="wide"
          color="danger"
          onClick={() => {
            if (duplicate_event) duplicate_event(event.eventId);
          }}
        >
          <IonIcon
            size="large"
            slot="icon-only"
            color="light"
            icon={contentCopy}
          />
        </IonItemOption>
      )} */}
      {event.attends === "GOING" && event.hostId !== userId && (
        <IonItemOption
          className="wide"
          color="medium"
          onClick={() => {
            leaveEvent(event.eventId, {
              onSuccess: () => {
                event.attends = null;
                setRerender(Math.random());
                setMessage("primary", left_event);

                sendMetric({
                  name: "event_leave",
                  data: {
                    event_id: event.eventId,
                    source: source,
                    order: order,
                    old_status: "going",
                  },
                });
              },
            });
          }}
        >
          <IonIcon size="large" slot="icon-only" color="light" icon={close} />
        </IonItemOption>
      )}
      {event.attends === "INVITED" &&
        moment(calc_end_date(event)) > moment() && (
          <>
            <IonItemOption
              className="wide"
              color="medium"
              onClick={() => {
                declineInvitation(
                  {
                    state: "DECLINED",
                  },
                  {
                    onSuccess: () => {
                      event.attends = "DECLINED";
                      setRerender(Math.random());
                      logEvent("event_decline", {});
                      invalidateQueries("hp_events");
                      sendMetric({
                        name: "event_decline",
                        data: {
                          event_id: event.eventId,
                          source: source,
                          order: order,
                          old_status: "invited",
                        },
                      });
                    },
                    onError: (err) =>
                      setMessage("danger", "Something went wrong", err),
                  }
                );
              }}
            >
              <IonIcon
                size="large"
                slot="icon-only"
                color="light"
                icon={remove}
              />
            </IonItemOption>
            <IonItemOption
              className="wide"
              color="primary"
              onClick={() => {
                joinEvent(
                  {},
                  {
                    onSuccess: () => {
                      event.attends = "GOING";
                      setRerender(Math.random());
                      sendMetric({
                        name: "event_join",
                        data: {
                          event_id: event.eventId,
                          source: source,
                          order: order,
                        },
                      });
                    },
                  }
                );
              }}
            >
              <IonIcon
                size="large"
                slot="icon-only"
                color="light"
                icon={done}
              />
            </IonItemOption>
          </>
        )}
    </IonItemOptions>
  );
};

export const EventItem: React.FC<{
  event: Event;
  // duplicate_event?: CallableFunction;
  // set_directions?: CallableFunction;
  // activeDirections?: Coordinates;
  directionsPath?: DirectionsPath;
  // actions?: boolean;
  overview?: boolean;
  order: number;
  source: string;
}> = ({
  event,
  // duplicate_event,
  // set_directions,
  // activeDirections,
  directionsPath,
  // actions = false,
  overview = false,
  order,
  source,
}) => {
  const {
    // notifications,
    // currentUser: user,
    gpsCoordinates: gps,
  } = useStorage();
  // TODO: not sure how safe it is to do it like this
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setRerender] = useState<Number>(Math.random());

  // const hasNotification = has_object_notification(
  //   notifications,
  //   false,
  //   ["event"],
  //   event.eventId
  // );

  const mask = event.public === false;

  return (
    // <IonItemSliding
    //   className={`event_item ion-color ion-color-${get_event_attendance_class(
    //     event.isHost,
    //     event.attends
    //   )} ${hasNotification ? "highlighted" : ""} ${
    //     user ? "event_item_swipeable" : ""
    //   }`}
    //   onClick={() => {
    //     document.querySelector("ion-item-sliding")?.closeOpened();
    //   }}
    //   // disabled={!duplicate_event}
    // >
    <IonItem
      lines="none"
      className="event_item"
      routerLink={"/events/" + event.eventId + "/about"}
      detail={false}
    >
      <IonAvatar slot="start">
        <img
          src={get_event_photo_url(
            event.photoSmall || event.photo,
            get_primary_tag_slug_default(event.tags)
          )}
          alt=""
          data-sl={mask ? "exclude" : ""}
          className={mask ? "smartlook-hide" : ""}
        />
        {/* {event.attends && (
          <div className="badges">
            {!event.public && (
              <div className="badge_visibility">
                <span>
                  <IonIcon icon={lockFill} />
                </span>
              </div>
            )}
            <UserAttendanceBadge
              userId={user?.userId}
              state={event.attends}
              hostId={event.hostId}
            />
          </div>
        )} */}
      </IonAvatar>

      <IonLabel>
        <h3
          data-sl={mask ? "mask" : ""}
          className={mask ? "smartlook-hide" : ""}
        >
          {event.title}
        </h3>
        <div className="event_info_row time">
          <div className="event_info_col">
            <div className="icon">
              <IonIcon icon={calendarMonth} className="icon_small" />
            </div>
            <div className="texts">
              <EventTime
                start={event.start}
                end={event.end}
                isThumbnail={true}
              />
              {/* {!!event.seriesCount && (
                <span className="text-light">
                  {" "}
                  | See {event.seriesCount} more dates
                </span>
              )} */}
            </div>
          </div>
        </div>
        <div className="event_info_row address">
          <div className="event_info_col">
            <div className="icon">
              <IonIcon icon={locationOn} className="icon_small" />
            </div>
            <div
              className={`texts ${event.location?.name ? "" : "active"} ${
                mask ? "smartlook-hide" : ""
              }`}
              data-sl={mask ? "mask" : ""}
            >
              {event.location?.formattedAddress
                ? event.location.name
                : event.city
                ? event.city.name
                : event.location?.name || "Vote on the location"}
              {directionsPath && event.start && (
                <EventDirections
                  directionsPath={directionsPath}
                  start={event.start}
                />
              )}
            </div>
          </div>

          {!directionsPath &&
            event.location?.lat &&
            event.location.lng &&
            gps && (
              <div className="event_info_col">
                <div className="icon">
                  <IonIcon icon={nearMe} className="icon_small" />
                </div>
                <div className="texts">
                  <span className="gps_distance">
                    {gps_distance(
                      gps.lat,
                      gps.lng,
                      event.location?.lat,
                      event.location?.lng,
                      "K"
                    )}{" "}
                    km
                  </span>
                </div>
              </div>
            )}
        </div>
        {/* {event.dataProviderSlug !== null && (
          <div className="event_info_row time">
            <div className="event_info_col">
              <div className="icon">
                <IonIcon icon={star} className="icon_small" />
              </div>
              <div className="texts">Data provided by </div>
              <img
                alt={event.dataProviderSlug}
                src={PARTNERS[event.dataProviderSlug].logo}
                className="logo_pic"
                onClick={() => {
                  if (event.dataProviderSlug !== null)
                    window.open(PARTNERS[event.dataProviderSlug].url)?.focus();
                }}
              ></img>
            </div>
          </div>
        )} */}
        {/* <div className="event_info_row tags">
          <div className="event_info_col">
            {event.tags.length > 0 &&
              event.tags.map((t) => {
                return (
                  <span className="tag" key={t.slug}>
                    {t.name}
                  </span>
                );
              })}
          </div>
        </div> */}
      </IonLabel>
    </IonItem>
  );

  /* {user && (
  <EventItemOptions
    event={event}
    setRerender={setRerender}
    // duplicate_event={duplicate_event}
    userId={user.userId}
    overview={overview}
    order={order}
    source={source}
  />
)} */

  /* </IonItemSliding> */
};

function format_date_first(start: Date, end: Date | null | undefined) {
  const first_part = moment(start).isSame(moment(), "year")
    ? moment(start).format("ddd, MMM Do")
    : moment(start).format("ddd, MMM Do YYYY");

  return (moment(start).hour() === 0 && moment(start).minute() === 0) ||
    (end && moment(start).isSame(end, "day"))
    ? first_part
    : first_part + ", " + moment(start).format("H:mm");
}

function format_date_second(start: Date, end: Date) {
  if (moment(start).isSame(end, "day")) {
    return moment(start).format("H:mm") + " - " + moment(end).format("H:mm");
  }

  const first_part = moment(end).isSame(moment(), "year")
    ? moment(end).format("ddd, MMM Do")
    : moment(end).format("ddd, MMM Do YYYY");

  const second_part =
    moment(end).hour() === 0 && moment(end).minute() === 0
      ? ""
      : `, ${moment(end).format("H:mm")}`;

  return first_part + second_part;
}

const EventButtonJoin: React.FC<{
  event: Event;
  color: "primary" | "light";
  setRerender: React.Dispatch<React.SetStateAction<Number>>;
  onEventChanged?: CallableFunction;
  isPast: boolean;
  source: string;
}> = ({ event, color, setRerender, onEventChanged, isPast, source }) => {
  const { currentUser: user } = useStorage();
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const { sendMetric } = useAnalytics();
  const { joinEvent } = useJoinEvent(event.eventId);

  return (
    <>
      <PopoverActionButtons
        popoverState={popoverState}
        setShowPopover={setShowPopover}
      />
      <IonButton
        size="small"
        color={isPast ? "dark" : color}
        fill="solid"
        disabled={isPast}
        className="join"
        onClick={(e: any) => {
          if (user) {
            joinEvent(
              {},
              {
                onSuccess: () => {
                  event.attends = "GOING";
                  setRerender(Math.random());
                  if (onEventChanged) onEventChanged();
                  sendMetric({
                    name: "event_join",
                    data: {
                      event_id: event.eventId,
                      source: source,
                    },
                  });
                },
              }
            );
          } else setShowPopover({ showPopover: true, event: e });
        }}
      >
        <IonIcon icon={done} size="large" slot="icon-only"></IonIcon>
        {event?.attends === "INVITED" ? "Accept" : "Join"}
      </IonButton>
    </>
  );
};

const EventButtonMaybe: React.FC<{
  event: Event;
  color: "secondary" | "light";
  setRerender: React.Dispatch<React.SetStateAction<Number>>;
  onEventChanged?: CallableFunction;
  isPast: boolean;
  source: string;
}> = ({ event, color, setRerender, onEventChanged, isPast, source }) => {
  const { interestedInEvent } = useInterestedInEvent();
  const { currentUser: user } = useStorage();
  const { t } = useTranslation();
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const { sendMetric } = useAnalytics();

  return (
    <>
      <PopoverActionButtons
        popoverState={popoverState}
        setShowPopover={setShowPopover}
      />
      <IonButton
        size="small"
        color="secondary"
        fill="solid"
        disabled={isPast}
        className="participation-btn"
        onClick={(e: any) => {
          if (user) {
            interestedInEvent(event.eventId, {
              onSuccess: () => {
                event.attends = "MAYBE";
                setRerender(Math.random());
                if (onEventChanged) onEventChanged();
                sendMetric({
                  name: "event_intersted",
                  data: {
                    event_id: event.eventId,
                    source: source,
                  },
                });
              },
            });
          } else setShowPopover({ showPopover: true, event: e });
        }}
      >
        <IonIcon size="large" icon={favorite} slot="icon-only"></IonIcon>
        {t("event_detail.interested")}
      </IonButton>
    </>
  );
};

const EventButtonDecline: React.FC<{
  event: Event;
  setRerender: React.Dispatch<React.SetStateAction<Number>>;
  onEventChanged?: CallableFunction;
  isPast: boolean;
}> = ({ event, setRerender, onEventChanged, isPast }) => {
  const { setMessage } = useStorage();
  const { t } = useTranslation();
  const { went_wrong } = useTranlatedMsg();
  const invalidateQueries = useInvalidateQueries();
  const { axios } = useAxios();
  const { mutate: declineInvitation } = useMutation<
    never,
    AxiosError,
    { state: "DECLINED" }
  >((payload_state) =>
    axios.put(`/events/${event.eventId}/invitation`, payload_state)
  );

  return (
    <IonButton
      size="small"
      color="light"
      fill="solid"
      disabled={isPast}
      className="decline"
      onClick={() => {
        if (event.attends === "INVITED") {
          declineInvitation(
            {
              state: "DECLINED",
            },
            {
              onSuccess: () => {
                event.attends = "DECLINED";
                setRerender(Math.random());
                logEvent("event_decline", {});
                if (onEventChanged) onEventChanged();
                invalidateQueries("hp_events");
              },
              onError: (err) => setMessage("danger", went_wrong, err),
            }
          );
        }
      }}
    >
      <IonIcon size="large" slot="icon-only" icon={close}></IonIcon>
      {t("event_detail.decline")}
    </IonButton>
  );
};

const EventAttendingFriends: React.FC<{
  friends: Attendee[];
}> = ({ friends }) => {
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const { t } = useTranslation();

  return (
    <>
      <IonPopover
        isOpen={popoverState.showPopover}
        event={popoverState.event}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
      >
        {friends.map((friend) => (
          <IonList inset={true} key={friend.user.userId}>
            {friend.user.name}
          </IonList>
        ))}
      </IonPopover>
      {friends.length > 1 && (
        <div
          className="friends-part-text"
          onClick={(e: any) => setShowPopover({ showPopover: true, event: e })}
        >
          {t("event_detail.your")} <strong>{t("event_detail.friends")}</strong>{" "}
          {t("event_detail.are_going")}
        </div>
      )}
      {friends.length === 1 && (
        <div className="text">
          <strong>{friends[0].user.name}</strong> {t("event_detail.is_going")}
        </div>
      )}
    </>
  );
};

const EventAttendanceAccordion: React.FC<{
  event: Event;
  onEventChanged?: CallableFunction;
  setRerender: React.Dispatch<React.SetStateAction<Number>>;
  source: string;
}> = ({ event, onEventChanged, setRerender, source }) => {
  const leaveEvent = useLeaveEvent();
  const { interestedInEvent } = useInterestedInEvent();
  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);
  const closeAccordion = () => {
    if (!accordionGroup.current) return;
    accordionGroup.current.value = undefined;
  };
  const { sendMetric } = useAnalytics();
  const { joinEvent } = useJoinEvent(event.eventId);
  const { t } = useTranslation();

  return (
    <IonAccordionGroup
      expand="inset"
      className="accordion"
      ref={accordionGroup}
    >
      <IonAccordion>
        {event.attends === "MAYBE" ? (
          <>
            <IonItem slot="header" color={"secondary"}>
              <IonIcon icon={favorite}></IonIcon>
              <IonLabel>Interested</IonLabel>
            </IonItem>
            <div
              className="ion-padding accordion-item"
              slot="content"
              onClick={() => {
                joinEvent(
                  {},
                  {
                    onSuccess: () => {
                      event.attends = "GOING";

                      setRerender(Math.random());

                      closeAccordion();
                      if (onEventChanged) onEventChanged();

                      sendMetric({
                        name: "event_join",
                        data: {
                          event_id: event.eventId,
                          source: source,
                          old_status: "interested",
                        },
                      });
                    },
                  }
                );
              }}
            >
              <IonIcon icon={done}></IonIcon>
              {t("event_detail.going")}
            </div>
            <div
              className="ion-padding accordion-item"
              slot="content"
              onClick={() => {
                leaveEvent(event.eventId, {
                  onSuccess: () => {
                    event.attends = null;
                    setRerender(Math.random());
                    closeAccordion();
                    if (onEventChanged) onEventChanged();

                    sendMetric({
                      name: "event_leave",
                      data: {
                        event_id: event.eventId,
                        source: source,
                        old_status: "interested",
                      },
                    });
                  },
                });
              }}
            >
              <IonIcon icon={heartMinus}></IonIcon>
              {t("event_detail.not_interested")}
            </div>
          </>
        ) : (
          <>
            <IonItem slot="header" color={"primary"}>
              <IonIcon icon={done}></IonIcon>
              <IonLabel>{t("event_detail.going")}</IonLabel>
            </IonItem>
            {event.public && (
              <div
                className="ion-padding accordion-item"
                slot="content"
                onClick={() => {
                  interestedInEvent(event.eventId, {
                    onSuccess: () => {
                      event.attends = "MAYBE";
                      setRerender(Math.random());
                      closeAccordion();
                      if (onEventChanged) onEventChanged();
                      sendMetric({
                        name: "event_interested",
                        data: {
                          event_id: event.eventId,
                          source: source,
                          old_status: "going",
                        },
                      });
                    },
                  });
                }}
              >
                <IonIcon icon={favorite}></IonIcon>
                {t("event_detail.interested")}
              </div>
            )}
            <div
              className="ion-padding accordion-item"
              slot="content"
              onClick={() => {
                leaveEvent(event.eventId, {
                  onSuccess: () => {
                    event.attends = null;
                    setRerender(Math.random());
                    closeAccordion();
                    if (onEventChanged) onEventChanged();

                    sendMetric({
                      name: "event_leave",
                      data: {
                        event_id: event.eventId,
                        source: source,
                        old_status: "going",
                      },
                    });
                  },
                });
              }}
            >
              <IonIcon icon={close}></IonIcon>
              {t("event_detail.leave")}
            </div>
          </>
        )}
      </IonAccordion>
    </IonAccordionGroup>
  );
};

export const EventAttendanceRow: React.FC<{
  event: Event;
  onEventChanged?: CallableFunction;
  setRerender: React.Dispatch<React.SetStateAction<Number>>;
  isPast: boolean;
  order: number;
  source: string;
}> = ({ event, onEventChanged, setRerender, isPast, order, source }) => {
  const { t } = useTranslation();
  const { currentUser: user } = useStorage();
  const friends = event.attendees?.filter(
    (attendee) =>
      attendee.user.relationship === "FRIEND" && attendee.state === "GOING"
  );

  if (event.hostId === user?.userId)
    return (
      <div
        className={`event-flag event-flag-organiser${isPast ? " past" : ""}`}
      >
        {t("event_detail.you_are")}{" "}
        <strong>{t("event_detail.organiser")}</strong>!
      </div>
    );

  if (event.attends !== null && ["INVITED", "DECLINED"].includes(event.attends))
    return (
      <div
        className={[
          "event_attendance_options",
          event.attends === "INVITED"
            ? "event_attendance_invited"
            : "event_attendance_declined",
          isPast ? "event_attendance_past" : "",
        ].join(" ")}
      >
        {event.attends === "INVITED" && (
          <>
            <div className="text">{t("event_detail.invited")}</div>
            <div className="buttons">
              <EventButtonDecline
                event={event}
                setRerender={setRerender}
                onEventChanged={onEventChanged}
                isPast={isPast}
              />
              <EventButtonJoin
                event={event}
                color="primary"
                setRerender={setRerender}
                onEventChanged={onEventChanged}
                isPast={isPast}
                source={source}
              />
            </div>
          </>
        )}

        {event.attends === "DECLINED" && (
          <>
            <div className="text">{t("event_detail.decline_invi")}</div>
            <div className="buttons">
              <EventButtonJoin
                event={event}
                color="light"
                setRerender={setRerender}
                onEventChanged={onEventChanged}
                isPast={isPast}
                source={source}
              />
            </div>
          </>
        )}
      </div>
    );

  return (
    <div
      className={`event_attendance_options event_attendance_public${
        isPast ? " event_attendance_past" : ""
      }`}
    >
      {(!friends || friends.length === 0) && (
        <div className="text">{t("event_detail.open_for_anyone")}</div>
      )}
      {friends && <EventAttendingFriends friends={friends} />}

      {event.attends !== "MAYBE" && event.attends !== "GOING" ? (
        <div className="buttons">
          <EventButtonMaybe
            event={event}
            color="secondary"
            setRerender={setRerender}
            onEventChanged={onEventChanged}
            isPast={isPast}
            source={source}
          />
          <EventButtonJoin
            event={event}
            color="primary"
            setRerender={setRerender}
            onEventChanged={onEventChanged}
            isPast={isPast}
            source={source}
          />
        </div>
      ) : (
        <EventAttendanceAccordion
          event={event}
          onEventChanged={onEventChanged}
          setRerender={setRerender}
          source={source}
        />
      )}
    </div>
  );
};

// const EventInfoNiceCompact: React.FC<{
//   icon: string;
//   children: React.ReactNode;
//   link?: string;
//   onClick?: () => void;
// }> = ({ icon, children, link, onClick }) => {
//   return (
//     <div
//       className={[
//         "event_info_nice",
//         "event_info_nice_compact",
//         link ? "event_info_nice_link" : "",
//       ].join(" ")}
//       onClick={() => {
//         if (link) {
//           window
//             .open(
//               link.toLowerCase().startsWith("http") ? link : `https://${link}`,
//               "_blank"
//             )
//             ?.focus();
//           if (onClick) onClick();
//         }
//       }}
//     >
//       {/* <div className="icon">
//         <IonIcon icon={icon} />
//       </div> */}
//       <div className="texts">{children}</div>
//     </div>
//   );
// };

const Price: React.FC<{
  amount: number[] | undefined;
  currency: Currency | undefined;
  setTickets: React.Dispatch<React.SetStateAction<string>>;
}> = ({ amount, currency, setTickets }) => {
  const [price, setPrice] = useState("");

  useEffect(() => {
    if (!amount || !amount.length || (amount.length === 1 && amount[0] === 0)) {
      setPrice("");
      setTickets("Free entry");
    } else if (currency) {
      if (amount.length === 1) {
        setPrice(`${amount} ${currency}`);
      } else if (amount.length > 1) {
        const min = Math.min(...amount);
        const max = Math.max(...amount);
        setPrice(`${min} - ${max} ${currency}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, amount]);

  return <>{price}</>;
};

export const EventLocationPopover: React.FC<{
  location: Location | undefined;
  showPopover: PopoverSettings;
  setShowPopover: React.Dispatch<React.SetStateAction<PopoverSettings>>;
  // showMap: boolean;
  // setShowMap: React.Dispatch<React.SetStateAction<boolean>>;
  placeId: number | undefined;
}> = ({
  location,
  showPopover,
  setShowPopover,
  // showMap,
  // setShowMap,
  placeId,
}) => {
  const { setMessage } = useStorage();
  const { t } = useTranslation();
  const { adress_copied } = useTranlatedMsg();

  return (
    <IonPopover
      className="popover_options"
      event={showPopover.event}
      isOpen={showPopover.showPopover}
      onDidDismiss={() =>
        setShowPopover({
          showPopover: false,
          event: undefined,
        })
      }
    >
      <IonList>
        {/* <IonItem
          disabled={!placeId}
          onClick={() => {
            history.push("/place/" + placeId);
            setShowPopover({
              showPopover: false,
              event: undefined,
            });
          }}
        >
          <IonIcon icon={locationOn} size="default"></IonIcon> Location detail
        </IonItem> */}
        <IonItem
          button
          disabled={!location}
          detail={false}
          onClick={() => {
            if (location) {
              window
                .open(
                  `https://www.google.com/maps/search/?api=1&query=${location.formattedAddress}`
                )
                ?.focus();
            }
          }}
        >
          <IonIcon icon={locationOn} size="default"></IonIcon>{" "}
          {t("event_detail.open_in_maps")}
        </IonItem>

        <IonItem
          button
          disabled={!location}
          detail={false}
          onClick={() => {
            if (location) {
              copy(
                `${location?.name}, ${
                  location && get_location_name_suffix(location)
                }`
              );
              setMessage("primary", adress_copied);
              setShowPopover({
                showPopover: false,
                event: undefined,
              });
            }
          }}
        >
          <IonIcon icon={contentCopy} size="default"></IonIcon>{" "}
          {t("event_detail.copy_adress")}
        </IonItem>
        {/* <IonItem
          button
          disabled={!location}
          detail={false}
          onClick={() => {
            if (location) {
              location && setShowMap(!showMap);
              setShowPopover({
                showPopover: false,
                event: undefined,
              });
            }
          }}
        >
          <IonIcon icon={showMap ? image : map} size="default"></IonIcon>{" "}
          {showMap ? "Hide map" : "Show on a map"}
        </IonItem> */}
      </IonList>
    </IonPopover>
  );
};

export const EventDatePopover: React.FC<{
  event: Event;
  start: Date | null;
  showPopover: PopoverSettings;
  setShowPopover: React.Dispatch<React.SetStateAction<PopoverSettings>>;
}> = ({ start, showPopover, setShowPopover, event }) => {
  const { setMessage } = useStorage();
  const { t } = useTranslation();
  const { date_copied } = useTranlatedMsg();

  return (
    <IonPopover
      className="popover_options"
      event={showPopover.event}
      isOpen={showPopover.showPopover}
      onDidDismiss={() =>
        setShowPopover({
          showPopover: false,
          event: undefined,
        })
      }
    >
      <IonList>
        <IonItem
          button
          disabled={!start}
          detail={false}
          onClick={() => {
            if (start !== null) {
              copy(`${moment(start).format("llll")}`);
              setMessage("primary", date_copied);
              setShowPopover({
                showPopover: false,
                event: undefined,
              });
            }
          }}
        >
          <IonIcon icon={contentCopy} size="default"></IonIcon>{" "}
          {t("event_detail.copy_date")}
        </IonItem>
        <AddToCalendarButtonWrapper
          event={event}
          setShowPopover={setShowPopover}
        />
      </IonList>
    </IonPopover>
  );
};

const AddToCalendarButtonWrapper: React.FC<{
  event: Event;
  setShowPopover: React.Dispatch<React.SetStateAction<PopoverSettings>>;
}> = ({ event, setShowPopover }) => {
  const { t } = useTranslation();
  const config: Object = {
    name: event.title,
    description: `${event.description}
    
    Open in app: https://m.manyways.info/#/events/${event.eventId}/${event.hash}/about`,
    startDate: event.start?.toString(),
    location: event.location?.formattedAddress,
    endDate: event.end
      ? event.end?.toString()
      : moment(event.start).add(2, "hours").toISOString(),
    options: ["Google"],
    trigger: "click",
    iCalFileName: "Reminder-Event",
    listStyle: "overlay",
    size: "small",
    icons: "false",
  };
  const addtoCalendarButton = document.getElementById(
    "add_to_calendar"
  ) as HTMLElement;

  return (
    <IonItem
      button
      detail={false}
      onClick={() => {
        atcb_action(config, addtoCalendarButton);
        setShowPopover({
          showPopover: false,
          event: undefined,
        });
      }}
    >
      <IonIcon icon={calendarMonth} size="default"></IonIcon>
      {t("event_detail.copy_to_calendar")}
    </IonItem>
  );
};

export const EventBasicInfoDetail: React.FC<{
  event: Event;
  isPast: boolean;
  onEventChanged?: CallableFunction;
  // showMap: boolean;
  // setShowMap: React.Dispatch<React.SetStateAction<boolean>>;
  // setShowAddMembersModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSharePopover: React.Dispatch<React.SetStateAction<PopoverSettings>>;
}> = ({
  event,
  isPast,
  onEventChanged,
  // showMap,
  // setShowMap,
  // setShowAddMembersModal,
  setShowSharePopover,
}) => {
  const { t } = useTranslation();
  const { setMessage } = useStorage();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [_, setRerender] = useState<Number>(Math.random());
  const [tickets, setTickets] = useState<string>(t("event_detail.buy_tickets"));
  const [showDatePopover, setDateShowPopover] = useState<PopoverSettings>({
    showPopover: false,
    event: undefined,
  });
  const [showLocationPopover, setLocationShowPopover] =
    useState<PopoverSettings>({
      showPopover: false,
      event: undefined,
    });
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const mask = event.public === false;

  const [expanded, setExpanded] = useState<boolean>(isPlatform("desktop"));
  const divEle = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divEle?.current?.clientHeight && expanded === undefined)
      setExpanded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divEle?.current?.clientHeight]);

  const hasTickets = event.ticketOffers.reduce((prev, curr) => {
    if (curr.countSold < curr.count) return true;
    return prev;
  }, false);

  return (
    <div className="container event_detail_wrapper">
      {/* <EventAttendanceRow
        event={event}
        onEventChanged={onEventChanged}
        setRerender={setRerender}
        isPast={isPast}
        order={1}
        source={"event_detail"}
      /> */}

      <h1 className={mask ? "smartlook-hide" : ""}>{event.title}</h1>

      {event.description && (
        <>
          <div
            className={`description ${mask && "smartlook-hide"} ${
              expanded === undefined ? "short" : !expanded ? "collapsed" : ""
            }`}
            data-sl={mask ? "mask" : ""}
            ref={divEle}
            onClick={() => {
              if (!expanded) setExpanded(true);
            }}
          >
            {!event.hostId
              ? event.description
                  .split("\n")
                  .map((item, key) => (
                    <div
                      key={key}
                      className="line"
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                  ))
              : event.description.split("\n").map((item, key) => (
                  <div key={key} className="line">
                    {processString(link_replace_config(setMessage))(item)}
                  </div>
                ))}
          </div>
          {expanded !== undefined && !isPlatform("desktop") && (
            <span className="expander" onClick={() => setExpanded(!expanded)}>
              {expanded ? t("event_detail.hide") : t("event_detail.read_more")}
            </span>
          )}
        </>
      )}

      <div className="event_detail_row">
        <EventLocationPopover
          placeId={event.place?.placeId}
          location={event.location}
          showPopover={showLocationPopover}
          setShowPopover={setLocationShowPopover}
          // showMap={showMap}
          // setShowMap={setShowMap}
        />

        {event.location && (
          <div
            className="event_info_nice address_nice"
            onClick={(e: any) =>
              setLocationShowPopover({
                showPopover: true,
                event: e,
              })
            }
          >
            {/* <div className="icon">
                  <IonIcon
                    icon={locationOn}
                    color={isPast || !event.location ? "dark" : "primary"}
                  />
                </div> */}
            <div
              className={mask ? "texts smartlook-hide" : "texts"}
              data-sl={mask ? "mask" : ""}
            >
              <div className="location_icon_box">
                <div>
                  <div className="title">{t("event_detail.location")}</div>
                  <div className="main">
                    {event.location.formattedAddress
                      ? event.location.name
                      : event.city
                      ? event.city.name
                      : event.location.name}
                  </div>
                  <div className="text">
                    {event.location && get_location_name_suffix(event.location)}
                  </div>
                </div>
              </div>

              {/* //  : (
            //   <div className="title">Vote on the location</div>
            // ) */}
            </div>
            <div className="more">
              <IonIcon icon={expandMore}></IonIcon>
            </div>
          </div>
        )}
        {/* <td>
              <>
                <div
                  className="event_info_nice invite_nice"
                  onClick={(e: any) => {
                    if (isPast) return;
                    setShowSharePopover({ showPopover: true, event: e });
                  }}
                >
                  <div className="icon">
                    <IonIcon
                      color={isPast ? "dark" : "primary"}
                      icon={shareIos}
                    />
                  </div>
                  <div className="texts">
                    <div className="title"></div>
                  </div>
                </div>
              </>
            </td> */}
        <EventDatePopover
          event={event}
          start={event.start}
          showPopover={showDatePopover}
          setShowPopover={setDateShowPopover}
        />
        <div
          className="event_info_nice time_nice"
          onClick={(e: any) =>
            setDateShowPopover({ showPopover: true, event: e })
          }
        >
          {/* <div className="icon">
                  <IonIcon
                    icon={calendarToday}
                    color={isPast || !event.start ? "dark" : "primary"}
                  />
                  <strong className="calendar_icon_inner">
                    {event.start ? moment(event.start).format("D") : "?"}
                  </strong>
                </div> */}
          <div className="texts">
            <div className="title">{t("event_detail.date")}</div>
            <EventTime start={event.start} end={event.end} />
          </div>
          <div className="more">
            <IonIcon icon={expandMore}></IonIcon>
          </div>
        </div>
      </div>
      {/* <td>
              {user && (
                <div
                  className="event_info_nice invite_nice"
                  onClick={(e) => {
                    if (isPast) return;
                    setShowAddMembersModal(true);
                  }}
                >
                  <div className="icon">
                    <IonIcon color={isPast ? "dark" : "primary"} icon={share} />
                  </div>
                  <div className="texts">
                    <div className="title"></div>
                  </div>
                </div>
              )}
            </td> */}

      <div className="event_detail_row">
        {event.public && event.price !== null && (
          <div
            className="event_info_nice address_nice"
            onClick={() => {
              if (event.ticketOffers?.length) {
                if (!hasTickets) return;
                if (device === "mobile")
                  window
                    .open(getEventPublicUrlTickets(event.eventId), "_blank")
                    ?.focus();
                else setShowPaymentModal(true);
              } else if (tickets !== "Free entry" && event.ticketsUrl) {
                logEvent("event_redirect_tickets", {});
                window
                  .open(
                    event.ticketsUrl.toLowerCase().startsWith("http")
                      ? event.ticketsUrl
                      : `https://${event.ticketsUrl}`,
                    "_blank"
                  )
                  ?.focus();
              }
            }}
          >
            {/* <div className="icon">
                    <IonIcon
                      icon={confirmationNumber}
                      color={isPast || !event.location ? "dark" : "primary"}
                    />
                  </div> */}
            <div
              className={mask ? "texts smartlook-hide" : "texts"}
              data-sl={mask ? "mask" : ""}
            >
              <div className="location_icon_box">
                <div>
                  <div className="title">{t("event_detail.tickets")}</div>
                  {device === "desktop" && event.ticketOffers?.length ? (
                    <IonButton disabled={!hasTickets}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "3px",
                        }}
                      >
                        <span>{hasTickets ? tickets : "Sold out"}</span>
                        {hasTickets && (
                          <span style={{ fontSize: "11px" }}>
                            <Price
                              amount={event.price?.amount}
                              currency={event.price?.currency}
                              setTickets={setTickets}
                            />
                          </span>
                        )}
                      </div>
                    </IonButton>
                  ) : (
                    <div className="main">{tickets}</div>
                  )}
                  {!(device === "desktop" && event.ticketOffers?.length) && (
                    <div className="text">
                      <Price
                        amount={event.price?.amount}
                        currency={event.price?.currency}
                        setTickets={setTickets}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="event_info_nice">
          {/* <div className="event_info_row">
              {!event.public && (
                <EventInfoNiceCompact icon={event.public ? "" : lock}>
                  Private
                </EventInfoNiceCompact>
              )}

              {event.host?.name && (
                <EventInfoNiceCompact icon={star}>
                  <div className="title">
                    Created by{" "}
                    <strong
                      data-sl={mask ? "mask" : ""}
                      className={mask ? "smartlook-hide" : ""}
                    >
                      {event.host.name}
                    </strong>
                  </div>
                </EventInfoNiceCompact>
              )}
            </div> */}
          {event.public && (
            <>
              {event.sourceUrl && (
                <>
                  <div
                    className="texts"
                    onClick={() => {
                      if (event.sourceUrl) {
                        window
                          .open(
                            event.sourceUrl.toLowerCase().startsWith("http")
                              ? event.sourceUrl
                              : `https://${event.sourceUrl}`,
                            "_blank"
                          )
                          ?.focus();
                        logEvent("event_redirect_web", {});
                      }
                    }}
                  >
                    {/* <div className="icon">
        <IonIcon icon={icon} />
      </div> */}
                    <div className="title">
                      {t("event_detail.organiser").toUpperCase()}
                    </div>
                    <div className="main">{t("event_detail.visite_web")}</div>
                  </div>

                  {/* <EventInfoNiceCompact
                  icon={language}
                  link={event.sourceUrl}
                  onClick={() => logEvent("event_redirect_web", {})}
                >
                  Organizer's web
                </EventInfoNiceCompact> */}
                  {event.dataProviderSlug !== null &&
                    event.dataProviderSlug in PARTNERS && (
                      <img
                        alt={event.dataProviderSlug}
                        src={PARTNERS[event.dataProviderSlug].logo}
                        className="logo_pic org_web"
                        onClick={() => {
                          if (event.dataProviderSlug !== null)
                            window
                              .open(PARTNERS[event.dataProviderSlug].url)
                              ?.focus();
                        }}
                      ></img>
                    )}
                </>
              )}
            </>
          )}

          {/* {event.title.length > 24 && (
          <IonRow>
            <IonCol className="description">
              <div
                className={mask ? "title smartlook-hide" : "title"}
                data-sl={mask ? "mask" : ""}
              >
                {event.title}
              </div>
            </IonCol>
          </IonRow>
        )} */}
        </div>
      </div>

      {event.tags.length > 0 && (
        <div className="tags">
          {event.tags.map((t) => {
            return (
              <span className="tag" key={t.slug}>
                {t.name}
              </span>
            );
          })}
        </div>
      )}

      {event.ticketOffers?.length ? (
        <PaymentModal
          showModal={showPaymentModal}
          setShowModal={setShowPaymentModal}
          ticketOffers={event.ticketOffers}
        />
      ) : null}

      {event.series?.length && event.start !== null ? (
        <DateOptions
          eventCurrentDate={event.start}
          eventSeries={event.series}
        />
      ) : (
        ""
      )}
    </div>
  );
};

// export const EventAttendeesShortAndFilter: React.FC<{
//   eventId: number;
//   isPublic: boolean;
//   attendees: Attendee[];
//   attendeesFilter: AttendeesFilter;
//   setAttendeesFilter: React.Dispatch<React.SetStateAction<AttendeesFilter>>;
// }> = ({
//   eventId,
//   isPublic,
//   attendees,
//   attendeesFilter,
//   setAttendeesFilter,
// }) => {
//   const [stats, setStats] = useState<attendeesStatsModel>({
//     all_count: 0,
//     going_count: 0,
//     maybe_count: 0,
//     invited_count: 0,
//     declined_count: 0,
//   });

//   useEffect(() => {
//     setStats(get_attendees_stats(attendees));
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [attendees, eventId]);

//   return (
//     <IonSegment
//       value={attendeesFilter}
//       className="original simple attendance"
//       color="primary"
//     >
//       <IonSegmentButton value="all" onClick={() => setAttendeesFilter("all")}>
//         <IonLabel>
//           All <strong>{stats.all_count}</strong>
//         </IonLabel>
//       </IonSegmentButton>
//       <IonSegmentButton
//         value="going"
//         onClick={() => setAttendeesFilter("going")}
//       >
//         <IonLabel>
//           Going <strong>{stats.going_count}</strong>
//         </IonLabel>
//       </IonSegmentButton>
//       {isPublic && (
//         <IonSegmentButton
//           value="maybe"
//           onClick={() => setAttendeesFilter("maybe")}
//         >
//           <IonLabel>
//             Interested <strong>{stats.maybe_count}</strong>
//           </IonLabel>
//         </IonSegmentButton>
//       )}
//       <IonSegmentButton
//         value="invited"
//         onClick={() => setAttendeesFilter("invited")}
//       >
//         <IonLabel>
//           Invited <strong>{stats.invited_count}</strong>
//         </IonLabel>
//       </IonSegmentButton>
//       <IonSegmentButton
//         value="declined"
//         onClick={() => setAttendeesFilter("declined")}
//       >
//         <IonLabel>
//           Declined <strong>{stats.declined_count}</strong>
//         </IonLabel>
//       </IonSegmentButton>
//     </IonSegment>
//   );
// };

// const AttendanceStatesFriends = {
//   all: "None of your friends is going",
//   going: "None of your friends is going",
//   maybe: "None of your friends is interested",
//   invited: "None of your friends is invited",
//   declined: "None of your friends declined an invitation",
// };

// const AttendanceStatesAll = {
//   all: "Nobody is going",
//   going: "Nobody is going",
//   maybe: "Nobody is interested",
//   invited: "Nobody is invited",
//   declined: "Nobody declined an invitation",
// };

// export const EventNoAttendeesMessage: React.FC<{
//   friendList: boolean;
//   attendeesFilter: AttendeesFilter;
// }> = ({ friendList, attendeesFilter }) => {
//   return (
//     <div className="no_attendees">
//       <IonIcon icon={groupFill} size="large" />
//       {friendList
//         ? AttendanceStatesFriends[attendeesFilter]
//         : AttendanceStatesAll[attendeesFilter]}
//     </div>
//   );
// };
export const MainImage: React.FC<{
  src: string;
  // showMap: boolean | undefined;
  imgRef: LegacyRef<HTMLImageElement> | undefined;
  mask: boolean;
}> = ({ src, imgRef, mask }) => {
  const [slideIsOpen, setSlideIsOpen] = useState(false);

  useSmartlookSensitive(slideIsOpen);

  return (
    <>
      <div
        className="visual_content"
        // style={{ display: showMap ? "none" : "block" }}
        style={{
          backgroundImage: `url(${src})`,
        }}
        onClick={() => setSlideIsOpen(true)}
      >
        {/* <img
          src={src}
          alt=""
          onClick={() => setSlideIsOpen(true)}
          ref={imgRef}
          data-sl={mask ? "mask" : ""}
          className={mask ? "smartlook-hide" : ""}
        /> */}
        <div className="gradient" />
      </div>
      <ImageSlides
        images={[src]}
        isOpen={slideIsOpen}
        index={0}
        onChange={() => {}}
      />
      <ImageSlidesClose isOpen={slideIsOpen} setIsOpen={setSlideIsOpen} />
    </>
  );
};

export const EventsDateLabel: React.FC<{
  start: Date | null;
  end: Date | null;
}> = ({ start, end }) => {
  let topRow = "";
  let midRow = "";
  let btmRow = "";

  const now = moment();
  if (moment(start) < now && moment(end) > now) {
    topRow = "untill";
    midRow = moment(end).format("D MMM");
    btmRow = "Ongoing";
  } else {
    topRow = moment(start).format("D");

    midRow = moment(start).format("MMM");

    btmRow = moment(start).format("ddd");
  }

  return (
    <div className="slide-date-rectangle">
      <div className="light">{topRow}</div>
      <div className="strong">{midRow}</div>
      <div className="light">{btmRow}</div>
    </div>
  );
};

export const UserAttendanceSlideIcon: React.FC<{
  state: AttendanceState;
  isHost: boolean;
}> = ({ isHost, state }) => {
  if (isHost)
    return (
      <IonBadge color="danger" className="slide-icon-state">
        <IonIcon
          icon={starFill}
          color="light"
          className="slide-icon-organiser"
        />
      </IonBadge>
    );
  else
    return (
      <IonBadge
        color={attendanceStateColors[state]}
        className="slide-icon-state"
      >
        <IonIcon icon={attendanceSlideIcons[state]} color="light" />
      </IonBadge>
    );
};

export const EventBox: React.FC<{
  event: Event;
  source: string;
  order: number;
  slug?: string;
}> = ({ event, source, order, slug }) => {
  const { gpsCoordinates: gps } = useStorage();
  const { sendMetric } = useAnalytics();

  return (
    <IonCard
      className={`event_box color-${get_event_attendance_class(
        event.isHost,
        event.attends
      )}`}
      button={true}
      routerLink={`/events/${event.eventId}/about`}
      onClick={() => {
        sendMetric({
          name: "event_open",
          data: {
            event_id: event.eventId,
            source: source,
            order: order,
            category: source === "homepage_slides_category" ? slug : undefined,
          },
        });
      }}
    >
      {/* <div className="event-card-gradient-color" /> */}
      <div
        className="image"
        style={{
          backgroundImage: `url('${get_event_photo_url(
            event.photo,
            get_primary_tag_slug_default(event.tags)
          )}')`,
        }}
      >
        <div className="event-card-title-box">
          <div className="texts">
            <h4 className="event-card-title">{event.title}</h4>
            <span className="event-card-subtitle">
              {`${
                event.location?.formattedAddress
                  ? event.location.name
                  : event.city
                  ? event.city.name
                  : event.location?.name
              }${
                gps !== null &&
                event.location?.lat !== undefined &&
                event.location?.lng !== undefined
                  ? `, ${gps_distance(
                      gps.lat,
                      gps.lng,
                      event.location?.lat,
                      event.location?.lng,
                      "K"
                    )} km`
                  : ""
              }`}
              {/* {!!event.seriesCount && (
            <div className="event-card-dates-title">
              See {event.seriesCount} more dates
            </div>
          )} */}
            </span>
            <span className="event-card-subtitle">
              <EventTime start={event.start} end={event.end} />
            </span>
            {/* {event.attends !== null && (
          <UserAttendanceSlideIcon
            state={event.attends}
            isHost={event.isHost}
          />
        )} */}
          </div>
        </div>
      </div>
      {/* <EventsDateLabel start={event.start} end={event.end} /> */}
    </IonCard>
  );
};

export const EventBoxMap: React.FC<{
  event: Event;
  source: string;
  order: number;
}> = ({ event, source, order }) => {
  const { sendMetric } = useAnalytics();
  const { t } = useTranslation();

  return (
    <IonCard
      className={`event_box_map`}
      button={true}
      routerLink={`/events/${event.eventId}/about`}
      onClick={() => {
        sendMetric({
          name: "event_open",
          data: {
            event_id: event.eventId,
            source: source,
            order: order,
          },
        });
      }}
    >
      <div
        className="image"
        style={{
          backgroundImage: `url('${get_event_photo_url(
            event.photo,
            get_primary_tag_slug_default(event.tags)
          )}')`,
        }}
      >
        <EventsDateLabel start={event.start} end={event.end} />
      </div>
      <div className="event-card-texts">
        <div className="title">
          {event.tags
            .filter((tag) => tag.level === 1)
            .map((tag) => tag.name)
            .join(", ")}
        </div>
        <h3 className="event-card-title">{event.title}</h3>

        {/* <table>
          <tbody>
            <tr> */}
        {/* <td>
                <div className="title">Date</div>
                <div className="main">
                  {/* TODO: fix start when undefined */}
        {/* {format_date_first(event.start!, event.end)}
                </div>
              </td> */}

        {/* <td> */}
        <div className="title">{t("event_detail.location").toUpperCase()}</div>
        <div
          className="main"
          // TODO: handle smartlook
          // className={mask ? "title smartlook-hide" : "title"}
          // data-sl={mask ? "mask" : ""}
        >
          {event.location?.formattedAddress
            ? event.location.name
            : event.city
            ? event.city.name
            : event.location?.name}
        </div>
        {/* </td>
            </tr>
          </tbody>
        </table> */}
      </div>
    </IonCard>
  );
};
