import React from "react";
import { IonModal, IonButton, IonContent } from "@ionic/react";
import { ModalHeader } from "./Headers";
import { useQuery } from "react-query";
import axios, { AxiosError, AxiosResponse } from "axios";

export const ShowTerms: React.FC<{
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ showModal, setShowModal }) => {
  const { data: content } = useQuery<AxiosResponse<string>, AxiosError, string>(
    ["tac"],
    () => axios.get<string>("https://documents.manyways.info/mtac"),
    {
      select: (res) => res.data,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
      <ModalHeader
        title="Terms &amp; conditions"
        onClick={() => setShowModal(false)}
      />
      <IonContent>
        {content ? (
          <div
            className="content-padded"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        ) : null}
      </IonContent>
      <IonButton
        className="modal_big_button"
        onClick={() => setShowModal(false)}
      >
        Done
      </IonButton>
    </IonModal>
  );
};

export const ShowPrivacy: React.FC<{
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ showModal, setShowModal }) => {
  const { data: content } = useQuery<AxiosResponse<string>, AxiosError, string>(
    ["privacy_policy"],
    () => axios.get<string>("https://documents.manyways.info/mprivacy"),
    { select: (res) => res.data, refetchOnWindowFocus: false }
  );

  return (
    <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
      <ModalHeader title="Privacy policy" onClick={() => setShowModal(false)} />
      <IonContent>
        {content ? (
          <div
            className="content-padded"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        ) : null}
      </IonContent>
      <IonButton
        className="modal_big_button"
        onClick={() => setShowModal(false)}
      >
        Done
      </IonButton>
    </IonModal>
  );
};
