import { AppsFlyer } from "appsflyer-capacitor-plugin";
import { useEffect } from "react";
import { appsFlyerKeys } from "../config";
import { User } from "../models";

export const AppsFlyerInitialization: React.FC<{
  currentUser: User | null;
}> = ({ currentUser }) => {
  useEffect(() => {
    initAppsFlyer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUser)
      AppsFlyer.setCustomerUserId({ cuid: currentUser.userId.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.userId]);

  function initAppsFlyer() {
    runAppsFlyerAPIs();
    AppsFlyer.initSDK({
      appID: appsFlyerKeys.appID,
      devKey: appsFlyerKeys.devKey,
      isDebug: false,
      waitForATTUserAuthorization: 10,
      registerOnDeepLink: true,
      minTimeBetweenSessions: 6,
      registerConversionListener: true,
      registerOnAppOpenAttribution: false,
      useReceiptValidationSandbox: false,
      useUninstallSandbox: false,
    });
  }

  function runAppsFlyerAPIs() {}

  return null;
};
