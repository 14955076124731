export const econify = (icon: string) => {
  return `data:image/svg+xml;utf8,${icon.replace(
    `height="48" width="48"`,
    `class="ionicon" viewBox="0 0 48 48"`
  )}`;
};

export const done = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M18.95 34.5q-.3 0-.525-.1-.225-.1-.475-.35l-8.7-8.65q-.35-.35-.35-.825t.35-.825q.35-.35.8-.35.45 0 .8.35l8.1 8.1 18.15-18.2q.35-.3.825-.3t.825.3q.35.4.35.85 0 .45-.35.8l-18.8 18.75q-.2.25-.45.35-.25.1-.55.1Z"/></svg>`
);
export const search = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m38.7 40.25-12.15-12.1q-1.5 1.3-3.475 2.025-1.975.725-4.125.725-5.1 0-8.625-3.525Q6.8 23.85 6.8 18.8q0-5 3.525-8.525Q13.85 6.75 18.9 6.75q5.05 0 8.575 3.525Q31 13.8 31 18.8q0 2.1-.725 4.1-.725 2-2.075 3.6l12.2 12.15q.35.35.35.8 0 .45-.4.8-.35.4-.85.4t-.8-.4Zm-19.75-11.6q4.05 0 6.9-2.875Q28.7 22.9 28.7 18.8t-2.85-6.95Q23 9 18.95 9q-4.15 0-7 2.85Q9.1 14.7 9.1 18.8t2.85 6.975q2.85 2.875 7 2.875Z"/></svg>`
);
export const list = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M15.75 16.75q-.5 0-.825-.325-.325-.325-.325-.825 0-.45.325-.775t.825-.325h24.6q.5 0 .825.325.325.325.325.825 0 .5-.325.8-.325.3-.825.3Zm0 8.4q-.5 0-.825-.325Q14.6 24.5 14.6 24q0-.5.325-.825.325-.325.825-.325h24.6q.5 0 .825.325.325.325.325.825 0 .5-.325.825-.325.325-.825.325Zm0 8.35q-.5 0-.825-.325-.325-.325-.325-.825 0-.5.325-.8.325-.3.825-.3h24.6q.5 0 .825.325.325.325.325.825 0 .45-.325.775t-.825.325ZM7.8 16.9q-.55 0-.925-.35t-.375-.9q0-.55.375-.925t.925-.375q.55 0 .925.375t.375.925q0 .55-.375.9t-.925.35Zm0 8.4q-.55 0-.925-.375T6.5 24q0-.55.375-.925T7.8 22.7q.55 0 .925.375T9.1 24q0 .55-.375.925T7.8 25.3Zm0 8.35q-.55 0-.925-.375T6.5 32.35q0-.55.375-.9t.925-.35q.55 0 .925.35t.375.9q0 .55-.375.925t-.925.375Z"/></svg>`
);
export const forum = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M5.4 30.75V6.85q0-.65.55-1.25t1.2-.6h24.9q.65 0 1.225.575.575.575.575 1.275V23.6q0 .65-.575 1.25t-1.225.6h-19.6l-5.8 5.8q-.35.35-.8.175-.45-.175-.45-.675Zm2.25-23.5V23.2 7.25Zm6.6 27q-.65 0-1.225-.6-.575-.6-.575-1.25v-3.65H36.5l.6.6V12h3.75q.65 0 1.2.6.55.6.55 1.3v25.6q0 .5-.45.675-.45.175-.8-.175l-5.8-5.75Zm17.35-27H7.65V24.6l1.4-1.4H31.6Z"/></svg>`
);
export const notifications = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M9.65 37.75q-.5 0-.825-.35-.325-.35-.325-.8 0-.5.325-.825.325-.325.825-.325h3V20q0-3.95 2.475-7.2t6.525-4V7.35q0-1 .675-1.625T24 5.1q1 0 1.675.625t.675 1.625V8.8q4.05.75 6.55 4t2.5 7.2v15.45h2.95q.5 0 .825.325.325.325.325.825 0 .5-.325.825-.325.325-.825.325ZM24 23.4Zm0 20.2q-1.45 0-2.525-1.05T20.4 40h7.2q0 1.5-1.05 2.55Q25.5 43.6 24 43.6Zm-9.1-8.15h18.2V20q0-3.8-2.625-6.475T24.05 10.85q-3.8 0-6.475 2.675T14.9 20Z"/></svg>`
);
export const person = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 23.35q-2.9 0-4.775-1.875Q17.35 19.6 17.35 16.7q0-2.9 1.875-4.75T24 10.1q2.9 0 4.775 1.85 1.875 1.85 1.875 4.75t-1.875 4.775Q26.9 23.35 24 23.35ZM36.7 38.6H11.3q-.95 0-1.625-.675T9 36.3v-1.5q0-1.6.85-2.8.85-1.2 2.2-1.85 3.2-1.4 6.125-2.1 2.925-.7 5.825-.7 2.9 0 5.825.725Q32.75 28.8 35.9 30.2q1.4.65 2.25 1.825Q39 33.2 39 34.8v1.5q0 .95-.675 1.625T36.7 38.6Zm-25.45-2.25h25.5V34.8q0-.75-.5-1.475-.5-.725-1.3-1.125-2.95-1.45-5.575-2.025T24 29.6q-2.75 0-5.425.575T13 32.2q-.75.4-1.25 1.125t-.5 1.475ZM24 21.1q1.85 0 3.1-1.25t1.25-3.15q0-1.85-1.25-3.1T24 12.35q-1.85 0-3.1 1.25t-1.25 3.1q0 1.9 1.25 3.15T24 21.1Zm0-4.4Zm0 19.65Z"/></svg>`
);
export const map = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m29.5 40.6-11.9-4.2-8.35 3.3q-.75.4-1.5-.05T7 38.25V12.1q0-.55.325-1.025.325-.475.875-.675l8.45-3q.45-.1.95-.1.5 0 .95.1l11.9 4.15 8.3-3.25q.75-.4 1.5.025Q41 8.75 41 9.6v26.6q0 .5-.35.9t-.85.55l-8.4 2.95q-.45.15-.95.125-.5-.025-.95-.125Zm-.35-2.5V13.35l-10.3-3.5V34.6Zm2.25 0 7.35-2.4V10.6l-7.35 2.75Zm-22.15-.75 7.35-2.75V9.85L9.25 12.3Zm22.15-24V38.1Zm-14.8-3.5V34.6Z"/></svg>`
);
export const close = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 25.6 13.35 36.25q-.35.35-.8.35-.45 0-.8-.35-.35-.35-.35-.8 0-.45.35-.8L22.4 24 11.75 13.35q-.35-.35-.35-.8 0-.45.35-.8.35-.35.8-.35.45 0 .8.35L24 22.4l10.65-10.65q.35-.35.8-.35.45 0 .8.35.35.35.35.8 0 .45-.35.8L25.6 24l10.65 10.65q.35.35.35.8 0 .45-.35.8-.35.35-.8.35-.45 0-.8-.35Z"/></svg>`
);
export const calendarMonth = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 28.15q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.275.5-.525 1.25-.525t1.25.525q.5.525.5 1.275 0 .7-.5 1.225-.5.525-1.25.525Zm-8 0q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.275.5-.525 1.25-.525t1.25.525q.5.525.5 1.275 0 .7-.5 1.225-.5.525-1.25.525Zm16 0q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.275.5-.525 1.25-.525t1.25.525q.5.525.5 1.275 0 .7-.5 1.225-.5.525-1.25.525ZM24 36q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.275.5-.525 1.25-.525t1.25.525q.5.525.5 1.275 0 .7-.5 1.225Q24.75 36 24 36Zm-8 0q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.275.5-.525 1.25-.525t1.25.525q.5.525.5 1.275 0 .7-.5 1.225Q16.75 36 16 36Zm16 0q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.275.5-.525 1.25-.525t1.25.525q.5.525.5 1.275 0 .7-.5 1.225Q32.75 36 32 36ZM9.9 43q-1.2 0-2.05-.85Q7 41.3 7 40.1V11.15q0-1.15.85-2.025T9.9 8.25h3.7V6q0-.5.35-.875t.85-.375q.55 0 .925.375T16.1 6v2.25H32V6q0-.55.325-.9t.875-.35q.5 0 .875.35t.375.9v2.25h3.65q1.2 0 2.05.875Q41 10 41 11.15V40.1q0 1.2-.85 2.05-.85.85-2.05.85Zm0-2.25h28.2q.3 0 .475-.175t.175-.475V20.25H9.25V40.1q0 .3.175.475t.475.175ZM9.25 18h29.5v-6.85q0-.25-.175-.425t-.475-.175H9.9q-.3 0-.475.175t-.175.425Zm0 0V10.55 18Z"/></svg>`
);
export const payments = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M26.75 26.15q-2.1 0-3.55-1.45-1.45-1.45-1.45-3.55 0-2.1 1.45-3.55 1.45-1.45 3.55-1.45 2.1 0 3.55 1.45 1.45 1.45 1.45 3.55 0 2.1-1.45 3.55-1.45 1.45-3.55 1.45ZM12.1 33.1q-1.15 0-2-.875-.85-.875-.85-2.025V12.1q0-1.15.85-2 .85-.85 2-.85h29.3q1.2 0 2.05.85.85.85.85 2v18.1q0 1.15-.85 2.025t-2.05.875Zm3.75-2.3H37.7q0-1.8 1.275-3.075 1.275-1.275 3.075-1.275v-10.6q-1.8 0-3.075-1.275Q37.7 13.3 37.7 11.5H15.85q0 1.8-1.275 3.075Q13.3 15.85 11.5 15.85v10.6q1.8 0 3.075 1.275Q15.85 29 15.85 30.8ZM6.6 38.6q-1.2 0-2.05-.85-.85-.85-.85-2v-19.4q0-.5.325-.825.325-.325.825-.325.45 0 .775.325t.325.825v19.4q0 .2.2.4t.45.2h30.6q.5 0 .825.325.325.325.325.825 0 .5-.325.8-.325.3-.825.3Zm5.5-7.8h-.6V11.5h.6q-.25 0-.425.175t-.175.425v18.1q0 .25.175.425t.425.175Z"/></svg>`
);
export const lock = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M11.9 43q-1.2 0-2.05-.85Q9 41.3 9 40.1v-20q0-1.2.85-2.05.85-.85 2.05-.85h3.45v-4.55q0-3.6 2.525-6.125T24 4q3.6 0 6.125 2.525t2.525 6.125v4.55h3.45q1.2 0 2.05.85.85.85.85 2.05v20q0 1.2-.85 2.05-.85.85-2.05.85Zm0-2.25h24.2q.3 0 .475-.175t.175-.475v-20q0-.25-.175-.425T36.1 19.5H11.9q-.3 0-.475.175t-.175.425v20q0 .3.175.475t.475.175ZM24 33.5q1.4 0 2.4-.975t1-2.375q0-1.3-1-2.375T24 26.7q-1.4 0-2.4 1.075t-1 2.425q0 1.35 1 2.325 1 .975 2.4.975Zm-6.35-16.3h12.7v-4.55q0-2.65-1.85-4.525Q26.65 6.25 24 6.25t-4.5 1.875Q17.65 10 17.65 12.65Zm-6.4 23.55V19.5 40.75Z"/></svg>`
);
export const lockOpen = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M11.9 43q-1.2 0-2.05-.85Q9 41.3 9 40.1v-20q0-1.2.85-2.05.85-.85 2.05-.85h18.45v-4.55q0-2.65-1.85-4.525Q26.65 6.25 24 6.25q-2.4 0-4.125 1.475T17.75 11.45q-.1.55-.45.875-.35.325-.8.325-.5 0-.825-.35-.325-.35-.225-.8.4-3.2 2.8-5.35Q20.65 4 24 4q3.6 0 6.125 2.525t2.525 6.125v4.55h3.45q1.2 0 2.05.85.85.85.85 2.05v20q0 1.2-.85 2.05-.85.85-2.05.85Zm0-2.25h24.2q.3 0 .475-.175t.175-.475v-20q0-.25-.175-.425T36.1 19.5H11.9q-.3 0-.475.175t-.175.425v20q0 .3.175.475t.475.175ZM24 33.5q1.4 0 2.4-.975t1-2.375q0-1.3-1-2.375T24 26.7q-1.4 0-2.4 1.075t-1 2.425q0 1.35 1 2.325 1 .975 2.4.975Zm-12.75-14V40.75 19.5Z"/></svg>`
);
export const earth = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 43q-3.95 0-7.425-1.5Q13.1 40 10.55 37.45 8 34.9 6.5 31.425 5 27.95 5 24q0-3.95 1.5-7.425Q8 13.1 10.55 10.55 13.1 8 16.575 6.5 20.05 5 24 5q3.95 0 7.425 1.5Q34.9 8 37.45 10.55 40 13.1 41.5 16.575 43 20.05 43 24q0 3.95-1.5 7.425Q40 34.9 37.45 37.45 34.9 40 31.425 41.5 27.95 43 24 43Zm-2.1-2.35V36.6q-1.75 0-2.925-1.25-1.175-1.25-1.175-3V30.2L7.7 20.15q-.2.95-.325 1.9T7.25 24q0 6.4 4.175 11.15t10.475 5.5Zm14.45-5.3q1.05-1.15 1.875-2.5t1.4-2.8q.575-1.45.85-2.975.275-1.525.275-3.075 0-5.2-2.85-9.475-2.85-4.275-7.65-6.225v.95q0 1.7-1.175 2.975Q27.9 13.5 26.15 13.5H21.9v4.25q0 .85-.65 1.4-.65.55-1.5.55h-4.1V24H28.3q.85 0 1.4.625.55.625.55 1.475v6.25h2.1q1.45 0 2.525.825t1.475 2.175Z"/></svg>`
);
export const apps = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M11.5 39.25q-1.15 0-1.95-.8t-.8-1.95q0-1.15.8-1.925.8-.775 1.95-.775t1.925.775q.775.775.775 1.925t-.775 1.95q-.775.8-1.925.8Zm12.5 0q-1.15 0-1.925-.8-.775-.8-.775-1.95t.775-1.925Q22.85 33.8 24 33.8t1.925.775q.775.775.775 1.925t-.775 1.95q-.775.8-1.925.8Zm12.5 0q-1.15 0-1.925-.8-.775-.8-.775-1.95t.775-1.925q.775-.775 1.925-.775t1.95.775q.8.775.8 1.925t-.8 1.95q-.8.8-1.95.8Zm-25-12.55q-1.15 0-1.95-.775T8.75 24q0-1.15.8-1.925.8-.775 1.95-.775t1.925.775q.775.775.775 1.925t-.775 1.925q-.775.775-1.925.775Zm12.5 0q-1.15 0-1.925-.775Q21.3 25.15 21.3 24t.775-1.925Q22.85 21.3 24 21.3t1.925.775q.775.775.775 1.925t-.775 1.925Q25.15 26.7 24 26.7Zm12.5 0q-1.15 0-1.925-.775Q33.8 25.15 33.8 24t.775-1.925q.775-.775 1.925-.775t1.95.775q.8.775.8 1.925t-.8 1.925q-.8.775-1.95.775Zm-25-12.5q-1.15 0-1.95-.775t-.8-1.925q0-1.15.8-1.95t1.95-.8q1.15 0 1.925.8.775.8.775 1.95t-.775 1.925q-.775.775-1.925.775Zm12.5 0q-1.15 0-1.925-.775-.775-.775-.775-1.925t.775-1.95q.775-.8 1.925-.8t1.925.8q.775.8.775 1.95t-.775 1.925Q25.15 14.2 24 14.2Zm12.5 0q-1.15 0-1.925-.775-.775-.775-.775-1.925t.775-1.95q.775-.8 1.925-.8t1.95.8q.8.8.8 1.95t-.8 1.925q-.8.775-1.95.775Z"/></svg>`
);
export const tag = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M14.75 39.5q-.5 0-.825-.425-.325-.425-.225-.925l1.8-7H9.05q-.5 0-.85-.45t-.25-.95q.1-.35.4-.625.3-.275.7-.275h7l2.45-9.7h-7.45q-.5 0-.85-.45t-.25-.95q.1-.35.4-.625.3-.275.7-.275h8l1.9-7.5q.05-.4.35-.625.3-.225.65-.225.55 0 .875.425.325.425.175.925l-1.75 7h9.05l1.9-7.5q.05-.4.325-.625.275-.225.675-.225.5 0 .825.425.325.425.225.925l-1.75 7h6.45q.5 0 .85.45t.25.95q-.1.35-.4.625-.3.275-.7.275h-7l-2.45 9.7h7.45q.5 0 .85.45t.25.95q-.1.35-.4.625-.3.275-.7.275h-8l-1.9 7.5q-.05.3-.3.575-.25.275-.75.275t-.85-.425q-.35-.425-.2-.925l1.8-7H17.7l-1.9 7.5q-.05.3-.3.575-.25.275-.75.275Zm3.5-10.65h9.05l2.45-9.7H20.7Z"/></svg>`
);
export const group = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M4.95 38.6q-.6 0-1.025-.4-.425-.4-.425-1.05V34.8q0-1.55.825-2.775Q5.15 30.8 6.6 30.15q3.25-1.4 5.925-2.1 2.675-.7 5.975-.7 3.35 0 6 .7t5.9 2.1q1.45.65 2.275 1.875T33.5 34.8v2.35q0 .65-.4 1.05t-1.05.4Zm30.95 0q.4-.15.65-.525t.25-.975v-2.2q0-2.7-1.25-4.475Q34.3 28.65 32.3 27.5q2.65.4 5.05 1.05t4 1.5q1.45.8 2.3 2.05.85 1.25.85 2.8v2.25q0 .65-.425 1.05t-1.025.4ZM18.5 23.35q-2.85 0-4.725-1.875Q11.9 19.6 11.9 16.7q0-2.9 1.875-4.75T18.5 10.1q2.9 0 4.775 1.85 1.875 1.85 1.875 4.75t-1.875 4.775Q21.4 23.35 18.5 23.35Zm15.8-6.65q0 2.9-1.85 4.775-1.85 1.875-4.75 1.875-.35 0-.725-.05t-.725-.15q1.05-1.2 1.6-2.825.55-1.625.55-3.625 0-1.95-.575-3.5t-1.575-2.9q.3-.1.7-.15.4-.05.75-.05 2.9 0 4.75 1.85t1.85 4.75ZM5.8 36.35h25.45V34.8q0-.8-.45-1.5t-1.3-1.1q-3.15-1.45-5.55-2.025T18.5 29.6q-3 0-5.4.575-2.4.575-5.55 2.025-.85.4-1.3 1.1-.45.7-.45 1.5ZM18.5 21.1q1.9 0 3.15-1.25t1.25-3.15q0-1.85-1.25-3.1t-3.15-1.25q-1.85 0-3.1 1.25t-1.25 3.1q0 1.9 1.25 3.15t3.1 1.25Zm0-4.4Zm0 12.9Z"/></svg>`
);
export const download = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/></svg>`
);
export const sentiment = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M31.05 21.15q1 0 1.675-.675t.675-1.725q0-1.05-.675-1.725t-1.675-.675q-1.05 0-1.725.675t-.675 1.725q0 1.05.675 1.725t1.725.675Zm-14.1 0q1.05 0 1.725-.675t.675-1.725q0-1.05-.675-1.725t-1.725-.675q-1 0-1.675.675T14.6 18.75q0 1.05.675 1.725t1.675.675ZM24 34.35q2.6 0 4.925-1.2 2.325-1.2 3.775-3.45.3-.5.075-.975-.225-.475-.825-.475-.25 0-.5.15t-.4.35q-1.2 1.7-3.025 2.65T24 32.35q-2.15 0-4-.925T17 28.8q-.15-.25-.4-.4-.25-.15-.5-.15-.55 0-.825.475Q15 29.2 15.4 29.8q1.4 2.15 3.7 3.35 2.3 1.2 4.9 1.2ZM24 43q-3.95 0-7.4-1.5t-6.025-4.075Q8 34.85 6.5 31.4T5 24q0-3.95 1.5-7.425Q8 13.1 10.575 10.55 13.15 8 16.6 6.5T24 5q3.95 0 7.425 1.5Q34.9 8 37.45 10.55 40 13.1 41.5 16.575 43 20.05 43 24q0 3.95-1.5 7.4t-4.05 6.025Q34.9 40 31.425 41.5 27.95 43 24 43Zm0-19Zm0 16.75q7 0 11.875-4.9T40.75 24q0-7-4.875-11.875T24 7.25q-6.95 0-11.85 4.875Q7.25 17 7.25 24q0 6.95 4.9 11.85 4.9 4.9 11.85 4.9Z"/></svg>`
);
export const image = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M14.85 33.6h18.5q.5 0 .7-.4.2-.4-.1-.8l-5-6.65q-.25-.3-.6-.3t-.55.3l-5.5 6.95-3.5-4.6q-.25-.3-.6-.3t-.55.3l-3.35 4.3q-.25.4-.075.8t.625.4ZM9.9 41q-1.2 0-2.05-.85Q7 39.3 7 38.1V9.9q0-1.2.85-2.05Q8.7 7 9.9 7h28.2q1.2 0 2.05.85.85.85.85 2.05v28.2q0 1.2-.85 2.05-.85.85-2.05.85Zm0-2.25h28.2q.25 0 .45-.2t.2-.45V9.9q0-.25-.2-.45t-.45-.2H9.9q-.25 0-.45.2t-.2.45v28.2q0 .25.2.45t.45.2Zm-.65-29.5v29.5-29.5Z"/></svg>`
);
export const send = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M7 36.35v-24.7q0-.75.65-1.15.65-.4 1.4-.15l29.2 12.3q.85.4.85 1.375t-.85 1.325L9.05 37.6q-.75.3-1.4-.1Q7 37.1 7 36.35ZM9.25 35l26.3-11-26.3-11.1v8.4L20.2 24 9.25 26.6Zm0-11V12.9 35Z"/></svg>`
);
export const contentCopy = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M16.35 37.05q-1.2 0-2.075-.85-.875-.85-.875-2.05V8.45q0-1.2.875-2.025T16.35 5.6H36q1.2 0 2.05.825.85.825.85 2.025v25.7q0 1.2-.85 2.05-.85.85-2.05.85Zm0-2.25H36q.3 0 .45-.2.15-.2.15-.45V8.45q0-.25-.15-.425T36 7.85H16.35q-.25 0-.45.175t-.2.425v25.7q0 .25.2.45t.45.2Zm-5.6 7.8q-1.15 0-2-.85-.85-.85-.85-2.05V13.9q0-.5.325-.825.325-.325.825-.325.45 0 .775.325t.325.825v25.8q0 .25.2.425t.4.175h19.5q.5 0 .825.325.325.325.325.825 0 .5-.325.825-.325.325-.825.325ZM15.7 7.85V34.8 7.85Z"/></svg>`
);
export const trash = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M13.9 41q-1.15 0-2-.85-.85-.85-.85-2.05V10.9h-.9q-.5 0-.825-.325Q9 10.25 9 9.75q0-.5.325-.8.325-.3.825-.3h7.4q0-.65.425-1.025Q18.4 7.25 19 7.25h10q.6 0 1.025.4.425.4.425 1h7.4q.5 0 .825.325Q39 9.3 39 9.8q0 .45-.325.775t-.825.325h-.9v27.2q0 1.2-.85 2.05-.85.85-2 .85Zm-.6-30.1v27.2q0 .3.175.475t.425.175h20.2q.25 0 .425-.175t.175-.475V10.9Zm5.75 22.45q0 .5.325.825.325.325.825.325.5 0 .825-.325.325-.325.325-.825V16.2q0-.45-.35-.775t-.8-.325q-.5 0-.825.325-.325.325-.325.775Zm7.6 0q0 .5.35.825.35.325.8.325.5 0 .825-.325.325-.325.325-.825V16.2q0-.45-.325-.775T27.8 15.1q-.5 0-.825.325-.325.325-.325.775ZM13.3 10.9v27.2q0 .3.175.475t.425.175h-.6V10.9Z"/></svg>`
);
export const checkCircle = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m21.05 29-5.1-5.1q-.3-.35-.8-.35t-.9.4q-.35.35-.35.85t.35.85l5.8 5.75q.45.45 1.025.45t.975-.45L33.7 19.8q.35-.35.325-.85-.025-.5-.375-.85t-.85-.35q-.5 0-.9.35ZM24 43q-4 0-7.475-1.45-3.475-1.45-6.05-4.025Q7.9 34.95 6.45 31.475 5 28 5 24q0-3.95 1.45-7.425 1.45-3.475 4.025-6.05 2.575-2.575 6.05-4.05Q20 5 24 5q3.95 0 7.425 1.475 3.475 1.475 6.05 4.05 2.575 2.575 4.05 6.025Q43 20 43 24t-1.475 7.475q-1.475 3.475-4.05 6.05Q34.9 40.1 31.45 41.55 28 43 24 43Zm0-19Zm0 16.75q7.1 0 11.925-4.825Q40.75 31.1 40.75 24q0-7.1-4.825-11.925Q31.1 7.25 24 7.25q-7.1 0-11.925 4.825Q7.25 16.9 7.25 24q0 7.1 4.825 11.925Q16.9 40.75 24 40.75Z"/></svg>`
);
export const star = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M16.55 37.2 24 32.7l7.45 4.55-2-8.45L36 23.1l-8.6-.75L24 14.4l-3.4 7.9-8.6.75 6.55 5.7ZM24 35.4l-8.5 5.1q-.35.2-.675.175-.325-.025-.625-.225-.25-.2-.375-.5t-.025-.7L16 29.6l-7.45-6.55q-.35-.25-.425-.575-.075-.325.025-.675.1-.3.375-.5t.625-.25l9.9-.85 3.9-9.15q.15-.35.45-.525.3-.175.6-.175t.6.175q.3.175.45.525l3.9 9.15 9.9.85q.35.05.625.25t.375.5q.1.35.025.675-.075.325-.425.575L32 29.6l2.25 9.65q.05.4-.075.7-.125.3-.375.5-.3.2-.625.225-.325.025-.675-.175Zm0-9.1Z"/></svg>`
);
export const heartMinus = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M22 23.1Zm0 17.5-3.3-3.05Q13.8 33.1 10.75 30q-3.05-3.1-4.775-5.4-1.725-2.3-2.35-4.175T3 16.55q0-4.15 2.8-6.975Q8.6 6.75 12.65 6.75q2.8 0 5.175 1.375T22 12.2q2-2.75 4.325-4.1 2.325-1.35 5.025-1.35 4.05 0 6.825 2.825Q40.95 12.4 40.95 16.55q0 1.3-.275 2.55-.275 1.25-.675 2.1h-2.45q.45-.95.8-2.25.35-1.3.35-2.4 0-3.25-2.225-5.4Q34.25 9 31.35 9q-2.5 0-4.65 1.5-2.15 1.5-3.7 4.45h-2.05q-1.5-2.9-3.65-4.425T12.65 9q-3.2 0-5.3 2.15-2.1 2.15-2.1 5.4 0 1.8.65 3.525.65 1.725 2.475 4.025 1.825 2.3 5.1 5.5 3.275 3.2 8.525 8 1.6-1.45 3-2.7 1.4-1.25 2.75-2.45l.25.25q.25.25.55.525.3.275.55.525l.25.25q-1.35 1.2-2.725 2.425Q25.25 37.65 23.6 39.2Zm7.75-13.45v-2.3h15v2.3Z"/></svg>`
);
export const favorite = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m22.05 38.95-2.3-2.15q-6-5.5-10.375-10.5T5 15.95q0-4.1 2.775-6.875Q10.55 6.3 14.65 6.3q2.3 0 4.775 1.175Q21.9 8.65 24 11.8q2.25-3.15 4.625-4.325Q31 6.3 33.35 6.3q4.1 0 6.875 2.775Q43 11.85 43 15.95q0 5.4-4.475 10.425T28.25 36.8l-2.3 2.1q-.85.75-1.95.75t-1.95-.7Zm.95-24.6q-1.55-2.8-3.75-4.3t-4.6-1.5q-3.25 0-5.325 2.1t-2.075 5.3q0 2.65 1.625 5.45 1.625 2.8 4.075 5.575 2.45 2.775 5.3 5.4Q21.1 35 23.6 37.25q.15.15.4.15t.4-.15q2.5-2.2 5.35-4.85 2.85-2.65 5.3-5.425 2.45-2.775 4.075-5.6 1.625-2.825 1.625-5.425 0-3.2-2.1-5.3-2.1-2.1-5.3-2.1-2.45 0-4.625 1.475T24.95 14.35q-.2.3-.45.45-.25.15-.55.15-.3 0-.55-.15-.25-.15-.4-.45ZM24 23Z"/></svg>`
);
export const remove = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M11.65 25.15q-.5 0-.825-.325Q10.5 24.5 10.5 24q0-.5.325-.825.325-.325.825-.325h24.7q.5 0 .825.325.325.325.325.825 0 .5-.325.825-.325.325-.825.325Z"/></svg>`
);
export const locationOn = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 23.15q1.3 0 2.225-.925T27.15 20q0-1.3-.925-2.225T24 16.85q-1.3 0-2.225.925T20.85 20q0 1.3.925 2.225T24 23.15Zm0 17.45q6.55-5.85 9.9-11.125 3.35-5.275 3.35-9.075 0-6-3.825-9.825Q29.6 6.75 24 6.75q-5.6 0-9.425 3.825Q10.75 14.4 10.75 20.4q0 3.8 3.4 9.075Q17.55 34.75 24 40.6Zm.05 2.45q-.35 0-.625-.1t-.475-.3q-7.3-6.45-10.875-12.025T8.5 20.4q0-7.25 4.675-11.575Q17.85 4.5 24 4.5q6.15 0 10.825 4.325Q39.5 13.15 39.5 20.4q0 4.65-3.575 10.225Q32.35 36.2 25.05 42.65q-.2.2-.45.3-.25.1-.55.1ZM24 20.4Z"/></svg>`
);
export const nearMe = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M21.15 26.8 8.8 21.95q-.4-.1-.625-.475-.225-.375-.225-.775t.25-.75q.25-.35.65-.5l28.9-10.9q.35-.15.725-.05.375.1.625.35.3.3.4.65.1.35-.1.75L28.45 39.1q-.15.45-.5.675-.35.225-.75.225t-.75-.225q-.35-.225-.5-.675Zm5.95 9.1 9.4-24.45-24.45 9.4 10.8 4.2Zm-4.25-10.85Z"/></svg>`
);
export const share = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M36.25 43.5q-2.2 0-3.725-1.55T31 38.2q0-.35.075-.85t.225-.85l-15.6-9.1q-.7.85-1.75 1.375t-2.15.525q-2.2 0-3.75-1.55Q6.5 26.2 6.5 24t1.55-3.75Q9.6 18.7 11.8 18.7q1.1 0 2.125.475T15.7 20.5l15.6-9q-.15-.4-.225-.85Q31 10.2 31 9.8q0-2.2 1.525-3.75Q34.05 4.5 36.25 4.5T40 6.05q1.55 1.55 1.55 3.7 0 2.25-1.55 3.775t-3.75 1.525q-1.15 0-2.175-.4T32.35 13.4L16.8 22.2q.1.4.175.9.075.5.075.9t-.075.825q-.075.425-.175.825l15.55 8.9q.7-.7 1.675-1.15.975-.45 2.225-.45 2.2 0 3.75 1.525Q41.55 36 41.55 38.2T40 41.95q-1.55 1.55-3.75 1.55Zm0-30.7q1.25 0 2.125-.875t.875-2.175q0-1.25-.875-2.125T36.25 6.75q-1.25 0-2.125.875T33.25 9.8q0 1.25.875 2.125t2.125.875ZM11.8 27q1.25 0 2.125-.875T14.8 24q0-1.25-.875-2.125T11.75 21q-1.25 0-2.125.875T8.75 24q0 1.25.875 2.125T11.8 27Zm24.45 14.25q1.25 0 2.125-.875t.875-2.175q0-1.25-.875-2.125T36.25 35.2q-1.25 0-2.125.875t-.875 2.175q0 1.25.875 2.125t2.125.875Zm0-31.45ZM11.8 24Zm24.45 14.2Z"/></svg>`
);
export const calendarToday = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M9.9 43q-1.2 0-2.05-.85Q7 41.3 7 40.1V11.15q0-1.15.85-2.025T9.9 8.25h3.7V6q0-.5.35-.875t.85-.375q.55 0 .925.375T16.1 6v2.25H32v-2.3q0-.5.35-.85t.85-.35q.55 0 .9.35t.35.9v2.25h3.65q1.2 0 2.05.875Q41 10 41 11.15V40.1q0 1.2-.85 2.05-.85.85-2.05.85Zm0-2.25h28.2q.3 0 .475-.175t.175-.475V20.25H9.25V40.1q0 .3.175.475t.475.175ZM9.25 18h29.5v-6.85q0-.25-.175-.425t-.475-.175H9.9q-.3 0-.475.175t-.175.425Zm0 0V10.55 18Z"/></svg>`
);
export const shareIos = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M23.95 30.5q-.5 0-.8-.325-.3-.325-.3-.775V8.1L19 11.9q-.25.3-.725.3t-.825-.35q-.35-.35-.35-.825t.35-.825l5.5-5.5q.25-.25.5-.35.25-.1.5-.1.3 0 .55.1.25.1.5.35l5.55 5.55q.3.3.3.775 0 .475-.35.825t-.8.35q-.45 0-.8-.35L25.1 8.1v21.3q0 .45-.325.775t-.825.325ZM11.9 45q-1.2 0-2.05-.85Q9 43.3 9 42.1V18.8q0-1.15.85-2 .85-.85 2.05-.85h5.75q.5 0 .825.325.325.325.325.825 0 .45-.325.775t-.825.325H11.9q-.25 0-.45.2t-.2.4v23.3q0 .25.2.45t.45.2h24.2q.25 0 .45-.2t.2-.45V18.8q0-.2-.2-.4t-.45-.2h-5.8q-.5 0-.825-.325-.325-.325-.325-.825 0-.5.325-.8.325-.3.825-.3h5.8q1.2 0 2.05.85.85.85.85 2v23.3q0 1.2-.85 2.05-.85.85-2.05.85Z"/></svg>`
);
export const confirmationNumber = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M7.25 39q-.95 0-1.6-.65Q5 37.7 5 36.75V29.8q1.85-.65 3.025-2.2Q9.2 26.05 9.2 24t-1.175-3.625Q6.85 18.8 5 18.2v-6.95q0-.95.65-1.6Q6.3 9 7.25 9h33.5q.95 0 1.6.65.65.65.65 1.6v6.95q-1.85.6-3.025 2.175Q38.8 21.95 38.8 24t1.175 3.6Q41.15 29.15 43 29.8v6.95q0 .95-.65 1.6-.65.65-1.6.65Zm0-2.25h33.5V31.4q-1.9-1.25-3.075-3.175Q36.5 26.3 36.5 24t1.175-4.225Q38.85 17.85 40.75 16.6v-5.35H7.25v5.35q1.95 1.25 3.1 3.175Q11.5 21.7 11.5 24t-1.15 4.225Q9.2 30.15 7.25 31.4ZM24 33.2q.45 0 .8-.325.35-.325.35-.825 0-.45-.35-.775t-.8-.325q-.45 0-.8.325-.35.325-.35.775 0 .5.35.825.35.325.8.325Zm0-8.05q.45 0 .8-.35.35-.35.35-.8 0-.45-.35-.8-.35-.35-.8-.35-.45 0-.8.35-.35.35-.35.8 0 .45.35.8.35.35.8.35Zm0-8.1q.45 0 .8-.325.35-.325.35-.775 0-.5-.35-.825-.35-.325-.8-.325-.45 0-.8.325-.35.325-.35.825 0 .45.35.775t.8.325ZM24 24Z"/></svg>`
);
export const undo = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M15.55 37.5q-.5 0-.825-.325-.325-.325-.325-.825 0-.5.325-.8.325-.3.825-.3H28.3q3.6 0 6.225-2.375Q37.15 30.5 37.15 26.9q0-3.55-2.625-5.9-2.625-2.35-6.225-2.35H12.95l5.25 5.25q.35.35.35.8 0 .45-.35.8-.35.35-.8.35-.45 0-.8-.35l-7-7q-.2-.25-.3-.5-.1-.25-.1-.5 0-.3.1-.55.1-.25.3-.45l7-7q.35-.35.8-.35.45 0 .8.35.35.35.35.8 0 .45-.35.8l-5.25 5.25h15.3q4.55 0 7.85 3.05t3.3 7.5q0 4.5-3.3 7.55t-7.85 3.05Z"/></svg>`
);
export const arrowBackIos = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M18.85 42.15 1.7 25q-.25-.2-.35-.45-.1-.25-.1-.55 0-.3.1-.55.1-.25.35-.45L18.85 5.8q.45-.45 1.15-.45t1.15.5q.45.45.45 1.15t-.45 1.15L5.3 24l15.85 15.85q.5.5.475 1.15-.025.65-.475 1.15-.5.45-1.175.45t-1.125-.45Z"/></svg>`
);
export const call = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M38.75 40.9q-5.6 0-11.25-2.875T17.3 30.6q-4.55-4.55-7.4-10.175Q7.05 14.8 7.05 9.2q0-.95.625-1.575T9.25 7h5.7q.8 0 1.35.5t.75 1.35l1.2 5.35q.1.75-.05 1.35-.15.6-.6 1l-4.7 4.6q2.85 4.8 6.375 8.275Q22.8 32.9 27.35 35.4l4.5-4.7q.5-.55 1.125-.775.625-.225 1.325-.075l4.85 1.1q.8.15 1.3.75t.5 1.45v5.5q0 .95-.625 1.6-.625.65-1.575.65Zm-27-21.8 4.1-4q.1-.1.125-.225.025-.125.025-.225L14.9 9.6q-.05-.2-.15-.275-.1-.075-.25-.075H9.6q-.15 0-.225.075Q9.3 9.4 9.3 9.55q-.05 1.95.6 4.325t1.85 5.225ZM38.4 38.65q.15 0 .225-.075.075-.075.075-.225v-4.9q0-.15-.075-.25t-.225-.15l-4.55-.9q-.1-.05-.2 0l-.2.1-3.95 4.2q2.05 1 4.475 1.55t4.425.65Zm-8.95-2.2ZM11.75 19.1Z"/></svg>`
);
export const visibility = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 30.8q3.25 0 5.525-2.3Q31.8 26.2 31.8 23q0-3.25-2.3-5.525Q27.2 15.2 24 15.2q-3.25 0-5.525 2.3Q16.2 19.8 16.2 23q0 3.25 2.3 5.525Q20.8 30.8 24 30.8Zm0-2.25q-2.35 0-3.95-1.625T18.45 23q0-2.35 1.625-3.95T24 17.45q2.35 0 3.95 1.625T29.55 23q0 2.35-1.625 3.95T24 28.55ZM24 37q-6.55 0-11.95-3.5-5.4-3.5-8.4-9.35-.15-.2-.2-.5-.05-.3-.05-.65 0-.35.05-.65.05-.3.2-.5 3-5.85 8.4-9.35T24 9q6.55 0 11.95 3.5 5.4 3.5 8.4 9.35.15.2.2.5.05.3.05.65 0 .35-.05.65-.05.3-.2.5-3 5.85-8.4 9.35T24 37Zm0-14Zm0 11.75q5.95 0 10.925-3.2Q39.9 28.35 42.5 23q-2.6-5.35-7.575-8.55-4.975-3.2-10.925-3.2-5.95 0-10.925 3.2Q8.1 17.65 5.45 23q2.65 5.35 7.625 8.55 4.975 3.2 10.925 3.2Z"/></svg>`
);
export const visibilityOff = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m31 26.3-1.75-1.75q1.1-3.4-1.4-5.6-2.5-2.2-5.4-1.2L20.7 16q.7-.4 1.525-.6.825-.2 1.775-.2 3.25 0 5.525 2.275Q31.8 19.75 31.8 23q0 .95-.225 1.8-.225.85-.575 1.5Zm6.4 6.3-1.55-1.5q2.3-1.7 4.025-3.775Q41.6 25.25 42.5 23q-2.5-5.4-7.4-8.575-4.9-3.175-10.75-3.175-1.9 0-3.875.35t-3.275.85l-1.75-1.75q1.65-.75 4.05-1.225Q21.9 9 24.2 9q6.4 0 11.85 3.475Q41.5 15.95 44.4 21.8q.1.25.15.55.05.3.05.65 0 .35-.05.675-.05.325-.15.525-1.25 2.5-3 4.6-1.75 2.1-4 3.8Zm2.15 10-7.25-7.15q-1.55.7-3.725 1.125Q26.4 37 24 37q-6.55 0-12.025-3.475Q6.5 30.05 3.6 24.2q-.1-.3-.15-.575Q3.4 23.35 3.4 23t.05-.675Q3.5 22 3.6 21.8q1.15-2.3 2.875-4.425T10.4 13.5L5.05 8.2q-.3-.35-.325-.8-.025-.45.325-.8.35-.35.825-.35t.825.35l34.45 34.45q.3.3.3.725t-.3.775q-.3.4-.775.4-.475 0-.825-.35Zm-27.6-27.5q-1.9 1.3-3.775 3.55T5.45 23Q8 28.4 13 31.575q5 3.175 11.3 3.175 1.85 0 3.675-.3 1.825-.3 2.625-.7l-3.55-3.55q-.55.25-1.425.425T24 30.8q-3.25 0-5.525-2.25Q16.2 26.3 16.2 23q0-.75.175-1.6.175-.85.425-1.45Zm14.6 7.05Zm-5.3 2.65Z"/></svg>`
);
export const help = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24.2 35q.65 0 1.125-.475t.475-1.175q0-.65-.475-1.125T24.2 31.75q-.65 0-1.125.475T22.6 33.35q0 .7.475 1.175Q23.55 35 24.2 35Zm-.05-19.85q1.75 0 2.825.95 1.075.95 1.075 2.45 0 1-.575 1.95t-1.825 2.05q-1.25 1.15-1.975 2.35-.725 1.2-.775 2.25 0 .4.325.675t.725.275q.5 0 .8-.3.3-.3.35-.75.15-.95.65-1.725.5-.775 1.6-1.675 1.45-1.3 2.1-2.475.65-1.175.65-2.625 0-2.45-1.6-3.975t-4.2-1.525q-1.75 0-3.275.75T18.5 15.95q-.3.4-.25.85.05.45.4.65.45.35.925.225.475-.125.825-.575.65-.85 1.55-1.4.9-.55 2.2-.55ZM24 43q-4 0-7.45-1.45t-6.025-4.025q-2.575-2.575-4.05-6.05Q5 28 5 24q0-4 1.475-7.45t4.05-6.025Q13.1 7.95 16.55 6.475 20 5 24 5q3.95 0 7.4 1.475t6.025 4.05Q40 13.1 41.5 16.55T43 24q0 4-1.5 7.475-1.5 3.475-4.075 6.05Q34.85 40.1 31.4 41.55 27.95 43 24 43Zm0-19.4Zm0 17.15q6.95 0 11.85-4.925Q40.75 30.9 40.75 24q0-6.9-4.9-11.825Q30.95 7.25 24 7.25q-7 0-11.875 4.925T7.25 24q0 6.9 4.875 11.825Q17 40.75 24 40.75Z"/></svg>`
);
export const personAdd = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M37.75 27.5q-.5 0-.825-.325-.325-.325-.325-.825v-5.2h-5.2q-.5 0-.825-.325-.325-.325-.325-.825 0-.5.325-.825.325-.325.825-.325h5.2v-5.2q0-.5.325-.825.325-.325.825-.325.5 0 .825.325.325.325.325.825v5.2h5.2q.5 0 .825.325.325.325.325.825 0 .5-.325.825-.325.325-.825.325h-5.2v5.2q0 .5-.325.825-.325.325-.825.325Zm-20-4.15q-2.9 0-4.775-1.875Q11.1 19.6 11.1 16.7q0-2.9 1.875-4.75t4.775-1.85q2.9 0 4.775 1.85Q24.4 13.8 24.4 16.7t-1.875 4.775Q20.65 23.35 17.75 23.35ZM4.2 38.6q-.6 0-1.025-.4-.425-.4-.425-1.05V34.8q0-1.5.825-2.75t2.225-1.9q3.45-1.5 6.275-2.15 2.825-.65 5.675-.65 2.85 0 5.675.65 2.825.65 6.225 2.15 1.45.7 2.275 1.925t.825 2.725v2.35q0 .65-.425 1.05t-1.025.4Zm.8-2.25h25.5V34.8q0-.75-.45-1.475-.45-.725-1.35-1.125-3.15-1.55-5.65-2.075-2.5-.525-5.3-.525t-5.3.55q-2.5.55-5.7 2.05-.8.4-1.275 1.125Q5 34.05 5 34.8ZM17.75 21.1q1.85 0 3.1-1.25t1.25-3.15q0-1.85-1.25-3.1t-3.1-1.25q-1.85 0-3.1 1.25t-1.25 3.1q0 1.9 1.25 3.15t3.1 1.25Zm0-4.4Zm0 12.9Z"/></svg>`
);
export const moreVert = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 38.55q-.75 0-1.275-.55-.525-.55-.525-1.3t.525-1.3q.525-.55 1.275-.55.75 0 1.275.55.525.55.525 1.3 0 .8-.525 1.325-.525.525-1.275.525Zm0-12.75q-.75 0-1.275-.525Q22.2 24.75 22.2 24q0-.75.525-1.275Q23.25 22.2 24 22.2q.75 0 1.275.525.525.525.525 1.275 0 .75-.525 1.275-.525.525-1.275.525Zm0-12.65q-.75 0-1.275-.55-.525-.55-.525-1.3 0-.8.525-1.325Q23.25 9.45 24 9.45q.75 0 1.275.55.525.55.525 1.3t-.525 1.3q-.525.55-1.275.55Z"/></svg>`
);
export const logout = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M32.4 31.3q-.35-.35-.35-.825t.35-.825l4.05-4H19.9q-.45 0-.775-.325T18.8 24.5q0-.5.325-.825.325-.325.775-.325h16.5l-4.1-4.05q-.3-.35-.3-.8 0-.45.35-.8.35-.35.825-.35t.775.35l5.85 5.8q.25.25.35.5.1.25.1.55 0 .3-.1.525-.1.225-.35.475l-5.8 5.8q-.3.3-.75.3t-.85-.35ZM10.1 42q-1.2 0-2.05-.85-.85-.85-.85-2.05V9.9q0-1.2.85-2.05Q8.9 7 10.1 7h12.65q.5 0 .825.325.325.325.325.825 0 .5-.325.8-.325.3-.825.3H10.1q-.25 0-.45.2t-.2.45v29.2q0 .25.2.45t.45.2h12.65q.5 0 .825.325.325.325.325.775 0 .5-.325.825-.325.325-.825.325Z"/></svg>`
);
export const schedule = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M30.7 32.4q.35.35.8.35.45 0 .85-.4.35-.35.375-.85.025-.5-.375-.85l-7.1-7.1V14.6q0-.45-.325-.775T24.1 13.5q-.5 0-.8.325-.3.325-.3.825v9.25q0 .3.1.525.1.225.3.475ZM24 43q-3.9 0-7.375-1.5t-6.05-4.075Q8 34.85 6.5 31.375 5 27.9 5 24t1.5-7.375q1.5-3.475 4.075-6.05Q13.15 8 16.625 6.5 20.1 5 24 5t7.375 1.5q3.475 1.5 6.05 4.075Q40 13.15 41.5 16.625 43 20.1 43 24t-1.5 7.375q-1.5 3.475-4.075 6.05Q34.85 40 31.375 41.5 27.9 43 24 43Zm0-19Zm0 16.75q6.9 0 11.825-4.925Q40.75 30.9 40.75 24q0-6.9-4.925-11.825Q30.9 7.25 24 7.25q-6.9 0-11.825 4.925Q7.25 17.1 7.25 24q0 6.9 4.925 11.825Q17.1 40.75 24 40.75Z"/></svg>`
);
export const info = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24.1 33.5q.5 0 .825-.325.325-.325.325-.825V23.1q0-.45-.325-.775T24.1 22q-.5 0-.8.325-.3.325-.3.825v9.25q0 .45.325.775t.775.325ZM24 18.8q.55 0 .95-.375.4-.375.4-.975 0-.55-.4-.95-.4-.4-.95-.4-.6 0-.975.4t-.375.95q0 .6.4.975t.95.375ZM24 43q-4 0-7.475-1.45-3.475-1.45-6.05-4.025Q7.9 34.95 6.45 31.475 5 28 5 24q0-4 1.45-7.45t4.025-6.025q2.575-2.575 6.05-4.05Q20 5 24 5q4 0 7.45 1.475t6.025 4.05q2.575 2.575 4.05 6.025Q43 20 43 24t-1.475 7.475q-1.475 3.475-4.05 6.05Q34.9 40.1 31.45 41.55 28 43 24 43Zm0-19Zm0 16.75q6.9 0 11.825-4.925Q40.75 30.9 40.75 24q0-6.9-4.925-11.825Q30.9 7.25 24 7.25q-6.9 0-11.825 4.925Q7.25 17.1 7.25 24q0 6.9 4.925 11.825Q17.1 40.75 24 40.75Z"/></svg>`
);
export const block = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 43q-3.95 0-7.425-1.5Q13.1 40 10.55 37.45 8 34.9 6.5 31.425 5 27.95 5 24q0-3.95 1.5-7.425Q8 13.1 10.55 10.55 13.1 8 16.575 6.5 20.05 5 24 5q3.95 0 7.425 1.5Q34.9 8 37.45 10.55 40 13.1 41.5 16.575 43 20.05 43 24q0 3.95-1.5 7.425Q40 34.9 37.45 37.45 34.9 40 31.425 41.5 27.95 43 24 43Zm0-2.25q7 0 11.875-4.875T40.75 24q0-3.05-1.1-5.875T36.6 12.95L12.95 36.6q2.3 2 5.125 3.075Q20.9 40.75 24 40.75Zm-12.55-5.7 23.6-23.6q-2.3-2-5.125-3.1T24 7.25q-7 0-11.875 4.875T7.25 24q0 3.1 1.125 5.925Q9.5 32.75 11.45 35.05Z"/></svg>`
);

export const edit = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M9.6 38.75h2l22.1-22.1-2.05-2.05L9.6 36.7ZM38.55 15 33.3 9.8l2.1-2.15q.65-.65 1.625-.625.975.025 1.625.675l2 2q.65.65.65 1.6 0 .95-.65 1.6ZM8.75 41q-.6 0-1.025-.425Q7.3 40.15 7.3 39.55v-3.2q0-.3.1-.525.1-.225.35-.475L31.7 11.4l5.25 5.2-24 23.95q-.25.25-.5.35-.25.1-.5.1ZM32.7 15.6l-1.05-1 2.05 2.05Z"/></svg>`
);
export const add = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 37.5q-.5 0-.825-.325-.325-.325-.325-.825v-11.2h-11.2q-.5 0-.825-.325Q10.5 24.5 10.5 24q0-.5.325-.825.325-.325.825-.325h11.2v-11.2q0-.5.325-.825.325-.325.825-.325.5 0 .825.325.325.325.325.825v11.2h11.2q.5 0 .825.325.325.325.325.825 0 .5-.325.825-.325.325-.825.325h-11.2v11.2q0 .5-.325.825-.325.325-.825.325Z"/></svg>`
);
export const warning = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24.05 24.6ZM6.25 41q-.85 0-1.275-.725-.425-.725-.025-1.425l17.8-30.7q.45-.7 1.25-.7t1.25.7l17.8 30.7q.4.7-.025 1.425Q42.6 41 41.75 41Zm17.9-21.05q-.5 0-.825.325Q23 20.6 23 21.05v8.65q0 .5.325.825.325.325.825.325.5 0 .825-.325.325-.325.325-.825v-8.65q0-.45-.35-.775t-.8-.325Zm0 15.8q.55 0 .9-.375t.35-.925q0-.5-.375-.85t-.875-.35q-.55 0-.9.35t-.35.9q0 .5.35.875t.9.375Zm-16.5 3h32.7L24 10.55Z"/></svg>`
);
export const myLocation = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 44.4q-.5 0-.825-.325-.325-.325-.325-.825V41.1q-6.7-.65-11-4.95t-4.95-11H4.75q-.5 0-.825-.325Q3.6 24.5 3.6 24q0-.5.325-.825.325-.325.825-.325H6.9q.65-6.7 4.95-11t11-5v-2.1q0-.5.325-.825Q23.5 3.6 24 3.6q.5 0 .825.325.325.325.325.825v2.1q6.7.7 11 5t5 11h2.1q.5 0 .825.325.325.325.325.825 0 .5-.325.825-.325.325-.825.325h-2.1q-.7 6.7-5 11t-11 4.95v2.15q0 .5-.325.825-.325.325-.825.325Zm0-5.55q6.15 0 10.5-4.35T38.85 24q0-6.15-4.35-10.5T24 9.15q-6.15 0-10.5 4.35T9.15 24q0 6.15 4.35 10.5T24 38.85Zm0-8.2q-2.8 0-4.725-1.925Q17.35 26.8 17.35 24t1.925-4.725Q21.2 17.35 24 17.35t4.725 1.925Q30.65 21.2 30.65 24t-1.925 4.725Q26.8 30.65 24 30.65Zm0-2.3q1.85 0 3.1-1.25t1.25-3.1q0-1.85-1.25-3.1T24 19.65q-1.85 0-3.1 1.25T19.65 24q0 1.85 1.25 3.1t3.1 1.25ZM24 24Z"/></svg>`
);
export const translate = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M26.4 42.45q-1.1 0-1.575-.675-.475-.675-.125-1.725l6.85-18q.25-.7.975-1.225.725-.525 1.525-.525.8 0 1.575.525.775.525 1.025 1.225l6.9 17.9q.4 1.05-.1 1.775-.5.725-1.65.725-.55 0-1-.325-.45-.325-.65-.825l-1.55-4.6h-8.85l-1.7 4.6q-.2.5-.675.825-.475.325-.975.325Zm4.4-9h6.55l-3.25-9H34ZM15.15 17.6q.7 1.25 1.525 2.375T18.55 22.3q2.05-2.2 3.425-4.5t2.325-4.9H5.55q-.75 0-1.275-.525-.525-.525-.525-1.275 0-.8.525-1.325Q4.8 9.25 5.6 9.25h11.05V7.4q0-.8.525-1.325.525-.525 1.325-.525.8 0 1.325.525.525.525.525 1.325v1.85H31.4q.8 0 1.325.525.525.525.525 1.325 0 .75-.525 1.275-.525.525-1.325.525H28q-1 3.2-2.675 6.275Q23.65 22.25 21.2 25l4.5 4.55-1.4 3.75-5.8-5.6-7.95 7.95q-.55.55-1.275.55-.725 0-1.275-.6-.55-.5-.55-1.25t.55-1.3l8.1-8.1q-1.2-1.4-2.25-2.85-1.05-1.45-1.9-3-.55-1-.1-1.75.45-.75 1.65-.75.5 0 .95.3.45.3.7.7Z"/></svg>`
);
export const editSquare = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M9.9 46.4q-1.2 0-2.05-.85Q7 44.7 7 43.5V15.3q0-1.2.85-2.05.85-.85 2.05-.85h18.8l-2.25 2.25H9.9q-.25 0-.45.2t-.2.45v28.2q0 .25.2.45t.45.2h28.2q.25 0 .45-.2t.2-.45V26.85L41 24.6v18.9q0 1.2-.85 2.05-.85.85-2.05.85Zm14.1-17Zm9.6-16.7 1.65 1.6-14 13.9v3.95h3.9L39.2 18.1l1.55 1.6L26.9 33.65q-.4.35-.9.55-.5.2-1 .2h-4.55q-.65 0-1.05-.4t-.4-1.05V28.4q0-.5.2-1t.6-.9Zm7.15 7-7.15-7 4.6-4.6q.8-.8 2.025-.8 1.225 0 2.025.9l3 2.95q.8.9.8 2.075 0 1.175-.85 1.975Z"/></svg>`
);
export const photoCamera = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 34q3.35 0 5.6-2.275 2.25-2.275 2.25-5.575 0-3.35-2.25-5.6-2.25-2.25-5.6-2.25-3.35 0-5.6 2.25-2.25 2.25-2.25 5.6 0 3.3 2.25 5.575Q20.65 34 24 34ZM7.9 41q-1.2 0-2.05-.85Q5 39.3 5 38.1V14.15q0-1.15.85-2.025t2.05-.875h6.85l2.8-3.3q.4-.45.975-.7Q19.1 7 19.75 7h8.5q.65 0 1.225.25.575.25.975.7l2.8 3.3h6.85q1.15 0 2.025.875T43 14.15V38.1q0 1.2-.875 2.05-.875.85-2.025.85Zm32.2-2.25q.3 0 .475-.175t.175-.475V14.15q0-.25-.175-.45t-.475-.2H7.9q-.3 0-.475.2t-.175.45V38.1q0 .3.175.475t.475.175ZM24 26.15Z"/></svg>`
);
export const personRemove = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M33.55 20.7q-.5 0-.825-.325-.325-.325-.325-.825 0-.5.325-.825.325-.325.825-.325H42q.45 0 .775.35t.325.8q0 .5-.325.825-.325.325-.775.325ZM18 23.35q-2.9 0-4.775-1.875Q11.35 19.6 11.35 16.7q0-2.9 1.875-4.75T18 10.1q2.9 0 4.775 1.85 1.875 1.85 1.875 4.75t-1.875 4.775Q20.9 23.35 18 23.35ZM4.45 38.6q-.6 0-1.025-.4Q3 37.8 3 37.15V34.8q0-1.5.8-2.75t2.25-1.9q3.45-1.5 6.275-2.15 2.825-.65 5.675-.65 2.85 0 5.65.65 2.8.65 6.25 2.15 1.45.7 2.275 1.925T33 34.8v2.35q0 .65-.425 1.05t-1.025.4Zm.8-2.25h25.5V34.8q0-.75-.5-1.475-.5-.725-1.3-1.125-3.2-1.5-5.675-2.05Q20.8 29.6 18 29.6q-2.8 0-5.3.55T7 32.2q-.8.4-1.275 1.125-.475.725-.475 1.475ZM18 21.1q1.85 0 3.1-1.25t1.25-3.15q0-1.85-1.25-3.1T18 12.35q-1.85 0-3.1 1.25t-1.25 3.1q0 1.9 1.25 3.15T18 21.1Zm0-4.4Zm0 12.9Z"/></svg>`
);
export const calendarAdd = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M35.25 43.5v-6h-6v-2.25h6v-6h2.25v6h6v2.25h-6v6ZM9.9 39q-1.2 0-2.05-.85Q7 37.3 7 36.1V11.15q0-1.15.85-2.025T9.9 8.25h3.7v-3.5h2.5v3.5H28v-3.5h2.45v3.5h3.65q1.2 0 2.05.875Q37 10 37 11.15V25.1q-.55-.05-1.125-.05t-1.125.05v-4.85H9.25V36.1q0 .25.2.45t.45.2H25q0 .55.1 1.125T25.35 39Zm-.65-21h25.5v-6.85q0-.25-.2-.425t-.45-.175H9.9q-.25 0-.45.175t-.2.425Zm0 0V10.55 18Z"/></svg>`
);
export const chatBubble = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M5 38.6V7.9q0-1.15.85-2.025T7.9 5h32.2q1.15 0 2.025.875T43 7.9v24.2q0 1.15-.875 2.025T40.1 35h-28l-4.65 4.6q-.7.7-1.575.325Q5 39.55 5 38.6Zm2.25-2 3.85-3.85h29q.3 0 .475-.175t.175-.475V7.9q0-.3-.175-.475T40.1 7.25H7.9q-.3 0-.475.175T7.25 7.9Zm0-28.7V7.25 36.6Z"/></svg>`
);
export const event = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M30 36.75q-1.9 0-3.2-1.325-1.3-1.325-1.3-3.225 0-1.85 1.325-3.175Q28.15 27.7 30 27.7q1.9 0 3.2 1.325 1.3 1.325 1.3 3.225 0 1.85-1.3 3.175-1.3 1.325-3.2 1.325ZM9.9 43q-1.2 0-2.05-.85Q7 41.3 7 40.1V11.15q0-1.15.85-2.025T9.9 8.25h3.7V6q0-.5.35-.875t.85-.375q.55 0 .925.375T16.1 6v2.25H32V6q0-.55.325-.9t.875-.35q.5 0 .875.35t.375.9v2.25h3.65q1.2 0 2.05.875Q41 10 41 11.15V40.1q0 1.2-.85 2.05-.85.85-2.05.85Zm0-2.25h28.2q.3 0 .475-.175t.175-.475V20.25H9.25V40.1q0 .3.175.475t.475.175ZM9.25 18h29.5v-6.85q0-.25-.175-.425t-.475-.175H9.9q-.3 0-.475.175t-.175.425Zm0 0V10.55 18Z"/></svg>`
);

export const arrowUp = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 39.25q-.5 0-.825-.325-.325-.325-.325-.825v-25l-11.7 11.7q-.3.35-.775.35-.475 0-.825-.35T9.2 24q0-.45.35-.8L23 9.8q.2-.25.475-.35.275-.1.525-.1.3 0 .55.1.25.1.45.35l13.45 13.4q.35.35.35.8 0 .45-.35.8-.35.35-.8.35-.45 0-.8-.35l-11.7-11.7v25q0 .5-.325.825-.325.325-.825.325Z"/></svg>`
);
export const arrowDown = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 38.65q-.25 0-.525-.1T23 38.2L9.55 24.8q-.35-.35-.35-.8 0-.45.35-.8.35-.35.8-.35.45 0 .8.35l11.7 11.7v-25q0-.5.325-.825.325-.325.825-.325.5 0 .825.325.325.325.325.825v25l11.7-11.7q.35-.35.8-.35.45 0 .8.35.35.35.35.8 0 .45-.35.8L25 38.2q-.2.25-.45.35-.25.1-.55.1Z"/></svg>`
);
export const language = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M24 43q-3.95 0-7.425-1.5-3.475-1.5-6.05-4.075-2.575-2.575-4.05-6.05Q5 27.9 5 23.9q0-3.95 1.475-7.4t4.05-6.025Q13.1 7.9 16.575 6.45 20.05 5 24 5q3.95 0 7.425 1.45 3.475 1.45 6.05 4.025 2.575 2.575 4.05 6.025Q43 19.95 43 23.9q0 4-1.475 7.475-1.475 3.475-4.05 6.05Q34.9 40 31.425 41.5 27.95 43 24 43Zm0-2.15q1.85-2.05 3.05-4.4 1.2-2.35 2-5.5H19q.75 3.1 1.95 5.5t3.05 4.4Zm-3.15-.45q-1.45-1.8-2.525-4.225T16.65 30.95H8.7q1.9 3.9 4.8 6.125t7.35 3.325Zm6.35 0q3.95-.9 7.125-3.35t4.975-6.1h-7.9q-.75 2.8-1.8 5.2-1.05 2.4-2.4 4.25ZM7.95 28.7h8.25q-.15-1.4-.2-2.55-.05-1.15-.05-2.25 0-1.2.05-2.275t.25-2.325h-8.3q-.35 1.15-.525 2.225Q7.25 22.6 7.25 23.9q0 1.35.175 2.475T7.95 28.7Zm10.6 0H29.5q.2-1.55.275-2.6.075-1.05.075-2.2 0-1.05-.075-2.075Q29.7 20.8 29.5 19.3H18.55q-.2 1.5-.275 2.525Q18.2 22.85 18.2 23.9q0 1.15.075 2.2.075 1.05.275 2.6Zm13.2 0h8.3q.35-1.2.525-2.325.175-1.125.175-2.475 0-1.3-.175-2.375T40.05 19.3H31.8q.15 1.65.225 2.675Q32.1 23 32.1 23.9q0 1.15-.075 2.225-.075 1.075-.275 2.575Zm-.4-11.7h7.95q-1.75-3.8-4.875-6.225T27.15 7.55q1.45 1.9 2.475 4.25 1.025 2.35 1.725 5.2ZM19 17h10.1q-.65-2.8-1.975-5.3Q25.8 9.2 24 7.25q-1.7 1.55-2.875 3.85T19 17ZM8.7 17h7.95q.65-2.75 1.675-5.075T20.8 7.6q-4.15.8-7.225 3.175Q10.5 13.15 8.7 17Z"/></svg>`
);
export const questionMark = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M23.95 31.15q-.8 0-1.275-.55-.475-.55-.375-1.35.2-1.9 1.05-3.4.85-1.5 2.85-3.25 2-1.8 3.025-3.425 1.025-1.625 1.025-3.525 0-2.5-1.7-4.175-1.7-1.675-4.7-1.675-2.1 0-3.45.825t-2.2 2.125q-.4.6-1.025.8-.625.2-1.175-.05-.7-.3-.85-1.05-.15-.75.25-1.3 1.35-2.05 3.45-3.25 2.1-1.2 5-1.2 4.65 0 7.175 2.625 2.525 2.625 2.525 6.225 0 2.3-.975 4.2-.975 1.9-3.025 3.8t-2.825 3.125Q25.95 27.9 25.8 29.45q-.1.75-.625 1.225-.525.475-1.225.475ZM23.85 43q-1.1 0-1.875-.775-.775-.775-.775-1.875 0-1.05.8-1.825.8-.775 1.85-.775 1.1 0 1.875.775.775.775.775 1.825 0 1.1-.775 1.875Q24.95 43 23.85 43Z"/></svg>`
);
export const chat = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M13.65 27.55h12.5q.45 0 .775-.35t.325-.8q0-.5-.325-.825-.325-.325-.825-.325H13.6q-.45 0-.775.35t-.325.8q0 .5.325.825.325.325.825.325Zm0-6.4H34.4q.45 0 .775-.325T35.5 20q0-.45-.325-.775t-.825-.325H13.6q-.45 0-.775.325t-.325.825q0 .5.325.8.325.3.825.3Zm0-6.35H34.4q.45 0 .775-.325t.325-.825q0-.5-.325-.8-.325-.3-.825-.3H13.6q-.45 0-.775.325t-.325.825q0 .45.325.775t.825.325ZM5 38.6V7.9q0-1.15.85-2.025T7.9 5h32.2q1.15 0 2.025.875T43 7.9v24.2q0 1.15-.875 2.025T40.1 35h-28l-4.65 4.6q-.7.7-1.575.325Q5 39.55 5 38.6Zm2.25-2 3.85-3.85h29q.3 0 .475-.175t.175-.475V7.9q0-.3-.175-.475T40.1 7.25H7.9q-.3 0-.475.175T7.25 7.9Zm0-28.7V7.25 36.6Z"/></svg>`
);
export const chevroneLeft = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m26.95 34.9-9.9-9.9q-.25-.25-.35-.5-.1-.25-.1-.55 0-.3.1-.55.1-.25.35-.5L27 12.95q.45-.45 1.075-.45t1.075.45q.45.45.425 1.1-.025.65-.475 1.1l-8.8 8.8 8.85 8.85q.45.45.45 1.05 0 .6-.45 1.05-.45.45-1.1.45-.65 0-1.1-.45Z"/></svg>`
);
export const chevroneRight = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M17.7 34.9q-.4-.5-.425-1.1-.025-.6.425-1.05l8.8-8.8-8.85-8.85q-.4-.4-.375-1.075.025-.675.425-1.075.5-.5 1.075-.475.575.025 1.025.475l9.95 9.95q.25.25.35.5.1.25.1.55 0 .3-.1.55-.1.25-.35.5l-9.9 9.9q-.45.45-1.05.425-.6-.025-1.1-.425Z"/></svg>`
);
export const favoriteOutline = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m22.05 38.95-2.3-2.15q-6-5.5-10.375-10.5T5 15.95q0-4.1 2.775-6.875Q10.55 6.3 14.65 6.3q2.3 0 4.775 1.175Q21.9 8.65 24 11.8q2.25-3.15 4.625-4.325Q31 6.3 33.35 6.3q4.1 0 6.875 2.775Q43 11.85 43 15.95q0 5.4-4.475 10.425T28.25 36.8l-2.3 2.1q-.85.75-1.95.75t-1.95-.7Z"/></svg>`
);
export const homeOutline = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M11 39h7.5V26.5h11V39H37V19.5L24 9.75 11 19.5Zm0 3q-1.25 0-2.125-.875T8 39V19.5q0-.7.325-1.35.325-.65.875-1.05l13-9.75q.4-.3.85-.45.45-.15.95-.15.5 0 .95.15.45.15.85.45l13 9.75q.55.4.875 1.05.325.65.325 1.35V39q0 1.25-.875 2.125T37 42H26.5V29.5h-5V42Zm13-17.65Z"/></svg>`
);
export const scheduleOutline = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M594.695 744.739q10.472 11.261 25.693 10.978 15.221-.282 26.867-11.929 12.31-11.745 12.528-27.571.217-15.826-11.479-26.522L519.218 560.363V400.479q0-14.827-10.254-25.022-10.253-10.196-25.845-10.196-15.592 0-25.964 10.288-10.373 10.287-10.373 25.93V574.87q0 7.425 2.848 14.417 2.848 6.993 7.979 13.365l137.086 142.087ZM480.078 990.131q-85.469 0-160.943-32.555-75.473-32.555-131.802-88.863-56.33-56.308-88.897-131.753-32.567-75.444-32.567-160.898 0-85.453 32.678-160.99 32.677-75.536 88.833-131.692 56.156-56.156 131.639-89.116T480 161.304q85.498 0 160.981 32.96 75.483 32.96 131.639 89.116 56.156 56.156 89.116 131.639T894.696 576q0 85.498-32.96 160.981-32.96 75.483-89.116 131.639-56.156 56.156-131.614 88.833-75.459 32.678-160.928 32.678ZM480 576Zm-.288 334.913q138.027 0 236.614-98.022 98.587-98.021 98.587-236.608t-98.512-236.892q-98.512-98.304-236.396-98.304-138.309 0-236.614 98.235Q145.087 437.556 145.087 576q0 138.87 98.299 236.891 98.3 98.022 236.326 98.022Z"/></svg>`
);
export const homeWork = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M697 434.539h56.154v-56.154H697v56.154Zm0 167.922h56.154v-56.153H697v56.153Zm0 167.308h56.154v-56.154H697v56.154Zm-65.616 146.23v-45.384h223.231v-589.23H456.922v112.769l-45.383-31.614v-80.77q0-18.777 13.437-32.273 13.437-13.496 31.946-13.496h397.693q18.509 0 31.946 13.438 13.438 13.437 13.438 31.946v589.23q0 18.509-13.438 31.946-13.437 13.438-31.946 13.438H631.384Zm0-363.922ZM60.001 887.152V583.154q0-13.615 6.116-26.538 6.115-12.923 17.73-20.538l195.999-139.846q15.192-10.615 33.635-10.615 18.442 0 33.673 10.615l194.999 139.846q11.615 7.615 17.731 20.538 6.115 12.923 6.115 26.538v303.998q0 12.26-8.293 20.553-8.294 8.294-20.553 8.294H368.231V725.384H258.385v190.615H88.848q-12.26 0-20.553-8.294Q60 899.412 60 887.152Zm45.384-16.537h107.616V680h200.614v190.615h107v-294L313.308 429.077 105.385 577.141v293.474Zm308.23 0V680H213.001v190.615V680h200.614v190.615Z"/></svg>`
);

/** NEW */
export const navigateSmall = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M20.5 3L2 9.5L10.5 13L14 21L20.5 3Z" stroke="#141518" stroke-width="2" stroke-linejoin="bevel"/></svg>`
);
export const filterSmall = econify(
  `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none"><path d="M26 9H4M22 15H8M12.5 21H17" stroke="black" stroke-width="2"/></svg>`
);

