import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTextarea,
} from "@ionic/react";
import moment from "moment";
import { Calendar, DateObject } from "react-multi-date-picker";
import { Field, FieldArray, Formik } from "formik";
import {
  Location,
  LocationOption,
  newSubtagPayload,
  PlacesLog,
  SimpleDateOption,
  Subtag,
  SubtagResponse,
  Tag,
} from "../models/Event";
import useTranlatedMsg from "../hooks/useTranslatedMsg";
import { UserListed } from "../models/User";
// import { Category } from "../models/categories";
import { languages } from "../constants/languages";
import Autosuggest from "react-autosuggest";
import { load_coordinates } from "../cache/coordinates";
import { get_location_name, get_place_name } from "./Event";
// import CategoriesContext from "../contexts/CategoriesContext";
import { AddMembersModal } from "./Users";
import { find_users } from "../resources/users";
import { ImageCropper } from "./Cropper";
import { takePhotoWithAction } from "../helpers/camera";
import { get_event_photo_url } from "../helpers/event";
import { ImagePreview } from "./Images";
import { ModalHeader } from "./Headers";
import { TitleSuggestion } from "../models/Suggestions";
import { useStorage } from "../hooks";
import type { AxiosError, AxiosResponse } from "axios";
import { TITLE_LENGTH_LIMIT } from "../constants/events";
import { useMutation, useQuery } from "react-query";
import useAxios from "../hooks/useAxios";
import { filteredSortedTags } from "./Filters";
import * as Yup from "yup";

import {
  calendarMonthFill,
  photoCameraFill,
  barChartFill,
  groupFill,
  lockFill,
  mapFill,
} from "../icons/iconsFill";
import {
  payments,
  close,
  translate,
  earth,
  apps,
  tag,
  scheduleOutline,
  edit,
} from "../icons/iconsOutline";
import { AddEventProps } from "./AddEvent";
import { EditEventProps } from "./EditEvent";
import "react-multi-date-picker/styles/backgrounds/bg-gray.css";
import "./CUEvent.scss";
import currencies from "../constants/currencies";
import { useTranslation } from "react-i18next";
import { useTranslatedGPSModule } from "./Settings";

const FoundPlacesList: React.FC<{
  setShowModal: CallableFunction;
  props: AddEventProps | EditEventProps;
  foundPlaces: google.maps.places.PlaceResult[];
  onChange: CallableFunction;
  //setPlaceResult: React.Dispatch<React.SetStateAction<Location | undefined>>;
}> = ({ setShowModal, props, foundPlaces, onChange }) => {
  const { t } = useTranslation();
  if (foundPlaces.length === 0)
    return (
      <IonItem className="no_results">{t("cu_event.no_location")}</IonItem>
    );
  else
    return (
      <>
        {foundPlaces.map((el, _) => {
          return (
            <IonItem
              onClick={() => {
                if (el.place_id && el.formatted_address) {
                  //props.values.eventLocation = el.place_id;
                  //props.values.eventLocation__name = el.formatted_address;
                  //setPlaceResult({
                  onChange({
                    id: el.place_id,
                    name: el.name,
                    formattedAddress: el.formatted_address,
                    lat: el.geometry?.location?.lat(),
                    lng: el.geometry?.location?.lng(),
                  });
                  //props.validateField("eventLocation");
                  setShowModal(false);
                  console.log(el.place_id);
                }
              }}
              key={el.place_id}
            >
              {get_place_name(el, false)}
            </IonItem>
          );
        })}
      </>
    );
};

const PlacesList: React.FC<{
  setShowModal: CallableFunction;
  props: AddEventProps | EditEventProps;
  foundPlaces: Location[];
  //setPlaceResult: React.Dispatch<React.SetStateAction<Location | undefined>>;
  onChange: CallableFunction;
}> = ({ setShowModal, props, foundPlaces, onChange }) => {
  return (
    <>
      {foundPlaces.map((el, _) => {
        return (
          <IonItem
            onClick={() => {
              if (el.id && el.name) {
                //props.values.eventLocation = el.id;
                //props.values.eventLocation__name = el.formatted_address;
                //setPlaceResult(el);
                onChange({
                  id: el.id,
                  name: el.name,
                  formattedAddress: el.formattedAddress,
                  lat: el.lat,
                  lng: el.lng,
                });
                //props.validateField("eventLocation");
                setShowModal(false);
                console.log(el.id);
              }
            }}
            key={el.id}
          >
            {get_location_name(el, false)}
          </IonItem>
        );
      })}
    </>
  );
};

const SelectLocation: React.FC<{
  showModal: boolean;
  setShowModal: CallableFunction;
  props: AddEventProps | EditEventProps;
  map?: google.maps.Map | undefined;
  //setPlaceResult: React.Dispatch<React.SetStateAction<Location | undefined>>;
  onChange: CallableFunction;
}> = ({ showModal, setShowModal, props, map, onChange }) => {
  /*const [selectedLocation, setSelectedLocation] = useState<number>(
      props.values.eventLocation
    );*/
  const search_lock = useRef<number | undefined>(undefined);
  const [searching, setSearching] = useState<boolean>(false);
  const search_log = useRef<PlacesLog>({});
  const last_search = useRef<string>("");
  const [placesInstance, setPlacesInstance] = useState<
    google.maps.places.PlacesService | undefined
  >(undefined);
  const [localMap, setLocalMap] = useState<google.maps.Map | undefined>(
    undefined
  );

  /*var found_places:Array<any> = [];*/
  const [foundPlaces, setFoundPlaces] = useState<
    google.maps.places.PlaceResult[]
  >([]);
  const [eventLocationSearch, setEventLocationSearch] = useState("");

  const { gpsCoordinates } = useStorage();
  const { axios } = useAxios();
  const { t } = useTranslation();

  const { data: places } = useQuery(
    ["location_places"],
    () => axios.get<Location[]>("/places"),
    { select: (res) => res.data, refetchOnWindowFocus: false }
  );

  if (localMap === undefined) {
    if (map) setLocalMap(map);
    else {
      map = new google.maps.Map(document.createElement("div"), {
        center: {
          lat: load_coordinates().lat,
          lng: load_coordinates().lng,
        },
        zoom: 15,
        disableDefaultUI: true,
        clickableIcons: false,
        keyboardShortcuts: false,
      });
      setLocalMap(map);
    }
  }

  if (localMap && placesInstance === undefined) {
    setPlacesInstance(new google.maps.places.PlacesService(localMap));
  }

  function load_places_from_log(query: string) {
    if (query.length < 4) {
      setFoundPlaces([]);
      if (search_lock.current === undefined) setSearching(false);
      return true;
    }

    if (query in search_log.current) {
      setFoundPlaces(search_log.current[query]);
      if (search_lock.current === undefined) setSearching(false);
      return true;
    }

    return false;
  }

  function find_places(query: string) {
    search_lock.current = undefined;
    //setSearching(false);
    if (placesInstance === undefined) {
      setFoundPlaces([]);
      setSearching(false);
      return;
    }

    if (load_places_from_log(query)) return;

    placesInstance.textSearch(
      { query: query },
      function (results, status, pagination) {
        console.log("search results", results);
        console.log("search status", status);
        console.log("search pagination", pagination);
        //search_lock.current = undefined;
        if (results) search_log.current[query] = results;

        if (last_search.current === query) {
          if (results) setFoundPlaces(results);
        }
        //console.log("setting done 3", new Date());
        if (search_lock.current === undefined) setSearching(false);
      }
    );
  }

  useEffect(() => {
    last_search.current = eventLocationSearch;

    if (!load_places_from_log(eventLocationSearch)) {
      //console.log("setting timeout", new Date());
      setSearching(true);
      if (search_lock.current !== undefined) {
        clearTimeout(search_lock.current);
      }
      search_lock.current = window.setTimeout(
        find_places,
        1000,
        eventLocationSearch
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventLocationSearch]);

  function reset_form() {
    setShowModal(false);
    //setSelectedLocation(props.values.eventLocation);
  }

  return (
    <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
      <ModalHeader title={t("cu_event.select_location")} onClick={reset_form} />

      <IonContent>
        <IonSearchbar
          placeholder={t("cu_event.search_location")}
          onIonInput={(e) => setEventLocationSearch(e.target.value!)}
          clearIcon={close}
        />

        <IonList className="default_list" lines="none">
          {gpsCoordinates && (
            <IonItem
              className="use_special"
              onClick={() => {
                //props.values.eventLocation = eventLocationSearch;
                //props.values.eventLocation__name = el.formatted_address;

                onChange({
                  id: `${gpsCoordinates.lat.toFixed(
                    6
                  )}, ${gpsCoordinates.lng.toFixed(5)}`,
                  name: "Custom location",
                  formattedAddress: `${gpsCoordinates.lat.toFixed(
                    6
                  )}, ${gpsCoordinates.lng.toFixed(5)}`,
                  lat: gpsCoordinates.lat,
                  lng: gpsCoordinates.lng,
                });
                /*setPlaceResult({
                  id: `${gpsCoordinates.lat.toFixed(
                    6
                  )}, ${gpsCoordinates.lng.toFixed(5)}`,
                  name: "Custom location",
                  formattedAddress: `${gpsCoordinates.lat.toFixed(
                    6
                  )}, ${gpsCoordinates.lng.toFixed(5)}`,
                  lat: gpsCoordinates.lat,
                  lng: gpsCoordinates.lng,
                });*/
                //props.validateField("eventLocation");
                setShowModal(false);
                console.log("using custom location", eventLocationSearch);
              }}
            >
              <p>
                {t("cu_event.use_curr_gps")}&nbsp;
                <em>
                  {t("cu_event.custom_loc")} ({gpsCoordinates.lat.toFixed(5)},{" "}
                  {gpsCoordinates.lng.toFixed(5)})
                </em>
              </p>
            </IonItem>
          )}

          {eventLocationSearch && (
            <IonItem
              className="use_special"
              onClick={() => {
                //props.values.eventLocation = eventLocationSearch;
                //props.values.eventLocation__name = el.formatted_address;
                onChange({
                  id: eventLocationSearch,
                  name: eventLocationSearch,
                });
                /*setPlaceResult({
                  id: eventLocationSearch,
                  name: eventLocationSearch,
                });*/
                //props.validateField("eventLocation");
                setShowModal(false);
                console.log("using place", eventLocationSearch);
              }}
            >
              <p>
                {t("cu_event.use")}&nbsp;<em>{eventLocationSearch}</em>&nbsp;{" "}
                {t("cu_event.as_loc")}
              </p>
            </IonItem>
          )}

          <div className="results_section">{t("cu_event.found_loc")}:</div>

          {searching ? (
            <div className="spinner">
              <IonSpinner color="primary" name="crescent" />
            </div>
          ) : (
            <FoundPlacesList
              setShowModal={setShowModal}
              props={props}
              foundPlaces={foundPlaces}
              onChange={onChange}
            />
          )}
        </IonList>

        {places ? (
          <>
            <div className="results_section">{t("cu_event.rec_found_loc")}</div>
            <IonList className="default_list" lines="none">
              <PlacesList
                setShowModal={setShowModal}
                props={props}
                foundPlaces={places}
                onChange={onChange}
              />
            </IonList>
          </>
        ) : null}
      </IonContent>

      {/* <IonButton
        className="modal_big_button"
        disabled={!eventLocationSearch.length}
        onClick={() => {
          //props.values.eventLocation = selectedLocation;
          setShowModal(false);
        }}
      >
        Done
      </IonButton> */}
    </IonModal>
  );
};

// TODO: rework from checkboxes to normal texts like location selection
const SelectCategory: React.FC<{
  showModal: boolean;
  setShowModal: CallableFunction;
  props: AddEventProps | EditEventProps;
  tags: Tag[] | undefined;
}> = ({ showModal, setShowModal, props, tags }) => {
  const { t } = useTranslation();
  return (
    <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
      <ModalHeader
        title={t("cu_event.sel_cat")}
        onClick={() => setShowModal(false)}
      />

      <IonContent>
        <IonList className="default_list" lines="none">
          {tags?.map((t, _) => {
            return (
              <IonItem key={t.slug}>
                <IonLabel
                  onClick={() => {
                    props.setFieldValue("eventTags", []);
                    props.setFieldValue("eventCategory", t.slug);
                    setShowModal(false);
                  }}
                >
                  {t.name}
                </IonLabel>
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>

      <IonButton
        className="modal_big_button"
        onClick={() => {
          setShowModal(false);
        }}
      >
        {t("cu_event.close")}
      </IonButton>
    </IonModal>
  );
};
const SelectTag: React.FC<{
  showModal: boolean;
  setShowModal: CallableFunction;
  props: AddEventProps | EditEventProps;
  tags: Tag[] | undefined;
  uniqueTags: Subtag[] | undefined;
}> = ({ showModal, setShowModal, props, uniqueTags }) => {
  const [selectedTags, setSelectedTags] = useState<string[]>(
    props.values.eventTags
  );
  const { t } = useTranslation();
  useEffect(() => {
    setSelectedTags([]);
  }, [props.values.eventCategory]);
  useEffect(() => {
    setSelectedTags(props.values.eventTags);
  }, [props.values.eventTags]);

  return (
    <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
      <ModalHeader
        title={t("cu_event.sel_tags")}
        onClick={() => {
          setShowModal(false);
          if (props.values.eventTags.length === 0) setSelectedTags([]);
          if (selectedTags.length === 0) props.setFieldValue("eventTags", []);
          setSelectedTags(props.values.eventTags);
        }}
      />

      <IonContent>
        <IonRow>
          <IonCol>
            {uniqueTags?.map((t) => {
              return (
                <IonButton
                  key={t.slug}
                  size="small"
                  style={{ marginRight: "5px" }}
                  fill={selectedTags.includes(t.slug) ? "solid" : "outline"}
                  onClick={() => {
                    selectedTags.includes(t.slug)
                      ? setSelectedTags(
                          selectedTags.filter((tag) => tag !== t.slug)
                        )
                      : setSelectedTags([...selectedTags, t.slug]);
                  }}
                >
                  {t.name}
                </IonButton>
              );
            })}
          </IonCol>
        </IonRow>
        <AddCustomTag
          props={props}
          allTags={uniqueTags}
          setSelectedTags={setSelectedTags}
          selectedTags={selectedTags}
        />
      </IonContent>

      <IonButton
        className="modal_big_button"
        onClick={() => {
          props.values.eventTags = selectedTags;
          setShowModal(false);
        }}
      >
        {t("cu_event.apply")}
      </IonButton>
    </IonModal>
  );
};

const SelectLanguage: React.FC<{
  showModal: boolean;
  setShowModal: CallableFunction;
  props: any;
}> = ({ showModal, setShowModal, props }) => {
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>(
    props.values.eventLanguages
  );
  const { t } = useTranslation();

  function reset_form() {
    setShowModal(false);
    setSelectedLanguages(props.values.eventLanguages);
  }

  return (
    <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
      <ModalHeader title={t("cu_event.sel_lang")} onClick={reset_form} />

      <IonContent>
        <IonList className="default_list" lines="none">
          {languages?.map((l, _) => {
            return (
              <IonItem key={l.id}>
                <IonCheckbox
                  checked={selectedLanguages.includes(l.id)}
                  value={l.id}
                  onIonChange={() => {
                    selectedLanguages.includes(l.id)
                      ? setSelectedLanguages(
                          selectedLanguages.filter((v) => v !== l.id)
                        )
                      : setSelectedLanguages([...selectedLanguages, l.id]);
                  }}
                >
                  {l.name}
                </IonCheckbox>
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>

      <IonButton
        className="modal_big_button"
        onClick={() => {
          props.values.eventLanguages = selectedLanguages;
          setShowModal(false);
        }}
      >
        {t("cu_event.done")}
      </IonButton>
    </IonModal>
  );
};

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function getSuggestions(suggestions: TitleSuggestion[], value: string) {
  const escapedValue = escapeRegexCharacters(value.trim());

  const regex = new RegExp("^" + escapedValue, "i");

  return suggestions.filter(
    (suggestion) =>
      (regex.test(suggestion.title) && suggestion.title !== escapedValue) ||
      escapedValue.startsWith("Meeting of")
  );
}

function getSuggestionValue(suggestion: TitleSuggestion) {
  return suggestion.title;
}

function renderSuggestion(suggestion: TitleSuggestion) {
  return <span>{suggestion.title}</span>;
}

const TitleSuggestions: React.FC<{
  props: AddEventProps | EditEventProps;
}> = ({ props }) => {
  const { setMessage } = useStorage();
  const [suggestions, setSuggestions] = useState<TitleSuggestion[]>([]);
  const { axios } = useAxios();

  const { data: allSuggestions } = useQuery(
    ["allSuggestions"],
    () => axios.get<TitleSuggestion[]>("/suggestions/titles"),
    {
      select: (res) => res.data,
      onError: ({ response }: AxiosError) => {
        // TODO: But why?
        setMessage("danger", undefined, response?.statusText);
      },
      refetchOnWindowFocus: false,
    }
  );

  let onChange = (
    event: any,
    {
      newValue,
    }: {
      newValue: string;
    }
  ) => {
    // is set value needed?
    //setValue(newValue);
    //props.values.eventTitle = newValue;
    props.setFieldValue("eventTitle", newValue);
    //props.handleChange(event);
  };

  let onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    if (allSuggestions) {
      setSuggestions(getSuggestions(allSuggestions, value));
    }
  };

  let onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    name: "eventTitle",
    placeholder: "Title",
    value: props.values.eventTitle,
    onChange: onChange,
    className: "native-input sc-ion-input-ios sc-ion-input-ios-h",
    autoCapitalize: "sentences",
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      shouldRenderSuggestions={() => true}
    />
  );
};

const EventTitleFormRow: React.FC<{
  props: AddEventProps | EditEventProps;
  imagePreview: any | undefined;
  setImagePreview: CallableFunction;
  oldPhoto?: string;
}> = ({ props, imagePreview, setImagePreview, oldPhoto }) => {
  const [imageToCrop, setImageToCrop] = useState<string | undefined>(undefined);
  const { setMessage } = useStorage();
  const { access_denied, failed_photo } = useTranlatedMsg();
  return (
    <>
      {(imagePreview ||
        oldPhoto ||
        (props.values.eventVisibility === "public" &&
          props.values.eventCategory)) && (
        <IonRow>
          <IonCol
            className="full_size"
            onClick={() =>
              takePhotoWithAction(
                setImageToCrop,
                setMessage,
                access_denied,
                failed_photo
              )
            }
          >
            {imagePreview ? (
              <ImagePreview blob={imagePreview} />
            ) : (
              <img
                src={get_event_photo_url(oldPhoto, props.values.eventCategory)}
                alt="Old event cover"
              />
            )}
          </IonCol>
        </IonRow>
      )}
      <IonRow>
        <IonCol
          className={`input-old title ${
            (!props.touched.eventTitle && !props.values.eventTitle) ||
            props.errors.eventTitle
              ? "mandatory_col"
              : ""
          }`}
        >
          <TitleSuggestions props={props} />
          {(props.touched.eventTitle && props.errors.eventTitle) ||
          props.values.eventTitle.length > TITLE_LENGTH_LIMIT ? (
            <div className="error">{props.errors.eventTitle}</div>
          ) : null}

          <IonIcon
            className="photo_icon"
            icon={photoCameraFill}
            color={imagePreview ? "medium" : "primary"}
            onClick={() =>
              takePhotoWithAction(
                setImageToCrop,
                setMessage,
                access_denied,
                failed_photo
              )
            }
          />
        </IonCol>

        <ImageCropper
          imageToCrop={imageToCrop}
          aspect={1 / 0.6}
          maxDimensions={500}
          onResizeDone={(blob: any) => {
            setImagePreview(blob);
            setImageToCrop(undefined);
          }}
          onResizeCancel={() => {
            setImageToCrop(undefined);
          }}
        />
      </IonRow>
    </>
  );
};

const EventDescriptionFormRow: React.FC<{
  props: AddEventProps | EditEventProps;
}> = ({ props }) => {
  const { t } = useTranslation();
  return (
    <IonRow className="spacer">
      <IonCol>
        <IonTextarea
          name="eventDescription"
          className="input-old"
          auto-grow="true"
          rows={1}
          onIonInput={(e: any) => {
            props.handleChange(e);
          }}
          onIonBlur={(e: any) => {
            props.handleBlur(e);
          }}
          value={props.values.eventDescription}
          placeholder={t("cu_event.desc")}
          autocapitalize="true"
          aria-label="eventDescription"
        />
        {props.touched.eventDescription && props.errors.eventDescription ? (
          <div className="error">{props.errors.eventDescription}</div>
        ) : null}
      </IonCol>
    </IonRow>
  );
};
const AddCustomTag: React.FC<{
  props: AddEventProps | EditEventProps;
  allTags: Subtag[] | undefined;
  setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>;
  selectedTags: string[];
}> = ({ props, allTags, setSelectedTags, selectedTags }) => {
  const { axios } = useAxios();
  const { setMessage, refetchTags } = useStorage();
  const { t } = useTranslation();
  const { went_wrong } = useTranlatedMsg();
  const validationRules = Yup.object({
    tag: Yup.string()
      .matches(/^[A-Za-z\s0-9]*$/, "Invalid tag format")
      .test("is-unique", "Tag already exists", function (value): boolean {
        const duplicates = allTags?.filter((t) => t.name === this.parent.tag);
        return duplicates?.length === 0;
      }),
  });

  const { mutate: addSubtag } = useMutation<
    AxiosResponse<SubtagResponse>,
    AxiosError,
    newSubtagPayload
  >((newSubtagPayload) => axios.post("/tags", newSubtagPayload), {
    onSuccess: (data) => {
      refetchTags();
      setSelectedTags([...selectedTags, data.data?.slug]);
    },
    onError: (err) => setMessage("danger", went_wrong, err),
  });

  return (
    <Formik
      validationSchema={validationRules}
      initialValues={{ tag: "" }}
      onSubmit={(values, actions) => {
        if (props.values.eventCategory) {
          addSubtag({
            name: values.tag,
            parentSlug: props.values.eventCategory,
          });
          actions.resetForm();
        }
      }}
    >
      {({
        dirty,
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <IonGrid className="big_form">
          <IonRow>
            <IonCol>
              <IonInput
                aria-label="customTag"
                name="tag"
                onIonInput={handleChange}
                onIonBlur={handleBlur}
                value={values.tag}
                autocapitalize="true"
                placeholder="Add new tag"
              />
              {touched.tag && errors.tag ? (
                <div className="error">{errors.tag}</div>
              ) : null}
            </IonCol>
            <IonButton
              fill="clear"
              className="button-primary-light"
              disabled={
                isSubmitting || !dirty || !values.tag || errors.tag
                  ? true
                  : false
              }
              onClick={() => {
                handleSubmit();
              }}
            >
              {t("cu_event.add")}
            </IonButton>
          </IonRow>
        </IonGrid>
      )}
    </Formik>
  );
};
const EventLocationFormRows: React.FC<{
  props: AddEventProps | EditEventProps;
  placeResults: (Location | undefined)[];
  setPlaceResults: React.Dispatch<
    React.SetStateAction<(Location | undefined)[]>
  >;
  map?: google.maps.Map | undefined;
  prefetchedLocationOptions: LocationOption[] | undefined;
}> = ({
  props,
  placeResults,
  setPlaceResults,
  map,
  prefetchedLocationOptions,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [locationIndex, setLocationIndex] = useState<number>(0);
  const { t } = useTranslation();

  const removeOption = (i: number) => {
    props.values.eventLocationOptions.splice(i, 1);
    props.setFieldValue(
      "eventLocationOptions",
      props.values.eventLocationOptions
    );
    placeResults.splice(i, 1);
    setPlaceResults(placeResults);
  };

  useEffect(() => {
    if (props.values.eventVisibility !== "public") return;

    while (props.values.eventLocationOptions.length > 1) {
      removeOption(props.values.eventLocationOptions.length - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.eventVisibility]);

  const [votingReopened, setVotingReopened] = useState(false);

  useEffect(() => {
    setLocationIndex(props.values.eventLocationOptions.length - 1);
  }, [props.values.eventLocationOptions]);

  return (
    <>
      <FieldArray
        name="eventLocationOptions"
        render={() => (
          <>
            {props.values.eventLocationOptions.map(
              (locationOption: string, i: number) => {
                let local_result = placeResults[i];
                return (
                  <Field name={`eventLocationOptions[${i}]`} key={i}>
                    {() => (
                      <IonRow
                        key={i + "_" + locationOption}
                        onClick={() => {
                          setLocationIndex(i);
                          setShowModal(true);
                        }}
                      >
                        <IonCol
                          className={
                            !placeResults[i] ? "label mandatory_col" : "label"
                          }
                        >
                          {i === 0 && (
                            <IonIcon className="label_icon" icon={mapFill} />
                          )}

                          {!placeResults[i] && (
                            <div className="input_placeholder">
                              {props.values.eventLocationOptions.length > 1
                                ? `${t("cu_event.loc_op")} ${i + 1}`
                                : t("cu_event.loc")}
                            </div>
                          )}

                          {local_result &&
                            get_location_name(local_result, false)}
                          <IonInput
                            style={{ display: "none" }}
                            value={props.values.eventLocationOptions[i]}
                            aria-label="hiddenValue"
                          />
                          {props.touched.eventLocationOptions &&
                          props.errors.eventLocationOptions &&
                          props.errors.eventLocationOptions[i] ? (
                            <div className="error">
                              {props.errors.eventLocationOptions[i]}
                            </div>
                          ) : null}
                        </IonCol>
                        {props.values.eventLocationOptions.length > 1 && (
                          <IonCol size="2">
                            <IonButton
                              size="small"
                              fill="clear"
                              color="primary"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                removeOption(i);
                              }}
                            >
                              <IonIcon icon={close} />
                            </IonButton>
                          </IonCol>
                        )}
                      </IonRow>
                    )}
                  </Field>
                );
              }
            )}
          </>
        )}
      />

      {/* TODO: it has a reasing that its here.. react is using it with insertBefore when location is changed.. dunno why.. I spent too much time on it now.. :/ */}
      <div></div>

      {props.values.eventVisibility === "private" && (
        <IonRow className="spacer">
          <IonCol className="right">
            {prefetchedLocationOptions?.length &&
            props.values.eventLocationOptions.length <= 1 &&
            !votingReopened ? (
              <IonButton
                size="small"
                fill="clear"
                className="button-primary-light button-icon"
                onClick={() => {
                  props.setFieldValue(
                    "eventLocationOptions",
                    prefetchedLocationOptions.map((location) => location.id)
                  );
                  setPlaceResults(
                    prefetchedLocationOptions.map((location) => {
                      return {
                        id: location.id,
                        name: location.name,
                        formattedAddress: location.formattedAddress,
                        lat: location.lat,
                        lng: location.lng,
                      };
                    })
                  );
                  setVotingReopened(true);
                }}
              >
                <IonIcon icon={barChartFill} /> {t("cu_event.reopen_vot")}
              </IonButton>
            ) : (
              <IonButton
                size="small"
                fill="clear"
                className="button-primary-light button-icon"
                onClick={() => {
                  props.setFieldValue("eventLocationOptions", [
                    ...props.values.eventLocationOptions,
                    "",
                  ]);
                  setPlaceResults([...placeResults, undefined]);
                  setShowModal(true);
                }}
              >
                <IonIcon icon={barChartFill} /> {t("cu_event.add_vot_loc")}
              </IonButton>
            )}
          </IonCol>
        </IonRow>
      )}

      <SelectLocation
        showModal={showModal}
        setShowModal={setShowModal}
        props={props}
        map={map}
        onChange={(place: Location) => {
          props.setFieldValue(
            `eventLocationOptions[${locationIndex}]`,
            place.id
          );
          // TODO: rewrite to splice
          setPlaceResults([
            ...placeResults.slice(0, locationIndex),
            place,
            ...placeResults.slice(locationIndex + 1, placeResults.length),
          ]);
          // TODO: is it needed here? field above was not set with "setFieldValue" before, so it probably needed to be called explicitly
          if ("eventLocationOptions" in props.errors && place.id) {
            return delete props.errors.eventLocationOptions;
          }
        }}
      />
    </>
  );
};

function update_date_in_datetime(old_datetime: Date, new_date: Date) {
  const eh = moment(old_datetime).hours();
  const em = moment(old_datetime).minutes();

  return moment(new_date).set({ h: eh, m: em }).format();
}

function update_time_in_datetime(
  old_datetime: Date,
  hour: number,
  minute: number
) {
  return moment(old_datetime).set({ h: hour, m: minute }).format();
}

// TODO: pass maybe just event date options
function datetime_options_to_datetime(values: FormValuesWithEventDatetimes) {
  return values.eventDateOptions.map((v) => {
    return new Date(v.start);
  });
}

function onChange_start_time(
  date: string,
  props: AddEventProps | EditEventProps
) {
  const orig_end_time = moment(props.values.eventEndDatetime);
  const orig_start_time = moment(props.values.eventStartDatetime);
  const orig_diff = moment
    .duration(orig_end_time.diff(orig_start_time))
    .asMinutes();
  const new_end_time = moment(date).add(orig_diff, "minutes");
  props.setFieldValue("eventEndDatetime", new_end_time.format());
  props.setFieldValue("eventStartDatetime", date);
  props.validateForm();
}

function onChange_end_time(
  date: string,
  props: AddEventProps | EditEventProps
) {
  props.setFieldTouched("eventEndDatetime");
  props.setFieldValue("eventEndDatetime", date);
}

const TimePicker: React.FC<{
  props: AddEventProps | EditEventProps;
  field: "eventStartDatetime" | "eventEndDatetime";
  onChange: (date: string) => void;
  multiDays?: boolean;
}> = ({ onChange, props, field, multiDays }) => {
  const datetime = useRef<HTMLIonDatetimeElement | null>(null);
  const { t } = useTranslation();

  const cancel = () => {
    datetime.current?.cancel(true);
  };

  const confirm = () => {
    datetime.current?.confirm();

    onChange(
      update_time_in_datetime(
        props.values[field],
        moment(datetime.current?.value, "HH:mm").get("hours"),
        moment(datetime.current?.value, "HH:mm").get("minutes")
      )
    );

    if (multiDays) {
      update_date_options_with(
        props.values,
        field === "eventStartDatetime" ? "start" : "end",
        moment(datetime.current?.value, "HH:mm").get("hours"),
        moment(datetime.current?.value, "HH:mm").get("minutes")
      );
      props.setFieldValue(
        field,
        update_time_in_datetime(
          props.values[field],
          moment(datetime.current?.value, "HH:mm").get("hours"),
          moment(datetime.current?.value, "HH:mm").get("minutes")
        )
      );
    }

    datetime.current?.confirm(true);
  };

  return (
    <>
      <IonDatetimeButton datetime={field} className="time_picker_main_btn" />
      <IonModal keepContentsMounted={true} id="time-picker-modal">
        <IonDatetime
          id={field}
          value={moment(props.values[field]).format("HH:mm")}
          ref={datetime}
          showDefaultTimeLabel={false}
          presentation="time"
          locale="en-GB"
          minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"
          className="time_picker"
        >
          <IonButtons slot="buttons" className="time_picker_btns">
            <IonButton className="time_picker_btn" onClick={() => cancel()}>
              {t("cu_event.close")}
            </IonButton>
            <IonButton className="time_picker_btn" onClick={() => confirm()}>
              {t("cu_event.done")}
            </IonButton>
          </IonButtons>
        </IonDatetime>
      </IonModal>
    </>
  );
};
const DatePicker: React.FC<{
  props: AddEventProps | EditEventProps;
  field: "eventStartDatetime" | "eventEndDatetime";
  onChange?: (date: string) => void;
  showModal: boolean;
  setShowModal: CallableFunction;
  setRerender?: React.Dispatch<React.SetStateAction<Number>>;
  minDate?: any;
}> = ({
  onChange,
  props,
  field,
  showModal,
  setShowModal,
  minDate,
  setRerender,
}) => {
  const { t } = useTranslation();
  const [calendarDate, setCalendarDate] = useState<Date>(new Date());
  useEffect(() => {
    setCalendarDate(new Date(props.values[field]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values[field]]);
  return (
    <IonModal
      keepContentsMounted={true}
      isOpen={showModal}
      onDidDismiss={() => setShowModal(false)}
      id="calendar-modal"
    >
      <Calendar
        onChange={(date) => {
          if (date && date instanceof DateObject && onChange) {
            onChange(
              update_date_in_datetime(props.values[field], date.toDate())
            );
            setShowModal(false);
          } else if (date instanceof Array && setRerender) {
            sync_date_options(
              props.values,
              date.map((d) => moment(d.toDate()))
            );
            setRerender(Math.random());
          }
        }}
        weekStartDayIndex={1}
        value={
          minDate ? datetime_options_to_datetime(props.values) : calendarDate
        }
        minDate={minDate ? minDate : new Date()}
        disableYearPicker={true}
        disableMonthPicker={true}
        shadow={false}
        mapDays={({ date }) => {
          if (minDate) {
            // if there some date options before today, make them possible to uncheck
            if (
              moment(minDate).format("YYYY/MM/DD") ===
              moment().format("YYYY/MM/DD")
            )
              return;
            if (
              // apply code only if the date >= minDate and < today
              date.format() >= moment(minDate).format("YYYY/MM/DD") &&
              date.format() < moment().format("YYYY/MM/DD")
            ) {
              const pastDates = props.initialValues.eventDateOptions
                .map((option: SimpleDateOption) =>
                  moment(option.start).format("YYYY/MM/DD")
                )
                .filter(
                  (option: string) => option < moment().format("YYYY/MM/DD")
                );
              if (!pastDates.includes(date.format())) {
                return {
                  disabled: true,
                };
              }
            }
          }
        }}
        className="calendar-datepicker"
      />
      {minDate && (
        <IonButton
          size="small"
          fill="clear"
          className="button-primary-light button-icon"
          onClick={() => {
            setShowModal(false);
          }}
        >
          {t("cu_event.fin_edit_dates")}
        </IonButton>
      )}
    </IonModal>
  );
};

const DatetimeFormRow: React.FC<{
  props: AddEventProps | EditEventProps;
  field: "eventStartDatetime" | "eventEndDatetime";
  label: string;
  icon: boolean;
  onChange: (date: string) => void;
}> = ({ props, field, label, icon, onChange }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <IonRow className="row_no_line">
        <IonCol className="label" size="5">
          {icon && <IonIcon className="label_icon" icon={calendarMonthFill} />}
          <IonLabel>{label}</IonLabel>
        </IonCol>
        <IonCol className="label">
          <div
            onClick={() => {
              setShowModal(true);
            }}
          >
            {moment(props.values[field]).format("ddd D MMM YYYY")}
          </div>
        </IonCol>

        <IonCol size="2" className="label nowrap">
          <TimePicker field={field} props={props} onChange={onChange} />
        </IonCol>
      </IonRow>
      <DatePicker
        props={props}
        field={field}
        onChange={onChange}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <IonRow className="row_light">
        <IonCol>
          {props.touched[field] && props.errors[field] ? (
            <div className="error">
              <>{props.errors[field]}</>
            </div>
          ) : null}
        </IonCol>
      </IonRow>
    </>
  );
};

type FormValuesWithEventDatetimes = {
  eventDateOptions: SimpleDateOption[];
  eventStartDatetime: Date;
  eventEndDatetime: Date;
};

function add_date_option(values: FormValuesWithEventDatetimes) {
  if (values.eventDateOptions.length <= 1) {
    values.eventDateOptions = [];

    const eh = moment(values.eventEndDatetime).hours();
    const em = moment(values.eventEndDatetime).minutes();

    values.eventDateOptions.push({
      start: values.eventStartDatetime,
      end: moment(values.eventStartDatetime).set({ h: eh, m: em }).toDate(),
    });
  }

  values.eventDateOptions.push({
    start: moment(
      values.eventDateOptions[values.eventDateOptions.length - 1].start
    )
      .add(1, "day")
      .toDate(),
    end: moment(values.eventDateOptions[values.eventDateOptions.length - 1].end)
      .add(1, "day")
      .toDate(),
  });
}

function sync_date_options(
  values: FormValuesWithEventDatetimes,
  dates: moment.Moment[]
) {
  const eh = moment(values.eventDateOptions[0].start).hours();
  const em = moment(values.eventDateOptions[0].start).minutes();
  const diff = moment
    .duration(
      moment(values.eventDateOptions[0].end).diff(
        moment(values.eventDateOptions[0].start)
      )
    )
    .asMinutes();

  // new vals
  let new_vals: any = {};
  for (let i = 0; i < dates.length; i++) {
    new_vals[dates[i].format("YYYY-MM-DD")] = dates[i];
  }

  // old vals
  let old_vals: any = {};
  for (let i = 0; i < values.eventDateOptions.length; i++) {
    old_vals[moment(values.eventDateOptions[i].start).format("YYYY-MM-DD")] =
      values.eventDateOptions[i];
  }

  // add new
  let new_keys = Object.keys(new_vals).filter(
    (x) => !Object.keys(old_vals).includes(x)
  );

  for (let i = 0; i < new_keys.length; i++) {
    values.eventDateOptions.push({
      start: new_vals[new_keys[i]].set({ h: eh, m: em }).format(),
      end: new_vals[new_keys[i]]
        .set({ h: eh, m: em })
        .add(diff, "minutes")
        .format(),
    });
  }

  // remove old
  let old_keys = Object.keys(old_vals).filter(
    (x) => !Object.keys(new_vals).includes(x)
  );

  values.eventDateOptions = values.eventDateOptions.filter(
    (date) => !old_keys.includes(moment(date.start).format("YYYY-MM-DD"))
  );

  values.eventStartDatetime = values.eventDateOptions[0].start;
  values.eventEndDatetime = values.eventDateOptions[0].end;

  if (values.eventDateOptions.length === 1) values.eventDateOptions = [];
}

function update_date_options_with(
  values: FormValuesWithEventDatetimes,
  key: "start" | "end",
  hour: number,
  minute: number
) {
  for (let i = 0; i < values.eventDateOptions.length; i++) {
    values.eventDateOptions[i][key] = moment(values.eventDateOptions[i][key])
      .set({ h: hour, m: minute })
      .toDate();
  }
}

const EventDatetimeFormRows: React.FC<{
  props: AddEventProps | EditEventProps;
  prefetchedOptions?: SimpleDateOption[];
  setPrefetchedOptions?: React.Dispatch<
    React.SetStateAction<SimpleDateOption[] | undefined>
  >;
}> = ({ props, prefetchedOptions, setPrefetchedOptions }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setRerender] = useState<Number>(Math.random());
  const [showModal, setShowModal] = useState<boolean>(false);
  const [votingReopened, setVotingReopened] = useState(false);
  const { t } = useTranslation();

  const minDate = useMemo(() => {
    if (!props.initialValues.eventDateOptions.length) return new Date();

    const sorted = props.initialValues.eventDateOptions.sort(
      (a: SimpleDateOption, b: SimpleDateOption) =>
        new Date(a.start).getTime() - new Date(b.start).getTime()
    );

    return new Date(sorted[0].start).getTime() < Date.now()
      ? sorted[0].start
      : new Date();
  }, [props.initialValues.eventDateOptions]);

  const removeOption = (i: number) => {
    props.values.eventDateOptions.splice(i, 1);
    props.setFieldValue("eventDateOptions", props.values.eventDateOptions);
  };

  useEffect(() => {
    if (props.values.eventVisibility !== "public") return;

    while (props.values.eventDateOptions.length > 1) {
      removeOption(props.values.eventDateOptions.length - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.eventVisibility]);

  return props.values.eventDateOptions.length <= 1 ? (
    <>
      <DatetimeFormRow
        props={props}
        field={"eventStartDatetime"}
        label={t("new_event.starts")}
        icon={true}
        onChange={(date) => onChange_start_time(date, props)}
      />

      <DatetimeFormRow
        props={props}
        field={"eventEndDatetime"}
        label={t("new_event.ends")}
        icon={false}
        onChange={(date) => onChange_end_time(date, props)}
      />
      {props.values.eventVisibility === "private" && (
        <IonRow>
          <IonCol className="right">
            {prefetchedOptions &&
            prefetchedOptions.length > 1 &&
            !votingReopened ? (
              <IonButton
                size="small"
                fill="clear"
                className="button-primary-light button-icon"
                onClick={() => {
                  setVotingReopened(true);
                  props.setFieldValue("eventDateOptions", prefetchedOptions);
                }}
              >
                <IonIcon icon={barChartFill} /> {t("cu_event.reopen_vot")}
              </IonButton>
            ) : (
              <IonButton
                size="small"
                fill="clear"
                className="button-primary-light button-icon"
                onClick={() => {
                  setShowModal(true);
                  // this is for editing with closed voting
                  if (
                    prefetchedOptions &&
                    prefetchedOptions.length &&
                    setPrefetchedOptions
                  ) {
                    props.values.eventDateOptions = prefetchedOptions;
                    setPrefetchedOptions(undefined);
                  } else {
                    add_date_option(props.values);
                  }
                  setRerender(Math.random());
                }}
              >
                <IonIcon icon={barChartFill} /> {t("cu_event.add_vot_loc")}
              </IonButton>
            )}
          </IonCol>
        </IonRow>
      )}
    </>
  ) : (
    <>
      <IonRow>
        <IonCol className="label" size="5">
          <IonIcon className="label_icon" icon={scheduleOutline} />
          <IonLabel>{t("new_event.time")}</IonLabel>
        </IonCol>
        <IonCol size="7" className="label time-picker-btns-box">
          <div>
            <div className="label-time date-options-time">
              <TimePicker
                field="eventStartDatetime"
                props={props}
                onChange={(date) => onChange_start_time(date, props)}
                multiDays={true}
              />
            </div>
            {props.touched.eventStartDatetime &&
            props.errors.eventStartDatetime ? (
              <div className="error">
                <>{props.errors.eventStartDatetime}</>
              </div>
            ) : null}
            <span className="small_inputs_delimiter">-</span>

            <div className="label-time date-options-time">
              <TimePicker
                field="eventEndDatetime"
                props={props}
                onChange={(date) => onChange_end_time(date, props)}
                multiDays={true}
              />
            </div>
            {props.touched.eventEndDatetime && props.errors.eventEndDatetime ? (
              <div className="error">
                <>{props.errors.eventEndDatetime}</>
              </div>
            ) : null}
          </div>
        </IonCol>
      </IonRow>
      <DatePicker
        props={props}
        field={"eventStartDatetime"}
        showModal={showModal}
        setShowModal={setShowModal}
        setRerender={setRerender}
        minDate={minDate}
      />
      <IonRow>
        <IonCol className="label" size="5">
          <IonIcon className="label_icon" icon={calendarMonthFill} />
          <IonLabel>{t("new_event.date_options")}</IonLabel>
        </IonCol>
        <IonCol size="7" className="label">
          <div
            className="dates-box"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <table>
              <tbody>
                {props.values.eventDateOptions
                  .sort((a, b) => moment(a.start).diff(moment(b.start)))
                  .map((date) => (
                    <tr key={date.end.toString()}>
                      <td className="date-weekday">
                        {moment(date.start).format("ddd")}
                      </td>
                      <td className="date-day">
                        {moment(date.start).format("D MMM")}
                      </td>
                      <td className="date-year">
                        {moment(date.start).format("YYYY")}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <IonButton
              size="small"
              fill="clear"
              className="button-primary-light button-icon"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <IonIcon icon={edit} />
              {t("new_event.edit_dates")}
            </IonButton>
          </div>
        </IonCol>
      </IonRow>
    </>
  );
};

const EventFeeFormRow: React.FC<{
  props: AddEventProps | EditEventProps;
}> = ({ props }) => {
  const { t } = useTranslation();
  if (props.values.eventVisibility !== "public") return <></>;

  return (
    <IonRow>
      <IonCol className="label" size="5">
        <IonIcon className="label_icon" icon={payments} />
        <div>{t("new_event.entry_fee")}</div>
      </IonCol>
      <IonCol className="right_input_col" size="5">
        <IonInput
          aria-label="eventFeeFormRow"
          type="number"
          name="eventEntryFeeAmount"
          className="input-old"
          onIonInput={(e: any) => {
            props.handleChange(e);
          }}
          onIonBlur={(e: any) => {
            props.handleBlur(e);
          }}
          value={props.values.eventEntryFeeAmount}
          min="0"
          max="100000"
          placeholder="Price"
        />
        {props.touched.eventEntryFeeAmount &&
        props.errors.eventEntryFeeAmount ? (
          <div className="error">{props.errors.eventEntryFeeAmount}</div>
        ) : null}
      </IonCol>
      <IonCol className="label" size="2">
        <IonSelect
          aria-label="eventEntryFeeCurrency"
          name="eventEntryFeeCurrency"
          value={props.values.eventEntryFeeCurrency}
          onIonChange={props.handleChange}
          onIonBlur={(e) => {
            props.handleChange(e);
            props.handleBlur(e);
          }}
        >
          {currencies?.map((c) => {
            return (
              <IonSelectOption key={c} value={c}>
                {c}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </IonCol>
    </IonRow>
  );
};

const EventLanguageFormRow: React.FC<{
  props: any;
}> = ({ props }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <IonRow
        className="spacer"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <IonCol className="label">
          <IonIcon className="label_icon" icon={translate} />
          <IonLabel>{t("new_event.language")}</IonLabel>
        </IonCol>
        <IonCol>
          {languages?.map((item) => {
            if (props.values.eventLanguages.includes(item.id))
              return (
                <span
                  style={{
                    fontSize: "10px",
                    paddingRight: "3px",
                  }}
                  key={item.id}
                >
                  {item.name}
                </span>
              );
            else return undefined;
          })}
        </IonCol>
      </IonRow>

      <SelectLanguage
        showModal={showModal}
        setShowModal={setShowModal}
        props={props}
      />
    </>
  );
};

const EventVisibilityFormRow: React.FC<{
  props: AddEventProps | EditEventProps;
  disabled?: boolean;
}> = ({ props, disabled = false }) => {
  const { t } = useTranslation();
  return (
    <div className="event-public-switch">
      <div className="item">
        <IonIcon icon={earth}></IonIcon>
        <IonLabel>{t("new_event.public")}</IonLabel>
      </div>
      <div className="item disabled">
        <IonBadge color={"primary"}>{t("new_event.comming_soon")}</IonBadge>
        <IonIcon icon={lockFill}></IonIcon>
        <IonLabel>{t("new_event.private")}</IonLabel>
      </div>
    </div>
  );
};

// const EventVisibilityFormRow: React.FC<{
//   props: AddEventProps | EditEventProps;
//   disabled?: boolean;
// }> = ({ props, disabled = false }) => {
//   return (
//     <IonSegment
//       className="original"
//       color="tertiary"
//       value={props.values.eventVisibility}
//       disabled={disabled}
//       onIonChange={(e) => {
//         if (e.detail.value)
//           props.setFieldValue("eventVisibility", e.detail.value);
//       }}
//     >
//       <IonSegmentButton value="private">
//         <IonIcon icon={lockFill}></IonIcon>
//         <IonLabel>Private</IonLabel>
//       </IonSegmentButton>
//       <IonSegmentButton value="public">
//         <IonIcon icon={earth}></IonIcon>
//         <IonLabel>Public</IonLabel>
//       </IonSegmentButton>
//     </IonSegment>
//   );
// };

const EventCapacityFormRow: React.FC<{
  props: any;
}> = ({ props }) => {
  return (
    <IonRow className="spacer">
      <IonCol className="label">
        <IonLabel>Capacity</IonLabel>
      </IonCol>
      <IonCol className="right_input_col">
        <IonInput
          aria-label="eventCapacityFormRow"
          type="number"
          name="eventCapacity"
          onIonInput={(e: any) => {
            props.handleChange(e);
          }}
          onIonBlur={(e: any) => {
            props.handleBlur(e);
          }}
          value={props.values.eventCapacity}
          max="100000"
          placeholder="Unlimited"
        />
        {props.touched.eventCapacity && props.errors.eventCapacity ? (
          <div className="error">{props.errors.eventCapacity}</div>
        ) : null}
      </IonCol>
    </IonRow>
  );
};

const EventCategoryFormRow: React.FC<{
  props: AddEventProps | EditEventProps;
}> = ({ props }) => {
  const [showModal, setShowModal] = useState(false);
  const { tags } = useStorage();
  const { t } = useTranslation();

  if (props.values.eventVisibility === "private") return <></>;

  return (
    <>
      <IonRow
        onClick={() => {
          setShowModal(true);
        }}
      >
        <IonCol
          className={
            (!props.touched.eventCategory && !props.values.eventCategory) ||
            props.errors.eventCategory
              ? "label mandatory_col"
              : "label"
          }
        >
          <IonIcon className="label_icon" icon={apps} />
          {props.values.eventCategory ? (
            tags?.map((t) => {
              if (props.values.eventCategory === t.slug) return t.name;
              else return undefined;
            })
          ) : (
            <IonLabel className="input_placeholder">
              {t("new_event.category")}
            </IonLabel>
          )}
        </IonCol>
      </IonRow>

      <SelectCategory
        showModal={showModal}
        setShowModal={setShowModal}
        props={props}
        tags={tags}
      />
    </>
  );
};

const EventTagsFormRow: React.FC<{
  props: AddEventProps | EditEventProps;
}> = ({ props }) => {
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const { tags } = useStorage();
  const { t } = useTranslation();

  let uniqueTags;

  useEffect(() => {
    setShowError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.eventCategory !== undefined]);

  if (tags)
    uniqueTags = filteredSortedTags(tags, props.values.eventCategory || null);
  if (props.values.eventVisibility === "private") return <></>;

  return (
    <>
      <IonRow
        className="spacer"
        onClick={() => {
          if (props.values.eventCategory === undefined) setShowError(true);
          else setShowModal(true);
        }}
      >
        <IonCol className="label compact">
          <IonIcon className="label_icon hash" icon={tag} />
          {props.values.eventCategory && props.values.eventTags.length !== 0 ? (
            uniqueTags?.map((tag: Subtag) => {
              if (props.values.eventTags.includes(tag.slug))
                return (
                  <span className="tag" key={tag.slug}>
                    {tag.name}
                  </span>
                );
              else return undefined;
            })
          ) : (
            <>
              <IonLabel className="input_placeholder">
                {t("new_event.tags")}
              </IonLabel>

              {showError && (
                <div className="error">{t("cu_event.choose_tags")}</div>
              )}
            </>
          )}
        </IonCol>
      </IonRow>

      <SelectTag
        showModal={showModal}
        setShowModal={setShowModal}
        props={props}
        tags={tags}
        uniqueTags={uniqueTags}
      />
    </>
  );
};

const EventInviteesFormRow: React.FC<{
  props: AddEventProps;
}> = ({ props }) => {
  const { setMessage } = useStorage();
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState<UserListed[]>([]);
  const { axios } = useAxios();
  const { t } = useTranslation();
  const { fetch_noFriends } = useTranlatedMsg();

  useEffect(() => {
    if (!props.values.eventInvitees.length) return;
    find_users("", axios, props.values.eventInvitees, ["friends"]).then(
      (result) => {
        setUsers(result);
      },
      (err) => setMessage("danger", fetch_noFriends, err)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <IonRow
        onClick={() => {
          setShowModal(true);
        }}
      >
        {props.values.eventInvitees.length ? (
          <IonCol className="label compact">
            <IonIcon className="label_icon" icon={groupFill} />
            {users?.map((item) => {
              if (props.values.eventInvitees.includes(item.userId))
                return (
                  <span className="tag" key={item.userId}>
                    {item.name}
                  </span>
                );
              else return undefined;
            })}
          </IonCol>
        ) : (
          <>
            <IonCol className="label">
              <IonIcon className="label_icon" icon={groupFill} />
              <IonLabel className="input_placeholder">
                {t("cu_event.invitees")}
              </IonLabel>
            </IonCol>
          </>
        )}
      </IonRow>

      <AddMembersModal
        presentUsers={[]}
        showModal={showModal}
        setShowModal={setShowModal}
        prefilledUsers={props.values.eventInvitees}
        typeOfModal="invite_friends"
        keepState={true}
        onChange={(selectedUsers: number[]) => {
          props.values.eventInvitees = selectedUsers;
          find_users("", axios, props.values.eventInvitees, ["friends"]).then(
            (result) => {
              setUsers(result);
            },
            (err) => setMessage("danger", fetch_noFriends, err)
          );
        }}
      />
    </>
  );
};

const EventWebFormRow: React.FC<{
  props: AddEventProps | EditEventProps;
}> = ({ props }) => {
  const { t } = useTranslation();
  if (props.values.eventVisibility !== "public") return <></>;

  return (
    <>
      <IonRow>
        <IonCol className="label" size="5">
          <IonIcon className="label_icon" icon={earth} />
          <div>{t("cu_event.website")}</div>
        </IonCol>
        <IonCol className="right_input_col">
          <IonInput
            aria-label="eventWebFormRow"
            name="eventSourceUrl"
            className="input-old"
            onIonInput={(e: any) => {
              props.handleChange(e);
            }}
            onIonBlur={(e: any) => {
              props.handleBlur(e);
            }}
            value={props.values.eventSourceUrl}
            placeholder="https://"
          />
          {props.touched.eventSourceUrl && props.errors.eventSourceUrl ? (
            <div className="error">{props.errors.eventSourceUrl}</div>
          ) : null}
        </IonCol>
      </IonRow>
    </>
  );
};

const EventTicketsFormRow: React.FC<{
  props: AddEventProps | EditEventProps;
}> = ({ props }) => {
  const { t } = useTranslation();
  if (props.values.eventVisibility !== "public") return <></>;

  return (
    <>
      <IonRow>
        <IonCol className="label" size="5">
          <div>{t("new_event.tickets_url")}</div>
        </IonCol>
        <IonCol className="right_input_col">
          <IonInput
            aria-label="eventTicketsUrl"
            name="eventTicketsUrl"
            className="input-old"
            onIonInput={(e: any) => {
              props.handleChange(e);
            }}
            onIonBlur={(e: any) => {
              props.handleBlur(e);
            }}
            value={props.values.eventTicketsUrl}
            placeholder="https://"
          />
          {props.touched.eventTicketsUrl && props.errors.eventTicketsUrl ? (
            <div className="error">{props.errors.eventTicketsUrl}</div>
          ) : null}
        </IonCol>
      </IonRow>
    </>
  );
};

export {
  FoundPlacesList,
  PlacesList,
  SelectLocation,
  // SelectCategory,
  SelectLanguage,
  EventTitleFormRow,
  EventDescriptionFormRow,
  EventLocationFormRows,
  EventDatetimeFormRows,
  EventFeeFormRow,
  EventLanguageFormRow,
  EventVisibilityFormRow,
  EventCapacityFormRow,
  EventCategoryFormRow,
  EventInviteesFormRow,
  EventWebFormRow,
  EventTicketsFormRow,
  EventTagsFormRow,
};
