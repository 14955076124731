import { createContext, useEffect, useState } from "react";
import { Auth, User } from "../models";
import { ToastMessage } from "../models/Toast";
import { Coordinates } from "../models/Event";
import type { Dispatch } from "react";
import { useRollbar } from "@rollbar/react";
import { City } from "../models/City";
import { load } from "../cache/engine";
import { useRememberValue } from "../hooks/useLocalStorage";
import { load_coordinates } from "../cache/coordinates";
import { useJsApiLoader } from "@react-google-maps/api";
import { google_maps_api_key, stripePublishableKey } from "../config";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";

export type SetMessage = (
  type: "danger" | "primary",
  text?: string,
  error?: any
) => void;

type Storage = {
  auth: Auth;
  setAuth: (auth: Auth | null) => void;
  currentUser: User | null;
  setCurrentUser: Dispatch<React.SetStateAction<User | null>>;
  toast: ToastMessage | undefined;
  setToast: Dispatch<React.SetStateAction<ToastMessage | undefined>>;
  setMessage: SetMessage;
  gpsCoordinates: Coordinates | null;
  setGpsCoordinates: Dispatch<React.SetStateAction<Coordinates | null>>;
  dynamicLink: string | null;
  setDynamicLink: Dispatch<React.SetStateAction<string | null>>;
  customGps: City | null;
  setCustomGps: Dispatch<React.SetStateAction<City | null>>;
  interestsCategories: Set<string>;
  setInterestsCategories: Dispatch<React.SetStateAction<Set<string>>>;
  interestsTags: Set<string>;
  setInterestsTags: Dispatch<React.SetStateAction<Set<string>>>;
  seenOnboardnig: boolean;
  setSeenOnboarding: Dispatch<React.SetStateAction<boolean>>;
  preferredLoc: Coordinates;
  mapApiLoaded: boolean;
  stripePromise: Promise<Stripe | null> | null;
  currLanguage: string;
  setCurrLanguage: Dispatch<React.SetStateAction<string>>;
};

export const StateStorage = createContext<Storage>({} as Storage);

export const StateStorageProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { i18n } = useTranslation();
  const rollbar = useRollbar();
  const [auth, setStateAuth] = useState<Auth>({
    accessToken: localStorage.getItem("refreshToken")
      ? localStorage.getItem("accessToken") || "invalid"
      : null,
    refreshToken: localStorage.getItem("refreshToken") || null,
  });
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [dynamicLink, setDynamicLink] = useState<string | null>(null);
  const [toast, setToast] = useState<ToastMessage | undefined>(undefined);
  const [gpsCoordinates, setGpsCoordinates] = useState<Coordinates | null>(
    null
  );
  const [currLanguage, setCurrLanguage] = useState<Storage["currLanguage"]>(
    i18n.language
  );

  const [customGps, setCustomGps] = useState<City | null>(
    load<City | null>("customGps", null)
  );
  useRememberValue("customGps", customGps, true);

  const [interestsCategories, setInterestsCategories] = useState<Set<string>>(
    load<Set<string>>("interestsCategories", new Set())
  );
  const [interestsTags, setInterestsTags] = useState<Set<string>>(
    load<Set<string>>("interestsTags", new Set())
  );
  useRememberValue("interestsCategories", interestsCategories, true);
  useRememberValue("interestsTags", interestsTags, true);

  const [seenOnboardnig, setSeenOnboarding] = useState<boolean>(
    load<boolean>("seenOnboardnig", false)
  );
  useRememberValue("seenOnboardnig", seenOnboardnig);

  const [preferredLoc, setPreferredLoc] =
    useState<Coordinates>(load_coordinates);

  useEffect(() => {
    if (customGps?.lat && customGps?.lng)
      setPreferredLoc({
        lat: customGps.lat,
        lng: customGps.lng,
      });
    else if (!gpsCoordinates) return;
    else if (!preferredLoc) setPreferredLoc(gpsCoordinates);
    else if (
      Math.abs(preferredLoc.lat - gpsCoordinates.lat) > 0.01 ||
      Math.abs(preferredLoc.lng - gpsCoordinates.lng) > 0.01
    )
      setPreferredLoc(gpsCoordinates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gpsCoordinates, customGps]);

  const setAuth = (auth: Auth | null) => {
    setStateAuth({
      accessToken: auth?.accessToken || null,
      refreshToken: auth?.refreshToken || null,
    });

    if (!auth) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      return;
    }

    if (auth.accessToken) {
      localStorage.setItem("accessToken", auth.accessToken);
    }

    if (auth.refreshToken) {
      localStorage.setItem("refreshToken", auth.refreshToken);
    }
  };

  const setMessage: SetMessage = (type, text, error) => {
    if (text) {
      setToast({ type, text });
    }
    if (error) {
      console.error(error);
      if (type === "danger") rollbar.error(text, error);
    }
  };

  const { isLoaded: mapApiLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: google_maps_api_key,
  });

  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    setStripePromise(loadStripe(stripePublishableKey));
  }, []);

  const storage: Storage = {
    auth,
    setAuth,
    currentUser,
    setCurrentUser,
    toast,
    setToast,
    setMessage,
    gpsCoordinates,
    setGpsCoordinates,
    dynamicLink,
    setDynamicLink,
    customGps,
    setCustomGps,
    interestsCategories,
    setInterestsCategories,
    interestsTags,
    setInterestsTags,
    preferredLoc,
    seenOnboardnig,
    setSeenOnboarding,
    mapApiLoaded,
    stripePromise,
    currLanguage,
    setCurrLanguage,
  };

  return (
    <StateStorage.Provider value={storage}>{children}</StateStorage.Provider>
  );
};
