
const translations = {
  en: {
    translation: {
      menu: {
        events: "Events",
        map: "Map",
        activities: "Activities",
        profile: "Profile",
      }, 
      homepage: {
        events_in_area: "Explore events in your area",
        events_other: "Explore more events",
        city_choice: "Choose city",
        search_bar: "Search for city",
        current_location: "Current location",
      },
      map: {
        banner_title: "Coming soon!",
        banner_subtitle: "We are not in this region yet",
        search_events: "Search for events"
      },
      filter: {
        future: "Anytime",
        today: "Today",
        week: "This week",
        weekend: "This weekend",
        "30days": "One month",
        range: "Date range",
        days: "Days",
        all_categories: "All categories",
        select_tags: "Select Tags",
        categories: "Categories",
        tags: "Tags",
        all_tags: "All tags",
        tags_choice: "Choose category to see tags",
        on_map: "on a map",
        in_list: "in a list",
        event: "event",
        events: "events",

      },
      event_detail:{
        read_more: "Read more",
        location: "Location",
        date: "Date",
        hide: "Hide",
        walk: "walk", //ujdi
        in: "in",
        interested: "Interested",
        decline: "decline",
        your: "your", //Vaši,
        friends: "friends", // přátelé,
        are_going: "are going", //se účastní
        is_going: "se účastní",
        going: "Going", //účastním se
        not_interested: "Not interested",//Nemám zájem
        leave: "Leave", //Odejít
        you_are: "You are", // Jste
        organiser: "organiser",
        invited: "You are invited",
        decline_invi: "You have declined invitation",
        open_for_anyone: "Open for anyone",
        open_in_maps: "Open in maps",
        copy_adress: "Copy adress",
        copy_date: "Copy date",
        copy_to_calendar: "Copy to calendar",
        buy_tickets: "Buy tickets",
        tickets: "Tickets",
        visite_web: "Visite website",
        share_via: "Share via",
        copy_link: "Copy link",
        join: "Join"
      },
      payment:{
          sum: "Sum",
          contact: "Contact",
          name: "Name",
          surname: "surname",
          email: "email",
          phone: "Phone",
          agree_with: "Agree with",
          conditons:  "trade terms", //obchodními podmínkami
          gdpr: "privacy policy", //zásadami ochranou osobních údajů
          service_terms : "Terms of Service", // zpracovatelskými podmínkami
          payment_continue: "Continue to payment"
      },
      activities: {
        organising: "Organising",
        notifications: "Notifications",
        going: "Going",
        interested: "Interested",
        past: "Past",
        no_events: "No events",
        no_events_yet: "No events here yet",
        no_events_plan: "You have no events planned",
        filter_days: "Filter days",
        search_events: "Explore public events",
        no_notifications: "No notifications",
        no_notifications_subtitle: "Keep track of everthing that will happen",
        show: "Show",
        no_past_events: "You have no declined and past events",
      },
      new_event: {
        new_event: "New event",
        public: "Public",
        private: "Private",
        title: "Title",
        description: "Description",
        category: "Category",
        tags: "Tags",
        location: "Location",
        starts: "Starts",
        ends: "Ends",
        website: "Website",
        entry_fee: "Entry fee",
        tickets_url: "Tickets url",
        create_event: "Create event",
        edit_dates: "Edit dates",
        comming_soon: "Comming soon!",
        language: "Language",
        date_options: "Date options",
        time: "Time",
      },
      
      setting:{
        feedback: "Feedback",
        my_interests: "My interests",
        report_abuse: "Report abuse",
        allow_gps: "Allow GPS",
        allow_gps_subtitle: "Needed for event search, map positioning, event navigation",
        allow_notification: "Allow notitfications",
        notifications: "notificcations",
        allow_notification_subtitle: "Needed for sending push notification to your mobile device",
        allow_tips: "Allow tips",
        allow_tips_subtitle: "Needed for sending notifications about interesting events",
        terms: "Terms & Conditions",
        privacy_policy: "Privacy policy",
        edit_profile: "Edit profile",
        logout: "Logout",
        delete_account: "Delete account",
        report_abuse_title: "We are all ears.",
        report_abuse_subtitle: "Please,share with us what happend.",
        report_abuse_placeholder: "Please, describe what happned.",
        feedback_title: "We are all ears.",
        feedback_subtitle: "Hey there! We're all about making Manyways awesome for you. So, tell us, is there anything we can do to make it even better? Your feedback means the world to us.",
        feedback_placeholder: "Your feedback",
        send: "Send",
        save: "Save",
        language: "Language",
        language_subtitle: "Change language by clicking on the button.",
        close: "Close",
    },
    //zde group
    add_group:{
      create_group: "Create group"
    },
    crooper: {
      cancel: "Cancel",
      use_photo: "Use photo",
    },
    discussion: {
      donwload_image: "Download image",
      download_all: "Download all",
      images: "images",
      erase: "Erase",
    },
    edit_event:{
      save: "Save event",
      editing: "Editing event"
    },
    place_detail_info: {
      copy_adress:"Copy adress"},
    registration: {
        title: "Registration",
        invalid_phone: "Invalid phone",
        password: "Passwords do not match",
        register:"Register",
        or: "or",
        login: "Log in",
        reset_pass: "Reset password",
        and: "and",
        by_click: "By clicking",
        you: "you",
        accept: "accept",
        our: "our",
        terms: "Terms & Conditions",
        gdpr: "Privacy policy"
      },
      profile: {
        reset_onboarding: "RESET ONBOARDING",
        info: "close and open app after click",
        sign_up: "Sign up"
      },
      users:{
        you: "You",
        sent_image: "sent an image",
        add_frined: "Add friend",
        requested: "Requsted",
        friend:"Friend",
        req_sent:"Friend request sent",
        friend_req:"Friend request",
        user_block:"User blocked",
        user_block_you:"User blocked you",
        you_know:"You may know",
      },
      foll_places_evn: {
        favourite_places: "From your favorite places",
      },
      friend_detail: {
        unfriend: "Unfriend?",
        cancel: "Cancel",
        confirm: "Confirm",
      },
    messages: {
      joined_event: "You have joined event",
      went_wrong: "Something went wrong",
      access_denied: "Access denied",
      failed_photo: "Failed to take photo",
      fetch_noFriends: "Error in fetching friends",
      fail_crt_event: "Failed to fully create event",
      err_crt_event: "Error in creating event",
      interested_event: "You are interested in event",
      gps_reject: "Couldn't get access to gps — please, enable it on your phone settings",
      err_inviting: "Error in inviting members",
      err_crt_group: "Error while creating group",
      fail_picture:"Failed to process picture",
      fail_size_picture:"Failed to resize picture",
      gallery: "Gallery access denied",
      donwloaded:"Downloade",
      fail_download:"Download failed",
      no_access: "Invalid access rights",
      invit_sent: "Invitation(s) sent",
      text_copied: "Text copied",
      err_while_upload:"Error while uploading photo",
      err_update_event: "Error updating event",
      err_edit_group: "Error while editing group",
      not_interested: "You are not interested in event",
      left_event: "You have left the event",
      adress_copied: "Adress copied",
      date_copied: "Date copied",
      feedback_sent: "Feedback sent",
      err_feedback: "Error while sending feedback",
      err_cities_load: "Couldn't load cities",
      err_register: "Error in registration",
      err_send_report: "Error while sending report.",
      err_load_users: "Couldn't load users",
      err_connection: "Please, check your internet connection. App was not loaded properly.",
      err_login: "An error occured while logging in",
      err_msg_load:"Couldn't load previous messages",
      err_load_profile: "Couldn't load profile.",
      err_updt_setting: "Error updating settings",
      err_updt_name: "Error updating name",
      err_updt_photo: "Error updating photo",
      link_copied: "Link copied",
      err_load_fut_events:"Couldn't load future events",
      err_load_events:"Couldn't load events",
      user_reported: "User reported",
      admin_leave: "The only admin cannot leave the group",
      err_load_notif:"Couldn't load notifications",
      err_past_eve:  "Couldn't load past events",
      alert_app_cls_op: "Done: close and open app",


    },
    cu_event: {
      add: "Add",
      desc: "Description",
      no_location: "No locations found",
      select_location: "Select location",
      search_location: "Search location",
      use_curr_gps: "Use my current GPS as",
      custom_loc: "Custom location",
      use: "Use",
      as_loc: "as a location",
      found_loc: "Found locations",
      rec_found_loc: "Recently used locations",
      sel_cat: "Select category",
      close: "Close",
      sel_tags: "Select tags",
      apply: "Apply",
      sel_lang: "Select languages",
      done: "Done",
      loc_op: "Location option",
      loc: "Locations",
      reopen_vot: "Reopen voting",
      add_vot_loc: "Add more locations for voting",
      fin_edit_dates: "Finish editing dates",
      choose_tags: "Choose category to see tags",
      invitees: "Invitees",
      website: "Website"
    },
    add_event: {
      create: "Create event",
      discard_chngs: "Discard changes?",
      cancel: "Cancel",
      confirm: "Confirm",
    },
    onBoarding: {
      title: "What do you love?",
      continue: "Continue",
      save: "Save",
      tell_us: "tell us what you love",
      tell_us_subtitle: "Choose your interest, and well will curate the best events for you feed"
    },
    },
  },
  cs: {
    translation: {
      menu: {
        events: "Akce",
        map: "Mapa",
        activities: "Aktivity",
        profile: "Profil",
      },
      add_event: {
        create: "Vytvořit událost",
        discard_chngs: "Zrušit změny?",
        cancel: "Ukončit",
        confirm: "Potvrdit",
      },
      homepage: {
        events_in_area: "Objev akce v tvém okolí",
        events_other: "Objev více akcí",
        city_choice: "Vyber město",
        search_bar: "Vyhledej město",
        current_location: "Aktuální lokace",
      },
      map: {
        banner_title: "Už brzy!",
        banner_subtitle: "V tomto regionu ještě nepůsobíme",
        search_events: "Hledat události"
      },
      filter: {
        future: "Kdykoliv",
        today: "Dnes",
        week: "Tento týden",
        weekend: "Tento víkend",
        "30days": "Jeden měsíc",
        range: "Zvolit datumy",
        days: "Dny",
        categories: "Kategorie",
        all_categories: "Všechny kategorie",
        select_tags: "Vybrat tagy",
        tags: "Tagy",
        all_tags: "Všechny tagy",
        tags_choice: "Vyberte kategorii pro zobrazení tagů",
        on_map: "na mapě",
        in_list: "v seznamu",
        event: "událost",
        events: "událostí",
      },
      activities: {
        organising: "Organizované",
        notifications: "Notifikace",
        going: "Zúčastním se",
        interested: "Zájímá mě",
        past: "Minulé",
        no_events: "Žádné eventy",
        no_events_yet: "Zatím žádné eventy",
        no_events_plan: "Nemáte naplánované žádné eventy",
        filter_days: "Filtrovat dny",
        search_events: "Vyhledej veřejnou událost",
        no_notifications: "Žádné notifikace",
        no_notifications_subtitle: "Udržuj si přehled o všem, co se děje.",
        show: "Zobrazit",
        no_past_events: "Nemáte žádné odmítnuté ani minulé události.",
      },
      payment:{
        sum: "Součet",
        contact: "Kontakt",
        name: "Jméno",
        surname: "Příjmení",
        email: "Email",
        phone: "Telefon",
        agree_with: "Souhlasím s",
        conditions:  "obchodními podmínkami", 
        gdpr: "zásadami ochranou osobních údajů", 
        service_terms : "zpracovatelskými podmínkami", 
        payment_continue: "Pokračovat k platbě"
    },
      event_detail:{
        read_more: "Číst více",
        location: "Lokace",
        date: "Datum",
        hide: "Skrýt",
        walk: "ujdi",
        in: "v",
        interested: "Zajímavý",
        decline: "odmítnout",
        your: "vaši",
        friends: "přátelé",
        are_going: "se účastní",
        is_going: "se účastní",
        going: "Účastním se",
        not_interested: "Nemám zájem",
        leave: "Odejít",
        you_are: "Jste",
        organiser: "organizátor",
        invited: "Jste pozváni",
        decline_invi: "Odmítnuli jste pozvání",
        open_for_anyone: "Otevřeno pro každého",
        open_in_maps: "Otevřít v mapách",
        copy_adress: "Kopírovat adresu",
        copy_date: "Kopírovat datum",
        copy_to_calendar: "Kopírovat do kalendáře",
        buy_tickets: "Koupit vstupenky",
        tickets: "Vstupenky",
        visite_web: "Navštívit web",
        share_via: "Sdílet přes",
        copy_link: "Kopírovat link",
        join: "Přidat se"
      },
      onBoarding: {
        title: "Co máte nejraději?",
        continue: "Pokračovat",
        save: "Uložit",
        tell_us: "Sdělte nám oblíbená témata",
        tell_us_subtitle: "Vyberte vaše zájmy, a my Vám doporučíme to nejlepší na nástěnku"
      },
      new_event: {
        new_event: "Nová událost",
        public: "Veřejné",
        private: "Soukromé",
        title: "Titul",
        description: "Popis",
        category: "Kategorie",
        tags: "Tagy",
        location: "Lokace",
        starts: "Začíná",
        ends: "Končí",
        website: "Webová stránka",
        entry_fee: "Vstupní poplatek",
        tickets_url: "Odkaz na lístky",
        create_event: "Vytvořit event",
        edit_dates: "Upravit datumy",
        comming_soon: "Už brzy!",
        language: "Jazyk",
        date_options: "Možnosti datumu",
        time: "Čas",
      },
      setting: {
        feedback: "Zpětná vazba",
        my_interests: "Moje zájmy",
        report_abuse: "Nahlásit zneužití",
        allow_gps: "Povolit GPS",
        allow_gps_subtitle: "Potřebné pro vyhledávání událostí, pozicionování na mapě, navigaci v událostech",
        allow_notification: "Povolit upozornění",
        allow_notification_subtitle: "Potřebné pro zasílání oznámení na váš mobilní telefon",
        allow_tips: "Povolit tipy",
        allow_tips_subtitle: "Potřebné pro zasílání oznámení o zajímavých událostech",
        terms: "Pravidla & podmínky",
        privacy_policy: "Zásady ochrany osobních údajů",
        edit_profile: "Editovat profil",
        logout: "Odhlásit se",
        delete_account: "Vymazat účet",
        report_abuse_title: "Jsme jedno ucho.",
        report_abuse_subtitle: "Prosím, podělte se s námi o to, co se stalo.",
        report_abuse_placeholder: "Popište, co se stalo.",
        feedback_title: "Jsme jedno ucho.",
        feedback_subtitle: "Hezký den! Jsme tu pro vás a snažíme se udělat Manyways ještě lepším. Takže nám řekněte, je něco, co můžeme udělat, abychom ho ještě vylepšili? Vaše zpětná vazba pro nás znamená hodně.",
        feedback_placeholder: "Vaše zpětná vazba",
        send: "Odeslat",
        save: "Uložit",
        language: "Jazyk",
        language_subtitle: "Změňte jazyk kliknutím na vlajku.",
        close: "Zavřít",
      },
      add_group: {
        create_group: "Vytvořit skupinu"
      },
      crooper: {
        cancel: "Zrušit",
        use_photo: "Použít fotku",
      },
      discussion: {
        donwload_image: "Stáhnout obrázek",
        download_all: "Stáhnout vše",
        images: "obrázků",
        erase: "Smazat",
      },
      edit_event: {
        save: "Uložit událost",
        editing: "Úprava události"
      },
      place_detail_info: {
        copy_adress: "Zkopírovat adresu",
      },
      registration: {
        title: "Registrace",
        invalid_phone: "Neplatný telefon",
        password: "Hesla se neshodují",
        register: "Registrovat",
        or: "nebo",
        login: "Přihlásit se",
        reset_pass: "Obnovit heslo",
        and: "a",
        by_click: "Kliknutím",
        you: "vy",
        accept: "přijímáte",
        our: "naše",
        terms: "Podmínky použití",
        gdpr: "Zásady ochrany osobních údajů"
      },
      profile: {
        reset_onboarding: "RESETOVAT ONBOARDING",
        info: "Zavřete a znovu otevřete aplikaci po kliknutí",
        sign_up: "Registrovat se"
      },
      users: {
        you: "Vy",
        sent_image: "odeslal(a) obrázek",
        add_frined: "Přidat přítele",
        requested: "Požadované",
        friend: "Přítel",
        req_sent: "Žádost o přátelství odeslána",
        friend_req: "Žádost o přátelství",
        user_block: "Uživatel zablokován",
        user_block_you: "Uživatel vás zablokoval",
        you_know: "Můžete znát",
      },
      foll_places_evn: {
        favourite_places: "Z vašich oblíbených míst",
      },
      friend_detail: {
        unfriend: "Zrušit přátelství?",
        cancel: "Zrušit",
        confirm: "Potvrdit",
      },
      messages: {
        joined_event: "Přihlásil ses na událost",
        went_wrong: "Něco se pokazilo",
        access_denied: "Přístup odepřen",
        failed_photo: "Selhalo nahrání fotografie",
        fetch_noFriends: "Chyba při načtení přátel",
        fail_crt_event: "Vytvoření eventu částečně selhalo",
        err_crt_event: "Chyba ve vytváření eventu",
        interested_event: "Událost vás nyní zajímá",
        gps_reject: "Nepodařilo se získat přístup k GPS - prosím, povolte ho v nastavení vašeho telefonu",
        err_inviting: "Chyba při pozvání členů",
        err_crt_group: "Chyba při vytváření skupiny",
        fail_picture: "Nepodařilo se zpracovat obrázek",
        fail_size_picture: "Nepodařilo se změnit velikost obrázku",
        gallery: "Přístup k galerii odepřen",
        donwloaded: "Staženo",
        fail_download: "Stažení se nezdařilo",
        no_access: "Neplatná přístupová práva",
        invit_sent: "Pozvánka(y) odeslána",
        text_copied: "Text zkopírován",
        err_while_upload: "Chyba při nahrávání fotky",
        err_update_event: "Chyba při aktualizaci události",
        err_edit_group: "Chyba při úpravě skupiny",
        not_interested: "Nejste zajímáni o událost",
        left_event: "Opustili jste událost",
        adress_copied: "Adresa zkopírována",
        date_copied: "Datum zkopírováno",
        feedback_sent: "Zpětná vazba odeslána",
        err_feedback: "Chyba při odesílání zpětné vazby",
        err_cities_load: "Nepodařilo se načíst města",
        err_register: "Chyba při registraci",
        err_send_report: "Chyba při odesílání zprávy",
        err_load_users: "Nepodařilo se načíst uživatele",
        err_connection: "Prosím, zkontrolujte své internetové připojení. Aplikace nebyla správně načtena.",
        err_login: "Při přihlašování došlo k chybě",
        err_msg_load: "Nepodařilo se načíst předchozí zprávy",
        err_load_profile: "Nepodařilo se načíst profil",
        err_updt_setting: "Chyba při aktualizaci nastavení",
        err_updt_name: "Chyba při aktualizaci jména",
        err_updt_photo: "Chyba při aktualizaci fotografie",
        link_copied: "Odkaz zkopírován",
        err_load_fut_events: "Nepodařilo se načíst budoucí události",
        err_load_events: "Nepodařilo se načíst události",
        user_reported: "Uživatel nahlášen",
        admin_leave: "Jediný administrátor nemůže opustit skupinu",
        err_load_notif: "Nepodařilo se načíst oznámení",
        err_past_eve: "Nepodařilo se načíst minulé události",
        alert_app_cls_op: "Hotovo: zavřete a znovu otevřete aplikaci",
      },
      cu_event: {
        add: "Přidat",
        desc: "Popis",
        no_location: "Lokace nenalezeny",
        select_location: "Vyberte lokaci",
        search_location: "Hledejte lokaci",
        use_curr_gps: "Použít moji aktuální gps",
        custom_loc: "Vlastní lokace",
        use: "Použít",
        as_loc: "jako lokaci",
        found_loc: "Nalezené lokace",
        rec_found_loc: "Nedávno použité lokace",
        sel_cat: "Vyber kategorii",
        close: "Zavřít",
        sel_tags: "Vyberte tagy",
        apply: "Aplikovat",
        sel_lang: "Vyber jazyky",
        done: "Hotovo",
        loc_op: "Možnosti lokací",
        loc: "Lokace",
        reopen_vot: "Znovu otevřít hlasování",
        add_vot_loc: "Přidat více lokací do hlasování",
        fin_edit_dates: "Dokonřit úpravu datumu",
        choose_tags: "Vyberte kategorii pro zobrazení tagů",
        invitees: "Pozvaní",
        website: "Webová stránka"
  
  
  
      }
      

    },
  },
};
const DayFilterLabels = {
  future: "Anytime",
  today: "Today",
  week: "This week",
  weekend: "This weekend",
  "30days": "One month",
  range: "Range",
};
export default translations;
