import moment from "moment";
import { MutableRefObject } from "react";
import {
  Event,
  Coordinates,
  MarkerContainer,
  // AggregatedMarkerContainer,
} from "../models/Event";
import { calc_end_date } from "./event";
import { getLevelOneTags } from "./tags";

const OPACITY = "0.87";
const pin_template = `<svg width="34" height="42" viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.0309 38.8125C12.1153 32.9735 3.2504 25.0811 3.25 16.6641C3.25 9.0456 9.4091 2.8751 17 2.875C24.5909 2.8751 30.75 9.0456 30.75 16.6641C30.7495 25.048 21.9316 32.9914 17.0309 38.8125Z" fill="{{ color }}"/>
<path d="M1.875 16.664C1.8752 21.3358 4.3229 25.7171 7.1993 29.5327C8.6516 31.4593 10.2529 33.292 11.7816 34.9916C12.2026 35.4597 12.6161 35.9155 13.0193 36.36C14.0956 37.5466 15.0991 38.6528 15.979 39.698L18.0828 39.6981C18.8958 38.7324 19.8628 37.6608 20.8976 36.5141C22.8245 34.3788 24.9868 31.9828 26.8286 29.5236C29.6908 25.702 32.1248 21.3172 32.125 16.6641C32.125 8.2895 25.3535 1.50006 17 1.5C8.6465 1.50006 1.875 8.2894 1.875 16.664Z" stroke="{{ border_color }}" stroke-width="2.75" stroke-linejoin="bevel"/>
{{ pin_type }}
</svg>
`;

const pin_multi_template = `<svg width="46" height="42" viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.8618 34.8521L28.8499 34.8206L28.8215 34.8389L28.7577 34.6816C27.5386 31.6769 26.2746 28.5618 25.4031 25.6518C25.1194 24.7047 24.8843 23.8003 24.7064 22.946C24.2438 20.7241 24.1681 18.8415 24.6319 17.4308C26.2544 12.4964 31.5849 9.82062 36.535 11.4482C41.485 13.0759 44.1873 18.393 42.5649 23.3274C42.101 24.738 40.9229 26.2084 39.2319 27.7221C38.5817 28.3041 37.8557 28.8926 37.0654 29.4865C34.637 31.3114 31.7709 33.0686 29.0066 34.7634L28.8618 34.8521Z" fill="{{ color }}"/>
<path d="M29.7253 35.9358L29.5805 36.0246L28.5674 35.6788L27.5474 35.356L27.4836 35.1987L27.4695 35.164C26.2602 32.1836 24.9761 29.0189 24.0859 26.0465M29.7253 35.9358L29.0503 34.8349M29.7253 35.9358L29.7572 35.9162C32.4993 34.2351 35.4109 32.45 37.8914 30.5859C38.7064 29.9735 39.4638 29.3602 40.149 28.7468C41.8961 27.1828 43.293 25.5148 43.8711 23.7571C45.7325 18.0961 42.6306 12.0054 36.9645 10.1422C31.2983 8.27913 25.1871 11.3405 23.3257 17.0015C22.7478 18.7593 22.8823 20.9308 23.3603 23.2265C23.5478 24.1268 23.7934 25.07 24.0859 26.0465M24.0859 26.0465L25.3591 25.6652" stroke="{{ border_color }}" stroke-width="2.75" stroke-linejoin="bevel"/>
<path d="M17.1391 34.8521L17.151 34.8206L17.1794 34.8389L17.2432 34.6816C18.4623 31.6769 19.7263 28.5618 20.5979 25.6518C20.8815 24.7047 21.1166 23.8003 21.2945 22.946C21.7572 20.7241 21.8328 18.8415 21.369 17.4308C19.7465 12.4964 14.4161 9.82062 9.46599 11.4482C4.51589 13.0759 1.81359 18.393 3.43599 23.3274C3.89989 24.738 5.07809 26.2084 6.76899 27.7221C7.41919 28.3041 8.14519 28.8926 8.93549 29.4865C11.3639 31.3114 14.23 33.0686 16.9943 34.7634L17.1391 34.8521Z" fill="{{ color }}"/>
<path d="M16.2756 35.9358L16.4204 36.0246L17.4335 35.6788L18.4535 35.356L18.5173 35.1987L18.5314 35.164C19.7407 32.1836 21.0248 29.0189 21.915 26.0465M16.2756 35.9358L16.9506 34.8349M16.2756 35.9358L16.2437 35.9162C13.5016 34.2351 10.59 32.45 8.1095 30.5859C7.2945 29.9735 6.5371 29.3602 5.8519 28.7468C4.1048 27.1828 2.7079 25.5148 2.1298 23.7571C0.2684 18.0961 3.3703 12.0054 9.0365 10.1422C14.7027 8.27913 20.8138 11.3405 22.6752 17.0015C23.2531 18.7593 23.1186 20.9308 22.6406 23.2265C22.4532 24.1268 22.2075 25.07 21.915 26.0465M21.915 26.0465L20.6418 25.6652" stroke="{{ border_color }}" stroke-width="2.75" stroke-linejoin="bevel"/>
<path d="M23.0309 38.8125C18.1153 32.9735 9.2504 25.0811 9.25 16.6641C9.25 9.0456 15.4091 2.8751 23 2.875C30.5909 2.8751 36.75 9.0456 36.75 16.6641C36.7495 25.048 27.9316 32.9914 23.0309 38.8125Z" fill="{{ color }}"/>
<path d="M7.875 16.664C7.8752 21.3358 10.3229 25.7171 13.1993 29.5327C14.6516 31.4593 16.2529 33.292 17.7816 34.9916C18.2026 35.4597 18.6161 35.9155 19.0193 36.36C20.0956 37.5466 21.0991 38.6528 21.979 39.698L24.0828 39.6981C24.8958 38.7324 25.8628 37.6608 26.8976 36.5141C28.8245 34.3788 30.9868 31.9828 32.8286 29.5236C35.6908 25.702 38.1248 21.3172 38.125 16.6641C38.125 8.2895 31.3535 1.50006 23 1.5C14.6465 1.50006 7.875 8.2894 7.875 16.664Z" stroke="{{ border_color }}" stroke-width="2.75" stroke-linejoin="bevel"/>
{{ pin_type }}
</svg>
`;

const helpIcon = [
  '<g><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 ',
  `0 24 24" width="24px" fill="#000000" x="7.5" y="8"  opacity="${OPACITY}"><g><rect fill="none" height="24" `,
  'width="24" /></g><g><path d="M11.07,12.85c0.77-1.39,2.25-2.21,3.11-3.44c0.91-1.29,0.4-3.7-2.18-3.7c-1.69,',
  "0-2.52,1.28-2.87,2.34L6.54,6.96 C7.25,4.83,9.18,3,11.99,3c2.35,0,3.96,1.07,4.78,2.41c0.7,1.15,1.11,3.3,0.03,",
  "4.9c-1.2,1.77-2.35,2.31-2.97,3.45 c-0.25,0.46-0.35,0.76-0.35,2.24h-2.89C10.58,15.22,10.46,13.95,11.07,12.85z ",
  'M14,20c0,1.1-0.9,2-2,2s-2-0.9-2-2c0-1.1,0.9-2,2-2 S14,18.9,14,20z"/></g></svg></g>',
].join("");

// const lockClosedIcon = [
//   '<g><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000" ',
//   `opacity="${OPACITY}" x="8" y="7"><g fill="none"><path d="M0 0h24v24H0V0z"/><path d="M0 0h24v24H0V0z" `,
//   `opacity="${OPACITY}"/></g><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 `,
//   "2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 ",
//   '0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"/></svg></g>',
// ].join("");

const foodIcon = [
  '<svg x="10" y="10" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.66512 0C1.29772 0 1 0.297717 1 0.667223V4.66634C1 7.77231 3.52745 7.33312 3.38176 9.32635C3.23395 11.3217 2.99958 13.3339 2.99958 14.6662C2.99958 15.4031 3.59713 16.0007 4.33193 16.0007C5.06883 16.0007 5.66639 15.4031 5.66639 14.6662C5.66639 14.6367 5.66427 14.605 5.66216 14.5733C5.65161 13.241 5.42779 11.2795 5.28421 9.32635C5.13851 7.33312 7.66596 7.77231 7.66596 4.66634V0.667223C7.66596 0.297717 7.36824 0 6.99873 0C6.63134 0 6.33362 0.297717 6.33362 0.667223V4.00123C6.33362 4.36862 6.03378 4.66634 5.66639 4.66634C5.29899 4.66634 4.99916 4.36862 4.99916 4.00123V0.667223C4.99916 0.297717 4.70144 0 4.33193 0C3.96453 0 3.66681 0.297717 3.66681 0.667223V4.00123C3.66681 4.36862 3.36698 4.66634 2.99958 4.66634C2.63218 4.66634 2.33235 4.36862 2.33235 4.00123V0.667223C2.33235 0.297717 2.03463 0 1.66512 0ZM11 0C10.6305 0 10.3328 0.299828 10.3328 0.667223V14.6662C10.3328 15.4031 10.9303 16.0007 11.6651 16.0007C12.402 16.0007 12.9996 15.4031 12.9996 14.6662C12.9996 14.6198 12.9954 14.5712 12.9911 14.5227C12.9637 13.6591 12.6955 12.3922 12.4696 11.1802C12.2141 9.81409 12.269 9.33268 12.9996 9.33268C13.7302 9.33268 14.3319 8.73725 14.3319 8.00035V3.33401C14.3319 1.51603 12.875 0.040118 11.0655 0.00422267C11.0443 0.00211134 11.0211 0 11 0Z" fill="{{ icon_color }}"/></svg>',
].join("");

const musicIcon = [
  '<svg x="10" y="10" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 0V9.61806C6.49219 9.32292 5.91927 9.16667 5.33333 9.16667C3.49306 9.16667 2 10.6597 2 12.5C2 14.3403 3.49306 15.8333 5.33333 15.8333C7.17361 15.8333 8.66667 14.3403 8.66667 12.5V4.16667H13.6667V0H7Z" fill="{{ icon_color }}"/></svg>',
].join("");

const sportIcon = [
  '<svg x="10" y="10" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.22416 1C4.23532 1 1 4.23532 1 8.22416C1 8.40052 1.01603 8.57367 1.02886 8.74682C1.4489 8.64741 1.88178 8.5897 2.32427 8.5897C3.80245 8.5897 5.19085 9.16686 6.23615 10.2122C7.28146 11.2575 7.85863 12.6459 7.85863 14.1241C7.85863 14.5665 7.80091 14.9994 7.70151 15.4195C7.87466 15.4323 8.04781 15.4483 8.22416 15.4483C12.213 15.4483 15.4483 12.213 15.4483 8.22416C15.4483 8.04781 15.4323 7.87466 15.4195 7.70151C14.9994 7.80091 14.5665 7.85863 14.1241 7.85863C12.6459 7.85863 11.2575 7.28146 10.2122 6.23615C9.16686 5.19085 8.5897 3.80245 8.5897 2.32427C8.5897 1.88178 8.64741 1.4489 8.74682 1.02886C8.57367 1.01603 8.40052 1 8.22416 1ZM9.93962 1.21483C9.84663 1.57075 9.79212 1.9427 9.79212 2.32427C9.79212 3.4818 10.2442 4.56879 11.0619 5.38644C11.8795 6.20409 12.9665 6.6562 14.1241 6.6562C14.5056 6.6562 14.8744 6.60169 15.2335 6.5087C14.5954 3.89864 12.5497 1.85292 9.93962 1.21483ZM2.32427 9.79212C1.9427 9.79212 1.57396 9.84663 1.21483 9.93962C1.85292 12.5497 3.89864 14.5954 6.5087 15.2335C6.60169 14.8776 6.6562 14.5056 6.6562 14.1241C6.6562 12.9665 6.20409 11.8795 5.38644 11.0619C4.56879 10.2442 3.4818 9.79212 2.32427 9.79212Z" fill="{{ icon_color }}"/></svg>',
].join("");

const theaterIcon = [
  '<svg x="10" y="10" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.5183 1C10.4483 1 10.3734 1.00488 10.2985 1.0179L7.76925 1.46061L11.6771 2.88314L11.4965 1.8724C11.4037 1.34505 11.0179 1.00651 10.5183 1ZM5.60291 1.94727C5.16997 1.97005 4.7712 2.24512 4.61333 2.67643L2.83273 7.57064C1.75688 10.5296 5.34412 14.6182 5.7559 15.0755C5.96749 15.3099 6.2686 15.4401 6.5811 15.4401C6.63156 15.4401 6.68201 15.4369 6.73247 15.4303C7.34282 15.3457 12.7188 14.5189 13.7963 11.5615L15.5769 6.66732C15.7868 6.08952 15.489 5.45313 14.9128 5.24316L6.03748 2.01237C5.89425 1.96029 5.74777 1.93913 5.60291 1.94727ZM3.61072 2.19466L0.920293 2.66829C0.217168 2.80339 -0.0806839 3.36003 0.0185998 3.95573L0.923548 9.08431C1.05213 9.81348 1.43624 10.4661 1.95545 11.0439C1.50949 9.78581 1.3337 8.43652 1.78618 7.19141L3.61072 2.19466ZM6.63156 6.16439C6.77479 6.15788 6.9229 6.17904 7.06775 6.23112C7.64393 6.44108 7.94178 7.0791 7.73182 7.65527L5.6436 6.89518C5.80148 6.46224 6.19861 6.18718 6.63156 6.16439ZM10.974 7.74642C11.1189 7.73828 11.267 7.75944 11.4102 7.81152C11.988 8.02149 12.2842 8.65951 12.0743 9.23568L9.98605 8.47559C10.1439 8.04427 10.5427 7.76758 10.974 7.74642ZM6.50297 9.67188L9.63449 10.8112C9.32036 11.6755 8.36333 12.1214 7.49907 11.8073C6.63481 11.4932 6.18722 10.5361 6.50297 9.67188Z" fill="{{ icon_color }}"/></svg>',
].join("");

const cinemaIcon = [
  '<svg x="10" y="10" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.59558 6.00798C7.96621 6.89228 6.89539 7.43909 5.72198 7.35827C3.94165 7.23564 2.59933 5.71465 2.72382 3.96104C2.84831 2.20744 4.39249 0.885264 6.17282 1.00788C6.28616 1.01569 6.39771 1.02916 6.50723 1.04801C6.508 1.04415 6.5088 1.04034 6.50961 1.03658C7.97596 1.26403 8.71868 2.23938 9.13328 2.93177C9.42338 3.41612 9.69847 3.71129 10.2138 3.24014C10.2217 3.2329 10.241 3.21417 10.2682 3.18782C10.4101 3.05015 10.7664 2.70454 10.8311 2.70454C11.3138 2.35368 11.9209 2.16365 12.5662 2.2081C14.0075 2.30736 15.0941 3.53864 14.9933 4.95823C14.8925 6.37782 13.6425 7.44815 12.2013 7.34888C12.1192 7.34323 12.0384 7.33391 11.9588 7.3211C10.7603 7.1854 10.2885 6.44292 10.0584 6.08078C10.0296 6.03547 10.0046 5.99611 9.98238 5.96464C9.59568 5.41577 9.36317 5.28652 9.05637 5.50975C8.94417 5.59137 8.80467 5.75809 8.64927 5.94388C8.63157 5.96503 8.61368 5.98643 8.59558 6.00798Z" fill="{{ icon_color }}"/><path d="M4.20108 9.2946C4.20108 8.7971 4.61055 8.3938 5.11567 8.3938H12.8897C13.3948 8.3938 13.8043 8.7971 13.8043 9.2946V12.186C13.8043 12.4571 13.6803 12.7138 13.4668 12.8849L11.0785 14.7981C10.9155 14.9288 10.7117 15.0001 10.5015 15.0001H5.11567C4.61055 15.0001 4.20108 14.5968 4.20108 14.0992V13.1233C4.20108 12.8404 3.95561 12.6179 3.66941 12.6414L3.52851 12.653C3.37309 12.6658 3.22855 12.7367 3.12461 12.8512L2.30979 13.7488C2.19412 13.8762 2.0288 13.9491 1.85526 13.9491H1.15243C1.06825 13.9491 1 13.8819 1 13.7989V9.5949C1 9.512 1.06825 9.4448 1.15243 9.4448H1.85526C2.0288 9.4448 2.19412 9.5176 2.30979 9.645L3.12461 10.5427C3.22855 10.6572 3.37309 10.7281 3.52851 10.7409L3.69472 10.7545C3.9673 10.7769 4.20108 10.565 4.20108 10.2956V9.2946Z" fill="{{ icon_color }}"/></svg>',
].join("");

const exhibitionIcon = [
  '<svg x="10" y="10" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5625 0C5.95139 0 5.02431 1.25694 5.02431 2.77083C5.02431 3.28125 5.26736 3.79167 5.26736 3.79167C5.17014 3.84722 5.00694 4.02778 5.04861 4.34722C5.125 4.9375 5.37847 5.08681 5.54167 5.10069C5.60417 5.65278 6.05208 6.41667 6.26042 6.52083V7.38889C5.79861 8.7743 3 8.47569 3 10.4306L4.30208 11.7326H10.8229L12.125 10.4306C12.125 8.47569 9.32639 8.7743 8.86458 7.38889V6.52083C9.07292 6.41667 9.52083 5.65278 9.58333 5.10069C9.74653 5.08681 10 4.94097 10.0764 4.34722C10.1181 4.02778 9.95486 3.85069 9.85764 3.79514C9.85764 3.79514 10.1007 3.32986 10.1007 2.77083C10.1007 1.64931 9.66319 0.694444 8.71875 0.694444C8.71875 0.694444 8.54514 0 7.5625 0ZM4.30208 13.0382V14.3403C3.94444 14.3403 3.65278 14.6319 3.65278 14.9931C3.65278 15.3542 3.94444 15.6458 4.30208 15.6458H10.8229C11.1806 15.6458 11.4722 15.3542 11.4722 14.9931C11.4722 14.6319 11.1806 14.3403 10.8229 14.3403V13.0382H4.30208Z" fill="{{ icon_color }}"/></svg>',
].join("");

const educationIcon = [
  '<svg x="9" y="10" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.6955 8.0021L14.0442 7.87925V11.175L14.3706 12.0454C14.3985 12.1197 14.3865 12.2031 14.3389 12.2665L14.0558 12.644C13.9629 12.768 13.7769 12.768 13.6839 12.644L13.4008 12.2665C13.3532 12.2031 13.3412 12.1197 13.3691 12.0454L13.6955 11.175V8.0021ZM7.77335 3.03454C7.92879 2.98585 8.0961 2.98864 8.2501 3.0429L15.7673 5.69037C16.0778 5.79972 16.0778 6.23882 15.7673 6.34816L8.7328 8.8257C8.258 8.9929 7.74034 8.9929 7.26556 8.8257L4.10504 7.71256V7.71132L0.23286 6.34758C-0.07762 6.23823 -0.07762 5.79914 0.23286 5.68979L7.75005 3.04232C7.75779 3.03959 7.76556 3.037 7.77335 3.03454ZM11.8926 8.637V11.6003C11.8926 11.9949 11.7177 12.3691 11.4149 12.6221C9.4374 14.275 6.56023 14.275 4.58271 12.6221C4.27999 12.3691 4.10504 11.9949 4.10504 11.6003V8.6368L6.97597 9.6479C7.63817 9.8811 8.3602 9.8811 9.0224 9.6479L11.8926 8.637Z" fill="{{ icon_color }}"/></svg>',
].join("");

// const starIcon = [
//   '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000" x="8" y="8">',
//   '<path d="m8.85 17.825 3.15-1.9 3.15 1.925-.825-3.6 2.775-2.4-3.65-.325-1.45-3.4-1.45 3.375-3.65.325 2.775 2.425ZM5.825 22l1.625-7.025L2 10.25l7.2-.625L12 3l2.8 6.625 7.2.625-5.45 4.725L18.175 22 12 18.275ZM12 13.25Z"/>',
//   "</svg>",
// ].join("");

// function applyGradient(colors: string[]): string {
//   return [
//     '<defs><linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%" gradientTransform="rotate(45)">',
//     `<stop offset="0%" style="stop-color:${colors[0]};stop-opacity:1" />`,
//     `<stop offset="100%" style="stop-color:${colors[1]};stop-opacity:1" /></linearGradient></defs>`,
//   ].join("");
// }

// const ongoingGradient = applyGradient(["#24ad6b", "#167547"]);
// const organiserGradient = applyGradient(["#ad2424", "#ad2466"]);
// const invitedGradient = applyGradient(["#fcbe47", "#ef7f08"]);
// const goingGradient = applyGradient(["#ad2483", "#8a2ab5"]);
// const maybeGradient = applyGradient(["#c592da", "#c592da"]);
// const declinedGradient = applyGradient(["#92949c", "#6e6f75"]);

function create_pin_loc(
  map: google.maps.Map,
  pin_loc: MutableRefObject<google.maps.Marker | undefined>,
  lat: number,
  lng: number
) {
  pin_loc.current = new google.maps.Marker({
    position: new google.maps.LatLng(lat, lng),
    map: map,
    icon: {
      url: process.env.PUBLIC_URL + "/assets/icon/map/pin-loc.svg",
    },
  });
}

function change_pin_loc(pin_loc: google.maps.Marker, lat: number, lng: number) {
  pin_loc.setPosition({ lat, lng });
}

function handle_pin_loc(
  gpsCoordinates: Coordinates | null,
  map: MutableRefObject<google.maps.Map | undefined>,
  mapEle: React.RefObject<HTMLDivElement>,
  pin_loc: MutableRefObject<google.maps.Marker | undefined>,
  fixed_location: boolean = false
) {
  if (gpsCoordinates && map.current && mapEle.current) {
    if (pin_loc.current)
      change_pin_loc(pin_loc.current, gpsCoordinates.lat, gpsCoordinates.lng);
    else {
      var map_center = map.current.getCenter();
      if (
        map_center &&
        !fixed_location &&
        (Math.abs(map_center.lat() - gpsCoordinates.lat) > 0.01 ||
          Math.abs(map_center.lng() - gpsCoordinates.lng) > 0.01)
      ) {
        console.log("moving to new coordinates");
        map.current.setCenter({
          lat: gpsCoordinates.lat,
          lng: gpsCoordinates.lng,
        });
      }
      create_pin_loc(
        map.current,
        pin_loc,
        gpsCoordinates.lat,
        gpsCoordinates.lng
      );
    }
  }
}

function get_event_by_id(events: Event[], event_id: number) {
  for (let i = 0; i < events.length; i++) {
    const e = events[i];

    if (event_id === e.eventId) {
      return e;
    }
  }
  return;
}

function get_events_by_attends(events: Event[], event_ids: number[]) {
  var selected_events: Event[] = [];

  for (let i = 0; i < events.length; i++) {
    const e = events[i];

    if (event_ids.includes(e.eventId)) {
      selected_events.push(e);
    }
  }

  let priorities = ["DECLINED", "INVITED", "MAYBE", "GOING", null];
  let prio: Event | undefined;

  for (let i = 0; i < selected_events.length; i++) {
    if (selected_events[i].isHost) return selected_events[i];
    if (!selected_events[i].attends) continue;
    if (!prio) prio = selected_events[i];
    if (
      priorities.indexOf(selected_events[i].attends) >
      priorities.indexOf(prio.attends)
    )
      prio = selected_events[i];
  }

  return prio;
}

function get_events_by_ids(events: Event[], event_ids: number[]): Event[] {
  var selected_events: Event[] = [];

  for (let i = 0; i < events.length; i++) {
    const e = events[i];

    if (event_ids.includes(e.eventId)) {
      selected_events.push(e);
    }
  }

  return selected_events;
}

function get_numbered_icon(count: number) {
  const fontSize = count < 10 ? 16 : 14;
  const countToShow = count < 100 ? count : "99+";
  return [
    `<style>.text { font: bold ${fontSize}px sans-serif;; fill:{{ icon_color }}; opacity: ${OPACITY} }</style>`,
    `<text x="50%" y="24" text-anchor="middle" class="text">${countToShow}</text>`,
  ].join("");
}

function get_pin_icon(
  event: Event | undefined,
  tags: Set<string>,
  count?: number
): string {
  if (count && count > 1) {
    return get_numbered_icon(count);
  } else if (tags.has("concert")) {
    return musicIcon;
  } else if (tags.has("gastronomy")) {
    return foodIcon;
  } else if (tags.has("sport")) {
    return sportIcon;
  } else if (tags.has("theatre")) {
    return theaterIcon;
  } else if (tags.has("cinema")) {
    return cinemaIcon;
  } else if (tags.has("exhibition")) {
    return exhibitionIcon;
  } else if (tags.has("education")) {
    return educationIcon;
    // } else if (event?.public === false) {
    //   return lockClosedIcon;
  } else {
    // return helpIcon;
    // return "";
    return get_numbered_icon(1);
  }
}

function get_place_pin_icon(tag: string): string {
  if (tag === "concert") {
    return musicIcon;
  } else if (tag === "gastronomy") {
    return foodIcon;
  } else if (tag === "sport") {
    return sportIcon;
  } else if (tag === "theatre") {
    return theaterIcon;
  } else if (tag === "cinema") {
    return cinemaIcon;
  } else if (tag === "exhibition") {
    return exhibitionIcon;
  } else if (tag === "education") {
    return educationIcon;
  } else {
    return helpIcon;
  }
}

// function init_pin_color(event: Event | undefined) {
//   if (!event) return "white";
//   const now = moment();
//   const end = calc_end_date(event);
//   if (
//     event.attends === "GOING" &&
//     moment(end) > now &&
//     moment(event.start).subtract(1, "hour") < now
//   )
//     return "ongoing";
//   else if (event.isHost) return "organiser";
//   else return event.attends?.toLowerCase() || "white";
// }

function open_thumbmail(
  events: Event[],
  marker_container: MarkerContainer,
  marker_container_key: string,
  activated_markers: google.maps.Marker[],
  setSelectedEvents: React.Dispatch<React.SetStateAction<Event[]>>
) {
  if (
    !(marker_container_key in marker_container) ||
    marker_container[marker_container_key].active.size === 0
  )
    return;
  var selected_events = get_events_by_ids(
    events,
    Array.from(marker_container[marker_container_key].active)
  );
  const tags = getLevelOneTags(selected_events.flatMap((ev) => ev.tags));
  const active_event_by_attends = get_events_by_attends(
    events,
    Array.from(marker_container[marker_container_key].active)
  );

  marker_container[marker_container_key].marker.setIcon(
    set_pin({
      event: active_event_by_attends,
      clicked: true,
      count: marker_container[marker_container_key].active.size,
      tags,
    })
  );
  activated_markers.push(marker_container[marker_container_key].marker);
  setSelectedEvents(selected_events);
}

function close_thumbnails(
  marker_container: MarkerContainer,
  activated_markers: google.maps.Marker[],
  selectedEvents: Event[],
  setSelectedEvents: React.Dispatch<React.SetStateAction<Event[]>>,
  setOpenThumbnailKey: React.Dispatch<React.SetStateAction<string | undefined>>,
  events: Event[]
) {
  const tags = getLevelOneTags(selectedEvents.flatMap((e) => e.tags));
  activated_markers.forEach((activated_marker) => {
    const key = activated_marker.getTitle();

    if (!key) return;

    const active_event_by_attends = get_events_by_attends(
      events,
      Array.from(marker_container[key].active)
    );

    activated_marker.setIcon(
      set_pin({
        event: active_event_by_attends,
        count: selectedEvents.length,
        clicked: false,
        tags,
      })
    );
  });
  activated_markers.length = 0;
  setSelectedEvents([]);
  setOpenThumbnailKey(undefined);
}

function get_gps_key(lat: number | undefined, lng: number | undefined): string {
  return `${lat?.toFixed(4)}-${lng?.toFixed(4)}`;
}

function marker_in_container(
  marker_container: MarkerContainer,
  event: Event
): boolean {
  if (!event.location?.lat || !event.location?.lng) return false;
  const key = get_gps_key(event.location?.lat, event.location?.lng);
  return (
    key in marker_container // && event.eventId in marker_container[key].active
  );
}

function hide_marker(
  marker_container: MarkerContainer,
  events: Event[],
  event: Event,
  selectedEvents: Event[],
  setSelectedEvents: React.Dispatch<React.SetStateAction<Event[]>>
) {
  if (!event.location?.lat || !event.location?.lng) return;
  const key = get_gps_key(event.location?.lat, event.location?.lng);
  const tags = getLevelOneTags(selectedEvents.flatMap((e) => e.tags));
  if (
    key in marker_container &&
    (marker_container[key].active.has(event.eventId) ||
      moment(calc_end_date(event)) < moment())
  ) {
    marker_container[key].active.delete(event.eventId);

    setSelectedEvents(
      selectedEvents.filter((e) => e.eventId !== event.eventId)
    );

    if (
      (marker_container[key].active.size === 0 ||
        moment(calc_end_date(event)) < moment()) &&
      marker_container[key].marker.getMap()
    ) {
      marker_container[key].marker.setMap(null);
    } else if (
      marker_container[key].active.size === 1 &&
      marker_container[key].marker.getMap()
    ) {
      const last_event = get_event_by_id(
        events,
        Array.from(marker_container[key].active)[0]
      );

      if (last_event)
        marker_container[key].marker.setIcon(
          set_pin({
            event: last_event,
            count: marker_container[key].active.size,
            tags,
          })
        );
    }
  }
}

function show_marker(
  marker_container: MarkerContainer,
  events: Event[],
  event: Event,
  map: google.maps.Map
) {
  if (!event.location?.lat || !event.location?.lng) return;
  const key = get_gps_key(event.location?.lat, event.location?.lng);

  if (key in marker_container) {
    if (!marker_container[key].active.has(event.eventId)) {
      marker_container[key].active.add(event.eventId);
      var selected_events = get_events_by_ids(
        events,
        Array.from(marker_container[key].active)
      );
      const tags = getLevelOneTags(selected_events.flatMap((e) => e.tags));
      const active_event_by_attends = get_events_by_attends(
        events,
        Array.from(marker_container[key].active)
      );

      marker_container[key].marker.setIcon(
        set_pin({
          event: active_event_by_attends,
          count: marker_container[key].active.size,
          tags: tags,
        })
      );
    }
    if (!marker_container[key].marker.getMap())
      marker_container[key].marker.setMap(map);
  }
}

function clean_unused_markers(
  marker_container: MarkerContainer,
  events: Event[],
  selectedEvents: Event[],
  setSelectedEvents: React.Dispatch<React.SetStateAction<Event[]>>
) {
  for (const [key, value] of Object.entries(marker_container)) {
    const events_with_key: number[] = events
      .filter((e) => get_gps_key(e?.location?.lat, e?.location?.lng) === key)
      .map((e) => e.eventId);

    const keys_to_remove: number[] = Array.from(value.active).filter(
      (x) => !events_with_key.includes(x)
    );

    if (keys_to_remove.length === 0) continue;

    setSelectedEvents(
      selectedEvents.filter((e) => !keys_to_remove.includes(e.eventId))
    );

    value.active = new Set(
      Array.from(value.active).filter((x) => !keys_to_remove.includes(x))
    );
    var selected_events = get_events_by_ids(
      events,
      Array.from(marker_container[key].active)
    );
    const tags = getLevelOneTags(selected_events.flatMap((e) => e.tags));

    if (value.active.size === 0 && value.marker.getMap()) {
      value.marker.setMap(null);
    } else if (value.active.size >= 1 && value.marker.getMap()) {
      const last_event = get_event_by_id(events, Array.from(value.active)[0]);

      value.marker.setIcon(
        set_pin({
          event: last_event,
          count: value.active.size,
          tags,
        })
      );
    }
  }
}

function set_pin({
  event,
  clicked = false,
  count,
  tags,
}: {
  event: Event | undefined;
  tags: Set<string>;
  clicked?: boolean;
  count?: number;
}): string {
  let pin_type = get_pin_icon(event, tags, count);
  // let pin_type = count ? get_numbered_icon(count) : "";
  // let color = "";
  // let borderColor = "#8a2ab5";
  // let gradient: string | null = null;
  // if (event && moment(calc_end_date(event)) < moment()) {
  //   color = "#92949c"; // --ion-color-medium
  // } else if (clicked) {
  //   color = "#8a2ab5";
  // } else {
  //   color = init_pin_color(event);
  //   switch (color) {
  //     case "ongoing":
  //       gradient = ongoingGradient;
  //       break;
  //     case "organiser":
  //       gradient = organiserGradient;
  //       break;
  //     case "invited":
  //       gradient = invitedGradient;
  //       break;
  //     case "maybe":
  //       gradient = maybeGradient;
  //       break;
  //     case "going":
  //       gradient = goingGradient;
  //       break;
  //     case "declined":
  //       gradient = declinedGradient;
  //       break;
  //     default:
  //       break;
  //   }
  // }

  const color = clicked ? "#9E35E5" : "#FFFFFF";
  const borderColor = clicked ? "#FFFFFF" : "#9E35E5";
  const iconColor = clicked ? "#FFFFFF" : "#000000";

  const template = count && count > 1 ? pin_multi_template : pin_template;

  let svg = template.replace("{{ pin_type }}", pin_type);

  // if (gradient) {
  //   svg = svg
  //     // .replace("{{ border_color }}", borderColor)
  //     .replace("{{ color }}", "url(#grad1)")
  //     .replace("{{ defs }}", gradient);
  // } else {
  svg = svg
    .replaceAll("{{ border_color }}", borderColor)
    .replaceAll("{{ color }}", color)
    .replaceAll("{{ icon_color }}", iconColor);
  // .replace("{{ defs }}", "");
  // }
  return "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svg);
}

export function set_pin_place_detail(tag: string): string {
  let pin_type = get_place_pin_icon(tag);
  let color = "white";
  let borderColor = "#8a2ab5";
  let gradient: string | null = null;

  let svg = pin_template.replace("{{ pin_type }}", pin_type);
  if (gradient) {
    svg = svg
      .replace("{{ border_color }}", borderColor)
      .replace("{{ color }}", "url(#grad1)")
      .replace("{{ defs }}", gradient);
  } else {
    svg = svg
      .replace("{{ border_color }}", borderColor)
      .replace("{{ color }}", color)
      .replace("{{ defs }}", "");
  }
  return "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svg);
}

function set_aggregated_pin(count: number): string {
  return set_pin({
    tags: new Set(),
    event: undefined,
    count: count,
  });
  // const pin_type = get_numbered_icon(count);
  // let color = "white";
  // let borderColor = "#8a2ab5";
  // let svg = pin_template.replace("{{ pin_type }}", pin_type);
  // svg = svg
  //   .replace("{{ border_color }}", borderColor)
  //   .replace("{{ color }}", color)
  //   .replace("{{ defs }}", "");
  // return "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svg);
}

function calcAggregationLevel(zoomLevel: number) {
  if (zoomLevel > 14) return 0;
  if (zoomLevel < 5) return 3000;

  switch (zoomLevel) {
    case 14:
      return 2;
    case 13:
      return 4;
    case 12:
      return 14;
    case 11:
      return 22;
    case 10:
      return 50;
    case 9:
      return 90;
    case 8:
      return 170;
    case 7:
      return 300;
    case 6:
      return 650;
    case 5:
      return 1500;
    default:
      return 0;
  }
}

function gps_distance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: string
) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    if (dist >= 10) return Math.round(dist);
    else return Math.round(dist * 10) / 10;
  }
}

export {
  handle_pin_loc,
  open_thumbmail,
  close_thumbnails,
  get_gps_key,
  marker_in_container,
  hide_marker,
  // hide_aggregated_marker,
  show_marker,
  // show_aggregated_marker,
  clean_unused_markers,
  set_pin,
  set_aggregated_pin,
  calcAggregationLevel,
  gps_distance,
};
