import { useEffect, useState } from "react";
import useAxios from "./useAxios";
import useStorage from "./useStorage";
import { useQuery } from "react-query";
import useTranlatedMsg from "./useTranslatedMsg";

type ValidatedLocation = {
  country: {
    slug: string;
    name: string;
  } | null;
};

const useValitadeLocation = (
  coordinates: google.maps.LatLngLiteral | undefined
) => {
  const { axios } = useAxios();
  const { setMessage } = useStorage();
  const [isNotValid, setIsNotvalid] = useState<boolean>(false);
  const [coords, setCoodrs] = useState<google.maps.LatLngLiteral | undefined>(
    undefined
  );
  const {went_wrong}= useTranlatedMsg()

  useEffect(() => {
    if (coordinates) setCoodrs(coordinates);
  }, [coordinates]);

  const validatedLocation = useQuery(
    ["validate_location", coords],
    () =>
      axios.get<ValidatedLocation>("/regions", {
        params: {
          lat: coords?.lat,
          lng: coords?.lng,
        },
      }),
    {
      select: (res) => res.data,
      refetchOnWindowFocus: false,
      onError: (err) => setMessage("danger", went_wrong, err),
      enabled: !!coords,
    }
  );

  useEffect(() => {
    if (!validatedLocation.data) return;

    if (validatedLocation.data.country !== null) setIsNotvalid(false);
    else setIsNotvalid(true);
  }, [validatedLocation.data]);

  return isNotValid;
};

export default useValitadeLocation;
