import { useContext } from "react";

import { StateStorage } from "../contexts/StateStorage";
import { QueryStorage } from "../contexts/QueryStorage";

const useStorage = () => {
  const stateStorage = useContext(StateStorage);
  const queryStorage = useContext(QueryStorage);

  return {
    ...stateStorage,
    ...queryStorage,
  };
};

export default useStorage;
