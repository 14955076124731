import {
  IonButton,
  IonIcon,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonPopover,
  IonRadio,
  IonRadioGroup,
  IonTitle,
  IonSearchbar,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  createGesture,
} from "@ionic/react";
import { useHistory } from "react-router";
import { expandLess, expandMore } from "../icons/iconsFill";
import { close as closeIcon } from "../icons/iconsOutline";
import { AggregatedEvent, Event as Ev } from "../models";
import "./HomePage.scss";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useCallback, useEffect, useRef, useState, useTransition } from "react";
import { MAP_STYLES } from "../constants/map";
import useAxios from "../hooks/useAxios";
import { useInfiniteQuery, useQuery } from "react-query";
import { useImpreciseGps } from "../hooks/useGps";
import { set_aggregated_pin } from "../helpers/map";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { EventBox } from "./Event";
import { isDevelopment } from "../config";
import { useNavigation, useStorage } from "../hooks";
import { City } from "../models/City";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "@ionic/react/css/ionic-swiper.css";
import { get_default_gps, get_region } from "../cache/coordinates";
import { useTranslation } from "react-i18next";
import useTranlatedMsg from "../hooks/useTranslatedMsg";

const MapThumbnail: React.FC = () => {
  const history = useHistory();
  const { axios } = useAxios();
  const currentLoc = useImpreciseGps();
  const { preferredLoc, mapApiLoaded } = useStorage();
  const zoom = useRef<number>(13);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const mapOverlay = useRef<HTMLDivElement>(null);
  const [mapBounds, setMapBounds] = useState<google.maps.LatLngBoundsLiteral>();
  const mapEle = useRef<HTMLDivElement>(null);
  const [mapInitialised, setMapInitialised] = useState<boolean>(false);

  // TODO: this is duplicate with Search.tsx
  useEffect(() => {
    const int = setInterval(() => {
      if (isDevelopment)
        console.log("MAP INIT Interval", mapEle.current?.offsetWidth);
      if (mapEle.current?.offsetWidth && !mapInitialised) {
        setMapInitialised(true);
        clearInterval(int);
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      // const bounds = new window.google.maps.LatLngBounds(load_coordinates());
      // map.fitBounds(bounds);
      setMap(map);
    },
    [setMap]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, [setMap]);

  const onDrag = useCallback(() => {
    history.push({ pathname: "/search", state: "map" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBoundsChanged = useCallback(() => {
    setMapBounds(map?.getBounds()?.toJSON());
  }, [map]);

  useEffect(() => {
    if (!mapOverlay.current) return;

    const gesture = createGesture({
      el: mapOverlay.current,
      gestureName: "anything",
      threshold: 0,
      onStart: () => {},
      onEnd: () => {
        onDrag();
      },
    });
    gesture.enable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapOverlay.current]);

  const { data: aggEvents } = useQuery(
    ["home_map", mapBounds?.north],
    () => {
      return axios.get<AggregatedEvent[]>("/events", {
        params: {
          source: "home_map",
          a_lat: mapBounds?.north,
          a_lng: mapBounds?.east,
          b_lat: mapBounds?.south,
          b_lng: mapBounds?.west,
          lat: currentLoc?.lat,
          lng: currentLoc?.lng,
          aggregation_level: 4,
          filter_date: "future",
        },
      });
    },
    {
      select: (res) => res.data,
      enabled: !!mapBounds,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className="map_wrapper" ref={mapEle}>
      {mapApiLoaded && mapInitialised && (
        <>
          <div
            className="map_overlay"
            ref={mapOverlay}
            onClick={() => onDrag()}
          ></div>
          <GoogleMap
            mapContainerClassName="map-hp"
            center={preferredLoc}
            zoom={zoom.current}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onBoundsChanged={onBoundsChanged}
            onDrag={onDrag}
            options={{
              disableDefaultUI: true,
              styles: MAP_STYLES,
              zoomControl: false,
              scrollwheel: false,
              disableDoubleClickZoom: true,
              draggable: false,
              keyboardShortcuts: false,
            }}
          >
            {aggEvents?.map((ev, i) => {
              return (
                <Marker
                  position={{ lat: ev.lat, lng: ev.lng }}
                  key={i}
                  options={{ icon: set_aggregated_pin(ev.count) }}
                />
              );
            })}
          </GoogleMap>
        </>
      )}
    </div>
  );
};

const QFCitiesPopover: React.FC<{
  showPopover: boolean;
  setShowPopover: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ showPopover, setShowPopover }) => {
  const { setCustomGps, customGps, setMessage, gpsCoordinates } = useStorage();
  const { axios } = useAxios();
  const [searchValue, setSearchValue] = useState<string | null | undefined>("");
  const { t } = useTranslation();
  const { err_cities_load } = useTranlatedMsg();

  const { data: loadedCities, fetchNextPage } = useInfiniteQuery(
    ["found_cities", searchValue],
    ({ pageParam = 0 }) => {
      return axios.get<City[]>("/cities", {
        params: {
          fulltext: searchValue,
          limit: 40,
          offset: pageParam,
          region: get_region(),
        },
      });
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length < 40) return;
        else return pages.length * 40;
      },
      onError: (error) => {
        setMessage("danger", err_cities_load, error);
      },
      refetchOnWindowFocus: false,
    }
  );

  const loadMore = async (e: any) => {
    await fetchNextPage();
    e.target.complete();
  };

  const loadedCitiesFlat: City[] =
    loadedCities?.pages.flatMap((page) => page.data) || [];

  return (
    <IonPopover
      isOpen={showPopover}
      onDidDismiss={() => setShowPopover(false)}
      className="popover_quick_filters high"
    >
      <IonHeader className="popover-qf-header">
        <IonTitle size="small">{t("homepage.city_choice")}</IonTitle>
      </IonHeader>
      <IonContent>
        <IonList lines="none">
          <IonRadioGroup
            value={
              customGps
                ? customGps.name
                : gpsCoordinates
                ? "Current location"
                : get_default_gps().name
            }
          >
            {gpsCoordinates !== null && (
              <IonItem
                onClick={() => {
                  setShowPopover(false);
                  setCustomGps(null);
                }}
              >
                <IonRadio mode="md" value="Current location">
                  {t("hompage.current_location")}
                </IonRadio>
              </IonItem>
            )}{" "}
            <IonItem>
              <IonSearchbar
                className="city_search"
                onIonClear={() =>
                  Capacitor.isNativePlatform() && Keyboard.hide()
                }
                placeholder={t("homepage.search_bar")}
                onIonInput={(e) => {
                  setSearchValue(e.target.value);
                }}
                data-sl="unmask"
                clearIcon={closeIcon}
                autocomplete="on"
              />
            </IonItem>
            {loadedCitiesFlat?.map((lCity) => {
              return (
                <IonItem
                  key={lCity.name}
                  onClick={() => {
                    setShowPopover(false);
                    setCustomGps(lCity);
                  }}
                >
                  <IonRadio mode="md" value={lCity.name}>
                    {lCity.name}
                  </IonRadio>
                </IonItem>
              );
            })}
          </IonRadioGroup>
        </IonList>
        <IonInfiniteScroll onIonInfinite={loadMore} threshold="200px">
          <IonInfiniteScrollContent loadingSpinner="dots" />
        </IonInfiniteScroll>
      </IonContent>
    </IonPopover>
  );
};

export const HeaderMap: React.FC = () => {
  const [showCitiesPopover, setShowCitiesPopover] = useState<boolean>(false);
  const currentLoc = useImpreciseGps();
  const { customGps } = useStorage();
  const { t } = useTranslation();

  return (
    <header className="mainpage-header container">
      <div className="mainpage-header-box">
        <h4 className="mainpage-header-txt">{t("homepage.events_in_area")}</h4>
        <IonButton
          size="small"
          fill="clear"
          className="mainpage-header-btn location-btn"
          onClick={() => setShowCitiesPopover(true)}
        >
          {customGps
            ? customGps.name
            : currentLoc
            ? "Current location"
            : get_default_gps().name}
          <IonIcon icon={showCitiesPopover ? expandLess : expandMore} />
        </IonButton>
      </div>

      <MapThumbnail />

      <QFCitiesPopover
        showPopover={showCitiesPopover}
        setShowPopover={setShowCitiesPopover}
      />
    </header>
  );
};

export const EventsSlides: React.FC<{
  headerText: string;
  events?: Ev[] | undefined;
  slug: string;
  source: string;
}> = ({ headerText, events, slug, source }) => {
  const history = useHistory();
  const { navigate } = useNavigation();
  return (
    <>
      <div className="mainpage-header-box container">
        <h4 className="mainpage-header-txt">
          {/* <img
            className="header-icon"
            src="/assets/icon/new/purple-stars.svg"
            alt="icon"
          /> */}

          {headerText}
        </h4>
        <IonButton
          size="small"
          fill="clear"
          className="mainpage-header-btn"
          onClick={() => {
            if (slug === "following_places_events") navigate(`search/${slug}`);
            else history.push({ pathname: "/search", state: slug });
          }}
        >
          More
        </IonButton>
      </div>
      <Swiper
        slidesPerView={"auto"}
        initialSlide={0}
        speed={400}
        className="event_boxes-hp"
      >
        {events?.map((event, i) => {
          return (
            <SwiperSlide key={event.eventId}>
              <EventBox
                event={event}
                source={source}
                order={i + 1}
                slug={slug}
              />
            </SwiperSlide>
          );
        })}
        {/* <SwiperSlide className="slide-big-btn-box">
          <IonButton
            className="mainpage-big-btn"
            color="medium"
            shape="round"
            size="large"
            fill="clear"
            onClick={() => {
              history.push({ pathname: "/search", state: slug });
            }}
          >
            <IonIcon
              icon={expandCircleDownFill}
              size="large"
              color=""
            ></IonIcon>
          </IonButton>
          Show more
        </SwiperSlide> */}
      </Swiper>
    </>
  );
};
