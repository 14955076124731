import React, { useState } from "react";
import { Formik } from "formik";
import {
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonSelectOption,
  IonSelect,
  IonInput,
  IonIcon,
  isPlatform,
} from "@ionic/react";
import PhonePrefixes from "../constants/phone_prefixes";

import { LoginData, loginSchema } from "../models";
import { useNavigation, useStorage } from "../hooks";
import { Auth0AppleLoginButton, Auth0GoogleLoginButton } from "./auth0";
import { isNative } from "../config";
import { call, lock, visibility, visibilityOff } from "../icons/iconsOutline";
import { get_region } from "../cache/coordinates";

const LoginForm: React.FC = () => {
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const { postLogin } = useStorage();
  const { mutateAsync: login } = postLogin;
  const { navigate } = useNavigation();

  return (
    <>
      {/* <img
        src={process.env.PUBLIC_URL + "/assets/logomark.png"}
        className="logo"
        alt="logo"
      /> */}
      <IonIcon className="logo" icon="/assets/icon/new/logo.svg" />
      <Formik
        initialValues={{
          phonePrefix: get_region() === "usa" ? "+1" : "+420",
          phone: "",
          password: "",
        }}
        validationSchema={loginSchema}
        onSubmit={(formData: LoginData) => login(formData)}
      >
        {({
          dirty,
          values,
          errors,
          touched,
          isValid,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <IonGrid className="big_form">
            <IonRow className="navigation">
              <IonCol>Log in</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="2" className="label">
                <IonLabel>
                  <IonIcon className="label_icon" icon={call} />
                </IonLabel>
              </IonCol>
              <IonCol size="3" className="left_select">
                <IonSelect
                  aria-label="phonePrefix"
                  name="phonePrefix"
                  interfaceOptions={{ header: "Select country code" }}
                  value={values.phonePrefix}
                  placeholder="prefix"
                  className={
                    touched.phonePrefix && errors.phonePrefix ? "error" : ""
                  }
                  onIonChange={handleChange}
                  onIonBlur={(e) => {
                    handleChange(e);
                    handleBlur(e);
                  }}
                >
                  {PhonePrefixes?.map(({ dial_code, flag }) => {
                    return (
                      <IonSelectOption key={dial_code} value={dial_code}>
                        {flag} {dial_code}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
                {touched.phonePrefix && errors.phonePrefix ? (
                  <div className="error">{errors.phonePrefix}</div>
                ) : null}
              </IonCol>
              <IonCol size="7">
                <IonInput
                  aria-label="phone"
                  name="phone"
                  type="tel"
                  inputMode="tel"
                  value={values.phone}
                  minlength={9}
                  maxlength={10}
                  autocomplete="tel"
                  placeholder="number"
                  className={touched.phone && errors.phone ? "error" : ""}
                  onIonInput={handleChange}
                  onIonBlur={(e) => {
                    handleChange(e);
                    handleBlur(e);
                  }}
                />
                {touched.phone && errors.phone ? (
                  <div className="error">Invalid phone</div>
                ) : null}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="2" className="label">
                <IonLabel>
                  <IonIcon className="label_icon" icon={lock} />
                </IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonInput
                  aria-label="password"
                  name="password"
                  type={passwordIsVisible ? "text" : "password"}
                  value={values.password}
                  placeholder="password"
                  className={touched.password && errors.password ? "error" : ""}
                  onIonInput={handleChange}
                  onIonBlur={(e) => {
                    handleChange(e);
                    handleBlur(e);
                  }}
                />
                {touched.password && errors.password ? (
                  <div className="error">Enter password</div>
                ) : null}
              </IonCol>
              <IonCol size="2" className="label">
                <IonButton
                  fill="clear"
                  size="small"
                  onClick={() => setPasswordIsVisible(!passwordIsVisible)}
                >
                  <IonIcon
                    icon={passwordIsVisible ? visibilityOff : visibility}
                  />
                </IonButton>
              </IonCol>
            </IonRow>

            <IonRow className="big_button_row">
              <IonCol className="label">
                <IonButton
                  type="submit"
                  className="btn-social btn-social-primary"
                  disabled={isSubmitting || !isValid || !dirty}
                  onClick={() => handleSubmit()}
                >
                  Log in
                </IonButton>
              </IonCol>
            </IonRow>

            <IonRow className="alternative_action">
              <IonCol>
                <span>or</span>
              </IonCol>
            </IonRow>

            {isNative && (
              <IonRow className="big_button_row">
                <IonCol>
                  <Auth0GoogleLoginButton type="login" />
                </IonCol>
              </IonRow>
            )}

            {isNative && isPlatform("ios") && (
              <IonRow className="big_button_row">
                <IonCol>
                  <Auth0AppleLoginButton type="login" />
                </IonCol>
              </IonRow>
            )}

            <IonRow className="big_button_row">
              <IonCol className="label">
                <IonButton
                  className="big_button"
                  fill="outline"
                  onClick={() => {
                    navigate("/registration");
                  }}
                >
                  Register
                </IonButton>
              </IonCol>
            </IonRow>

            <IonRow className="navigation_bottom">
              <IonCol>
                <IonButton
                  onClick={() => {
                    navigate("/reset_password");
                  }}
                  fill="clear"
                >
                  Reset password
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
