import { useEffect, useRef, useState } from "react";

function useMapCenter() {
  const timeout = useRef<number | undefined>(undefined);

  const [center, setCenter] = useState<google.maps.LatLngLiteral>();
  const [centerDelayed, setCenterDelayed] =
    useState<google.maps.LatLngLiteral>();

  useEffect(() => {
    if (center && !centerDelayed) {
      setCenterDelayed(center);
    }
    if (!center) return;
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = window.setTimeout(setCenterDelayed, 1500, center);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);

  return { centerDelayed, setCenter };
}

export { useMapCenter };
