import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonButton,
  IonModal,
  IonIcon,
  IonSpinner,
  IonPopover,
  IonItem,
  IonList,
  IonHeader,
  IonCheckbox,
  IonRadioGroup,
  IonRadio,
  IonTitle,
} from "@ionic/react";
import "./Filters.scss";
import moment from "moment";
import { AmountOfEvents, Subtag, Tag } from "../models/Event";
import { FilterDateType, ifilter_dict } from "../models/eventFilter";
import { ModalHeader } from "./Headers";
import { Calendar } from "react-multi-date-picker";
import { uncheck_inappropriate_subtags } from "../hooks/useFilters";
import { useQuery } from "react-query";
import useAxios from "../hooks/useAxios";
import { search } from "../resources/events";
import { earth, list } from "../icons/iconsOutline";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const CategoriesFilter: React.FC<{
  tags: Tag[] | undefined;
  quick: boolean;
  value: Set<string> | null;
  setter: React.Dispatch<React.SetStateAction<Set<string>>>;
  subValue: Set<string> | null;
  subSetter: React.Dispatch<React.SetStateAction<Set<string>>>;
  closeQuickFilter?: () => void;
}> = ({
  tags,
  quick,
  value,
  setter,
  closeQuickFilter,
  subValue,
  subSetter,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (value && subValue)
      subSetter(uncheck_inappropriate_subtags(tags, value, subValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, tags]);

  return (
    <>
      {!quick && <h4>{t("filter.categories")}</h4>}
      <IonButton
        size="small"
        color={
          (typeof value !== "string" && value?.size === 0) || !quick
            ? ""
            : "light"
        }
        fill={
          (typeof value !== "string" && value?.size === 0) || quick
            ? "solid"
            : "outline"
        }
        onClick={() => {
          setter(new Set());
          if (closeQuickFilter) closeQuickFilter();
        }}
      >
        {t("filter.all_categories")}
      </IonButton>
      {tags?.map((t, _) => {
        return (
          value &&
          typeof value !== "string" && (
            <IonButton
              key={t.slug}
              size="small"
              color={value?.has(t.slug) || !quick ? "" : "light"}
              fill={value?.has(t.slug) || quick ? "solid" : "outline"}
              onClick={() => {
                if (value && typeof value !== "string") {
                  var arr = Array.from(value.values());
                  value.has(t.slug)
                    ? setter(new Set(arr.filter((v) => v !== t.slug)))
                    : setter(new Set([...arr, t.slug]));
                }
              }}
            >
              {t.name}
            </IonButton>
          )
        );
      })}
    </>
  );
};

const SubtagsFilter: React.FC<{
  tags: Tag[] | undefined;
  quick: boolean;
  value: Set<string> | null;
  setter: React.Dispatch<React.SetStateAction<Set<string>>>;
  subValue: Set<string> | null;
}> = ({ tags, quick, value, setter, subValue }) => {
  const { t } = useTranslation();
  return (
    <>
      <h4>{t("new_event.tags")}</h4>
      {value?.size === 0 && (
        <div className="pick-category"> {t("filter.tags_choice")}</div>
      )}
      {!quick && value?.size !== 0 && (
        <>
          <IonButton
            size="small"
            color={
              (typeof subValue !== "string" && subValue?.size === 0) || !quick
                ? ""
                : "light"
            }
            fill={
              (typeof subValue !== "string" && subValue?.size === 0) || quick
                ? "solid"
                : "outline"
            }
            onClick={() => {
              setter(new Set());
            }}
          >
            {t("filter.all_tags")}
          </IonButton>
        </>
      )}
      {tags &&
        filteredSortedTags(tags, value).map((t) => {
          return (
            <IonButton
              key={t.slug}
              size="small"
              color={subValue?.has(t.slug) || !quick ? "" : "light"}
              fill={subValue?.has(t.slug) || quick ? "solid" : "outline"}
              onClick={() => {
                if (subValue && typeof subValue !== "string") {
                  var arr = Array.from(subValue.values());
                  subValue.has(t.slug)
                    ? setter(new Set(arr.filter((v) => v !== t.slug)))
                    : setter(new Set([...arr, t.slug]));
                }
              }}
            >
              {t.name}
            </IonButton>
          );
        })}
    </>
  );
};

export function resetAllFilters(filters: ifilter_dict) {
  filters.dateFrom.setter(null);
  filters.dateTo.setter(null);
  filters.day.setter("future");

  filters.search.setter("");
  filters.tags.setter(new Set());
  filters.subtags.setter(new Set());
}

export function filteredSortedTags(
  categories: Tag[] | undefined,
  selectedCategory: Set<string> | null | string
) {
  let allTags: Subtag[] = [];
  // eslint-disable-next-line array-callback-return
  const filteredCategories = categories?.filter((category) => {
    if (selectedCategory instanceof Set)
      return selectedCategory?.has(category.slug);
    else if (typeof selectedCategory === "string")
      return selectedCategory === category.slug;
  });
  filteredCategories?.map((category) => allTags.push(...category.subtags));
  const uniqueTags = allTags.filter((tag, index, arr) => {
    return arr.findIndex((t) => t.slug === tag.slug) === index;
  });
  uniqueTags.sort((a, b) => b.priority - a.priority);
  return uniqueTags;
}

function datesForCalendar(dateFrom: Date | null, dateTo: Date | null) {
  if (!dateFrom && !dateTo) return;
  else if (dateFrom && !dateTo) return [dateFrom];
  else if (dateFrom && dateTo) return [dateFrom, dateTo];
}

export function dateRangeLabel(dateFrom: Date | null, dateTo: Date | null) {
  if (!dateFrom && !dateTo) return t("filter.range");
  else if (dateFrom && !dateTo)
    return `${moment(dateFrom).format("D. M.")} - ?`;
  else if (moment(dateFrom).isSame(moment(dateTo), "day"))
    return `${moment(dateFrom).format("D. M.")}`;
  else
    return `${moment(dateFrom).format("D. M.")} - ${moment(dateTo).format(
      "D. M."
    )}`;
}

const DaysFilter: React.FC<{
  value: FilterDateType;
  setter: React.Dispatch<React.SetStateAction<FilterDateType>>;
  dateFrom: Date | null;
  dateTo: Date | null;
  setDateFrom: React.Dispatch<React.SetStateAction<Date | null>>;
  setDateTo: React.Dispatch<React.SetStateAction<Date | null>>;
}> = ({ value, setter, setDateFrom, setDateTo, dateFrom, dateTo }) => {
  const [showPicker, setShowPicker] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    setShowPicker(value === "range");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <h4>{t("filter.days")}</h4>
      <IonButton
        size="small"
        fill={value === "future" ? "solid" : "outline"}
        onClick={() => setter("future")}
      >
        {t("filter.future")}
      </IonButton>
      <IonButton
        size="small"
        fill={value === "today" ? "solid" : "outline"}
        onClick={() => (value === "today" ? setter("future") : setter("today"))}
      >
        {t("filter.today")}
      </IonButton>
      <IonButton
        size="small"
        fill={value === "week" ? "solid" : "outline"}
        onClick={() => (value === "week" ? setter("future") : setter("week"))}
      >
        {t("filter.week")}
      </IonButton>
      <IonButton
        size="small"
        fill={value === "weekend" ? "solid" : "outline"}
        onClick={() =>
          value === "weekend" ? setter("future") : setter("weekend")
        }
      >
        {t("filter.weekend")}
      </IonButton>
      <IonButton
        size="small"
        fill={value === "30days" ? "solid" : "outline"}
        onClick={() =>
          value === "30days" ? setter("future") : setter("30days")
        }
      >
        {t("filter.30days")}
      </IonButton>

      <IonButton
        size="small"
        fill={value === "range" ? "solid" : "outline"}
        onClick={() => (value === "range" ? setter("future") : setter("range"))}
      >
        {dateRangeLabel(dateFrom, dateTo)}
      </IonButton>

      {showPicker && (
        <Calendar
          weekStartDayIndex={1}
          minDate={moment().toDate()}
          disableYearPicker={true}
          disableMonthPicker={true}
          shadow={false}
          range
          value={datesForCalendar(dateFrom, dateTo)}
          onChange={(dates) => {
            if (!dates) return;

            if (dates instanceof Array) {
              if (dates.length === 1) {
                setDateFrom(dates[0].toDate());
                setDateTo(null);
              }
              if (dates.length === 2) {
                setDateFrom(dates[0].toDate());
                setDateTo(dates[1].toDate());
              }
            }
          }}
        />
      )}
    </>
  );
};

export const Filters: React.FC<{
  showFilters: boolean;
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
  filters: ifilter_dict;
  tags: Tag[] | undefined;
  showSearchModal: boolean;
  map: google.maps.Map | undefined;
}> = ({ showFilters, setShowFilters, filters, tags, showSearchModal, map }) => {
  const [chosenDay, setChosenDay] = useState<FilterDateType>("future");
  const [chosenTags, setChosenTags] = useState<Set<string>>(new Set());
  const [chosenSubtags, setChosenSubtags] = useState<Set<string>>(new Set());
  const [chosenDateFrom, setChosenDateFrom] = useState<Date | null>(null);
  const [chosenDateTo, setChosenDateTo] = useState<Date | null>(null);

  function reset_form() {
    setChosenDay(filters.day.value);
    setChosenTags(filters.tags.value);
    setChosenSubtags(filters.subtags.value);
    setChosenDateFrom(filters.dateFrom.value);
    setChosenDateTo(filters.dateTo.value);
  }

  const applyChanges = () => {
    filters.tags.setter(chosenTags);
    filters.subtags.setter(chosenSubtags);
    if (chosenDay === "range" && chosenDateFrom && chosenDateTo)
      filters.day.setter(chosenDay);
    else if (chosenDay === "range") filters.day.setter("future");
    else filters.day.setter(chosenDay);
    filters.dateFrom.setter(chosenDateFrom);
    filters.dateTo.setter(chosenDateTo);
    setShowFilters(false);
  };

  useEffect(() => {
    reset_form();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.day.value,
    filters.tags.value,

    filters.subtags.value,
    filters.dateFrom.value,
    filters.dateTo.value,
  ]);

  // const onToggleChange = (e: CustomEvent<ToggleChangeEventDetail<any>>) => {
  //   e.preventDefault();
  //   filters.remember.setter(!filters.remember.value);
  // };

  const { axios } = useAxios();

  const { data: amountOfEvents, isLoading } = useQuery(
    [
      "amount_of_events",

      filters.search.value,
      chosenDay,
      Array.from(chosenTags).join(","),
      Array.from(chosenSubtags).join(","),
      chosenDateTo,
    ],
    () =>
      search<AmountOfEvents>(axios, {
        source: "filters_preview",

        preview: true,
        fulltext: filters.search.value,
        filter_date: chosenDay,
        tags: Array.from(chosenTags).join(","),
        subtags: Array.from(chosenSubtags).join(","),
        date_from: chosenDateFrom,
        date_to: chosenDateTo,
      }),
    { select: (res) => res.data, enabled: showFilters }
  );

  return (
    <IonModal
      isOpen={showFilters}
      onDidDismiss={() => setShowFilters(false)}
      className="filters"
    >
      <ModalHeader
        onClick={() => {
          setShowFilters(false);
          if (
            filters.day.value === "range" &&
            (!filters.dateFrom.value || !filters.dateTo.value)
          )
            filters.day.setter("future");
          reset_form();
        }}
        title="Filters"
      />

      <IonContent>
        <div className="content">
          {/* <IonRow className="profile_rowXXX">
            <IonCol size="7">
              <IonText color="dark">Remember my filters</IonText>
            </IonCol>
            <IonCol>
              <IonToggle
                aria-label="Remember my filters"
                onIonChange={onToggleChange}
                checked={filters.remember.value}
              ></IonToggle>
            </IonCol>
          </IonRow> */}
          <DaysFilter
            value={chosenDay}
            setter={setChosenDay}
            dateFrom={chosenDateFrom}
            dateTo={chosenDateTo}
            setDateFrom={setChosenDateFrom}
            setDateTo={setChosenDateTo}
          />

          <CategoriesFilter
            tags={tags}
            quick={false}
            value={chosenTags}
            setter={setChosenTags}
            subSetter={setChosenSubtags}
            subValue={chosenSubtags}
          />
          <SubtagsFilter
            tags={tags}
            quick={false}
            value={chosenTags}
            setter={setChosenSubtags}
            subValue={chosenSubtags}
          />
        </div>
      </IonContent>

      <IonButton className="modal_big_button" onClick={applyChanges}>
        {isLoading ? (
          <IonSpinner name="dots" />
        ) : (
          <>
            <IonIcon icon={showSearchModal ? list : earth} />{" "}
            {t("activities.show")} {amountOfEvents?.results}{" "}
            {amountOfEvents?.results === 1
              ? t("filter.event")
              : t("filter.events")}{" "}
            {showSearchModal ? t("filter.in_list") : t("filter.on_map")}
          </>
        )}
      </IonButton>
    </IonModal>
  );
};

export function dayFilterLabel(
  filter: FilterDateType,
  dateFrom: Date | null = null,
  dateTo: Date | null = null
) {
  if (filter === "range") return dateRangeLabel(dateFrom, dateTo);
  else return t(`filter.${DayFilterLabels[filter]}`);
}

const DayFilterLabels = {
  future: "future",
  today: "today",
  week: "week",
  weekend: "weekend",
  "30days": "30days",
  range: "range",
};

function get_tag_name(tags: Tag[], slug: string) {
  const t = tags.filter((t) => t.slug === slug);
  if (!t.length) return "";
  return t[0].name;
}

function get_subtag_name(subtags: Subtag[], slug: string) {
  const s = subtags.filter((s) => s.slug === slug);
  if (!s.length) return "";
  return s[0].name;
}

export function get_tags_filter_sum_label(
  tags: Tag[] | undefined,
  subTags: Subtag[] | undefined,
  value: Set<string> | null,
  subValue: Set<string> | null
) {
  if (!tags || !value || value.size === 0) return "All categories";
  if (subTags && subValue && subValue.size !== 0) {
    const tag_names = Array.from(subValue)
      .slice(0, 2)
      .map((v) => get_subtag_name(subTags, v));
    return subValue.size > 2
      ? tag_names.join(", ") + ",…"
      : tag_names.join(", ");
  } else if (tags && value) {
    const tag_names = Array.from(value)
      .slice(0, 2)
      .map((v) => get_tag_name(tags, v));

    return value.size > 2 ? tag_names.join(", ") + ",…" : tag_names.join(", ");
  }
}

export const QFDatePopover: React.FC<{
  filters: ifilter_dict;
  showPopover: boolean;
  setShowPopover: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
  dateFrom: Date | null;
  dateTo: Date | null;
}> = ({
  filters,
  dateFrom,
  dateTo,
  showPopover,
  setShowPopover,
  setShowFilters,
}) => {
  return (
    <IonPopover
      arrow={false}
      alignment="center"
      trigger="qf-dates-btn"
      isOpen={showPopover}
      onDidDismiss={() => setShowPopover(false)}
      className="popover_quick_filters qf-search-map"
    >
      <IonHeader className="popover-qf-header">
        <IonTitle size="small">{t("filter.filter_days")}</IonTitle>
      </IonHeader>
      <IonContent scrollY={false}>
        <IonList lines="none">
          <IonRadioGroup value={filters.day.value}>
            <IonItem
              onClick={() => {
                filters.day.setter("future");
                setShowPopover(false);
              }}
            >
              <IonRadio mode="md" value="future">
                {dayFilterLabel("future")}
              </IonRadio>
            </IonItem>
            <IonItem
              onClick={() => {
                filters.day.setter("today");
                setShowPopover(false);
              }}
            >
              <IonRadio mode="md" value="today">
                {dayFilterLabel("today")}
              </IonRadio>
            </IonItem>
            <IonItem
              onClick={() => {
                filters.day.setter("week");
                setShowPopover(false);
              }}
            >
              <IonRadio mode="md" value="week">
                {dayFilterLabel("week")}
              </IonRadio>
            </IonItem>
            <IonItem
              onClick={() => {
                filters.day.setter("weekend");
                setShowPopover(false);
              }}
            >
              <IonRadio mode="md" value="weekend">
                {dayFilterLabel("weekend")}
              </IonRadio>
            </IonItem>
            <IonItem
              onClick={() => {
                filters.day.setter("30days");
                setShowPopover(false);
              }}
            >
              <IonRadio mode="md" value="30days">
                {dayFilterLabel("30days")}
              </IonRadio>
            </IonItem>
            <IonItem
              onClick={() => {
                filters.day.setter("range");
                setShowPopover(false);
                setShowFilters(true);
              }}
            >
              <IonRadio mode="md" value="range">
                {dayFilterLabel("range", dateFrom, dateTo)}
              </IonRadio>
            </IonItem>
          </IonRadioGroup>
        </IonList>
      </IonContent>
    </IonPopover>
  );
};

export const QFCategoriesPopover: React.FC<{
  filters: ifilter_dict;
  showPopover: boolean;
  setShowPopover: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
  tags: Tag[] | undefined;
  value: Set<string> | null;
  setter: React.Dispatch<React.SetStateAction<Set<string>>>;
  closeQuickFilter?: () => void;
  subValue: Set<string> | null;
  subSetter: React.Dispatch<React.SetStateAction<Set<string>>>;
}> = ({
  tags,
  value,
  setter,
  closeQuickFilter,
  filters,
  showPopover,
  setShowPopover,
  setShowFilters,
  subSetter,
  subValue,
}) => {
  useEffect(() => {
    if (value && subValue)
      subSetter(uncheck_inappropriate_subtags(tags, value, subValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, tags]);
  return (
    <IonPopover
      arrow={false}
      alignment="center"
      trigger="qf-categories-btn"
      isOpen={showPopover}
      onDidDismiss={() => setShowPopover(false)}
      className="popover_quick_filters qf-search-map"
    >
      <IonHeader className="popover-qf-header">
        <IonTitle size="small">Filter categories</IonTitle>
      </IonHeader>
      <IonContent>
        <IonList lines="none">
          <IonItem>
            <IonCheckbox
              checked={filters.tags.value.size === 0}
              onIonChange={() => {
                setter(new Set());
                if (closeQuickFilter) closeQuickFilter();
              }}
            >
              {t("filter.all_categories")}
            </IonCheckbox>
          </IonItem>

          {tags?.map((t, _) => {
            return (
              value &&
              typeof value !== "string" && (
                <IonItem key={t.slug}>
                  <IonCheckbox
                    checked={value.has(t.slug)}
                    onIonChange={() => {
                      if (value && typeof value !== "string") {
                        var arr = Array.from(value.values());
                        value.has(t.slug)
                          ? setter(new Set(arr.filter((v) => v !== t.slug)))
                          : setter(new Set([...arr, t.slug]));
                      }
                    }}
                  >
                    {t.name}
                  </IonCheckbox>
                </IonItem>
              )
            );
          })}
          {filters.tags.value.size !== 0 && (
            <IonItem
              onClick={() => {
                setShowFilters(true);
                setShowPopover(false);
              }}
              button={true}
            >
              {t("filter.select_tags")}
            </IonItem>
          )}
        </IonList>
      </IonContent>
    </IonPopover>
  );
};
