import React, { useRef } from "react";
import {
  IonBackButton,
  IonButton,
  IonCard,
  IonCardHeader,
  IonContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonSearchbar,
} from "@ionic/react";

import {
  earth,
  search as searchIcon,
  close as closeIcon,
  filterSmall,
} from "../icons/iconsOutline";
import { Event } from "../models/Event";
import { EventItem } from "./Event";
import { EmptySection } from "./EmptySection";
import { useInfiniteQuery, useQuery } from "react-query";
import useAxios from "../hooks/useAxios";
import { search } from "../resources/events";
import { isInfQueryEmpty } from "../helpers/reactQuery";
import { SkeletonEventList } from "./Skeleton";
import { ifilter_dict } from "../models/eventFilter";
import { SCROLL_STEP } from "../constants/global";
import { City } from "../models/City";
import { useStorage } from "../hooks";
import { useImpreciseGps } from "../hooks/useGps";
import { Keyboard } from "@capacitor/keyboard";
import { Capacitor } from "@capacitor/core";
import { get_region } from "../cache/coordinates";
import { useTranslation } from "react-i18next";

const FulltextSearch: React.FC<{
  mapSearchText: string;
  setMapSearchText: React.Dispatch<React.SetStateAction<string>>;
  setBoundsChangedSignal: React.Dispatch<React.SetStateAction<number>>;
  highlighted: boolean;
  searchBarValue: React.MutableRefObject<any>;
  // readOnly: boolean;
}> = ({
  mapSearchText,
  setMapSearchText,
  setBoundsChangedSignal,
  highlighted,
  searchBarValue,
  // readOnly,
}) => {
  const search_lock = useRef<number | undefined>(undefined);
  const { t } = useTranslation();
  return (
    <IonSearchbar
      // disabled={readOnly}
      onIonClear={() => {
        if (Capacitor.isNativePlatform())
          setTimeout(() => Keyboard.hide(), 100);
      }}
      ref={searchBarValue}
      placeholder={t("map.search_events")}
      onClick={() => {
        setBoundsChangedSignal(Math.random());
      }}
      onIonInput={(e) => {
        if (search_lock.current) {
          clearTimeout(search_lock.current);
        }
        search_lock.current = window.setTimeout(
          setMapSearchText,
          1000,
          e.target.value!
        );
      }}
      className={[
        "smartlook-show",
        "map_search",
        "map_search_narrow",
        highlighted ? "highlighted" : "",
      ].join(" ")}
      data-sl="unmask"
      showClearButton={"always"}
      showCancelButton="never"
      cancelButtonText="Cancel"
      clearIcon={closeIcon}
      autocomplete="off"
    />
  );
};

export const SearchResults: React.FC<{
  filters: ifilter_dict;
  setTab: React.Dispatch<React.SetStateAction<string | undefined>>;
  // searchBarValue: React.MutableRefObject<any>;
  centerDelayed: google.maps.LatLngLiteral | undefined;
  setBoundsChangedSignal: React.Dispatch<React.SetStateAction<number>>;
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
  redirectBack: boolean;
}> = ({
  filters,
  setTab,
  // searchBarValue,
  centerDelayed,
  setBoundsChangedSignal,
  setShowFilters,
  redirectBack,
}) => {
  const { axios } = useAxios();
  const {
    setCustomGps,
    currentUser: user,
    interestsCategories,
    interestsTags,
  } = useStorage();
  const currentLoc = useImpreciseGps();
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined);
  const searchBarValue = useRef<any>(null);

  const {
    data: displayEvents,
    fetchNextPage,
    isLoading,
  } = useInfiniteQuery(
    [
      "search",
      filters.search.value,
      filters.day.value,
      Array.from(filters.tags.value).join(","),
      Array.from(filters.subtags.value).join(","),
      centerDelayed,
      filters.dateTo.value,
      user?.interests?.length,
    ],
    ({ pageParam = 0 }) =>
      search<Event[]>(axios, {
        source: "search_list",
        offset: pageParam,
        fulltext: filters.search.value,
        filter_date: filters.day.value,
        tags: Array.from(filters.tags.value).join(","),
        subtags: Array.from(filters.subtags.value).join(","),
        interests_tags: Array.from(interestsCategories).join(","),
        interests_subtags: Array.from(interestsTags).join(","),
        lat: centerDelayed?.lat,
        lng: centerDelayed?.lng,
        ref_lat: currentLoc?.lat,
        ref_lng: currentLoc?.lng,
        date_from: filters.dateFrom.value,
        date_to: filters.dateTo.value,
        order_by: "smart_extended",
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length < SCROLL_STEP) return;
        else return pages.flatMap((page) => page.data).length;
      },
      refetchOnWindowFocus: false,
    }
  );

  const loadMore = async (e: any) => {
    await fetchNextPage();
    e.target.complete();
  };

  const { data: foundCity } = useQuery(
    ["searched_location", searchBarValue.current?.value],
    () =>
      axios.get<City[]>(`/cities`, {
        params: {
          fulltext: searchBarValue.current.value,
          region: get_region(),
        },
      }),
    {
      select: (res) => res.data,
      enabled: !!searchBarValue.current?.value,
    }
  );

  // TODO: this was causing a bug: when clicking on event detail and getting back, list of events was already empty
  // But we should solve it somehow in future anyway. Now we keep in memory these events even if we don't have to, but it's a minor issue.
  // useIonViewDidLeave(() => {
  //   setCount(0);
  //   setDisplayEvents([]);
  // });

  return (
    <IonCard className="event_search_results">
      <IonCardHeader>
        <FulltextSearch
          mapSearchText={filters.search.value}
          setMapSearchText={filters.search.setter}
          setBoundsChangedSignal={setBoundsChangedSignal}
          highlighted={false}
          searchBarValue={searchBarValue}
          // readOnly={readOnly}
        />

        <div className="controls">
          {redirectBack ? (
            <IonBackButton
              defaultHref="/home"
              text=""
              className="back"
              icon={"/assets/icon/new/arrow-left.svg"}
            />
          ) : (
            <IonIcon
              className="map-icon top-left"
              icon={"/assets/icon/new/arrow-left.svg"}
              onClick={() => setTab("map")}
            />
          )}

          <IonIcon
            className="map-icon top-right"
            icon={filterSmall}
            onClick={() => setShowFilters(true)}
          />
        </div>
      </IonCardHeader>
      <IonContent
        scrollEvents={true}
        className="content"
        onIonScroll={() => {
          if (Capacitor.isNativePlatform()) {
            if (timeout.current) clearTimeout(timeout.current);
            timeout.current = setTimeout(() => Keyboard.hide(), 200);
          }
        }}
      >
        {foundCity?.length ? (
          <IonButton
            className="loc-button"
            size="large"
            fill="clear"
            onClick={() => {
              setTab("map");
              searchBarValue.current.value = "";
              filters.search.setter("");
              setCustomGps(foundCity[0]);
            }}
          >
            <div className="btn-loc-inner">
              <IonIcon size="large" icon={earth}></IonIcon>
              <span>Jump to</span> {foundCity[0].name}
            </div>
          </IonButton>
        ) : null}

        {!isLoading && isInfQueryEmpty(displayEvents) && (
          <EmptySection
            icon={searchIcon}
            title={"No events found"}
            description={"Try to adjust filters or search text"}
          />
        )}
        <IonList lines="none" className="events_list">
          {isLoading && <SkeletonEventList size="big" />}
          {displayEvents?.pages
            .flatMap((page) => page.data)
            .map((event, i) => (
              <EventItem
                key={event.eventId}
                event={event}
                // duplicate_event={duplicate_event}
                // actions={true}
                order={i + 1}
                source={"search_list"}
              />
            ))}
        </IonList>
        <IonInfiniteScroll onIonInfinite={loadMore} threshold="600px">
          <IonInfiniteScrollContent loadingSpinner="dots" />
        </IonInfiniteScroll>
      </IonContent>
    </IonCard>
  );
};
