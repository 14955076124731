const hideTabBar = (): void => {
  const tabBar = document.querySelector("ion-tab-bar");
  if (tabBar !== null && tabBar.style.display !== "none") {
    tabBar.style.display = "none";
  }
};

const showTabBar = (): void => {
  const tabBar = document.querySelector("ion-tab-bar");
  if (tabBar !== null && tabBar.style.display !== "flex") {
    tabBar.style.display = "flex";
  }
};

export { hideTabBar, showTabBar };
