import { useStorage } from ".";
import { useEffect, useState } from "react";
import { has_notification_path } from "../components/Notifications";
import { NotificationPath } from "../models/notifications";
import useAxios from "./useAxios";
import { useMutation } from "react-query";
import {
  UpdateNotificationsPayload,
  UpdateNotificationsResponseData,
} from "../contexts/QueryStorage";
import { AxiosError } from "axios";
import useTranlatedMsg from "./useTranslatedMsg";

const useNotifications = () => {
  const { notifications, setMessage } = useStorage();
  const [lastNidsUpdate, setLastNidsUpdate] = useState<number[]>([]);
  const [paths, setPaths] = useState<NotificationPath[]>([]);
  const { axios } = useAxios();
  const {went_wrong} = useTranlatedMsg()

  const { mutate: submit } = useMutation<
    UpdateNotificationsResponseData,
    AxiosError,
    UpdateNotificationsPayload
  >(
    ["update_notifications"],
    (payload) => axios.put("/notifications", payload),
    {
      onSuccess: () => notifications.refetch(),
      onError: (err) => {
        setMessage("danger",went_wrong, err);
      },
    }
  );
  const updatePathsAsSeen = async (paths: NotificationPath[]) => {
    setPaths(paths);
  };

  useEffect(() => {
    const nids = paths.reduce((nids: number[], path) => {
      return [...nids, ...has_notification_path(notifications, path)];
    }, []);

    const allNidsUpdated = nids.every((val) => lastNidsUpdate.includes(val));

    if (nids.length > 0 && !allNidsUpdated) {
      setLastNidsUpdate([...lastNidsUpdate, ...nids]);
      submit({ notificationIds: nids });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, paths]);

  return { updatePathsAsSeen, submit };
};

export default useNotifications;
