import { useState, useCallback, useEffect } from "react";
import { useStorage } from "../hooks";
// import type { AxiosResponse } from "axios";
import type { Message } from "../models/chat";
import moment from "moment";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import useAxios from "../hooks/useAxios";
import { SCROLL_STEP } from "../constants/global";
import { AxiosError, AxiosResponse } from "axios";
import useTranlatedMsg from "./useTranslatedMsg";
export type SeenBy = {
  [key: number]: number[];
};
// TODO: Write tests for this or move to BE
// const getSeenByPerMessage = (messages: Message[], userId?: number): SeenBy => {
//   return messages.reduce(
//     (acc, {messageId, user, seenBy}) => ({
//       ...acc,
//       [messageId]: seenBy.filter(
//         (id) =>
//           id !== userId &&
//           id !== user?.userId &&
//           !Object.values(acc)
//             .flatMap((seenBy) => seenBy)
//             .includes(id)
//       ),
//     }),
//     {}
//   )
// }
type MessagesSeenBy = { [key: number]: number[] };
const getLastSeenByPerMessage = (
  messages: Message[],
  user_id: number | undefined
): MessagesSeenBy => {
  // TODO: pass all group members, so we dont need to loop through whole array
  let processed: number[] = [];
  let lastSeenBy: MessagesSeenBy = {};
  for (let i = 0; i < messages.length; i++) {
    const authorId = messages[i].user?.userId;
    if (authorId && !processed.includes(authorId) && authorId !== user_id) {
      lastSeenBy[messages[i].messageId] = [];
      lastSeenBy[messages[i].messageId].push(authorId);
      processed.push(authorId);
    }
    for (let j = 0; j < messages[i].seenBy.length; j++) {
      if (
        !processed.includes(messages[i].seenBy[j]) &&
        messages[i].seenBy[j] !== user_id
      ) {
        if (!lastSeenBy[messages[i].messageId])
          lastSeenBy[messages[i].messageId] = [];
        lastSeenBy[messages[i].messageId].push(messages[i].seenBy[j]);
        processed.push(messages[i].seenBy[j]);
      }
    }
  }
  return lastSeenBy;
};
// TODO: Refactor this
export function fix_message_attributes_formats(
  discussion: Message[]
): Message[] {
  for (let i = 0; i < discussion.length; i++) {
    if (discussion[i].variables) {
      for (const [key] of Object.entries(discussion[i].variables)) {
        if (key.includes("_datetime"))
          discussion[i].variables[key] = moment(
            discussion[i].variables[key]
          ).format("ddd, MMM Do YYYY, H:mm");
      }
    }
  }
  return discussion;
}
type Payload = {
  message: string;
};
type Props = {
  id: number;
  isActive: boolean;
  groupId?: number | null;
  userId?: number | null;
  lastVisit?: Date | null;
};
const useChat = ({
  id,
  groupId = null,
  isActive = true,
  userId = null,
  lastVisit = null,
}: Props) => {
  const [userIds, setUserIds] = useState<number[]>([]);
  const [activeMessage, setActiveMessage] = useState<Message | null>(null);
  const [lastSeenMessage, setLastSeenMessage] = useState<Message | null>(null);
  const [unreadThreshold, setUnreadThreshold] = useState<Date | null>(null);
  const [seenBy, setSeenBy] = useState<SeenBy | null>(null);
  const [theirLastSeen, setTheirLastSeen] = useState<number | null>(null);
  const { currentUser } = useStorage();
  const { axios } = useAxios();
  const { setMessage } = useStorage();
  const [timeStampEnterMessages, setTimeStampEnterMessages] = useState<number>(
    moment().unix()
  );
  const [allMessages, setAllMessages] = useState<Message[]>();
  const {err_msg_load} = useTranlatedMsg()

  const getUsers = useMutation(["user", userIds], () =>
    axios.get("users/v2", {
      params: {
        ids: userIds.join(","),
      },
    })
  );

  useEffect(() => {
    setTimeStampEnterMessages(moment().unix());
  }, [id]);

  // TODO: Handle errors with setMessage
  const newMessages = useQuery(
    ["new_messages", id, timeStampEnterMessages],
    () =>
      axios.get<Message[]>(`/chats/${id}/messages`, {
        params: {
          from_timestamp: timeStampEnterMessages,
        },
      }),
    {
      select: (res) => res.data.slice().reverse(),
      enabled: isActive,
      refetchInterval: 5000,
    }
  );

  const {
    data: previousMessages,
    fetchNextPage,
    refetch: refetchPreviousMessages,
    isFetched: previousMessagesFetched,
  } = useInfiniteQuery(
    ["previousMessages", id, timeStampEnterMessages],
    async ({ pageParam = 0 }) => {
      return await axios.get<Message[]>(`/chats/${id}/messages`, {
        params: {
          limit: SCROLL_STEP,
          offset: pageParam,
          to_timestamp: timeStampEnterMessages,
        },
      });
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length < SCROLL_STEP) return;
        else return pages.flatMap((page) => page.data).length;
      },

      onError: (error) => {
        setMessage("danger", err_msg_load, error);
      },
    }
  );

  useEffect(() => {
    if (newMessages.data === undefined || previousMessages === undefined)
      return;
    const flatPreviousMessagesPage = previousMessages?.pages.flatMap((page) =>
      page.data.slice().reverse()
    );
    let totalMessages: Message[] = [
      ...newMessages.data,
      ...flatPreviousMessagesPage,
    ];
    totalMessages = fix_message_attributes_formats(
      totalMessages.filter(
        ({ message, File }) => message !== "" || File.length > 0
      )
    );

    // Unread Threshold
    if (
      lastVisit &&
      lastVisit < totalMessages[0]?.posted &&
      totalMessages[0]?.user?.userId !== currentUser?.userId
    ) {
      setUnreadThreshold(lastVisit);
    }
    // Seen By
    if (totalMessages) {
      const seenByPerMessage = getLastSeenByPerMessage(
        totalMessages,
        currentUser?.userId
      );

      const newUserIds = Object.values(seenByPerMessage).flatMap(
        (seenBy) => seenBy
      );
      if (newUserIds.filter((id) => !userIds.includes(id)).length > 0) {
        setUserIds(newUserIds);
      }
      setSeenBy(seenByPerMessage);
    }
    // Message Delivery
    const theirLastSeenNew =
      totalMessages?.find(({ user }) => user?.userId !== userId)?.messageId ||
      null;
    if (theirLastSeenNew !== theirLastSeen) {
      const url = `/chats/${id}/messages/${theirLastSeenNew}/delivery`;
      updateMessageDelivery({ url });
      setTheirLastSeen(theirLastSeenNew);
    }
    setAllMessages(totalMessages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessages.data, previousMessages?.pages]);

  const loadMore = async (e: any) => {
    await fetchNextPage();
    e.target.complete();
  };
  const postMessage = useMutation<never, AxiosError, Payload>(
    (message_payload) => axios.post(`/chats/${id}/messages`, message_payload),
    {
      onSuccess: () => {
        newMessages.refetch();
      },
    }
  );
  const postFile = useMutation<AxiosResponse<Message>, AxiosError, FormData>(
    (payload) =>
      axios.post(`/chats/${id}/files`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    {
      onSuccess: () => {
        newMessages.refetch();
      },
    }
  );

  const uploadFiles = useCallback((files: Blob[]) => {
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));
    postFile.mutate(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const sendMessage = (message: string, files?: Blob[]) => {
    if (files && files.length > 0) {
      uploadFiles(files);
    }
    if (message.length) {
      postMessage.mutate({ message });
    }
  };

  const deleteMessageApi = useMutation<
    never,
    AxiosError,
    { messageId: number; file: string | undefined }
  >(
    (data) =>
      axios.delete(`/chats/${id}/messages/${data.messageId}`, {
        params: {
          file_name: data.file || "",
        },
      }),
    {
      onSuccess: () => {
        refetchPreviousMessages();
        newMessages.refetch();
      },
    }
  );

  const deleteMessage = (message: Message | null, filename?: string | null) => {
    const { messageId, File } = message || {};
    const file = File?.find(({ name }) => name === filename)?.name;
    if (messageId) {
      deleteMessageApi.mutate({
        messageId: messageId,
        file: file,
      });
    }
  };

  const { mutate: updateMessageDelivery } = useMutation<
    never,
    AxiosError,
    { url: string }
  >((data) => axios.put(data.url));

  const { mutate: updateLastVisit } = useMutation(
    (groupId: number) => axios.put(`/groups/${groupId}/last_visit`),
    {
      onSuccess: (res) => console.log("PUT last visit", res),
      onError: (err) => setMessage("danger", undefined, err),
    }
  );

  return {
    updateLastVisit,
    newMessages,
    sendMessage,
    deleteMessage,
    getUsers,
    activeMessage,
    setActiveMessage,
    lastSeenMessage,
    setLastSeenMessage,
    unreadThreshold,
    seenBy,
    loadMore,
    postFile,
    postMessage,
    allMessages,
    previousMessagesFetched,
  };
};
export default useChat;
