import useAxios from "./useAxios";
import useStorage from "./useStorage";
import { useMutation } from "react-query";
import { logEvent } from "../helpers/logging";
import { AxiosError, AxiosResponse } from "axios";
import { useInvalidateQueries } from "./useInvalidateQueries";

const useLeaveEvent = () => {
  const { axios } = useAxios();
  const { setMessage } = useStorage();
  const invalidateQueries = useInvalidateQueries();

  const { mutate: leaveEvent } = useMutation<
    AxiosResponse<unknown>,
    AxiosError,
    number
  >((eventId: number) => axios.delete(`/events/${eventId}/users`), {
    onSuccess: () => {
      logEvent("event_leave", {});
      invalidateQueries('hp_events');
    },
    onError: (err) => setMessage("danger", undefined, err),
  });

  return leaveEvent;
};
export default useLeaveEvent;
