import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonBackButton,
} from "@ionic/react";
import "./Login.scss";
import "./Registration.scss";
import RegisterForm from "../components/Register";
import MissingInvitationError from "../components/MissingInvitationError";
import { hideTabBar } from "../helpers/tabBar";
import { Verification } from "../components";
import { arrowBackIos } from "../icons/iconsOutline";

const Registration: React.FC = () => {
  const [phonePrefix, setPhonePrefix] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [userId, setUserId] = useState<number | null>(null);
  const [missingInvitation, setMissingInvitation] = useState<boolean>(false);
  useEffect(() => hideTabBar(), []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {userId ? (
              <IonButton
                onClick={() => {
                  setUserId(null);
                }}
              >
                <IonIcon icon={arrowBackIos} />
              </IonButton>
            ) : (
              <IonBackButton
                defaultHref="/search"
                text=""
                className="back"
                icon={"/assets/icon/new/arrow-left.svg"}
              />
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className="form login">
        <div className="container">
          {missingInvitation ? (
            // TODO: change to phonePrefix & phone
            <MissingInvitationError numberPrefix={phonePrefix} number={phone} />
          ) : userId === null ? (
            <RegisterForm
              setPhonePrefix={setPhonePrefix}
              setPhone={setPhone}
              setPassword={setPassword}
              setUserId={setUserId}
              setMissingInvitation={setMissingInvitation}
            />
          ) : (
            <Verification
              phonePrefix={phonePrefix}
              phone={phone}
              password={password}
              userId={userId}
              isRegistration
            />
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Registration;
