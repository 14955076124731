import {
  useIonViewWillEnter,
  IonPage,
  IonBackButton,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
} from "@ionic/react";

import { useInfiniteQuery } from "react-query";
import { showTabBar } from "../helpers/tabBar";
import useAxios from "../hooks/useAxios";
import { Event } from "../models";
import { useStorage } from "../hooks";
import "./EventDetail.scss";
import { SCROLL_STEP } from "../constants/global";
import { SkeletonEventList } from "../components/Skeleton";
import { EventItem } from "../components/Event";
import useTranlatedMsg from "../hooks/useTranslatedMsg";
import { useTranslation } from "react-i18next";

const FollowingPlacesEvents: React.FC = () => {
  const { axios } = useAxios();
  const { setMessage } = useStorage();
  const { t } = useTranslation();
  const { err_load_events } = useTranlatedMsg();
  const {
    data: followingPlacesEvents,
    isLoading: isLoadingFollowingPlacesEvents,
    fetchNextPage: fetchFollowingPlacesEvents,
  } = useInfiniteQuery(
    ["following_places_events_infinite"],
    ({ pageParam = 0 }) => {
      return axios.get<Event[]>("/events", {
        params: {
          offset: pageParam,
          limit: SCROLL_STEP,
          filter_date: "future",
          order_by: "start",
          order_direction: "ASC",
          source: "following_places_events",
          following_places: true,
        },
      });
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length < SCROLL_STEP) return;
        else return pages.flatMap((page) => page.data).length;
      },

      onError: (error) => {
        setMessage("danger", err_load_events, error);
      },
      refetchOnWindowFocus: false,
    }
  );

  useIonViewWillEnter(() => {
    showTabBar();
  });

  const loadMore = async (e: any) => {
    await fetchFollowingPlacesEvents();
    e.target.complete();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/events" text="" className="back" />
          </IonButtons>
          <IonTitle className="title-wide">
            {t("foll_places_evn.favourite_places")}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList className="events_list" lines="none">
          {isLoadingFollowingPlacesEvents ? (
            <SkeletonEventList size="big" />
          ) : (
            followingPlacesEvents?.pages
              .flatMap((page) => page.data)
              .map((event, i) => (
                <EventItem
                  key={event.eventId}
                  event={event}
                  // actions={true}
                  order={i + 1}
                  source={"following_places_events"}
                />
              ))
          )}
        </IonList>
        <IonInfiniteScroll onIonInfinite={loadMore} threshold="200px">
          <IonInfiniteScrollContent loadingSpinner="dots" />
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default FollowingPlacesEvents;
