export const cookiesConsetDefaults = {
  categories: {
    necessary: {
      enabled: true,
      readOnly: true,
    },
    analytics: {},
  },

  language: {
    default: "en",
    translations: {
      en: {
        consentModal: {
          title: "Cookies usage",
          description: "Cookies are needed for website to work properly.",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          showPreferencesBtn: "Manage Individual preferences",
        },
        preferencesModal: {
          title: "Manage cookie preferences",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          savePreferencesBtn: "Accept current selection",
          closeIconLabel: "Close modal",
          sections: [
            {
              //   title: "Somebody said ... cookies?",
              description: "You can configure which cookies will be used.",
            },
            {
              title: "Strictly Necessary cookies",
              description:
                "These cookies are essential for the proper functioning of the website and cannot be disabled.",

              //this field will generate a toggle linked to the 'necessary' category
              linkedCategory: "necessary",
            },
            {
              title: "Performance and Analytics",
              description:
                "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
              linkedCategory: "analytics",
            },
            // {
            //   title: "More information",
            //   description:
            //     'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>',
            // },
          ],
        },
      },
    },
  },
};
