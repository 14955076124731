import useAxios from "./useAxios";
import { useMutation } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import { ChangeRelationshipPayload } from "../models";

const useRequestFriendship = () => {
  const { axios } = useAxios();

  const { mutateAsync: requestFriendship } = useMutation<
    AxiosResponse<ChangeRelationshipPayload>,
    AxiosError,
    ChangeRelationshipPayload
  >((changeRelationshipPayload) =>
    axios.post("/profile/relationships", changeRelationshipPayload)
  );
  return requestFriendship;
};
export default useRequestFriendship;
