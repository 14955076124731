import { useContext } from "react";
import { NavContext } from "@ionic/react";

const useNavigation = () => {
  const navigation = useContext(NavContext);

  return {
    ...navigation,
  };
};

export default useNavigation;
