import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonList,
  IonItem,
  IonTitle,
  IonLabel,
  IonIcon,
  IonAvatar,
  useIonViewWillEnter,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  useIonViewDidEnter,
  ScrollDetail,
  IonBackButton,
  IonButtons,
} from "@ionic/react";
import "./Notifications.scss";
import moment from "moment";
import { FullNotification } from "../models/notifications";
import {
  get_unnoticed_notifications,
  resolve_notification_path,
} from "../components/Notifications";
import { showTabBar } from "../helpers/tabBar";
import { EmptySection } from "../components/EmptySection";
import { useInfiniteQuery } from "react-query";
import useAxios from "../hooks/useAxios";
import { useStorage } from "../hooks";
import { isInfQueryEmpty } from "../helpers/reactQuery";
import { SkeletonEventList } from "../components/Skeleton";
import useNotifications from "../hooks/useNotifications";
import {
  eventFill,
  forumFill,
  notificationsFill,
  personFill,
} from "../icons/iconsFill";
import {
  sentiment,
  notifications as notificationsIcon,
  chat,
} from "../icons/iconsOutline";
import { useTranslation } from "react-i18next";
import useTranlatedMsg from "../hooks/useTranslatedMsg";

function resolve_icon(object: string) {
  if (object === "message") return chat;
  if (object === "group") return forumFill;
  if (object === "event") return eventFill;
  if (object === "events") return eventFill;
  if (object === "tip") return eventFill;
  if (object === "invitation") return eventFill; // TODO: maybe change to event and not use invitation?
  if (object === "user") return personFill;
  if (object === "voting") return eventFill;
  if (object === "feedback") return sentiment;
  if (object === "announcement") return notificationsFill;
}

const Notifications: React.FC<{
  bottomMenuSignal: Number;
}> = ({ bottomMenuSignal }) => {
  const { setMessage, notifications } = useStorage();
  const { axios } = useAxios();
  const { submit } = useNotifications();
  const contentEle = useRef<HTMLIonContentElement>(null);
  const [onTop, setOnTop] = useState<Number>(0);
  const { t } = useTranslation();
  const { err_load_notif } = useTranlatedMsg();
  function scrollTop() {
    if (onTop !== 0) contentEle.current?.scrollToTop(400);
  }

  useEffect(() => {
    scrollTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomMenuSignal]);

  const fetchNotifications = ({ pageParam = 0 }) => {
    return axios.get<FullNotification[]>(`/notifications/all`, {
      params: {
        offset: pageParam,
        limit: 20,
      },
    });
  };

  const { data, isLoading, refetch, fetchNextPage } = useInfiniteQuery(
    ["notifications_all"],
    fetchNotifications,
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length === 0) return;
        else return pages.length * 20;
      },
      onError: (error) => {
        setMessage("danger", err_load_notif, error);
      },
    }
  );
  function handleScroll(ev: CustomEvent<ScrollDetail>) {
    setOnTop(ev.detail.scrollTop);
  }

  useIonViewDidEnter(() => {
    const nids = get_unnoticed_notifications(notifications);
    submit({ notificationIds: nids, seen: false });
  }, [notifications]);

  const loadMore = async (e: any) => {
    await fetchNextPage();
    e.target.complete();
  };

  useIonViewWillEnter(() => {
    refetch();
    showTabBar();
  });

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/events"
              text=""
              className="back"
              icon={"/assets/icon/new/arrow-left.svg"}
            />
          </IonButtons>
          <IonTitle>{t("activities.notifications")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        scrollEvents={true}
        onIonScroll={handleScroll}
        ref={contentEle}
      >
        <>
          <IonList className="default_list notifications_list" lines="none">
            {!isLoading && isInfQueryEmpty(data) && (
              <EmptySection
                icon={notificationsIcon}
                title={t("activities.no_notifications")}
                description={t("activities.no_notifications_subtitle")}
              />
            )}
            {isLoading && <SkeletonEventList />}

            {!isLoading &&
              data &&
              data.pages.length !== 0 &&
              data.pages
                .flatMap((page) => page.data)
                .map((n: FullNotification) => {
                  return (
                    <IonItem
                      key={n.pushNotificationId}
                      routerLink={resolve_notification_path(n.data)}
                      className={n.seen ? "seen" : "highlighted"}
                    >
                      <IonAvatar>
                        <IonIcon
                          color={n.seen ? "medium" : "primary"}
                          size="large"
                          icon={resolve_icon(n.object)}
                        ></IonIcon>
                      </IonAvatar>
                      <IonLabel>
                        <span className="title smartlook-hide" data-sl="mask">
                          {n.title}
                        </span>
                        <span className="text smartlook-hide" data-sl="mask">
                          {n.text}
                        </span>
                        <span className="time">
                          {moment(n.createdAt).format("MMM Do YYYY, H:mm")}
                        </span>
                      </IonLabel>
                    </IonItem>
                  );
                })}
          </IonList>
          <IonInfiniteScroll onIonInfinite={loadMore} threshold="200px">
            <IonInfiniteScrollContent loadingSpinner="dots" />
          </IonInfiniteScroll>
        </>
      </IonContent>
    </>
  );
};

const NotificationsWrapper: React.FC<{
  bottomMenuSignal: Number;
}> = ({ bottomMenuSignal }) => {
  const { currentUser: user } = useStorage();

  return (
    <IonPage className="page-notifications">
      {user && (
        <Notifications bottomMenuSignal={bottomMenuSignal} />
        // ) : (
        // <DisabledSection section={"notifications"} />
      )}
    </IonPage>
  );
};

export default NotificationsWrapper;
