import { useEffect } from "react";
import { remove, store } from "../cache/engine";

function useRememberValue<T>(key: string, value: T, enabled: boolean = true) {
  useEffect(() => {
    if (enabled) store<T>(key, value);
    else remove(key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, enabled]);
}

export { useRememberValue };
