import React, { useState, useEffect, useRef } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonPopover,
  useIonViewWillEnter,
  IonButton,
  IonIcon,
  IonAlert,
} from "@ionic/react";
import { RouteComponentProps, useParams } from "react-router";
import { SocialSharing } from "@ionic-native/social-sharing";
import moment from "moment";
import "./EventDetail.scss";
import { Event } from "../models/Event";
import { EventBasicInfoDetail, getEventPublicUrl } from "../components/Event";
import {
  calc_end_date,
  get_event_photo_url,
  get_primary_tag_slug_default,
} from "../helpers/event";
import { PopoverSettings } from "../models/popover";
import { showTabBar } from "../helpers/tabBar";
import { useNavigation, useStorage } from "../hooks";
import { EmptySection } from "../components/EmptySection";
import { useMutation, useQuery } from "react-query";
import useAxios from "../hooks/useAxios";

import { AxiosError, AxiosResponse } from "axios";
import { logEvent } from "../helpers/logging";
import copy from "copy-to-clipboard";
import useNotifications from "../hooks/useNotifications";
import { block, edit, moreVert, shareIos, trash } from "../icons/iconsOutline";
import { SkeletonEvDet } from "../components/Skeleton";
import { AboutHeader } from "../components/Detail";
import useJoinEvent from "../hooks/useJoinEvent";
import useAnalytics from "../hooks/useAnalytics";
import useInterestedInEvent from "../hooks/useInterestedInEvent";
import useLeaveEvent from "../hooks/useLeaveEvent";
import { PopoverActionButtons } from "../components/Unregistered";
import AddEvent from "../components/AddEvent";
import EditEvent from "../components/EditEvent";
import { load_event } from "../resources/events";
import { device } from "../config";
import { useTranslation } from "react-i18next";
import useTranlatedMsg from "../hooks/useTranslatedMsg";

// function is_chat_disabled(event: Event, user: User | null) {
//   return event.hostId !== user?.userId && !event.attends;
// }

// const EventAttendee: React.FC<{
//   event: Event;
//   attendee: Attendee;
//   onAttendeeChanged?: CallableFunction;
// }> = ({ event, attendee, onAttendeeChanged }) => {
//   const [showPopover, setShowPopover] = useState({
//     showPopover: false,
//     event: undefined,
//   });
//   const [showKickAlert, setShowKickAlert] = useState(false);
//   const [relationship, setRelationship] = useState<relationshipType>();

//   const { currentUser: user, setMessage } = useStorage();

//   const { axios } = useAxios();

//   const { mutate: kickAttendee } = useMutation(["kick_attendee"], () =>
//     axios.delete(`/events/${event.eventId}/users/${attendee.user.userId}`)
//   );

//   useEffect(() => {
//     setRelationship(attendee.user.relationship);
//   }, [attendee.user.relationship]);

//   return (
//     <IonItem>
//       <Avatar photo={attendee.user.photo}>
//         <UserAttendanceBadge
//           state={attendee.state}
//           hostId={event.hostId}
//           userId={attendee.user.userId}
//         />
//       </Avatar>
//       <IonButtons slot="end">
//         {user?.userId !== attendee.user.userId && (
//           <UserListedButtons
//             user={attendee.user}
//             relationship={relationship}
//             setRelationship={setRelationship}
//           />
//         )}

//         {event.hostId === user?.userId &&
//           attendee.user.userId !== user?.userId && (
//             <>
//               <IonButton
//                 color="dark"
//                 fill="clear"
//                 onClick={(e: any) => {
//                   e.persist();
//                   setShowPopover({ showPopover: true, event: e });
//                 }}
//               >
//                 <IonIcon icon={moreVert} />
//               </IonButton>

//               <IonPopover
//                 event={showPopover.event}
//                 isOpen={showPopover.showPopover}
//                 onDidDismiss={() => {
//                   setShowPopover({
//                     showPopover: false,
//                     event: undefined,
//                   });
//                 }}
//               >
//                 <IonList>
//                   <IonItem lines="none" onClick={() => setShowKickAlert(true)}>
//                     <IonIcon icon={logout} /> remove
//                   </IonItem>
//                 </IonList>
//               </IonPopover>
//             </>
//           )}
//       </IonButtons>
//       <IonLabel>
//         <h2 data-sl="mask" className="smartlook-hide">
//           {attendee.user.name}
//         </h2>
//         <UserState relationship={relationship}></UserState>
//       </IonLabel>

//       <IonAlert
//         isOpen={showKickAlert}
//         onDidDismiss={() => setShowKickAlert(false)}
//         onWillPresent={() =>
//           setShowPopover({
//             showPopover: false,
//             event: undefined,
//           })
//         }
//         header={"Remove attendee?"}
//         buttons={[
//           {
//             text: "Cancel",
//             role: "cancel",
//             cssClass: "secondary",
//             handler: () => {},
//           },
//           {
//             text: "Confirm",
//             cssClass: "primary",
//             handler: () => {
//               kickAttendee(undefined, {
//                 onSuccess: () => {
//                   if (onAttendeeChanged) onAttendeeChanged();
//                 },
//                 onError: (err) => setMessage("danger", undefined, err),
//               });
//             },
//           },
//         ]}
//       />
//     </IonItem>
//   );
// };

// const self_sorting = [true, false];
// const organiser_sorting = [true, false];
// const states_sorting = ["GOING", "MAYBE", "INVITED", "DECLINED"];

// function sort_attendees(
//   a: Attendee,
//   b: Attendee,
//   user: User | null,
//   hostId: number | undefined
// ) {
//   if (!user) return 0;

//   const self_order =
//     self_sorting.indexOf(a.user.userId === user.userId) -
//     self_sorting.indexOf(b.user.userId === user.userId);

//   if (self_order !== 0) return self_order;

//   if (hostId) {
//     const organiser_order =
//       organiser_sorting.indexOf(a.user.userId === hostId) -
//       organiser_sorting.indexOf(b.user.userId === hostId);

//     if (organiser_order !== 0) return organiser_order;
//   }

//   const friend_order =
//     USER_STATE_SORTING.indexOf(a.user.relationship) -
//     USER_STATE_SORTING.indexOf(b.user.relationship);

//   if (friend_order !== 0) return friend_order;

//   return states_sorting.indexOf(a.state) - states_sorting.indexOf(b.state);
// }

// function user_voted(votes: SimpleUser[], user_id?: number) {
//   for (let i = 0; i < votes.length; i++) {
//     if (votes[i].userId === user_id) return true;
//   }
//   return false;
// }

// function calculate_max_votes(options: Array<DateOption | LocationOption>) {
//   let max_votes = 0;

//   for (let i = 0; i < options.length; i++) {
//     max_votes = Math.max(max_votes, options[i].votes.length);
//   }

//   return max_votes;
// }

// function calculate_votes_gradient(
//   maxVotes: number,
//   votes: SimpleUser[],
//   voted: boolean
// ) {
//   let perc = 0;

//   if (maxVotes !== 0) {
//     perc = votes.length / maxVotes;
//   }

//   return `linear-gradient(270deg, ${
//     voted ? "var(--ion-color-primary-light)" : "rgba(205, 205, 205, 0.5)"
//   } ${perc * 100}%, rgba(255, 255, 255, 0) ${perc * 100}%)`;
// }

// const CloseVoting: React.FC<{
//   options: Array<DateOption | LocationOption>;
//   showModal: boolean;
//   setShowModal: CallableFunction;
//   onChange: (
//     finalOption: DateOption | LocationOption
//   ) => Promise<any> | undefined;
// }> = ({ options, showModal, setShowModal, onChange }) => {
//   const [selecteOption, setSelecteOption] = useState<
//     DateOption | LocationOption | undefined
//   >();
//   const { currentUser: user } = useStorage();

//   function reset_form() {
//     setShowModal(false);
//     setSelecteOption(undefined);
//     //setSearchText("");
//   }

//   // TODO: store in state?
//   const maxVotes = calculate_max_votes(options);

//   return (
//     <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
//       <ModalHeader title="Choose final option" onClick={reset_form} />

//       <IonContent>
//         <IonList className="default_list">
//           {options.map((option) => {
//             return (
//               <IonItem
//                 key={
//                   "eventDateOptionId" in option
//                     ? option.eventDateOptionId
//                     : option.eventLocationOptionId
//                 }
//                 lines="none"
//               >
//                 <IonCheckbox
//                   checked={selecteOption === option}
//                   onIonChange={() => {
//                     setSelecteOption(option);
//                   }}
//                 >
//                   <h2>
//                     {"eventDateOptionId" in option
//                       ? format_date(moment(option.start).toDate())
//                       : option.name}
//                   </h2>
//                   <span className="label_note">
//                     {`${option.votes.length} vote${
//                       option.votes.length > 1 || option.votes.length === 0
//                         ? "s"
//                         : ""
//                     }`}
//                   </span>
//                 </IonCheckbox>
//                 <div
//                   className="votes_line"
//                   style={{
//                     background: calculate_votes_gradient(
//                       maxVotes,
//                       option.votes,
//                       user_voted(option.votes, user?.userId)
//                     ),
//                   }}
//                 />
//               </IonItem>
//             );
//           })}
//         </IonList>
//       </IonContent>

//       <IonButton
//         className="modal_big_button"
//         disabled={selecteOption === undefined}
//         onClick={() => {
//           if (selecteOption) {
//             const fnRes = onChange(selecteOption);
//             if (fnRes)
//               fnRes.then(() => {
//                 setShowModal(false);
//                 setSelecteOption(undefined);
//               });
//             else {
//               setShowModal(false);
//               setSelecteOption(undefined);
//             }
//           }
//         }}
//       >
//         End voting
//       </IonButton>
//     </IonModal>
//   );
// };

// function format_date(date: Date) {
//   return moment(date).isSame(moment(), "year")
//     ? moment(date).format("ddd, MMM Do")
//     : moment(date).format("ddd, MMM Do YYYY");
// }

// function date_options_to_datetime(date_options: DateOption[]) {
//   return date_options.map((v) => {
//     return new Date(v.start);
//   });
// }

// function date_in_date_options(
//   date_options: DateOption[],
//   date: Moment
// ): number | undefined {
//   for (let i = 0; i < date_options.length; i++) {
//     if (
//       moment(date_options[i].start).format("YYYY-MM-DD") ===
//       date.format("YYYY-MM-DD")
//     )
//       return i;
//   }
// }

// function get_selected_date_option(
//   date_options: DateOption[],
//   date_strings: string[]
// ) {
//   for (let i = 0; i < date_options.length; i++) {
//     if (
//       !date_strings.includes(moment(date_options[i].start).format("YYYY-MM-DD"))
//     )
//       return date_options[i];
//   }
// }

// const EventVotingDate: React.FC<{
//   eventId: number;
//   start: Date | null;
//   dateOptions: DateOption[];
//   isHost: boolean;
//   votingClosed: boolean;
//   onChange: () => void;
// }> = ({ eventId, start, dateOptions, isHost, votingClosed, onChange }) => {
//   const [showModal, setShowModal] = useState(false);
//   const [collapsed, setCollapsed] = useState(true);
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   var [_, setRerender] = useState<Number>(Math.random());
//   var [view, setView] = useState<"chart" | "calendar">("chart");

//   const { setMessage } = useStorage();

//   // TODO: store in state?
//   const maxVotes = calculate_max_votes(dateOptions);

//   const { axios } = useAxios();

//   const { mutateAsync: finishVoting } = useMutation<
//     AxiosResponse<unknown>,
//     AxiosError,
//     FinishVotingPayload
//   >(
//     (finishVotingPayload) =>
//       axios.post<FinishVotingPayload>(
//         `/events/${eventId}/voting/date/finish`,
//         finishVotingPayload
//       ),
//     {
//       onSuccess: () => onChange(),
//       onError: (err) => setMessage("danger", "Failed to finish voting", err),
//     }
//   );
//   return (
//     <>
//       {votingClosed && (
//         <div
//           className="voting_controls"
//           onClick={() => {
//             setCollapsed(!collapsed);
//           }}
//         >
//           {collapsed ? "Show date voting results" : "Hide date voting results"}
//           <IonIcon
//             color="primary"
//             size="large"
//             icon={collapsed ? expandMore : expandLess}
//           />
//         </div>
//       )}
//       <div
//         className="event_voting"
//         style={{ display: votingClosed && collapsed ? "none" : "block" }}
//       >
//         <div className="compact_header">Voting on the date</div>
//         <IonToolbar className="inner">
//           <div className="time">
//             <IonIcon icon={schedule} />{" "}
//             <span>
//               {moment(dateOptions[0].start).format("H:mm")} -{" "}
//               {moment(dateOptions[0].end).format("H:mm")}
//             </span>
//           </div>

//           <IonButton
//             className="btn-toolbar-inner button-primary-light"
//             size="small"
//             slot="end"
//             fill="clear"
//             color={"primary"}
//             onClick={() => {
//               if (view === "chart") setView("calendar");
//               if (view === "calendar") setView("chart");
//             }}
//           >
//             <IonIcon icon={view === "chart" ? calendarMonth : barChartFill} />
//           </IonButton>
//         </IonToolbar>

//         {view === "chart" && (
//           <>
//             {dateOptions.map((option, i) => {
//               return (
//                 <VotingOption
//                   key={i}
//                   eventId={eventId}
//                   option={option}
//                   final={start === option.start}
//                   maxVotes={maxVotes}
//                   votingClosed={votingClosed}
//                   setRerender={setRerender}
//                   onChange={onChange}
//                 />
//               );
//             })}
//           </>
//         )}

//         {view === "calendar" && (
//           <CalendarVoting
//             eventId={eventId}
//             start={start}
//             options={dateOptions}
//             votingClosed={votingClosed}
//             setRerender={setRerender}
//             onChange={onChange}
//           />
//         )}

//         {isHost && !votingClosed && (
//           <div className="align_right">
//             <IonButton
//               className="finalize_voting button-primary-light"
//               size="default"
//               fill="clear"
//               onClick={() => setShowModal(true)}
//             >
//               <IonIcon icon={done} /> End voting
//             </IonButton>
//           </div>
//         )}

//         <CloseVoting
//           options={dateOptions}
//           showModal={showModal}
//           setShowModal={setShowModal}
//           onChange={(finalOption: DateOption | LocationOption) => {
//             if ("eventDateOptionId" in finalOption)
//               return finishVoting({
//                 optionId: finalOption.eventDateOptionId,
//               });
//           }}
//         />
//       </div>
//     </>
//   );
// };

// const CalendarVoting: React.FC<{
//   eventId: number;
//   start: Date | null;
//   options: DateOption[];
//   votingClosed: boolean;
//   setRerender: React.Dispatch<React.SetStateAction<Number>>;
//   onChange: () => void;
// }> = ({ eventId, start, options, votingClosed, setRerender, onChange }) => {
//   const { currentUser: user } = useStorage();
//   const { axios } = useAxios();

//   const { mutate: vote } = useMutation(
//     (optionId: number) =>
//       axios.post(`/events/${eventId}/vote/date/${optionId}`),
//     {
//       onSuccess: () => onChange(),
//     }
//   );
//   // TODO: unify with up?
//   const { mutate: downvote } = useMutation(
//     (optionId: number) =>
//       axios.delete(`/events/${eventId}/vote/date/${optionId}`),
//     {
//       onSuccess: () => onChange(),
//     }
//   );

//   return (
//     <>
//       <Calendar
//         onChange={(dates) => {
//           setRerender(Math.random());
//           if (votingClosed || !user) return;

//           var dates_stringed = [];

//           if (dates instanceof DateObject)
//             dates_stringed.push(moment(dates.toDate()).format("YYYY-MM-DD"));

//           if (dates instanceof Array) {
//             dates_stringed = dates.map((d) =>
//               moment(d.toDate()).format("YYYY-MM-DD")
//             );
//           }

//           const removed_option = get_selected_date_option(
//             options,
//             dates_stringed
//           );

//           if (removed_option && user) {
//             if (user_voted(removed_option.votes, user.userId)) {
//               // optimistic update
//               removed_option.votes = removed_option.votes.filter(
//                 (vote) => vote.userId !== user.userId
//               );
//               setRerender(Math.random());
//               downvote(removed_option.eventDateOptionId);
//             } else {
//               // optimistic update
//               if (user.userId) {
//                 removed_option.votes.push({
//                   userId: user.userId,
//                   name: user.name,
//                   photo: user.photo,
//                 });
//                 setRerender(Math.random());
//               }

//               vote(removed_option.eventDateOptionId);
//             }
//           }
//         }}
//         weekStartDayIndex={1}
//         value={date_options_to_datetime(options)}
//         minDate={options[0].start}
//         maxDate={options[options.length - 1].end}
//         disableYearPicker={true}
//         disableMonthPicker={true}
//         shadow={false}
//         mapDays={({ date, selectedDate, currentMonth, isSameDate }) => {
//           const option_index = date_in_date_options(
//             options,
//             moment(date.toDate())
//           );

//           const voted =
//             option_index !== undefined &&
//             options[option_index].votes.some((v) => v.userId === user?.userId);

//           const won =
//             option_index !== undefined && start === options[option_index].start;

//           return {
//             className: [
//               voted ? "option-selected" : "option-selectable",
//               won ? "event_voting_option_won" : "",
//             ].join(" "),
//             disabled:
//               (selectedDate instanceof DateObject &&
//                 isSameDate(selectedDate, date)) ||
//               (selectedDate instanceof Array &&
//                 selectedDate.some((d) => isSameDate(d, date)))
//                 ? false
//                 : true,
//             children: (
//               <>
//                 <div
//                   className={isSameDate(date, new DateObject()) ? "today" : ""}
//                 >
//                   {date.format("D")}
//                 </div>
//                 {option_index !== undefined && (
//                   <div className="note">
//                     <div className="number">
//                       {options[option_index].votes.length}
//                     </div>{" "}
//                     <div className="text">vot.</div>
//                   </div>
//                 )}
//               </>
//             ),
//           };
//         }}
//       />
//     </>
//   );
// };

// const VotingOption: React.FC<{
//   eventId: number;
//   option: DateOption | LocationOption;
//   final: boolean;
//   maxVotes: number;
//   votingClosed: boolean;
//   setRerender: React.Dispatch<React.SetStateAction<Number>>;
//   onChange: () => void;
// }> = ({
//   eventId,
//   option,
//   final,
//   maxVotes,
//   votingClosed,
//   setRerender,
//   onChange,
// }) => {
//   const { currentUser: user } = useStorage();
//   const [popoverState, setShowPopover] = useState<PopoverSettings>({
//     showPopover: false,
//     event: undefined,
//   });
//   const { axios } = useAxios();

//   const { mutate: vote } = useMutation(
//     ["location_or_date_vote"],
//     () =>
//       axios.post(
//         `/events/${eventId}/vote/${
//           "eventLocationOptionId" in option ? "location" : "date"
//         }/${
//           "eventLocationOptionId" in option
//             ? option.eventLocationOptionId
//             : option.eventDateOptionId
//         }`
//       ),
//     { onSuccess: () => onChange() }
//   );
//   const { mutate: downvote } = useMutation(
//     ["location_or_date_downvote"],
//     () =>
//       axios.delete(
//         `/events/${eventId}/vote/${
//           "eventLocationOptionId" in option ? "location" : "date"
//         }/${
//           "eventLocationOptionId" in option
//             ? option.eventLocationOptionId
//             : option.eventDateOptionId
//         }`
//       ),
//     { onSuccess: () => onChange() }
//   );

//   return (
//     <>
//       <div
//         className={[
//           "event_voting_option",
//           user_voted(option.votes, user?.userId)
//             ? "event_voting_option_voted"
//             : "",
//           final ? "event_voting_option_won" : "",
//         ].join(" ")}
//         style={{
//           background: calculate_votes_gradient(
//             maxVotes,
//             option.votes,
//             user_voted(option.votes, user?.userId)
//           ),
//         }}
//         onClick={() => {
//           if (votingClosed || !user) return;
//           // TODO: handle errors
//           if (user_voted(option.votes, user?.userId)) {
//             // optimistic update
//             option.votes = option.votes.filter(
//               (vote) => vote.userId !== user?.userId
//             );
//             setRerender(Math.random());
//             downvote();
//           } else {
//             // optimistic update
//             if (user?.userId) {
//               option.votes.push({
//                 userId: user.userId,
//                 name: user.name,
//                 photo: user.photo,
//               });
//               setRerender(Math.random());
//             }
//             vote();
//           }
//         }}
//       >
//         <div className="label">
//           {!votingClosed && user && (
//             <IonCheckbox
//               aria-label="votingOption"
//               color="primary"
//               checked={user_voted(option.votes, user?.userId)}
//             />
//           )}
//           <span>
//             {"name" in option
//               ? option.name
//               : format_date(moment(option.start).toDate())}
//           </span>
//         </div>
//         <div
//           className="info"
//           onClick={(e: any) => {
//             e.persist();
//             setShowPopover({ showPopover: true, event: e });
//             e.stopPropagation();
//           }}
//         >
//           <div className="votes">{option.votes.length} votes</div>
//           <IonIcon icon={info} />
//         </div>
//       </div>

//       <IonPopover
//         className="popover_social"
//         event={popoverState.event}
//         isOpen={popoverState.showPopover}
//         onDidDismiss={() =>
//           setShowPopover({ showPopover: false, event: undefined })
//         }
//       >
//         <IonList>
//           {option.votes.length ? (
//             option.votes.map((u) => {
//               return (
//                 <IonItem
//                   data-sl="mask"
//                   className="smartlook-hide"
//                   key={u.userId}
//                   detail={false}
//                 >
//                   <Avatar photo={u.photo} />
//                   {u.name}
//                 </IonItem>
//               );
//             })
//           ) : (
//             <IonItem>No votes{votingClosed ? "" : " yet"}</IonItem>
//           )}
//         </IonList>
//       </IonPopover>
//     </>
//   );
// };

// // TOOD: merge with Date Voting
// const EventVotingLocation: React.FC<{
//   eventId: number;
//   location: Location | undefined;
//   locationOptions: LocationOption[];
//   isHost: boolean;
//   votingClosed: boolean;
//   onChange: () => void;
// }> = ({
//   eventId,
//   location,
//   locationOptions,
//   isHost,
//   votingClosed,
//   onChange,
// }) => {
//   const [showModal, setShowModal] = useState(false);
//   const [collapsed, setCollapsed] = useState(true);
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   var [_, setRerender] = useState<Number>(Math.random());

//   const { setMessage } = useStorage();

//   // TODO: store in state?
//   const maxVotes = calculate_max_votes(locationOptions);

//   const { axios } = useAxios();

//   const { mutateAsync: finishVoting } = useMutation<
//     AxiosResponse<unknown>,
//     AxiosError,
//     FinishVotingPayload
//   >(
//     (finishVotingPayload) =>
//       axios.post<FinishVotingPayload>(
//         `/events/${eventId}/voting/location/finish`,
//         finishVotingPayload
//       ),
//     {
//       onSuccess: () => onChange(),
//       onError: (err) => setMessage("danger", "Failed to finish voting", err),
//     }
//   );

//   return (
//     <>
//       {votingClosed && (
//         <div
//           className="voting_controls"
//           onClick={() => {
//             setCollapsed(!collapsed);
//           }}
//         >
//           {collapsed
//             ? "Show location voting results"
//             : "Hide location voting results"}
//           <IonIcon
//             color="primary"
//             size="large"
//             icon={collapsed ? expandMore : expandLess}
//           />
//         </div>
//       )}
//       <div
//         className="event_voting"
//         style={{ display: votingClosed && collapsed ? "none" : "block" }}
//       >
//         <div className="compact_header">Voting on the location</div>

//         {locationOptions.map((option, i) => {
//           return (
//             <VotingOption
//               key={i}
//               eventId={eventId}
//               option={option}
//               final={location?.id === option.id}
//               maxVotes={maxVotes}
//               votingClosed={votingClosed}
//               setRerender={setRerender}
//               onChange={onChange}
//             />
//           );
//         })}

//         {isHost && !votingClosed && (
//           <div className="align_right">
//             <IonButton
//               className="finalize_voting button-primary-light"
//               size="default"
//               fill="clear"
//               onClick={() => setShowModal(true)}
//             >
//               <IonIcon icon={done} /> End voting
//             </IonButton>
//           </div>
//         )}

//         <CloseVoting
//           options={locationOptions}
//           showModal={showModal}
//           setShowModal={setShowModal}
//           onChange={(finalOption: DateOption | LocationOption) => {
//             if ("eventLocationOptionId" in finalOption)
//               return finishVoting({
//                 optionId: finalOption.eventLocationOptionId,
//               });
//           }}
//         />
//       </div>
//     </>
//   );
// };

const About: React.FC<{
  event: Event;
  onEventChanged?: CallableFunction;
}> = ({ event, onEventChanged }) => {
  // const [attendeesFilter, setAttendeesFilter] =
  //   useState<AttendeesFilter>("all");
  const [isPast, setIsPast] = useState(false);
  const eventEl = useRef<HTMLIonContentElement>(null);
  const { id } = useParams<{ id: string; section: string }>();
  const { t } = useTranslation();
  // const { axios } = useAxios();

  const { setMessage, currentUser: user } = useStorage();
  // const [showMap, setShowMap] = useState(false);
  // const [showAddMembersModal, setShowAddMembersModal] = useState(false);
  const [popoverShareState, setShowSharePopover] = useState<PopoverSettings>({
    showPopover: false,
    event: undefined,
  });

  const { joinEvent } = useJoinEvent(event.eventId);
  const { interestedInEvent } = useInterestedInEvent();
  const { sendMetric } = useAnalytics();
  const leaveEvent = useLeaveEvent();
  const { link_copied } = useTranlatedMsg();

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  useEffect(() => {
    if (moment(calc_end_date(event)) < moment()) {
      setIsPast(true);
    } else {
      setIsPast(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  useEffect(() => {
    eventEl.current?.scrollToTop();
  }, [id]);

  return (
    <IonContent className="event_about" ref={eventEl}>
      <div className="event_details">
        <AboutHeader
          photo={get_event_photo_url(
            event.photo,
            get_primary_tag_slug_default(event.tags)
          )}
          // coordinates={
          //   event?.location?.lat && event?.location?.lng
          //     ? {
          //         lat: event.location.lat,
          //         lng: event.location.lng,
          //       }
          //     : undefined
          // }
          mask={event?.public === false}
          isPast={isPast}
          // showMap={showMap}
          // setShowMap={setShowMap}
          // pin={set_pin({ event, tags: getLevelOneTags(event?.tags!) })}
          end={
            <>
              <EventDropdownMenu
                event={event}
                onEventUpdated={() => {
                  if (onEventChanged) onEventChanged();
                }}
              />
              <IonButton
                size="small"
                onClick={(e: any) => {
                  setShowSharePopover({ showPopover: true, event: e });
                }}
                className="button share"
              >
                <IonIcon color="dark" icon={shareIos} />
              </IonButton>
            </>
          }
        />
        <EventBasicInfoDetail
          // showMap={showMap}
          // setShowMap={setShowMap}
          event={event}
          onEventChanged={() => {
            if (onEventChanged) onEventChanged();
          }}
          // setShowAddMembersModal={setShowAddMembersModal}
          isPast={isPast}
          setShowSharePopover={setShowSharePopover}
        />

        {user && (
          <div className="big_button_wrapper low">
            <PopoverActionButtons
              popoverState={popoverState}
              setShowPopover={setShowPopover}
            />
            {/* <IonButton
            className="modal_big_button"
            onClick={() => console.log("//")}
          >
            
          </IonButton> */}
            <IonButton
              className="modal_big_button"
              onClick={() => {
                if (!user) {
                  setShowPopover({ showPopover: true, event: undefined });
                  return;
                }
                if (
                  !event.attends ||
                  event.attends === "DECLINED" ||
                  event.attends === "MAYBE" ||
                  event.attends === "INVITED"
                ) {
                  joinEvent(
                    {},
                    {
                      onSuccess: () => {
                        if (onEventChanged) onEventChanged();
                        sendMetric({
                          name: "event_join",
                          data: {
                            event_id: event.eventId,
                            source: "detail",
                          },
                        });
                      },
                    }
                  );
                } else {
                  leaveEvent(event.eventId, {
                    onSuccess: () => {
                      if (onEventChanged) onEventChanged();
                      sendMetric({
                        name: "event_leave",
                        data: {
                          event_id: event.eventId,
                          source: "detail",
                          // old_status: "going",
                        },
                      });
                    },
                  });
                }
              }}
            >
              {event.attends === "GOING"
                ? t("event_detail.going")
                : t("event_detail.join")}
            </IonButton>

            <IonIcon
              className="icon-bookmark"
              color="primary"
              icon={
                event.attends && ["GOING", "MAYBE"].includes(event.attends)
                  ? "/assets/icon/new/bookmark-full.svg"
                  : "/assets/icon/new/bookmark.svg"
              }
              onClick={() => {
                if (!user) {
                  setShowPopover({ showPopover: true, event: undefined });
                  return;
                }
                if (
                  !event.attends ||
                  event.attends === "DECLINED" ||
                  event.attends === "INVITED"
                ) {
                  interestedInEvent(event.eventId, {
                    onSuccess: () => {
                      if (onEventChanged) onEventChanged();
                      sendMetric({
                        name: "event_interested",
                        data: {
                          event_id: event.eventId,
                          source: "detail",
                          // old_status: "going",
                        },
                      });
                    },
                  });
                } else {
                  leaveEvent(event.eventId, {
                    onSuccess: () => {
                      if (onEventChanged) onEventChanged();
                      sendMetric({
                        name: "event_leave",
                        data: {
                          event_id: event.eventId,
                          source: "detail",
                          // old_status: "going",
                        },
                      });
                    },
                  });
                }
              }}
            />
          </div>
        )}
      </div>

      {/* <AddMembersModal
        presentUsers={get_event_attendee_ids(event)}
        showModal={showAddMembersModal}
        setShowModal={setShowAddMembersModal}
        onChange={(selectedUsers) => {
          if (event.eventId) {
            invite_to_event(event.eventId, selectedUsers, axios)
              .then(() => {
                if (onEventChanged) onEventChanged(Math.random());
                setMessage(
                  "primary",
                  `User${selectedUsers.length > 1 ? "s" : ""} invited`
                );
              })
              .catch((err) => setMessage("danger", undefined, err));
          }
        }}
        typeOfModal="invite_friends"
      /> */}

      <IonPopover
        className="popover_options"
        event={popoverShareState.event}
        isOpen={popoverShareState.showPopover}
        onDidDismiss={() =>
          setShowSharePopover({ showPopover: false, event: undefined })
        }
      >
        <IonList>
          <IonItem
            onClick={() => {
              copy(getEventPublicUrl(event.eventId, event.hash));
              setMessage("primary", link_copied);
              setShowSharePopover({
                showPopover: false,
                event: undefined,
              });
              logEvent("event_share_copy", {});
            }}
          >
            {t("event_detail.copy_link")}
          </IonItem>
          {device !== "desktop" && (
            <IonItem
              onClick={() => {
                SocialSharing.share(
                  `Hi, check and come with me to ${event?.title}\nMore info: https://m.manyways.info/events/${event?.eventId}/${event?.hash}/about`
                );
                setShowSharePopover({
                  showPopover: false,
                  event: undefined,
                });
                logEvent("event_share_native", {});
              }}
            >
              {t("event_detail.share_via")} ...
            </IonItem>
          )}
        </IonList>
      </IonPopover>

      {/* <IonGrid>
        {!!event.dateOptions.length && (
          <IonRow>
            <IonCol>
              <EventVotingDate
                eventId={event.eventId}
                start={event.start}
                dateOptions={event.dateOptions}
                isHost={event.hostId === user?.userId}
                votingClosed={!!event.start}
                onChange={() => {
                  if (onEventChanged) onEventChanged();
                }}
              />
            </IonCol>
          </IonRow>
        )}

        {!!event.locationOptions.length && (
          <IonRow>
            <IonCol>
              <EventVotingLocation
                eventId={event.eventId}
                location={event.location}
                locationOptions={event.locationOptions}
                isHost={event.hostId === user?.userId}
                votingClosed={!!event.location}
                onChange={() => {
                  if (onEventChanged) onEventChanged();
                }}
              />
            </IonCol>
          </IonRow>
        )}
      </IonGrid> */}

      {/* {user && (
        <EventAttendees
          event={event}
          attendeesFilter={attendeesFilter}
          setAttendeesFilter={setAttendeesFilter}
          onEventChanged={onEventChanged}
          setShowAddMembersModal={setShowAddMembersModal}
          isPast={isPast}
          setShowSharePopover={setShowSharePopover}
        />
      )} */}
    </IonContent>
  );
};

// const EventAttendees: React.FC<{
//   event: Event;
//   attendeesFilter: AttendeesFilter;
//   setAttendeesFilter: React.Dispatch<React.SetStateAction<AttendeesFilter>>;
//   onEventChanged: CallableFunction | undefined;
//   setShowAddMembersModal: React.Dispatch<React.SetStateAction<boolean>>;
//   setShowSharePopover: React.Dispatch<React.SetStateAction<PopoverSettings>>;
//   isPast: boolean;
// }> = ({
//   event,
//   attendeesFilter,
//   setAttendeesFilter,
//   onEventChanged,
//   setShowAddMembersModal,
//   setShowSharePopover,
//   isPast,
// }) => {
//   const { currentUser: user } = useStorage();

//   const friendList = event.public && event.hostId !== user?.userId;

//   const filteredAttendees =
//     event.attendees?.filter((a) =>
//       friendList ? friends_filter(a, user?.userId) : true
//     ) || [];

//   const sectionAttendees = filteredAttendees
//     ?.filter((a) => attendee_filter(a, attendeesFilter))
//     ?.sort((a: Attendee, b: Attendee) =>
//       sort_attendees(a, b, user, event.hostId)
//     );

//   return (
//     <>
//       <IonGrid>
//         <IonRow>
//           <IonCol>
//             <IonToolbar className="inner">
//               <h5>{friendList ? "Your friends attendance" : "Attendees"}</h5>
//               <IonButton
//                 disabled={isPast}
//                 className="btn-toolbar-inner button-primary-light"
//                 size="small"
//                 slot="end"
//                 fill="clear"
//                 color={isPast ? "dark" : "primary"}
//                 onClick={(e: any) => {
//                   if (isPast) return;
//                   setShowSharePopover({ showPopover: true, event: e });
//                 }}
//               >
//                 <IonIcon icon={shareIos} />
//               </IonButton>
//               <IonButton
//                 disabled={isPast}
//                 className="btn-toolbar-inner button-primary-light"
//                 size="small"
//                 slot="end"
//                 fill="clear"
//                 color={isPast ? "dark" : "primary"}
//                 onClick={() => {
//                   if (isPast) return;
//                   setShowAddMembersModal(true);
//                 }}
//               >
//                 <IonIcon icon={share} />
//               </IonButton>
//             </IonToolbar>
//           </IonCol>
//         </IonRow>
//         <IonRow>
//           <IonCol>
//             <EventAttendeesShortAndFilter
//               eventId={event.eventId}
//               isPublic={event.public}
//               attendees={filteredAttendees}
//               attendeesFilter={attendeesFilter}
//               setAttendeesFilter={setAttendeesFilter}
//             />
//           </IonCol>
//         </IonRow>
//       </IonGrid>
//       {sectionAttendees.length ? (
//         <IonList className="community_list" lines="none">
//           {sectionAttendees.map((attendee, i) => {
//             return (
//               <EventAttendee
//                 key={i}
//                 event={event}
//                 attendee={attendee}
//                 onAttendeeChanged={() => {
//                   if (onEventChanged) onEventChanged();
//                 }}
//               />
//             );
//           })}{" "}
//         </IonList>
//       ) : (
//         <EventNoAttendeesMessage
//           friendList={friendList}
//           attendeesFilter={attendeesFilter}
//         />
//       )}
//     </>
//   );
// };

const InvalidAccessPage: React.FC = () => {
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Event not found</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="event_about">
        <EmptySection
          icon={block}
          title={"Ouuups!"}
          description={"Event doesn't exist"}
        />
      </IonContent>
    </>
  );
};

const EventDropdownMenu: React.FC<{
  event: Event;
  onEventUpdated: CallableFunction;
}> = ({ event, onEventUpdated }) => {
  const { navigate } = useNavigation();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showEditEventModal, setShowEditEventModal] = useState(false);
  var [prefetchedEvent, setPrefetchedEvent] = useState<Event>();
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const { currentUser: user, setMessage } = useStorage();
  const { axios } = useAxios();

  // TODO: I don't like this API path

  const { mutate: deleteEvent } = useMutation(
    () => axios.delete(`/events/${event.eventId}`),
    {
      onSuccess: () => {
        setShowPopover({
          showPopover: false,
          event: undefined,
        });
        logEvent("event_delete", {});

        navigate(`/events`);
      },
      onError: (err) => setMessage("danger", undefined, err),
    }
  );

  // async function duplicate_event(eventId: number) {
  //   return load_event(axios, eventId).then((event) => {
  //     setPrefetchedEvent(event);
  //     setShowAddEventModal(true);
  //   });
  // }

  async function edit_event(eventId: number) {
    return load_event(axios, eventId).then((event) => {
      setPrefetchedEvent(event);
      setShowEditEventModal(true);
    });
  }

  return (
    <>
      {/* {(!event.public || event.hostId === user?.userId) && ( */}
      {event.hostId === user?.userId && (
        <IonButton
          color="dark"
          onClick={(e: any) => {
            e.persist();
            setShowPopover({ showPopover: true, event: e });
          }}
        >
          <IonIcon icon={moreVert} />
        </IonButton>
      )}
      <IonPopover
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
      >
        <IonList lines="none">
          {/* {(!event.public || event.hostId === user?.userId) && (
            <IonItem
              onClick={() => {
                setShowPopover({
                  showPopover: false,
                  event: undefined,
                });
                duplicate_event(event.eventId).catch((err) =>
                  setMessage("danger", undefined, err)
                );
              }}
            >
              <IonIcon size="small" icon={contentCopy} /> Duplicate event
            </IonItem>
          )} */}
          {event.hostId === user?.userId && (
            <>
              <IonItem
                onClick={() => {
                  setShowPopover({
                    showPopover: false,
                    event: undefined,
                  });
                  edit_event(event.eventId).catch((err) =>
                    setMessage("danger", undefined, err)
                  );
                }}
              >
                <IonIcon size="small" icon={edit} /> Edit event
              </IonItem>

              <IonItem onClick={() => setShowDeleteAlert(true)}>
                <IonIcon size="small" icon={trash} /> Delete event
              </IonItem>
            </>
          )}
        </IonList>
      </IonPopover>

      {prefetchedEvent && (
        <EditEvent
          showModal={showEditEventModal}
          setShowModal={setShowEditEventModal}
          prefetchedEvent={prefetchedEvent}
          onEventUpdated={() => {
            onEventUpdated();
          }}
        />
      )}

      <AddEvent
        showModal={showAddEventModal}
        setShowModal={setShowAddEventModal}
        // TODO: handle event type in all event duplications
        type="default"
        prefetchedEvent={prefetchedEvent}
      />

      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => setShowDeleteAlert(false)}
        onWillPresent={() =>
          setShowPopover({
            showPopover: false,
            event: undefined,
          })
        }
        header={"Delete event?"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "Confirm",
            cssClass: "primary",
            handler: () => {
              deleteEvent();
            },
          },
        ]}
      />
    </>
  );
};

interface EventDetailProps
  extends RouteComponentProps<{
    id: string;
    hash?: string;
    section?: string;
  }> {}

const EventDetail: React.FC<EventDetailProps> = ({ match }) => {
  // const [isActive, setIsActive] = useState<boolean>(false);
  const [invalidAccess, setInvalidAccess] = useState(false);
  // const [tab, setTab] = useState<string | undefined>(
  //   match.params.section ? match.params.section : "about"
  // );

  const { setDynamicLink } = useStorage();
  const { updatePathsAsSeen } = useNotifications();

  const {
    data: event,
    refetch,
    isLoading,
  } = useQuery<AxiosResponse<Event>, AxiosError, Event>(
    ["event", match.params.id],
    () =>
      axios.get<Event>(`/events/${parseInt(match.params.id)}`, {
        params: {
          hash: match.params.hash,
        },
      }),
    {
      select: (res) => res.data,
      onSuccess: (res) => {
        console.log("GET event", res);
        setInvalidAccess(false);

        if (setDynamicLink && res.dynamicLink) setDynamicLink(res.dynamicLink);
        // if (!res.public && !res.attends && match.params.hash && user) {
        //   invite_to_event(res.eventId, [user?.userId], axios, match.params.hash)
        //     .then(() => {
        //       refetch();
        //     })
        //     .catch((err) => setMessage("danger", undefined, err));
        // }
      },
      onError: (error) => {
        console.log("GET event error", error);
        setInvalidAccess(true);
      },
      retry: (failureCount, error) => {
        return error.message.includes("403") ||
          error.message.includes("404") ||
          error.message.includes("401")
          ? false
          : true;
      },
    }
  );

  const { axios } = useAxios();

  // const { data: chat } = useQuery(
  //   ["chat", event?.chatId],
  //   () => axios.get<Chat>(`/chats/${event?.chatId}`),
  //   { select: (res) => res.data, enabled: !!event && !!user }
  // );

  // useEffect(() => {
  //   setIsActive(true);

  //   return () => {
  //     setIsActive(false);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useIonViewWillEnter(() => {
    // setIsActive(true);
    showTabBar();
  });

  // useIonViewWillLeave(() => {
  //   setIsActive(false);
  // });

  // TODO: refresh events when new event notification is received

  useEffect(() => {
    updatePathsAsSeen([
      ["event", parseInt(match.params.id)],
      ["event", parseInt(match.params.id), "invitation"],
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  // useIonViewDidEnter(() => {
  //   if (tab !== match.params.section) setTab(match.params.section);
  // }, [match.params.section]);

  // useEffect(() => {
  //   if (tab === "about") refetch();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tab]);

  return (
    <IonPage className="event_detail">
      {invalidAccess && !event && <InvalidAccessPage />}

      {isLoading ? (
        <SkeletonEvDet />
      ) : (
        event && (
          <>
            {/* <IonHeader> */}

            {/* <IonToolbar>
                <IonButtons slot="start">
                  
                </IonButtons>

                <IonTitle
                  data-sl={event.public === false ? "mask" : ""}
                  className={event.public === false ? "smartlook-hide" : ""}
                >
                  {event.title}
                </IonTitle> */}
            {/* <IonButtons slot="end">
                  {!invalidAccess && (
                    <EventDropdownMenu
                      event={event}
                      onEventUpdated={() => {
                        refetch();
                      }}
                    />
                  )}
                </IonButtons> */}
            {/* </IonToolbar> */}

            {/* {!event.public && (
                <IonSegment
                  value={tab}
                  onIonChange={(e) => {
                    setTab(e.detail.value);
                  }}
                  className="simple original"
                  color="primary"
                >
                  <IonSegmentButton value="about">
                    <IonLabel>About</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="discussion"
                    disabled={is_chat_disabled(event, user)}
                  >
                    <IonLabel>
                      Discussion
                      {has_notification_path(notifications, [
                        "event",
                        event.eventId,
                        "chat",
                      ]).length > 0 ? (
                        <span className="notification_dot" />
                      ) : (
                        ""
                      )}
                    </IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              )} */}
            {/* </IonHeader> */}
            {/* {tab === "discussion" ? (
              chat && isActive ? (
                <Discussion
                  type="event"
                  chat={chat}
                  groupId={event.groupId}
                  isActive={isActive}
                  id={parseInt(match.params.id)}
                />
              ) : // TODO: change null to Loading component
              null
            ) : ( */}

            <About
              event={event}
              onEventChanged={() => {
                refetch();
              }}
            />
            {/* )} */}
          </>
        )
      )}
    </IonPage>
  );
};

export default EventDetail;
