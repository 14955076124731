import React from "react";
import { IonContent, IonModal, IonButton, IonTextarea } from "@ionic/react";
import "./CUGroup.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import { Friend } from "../models/User";
import { ModalHeader } from "./Headers";
import { useStorage } from "../hooks";
import { InferType } from "yup";
import useAxios from "../hooks/useAxios";
import { useMutation } from "react-query";
import { AxiosResponse, AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import useTranlatedMsg from "../hooks/useTranslatedMsg";

const validationRules = Yup.object({
  // userId: Yup.number(),
  text: Yup.string().min(15).required("Required"),
}).required();

const ReportAbuse: React.FC<{
  showModal: boolean;
  setShowModal: CallableFunction;
  friend?: Friend;
  onChange?: CallableFunction;
}> = ({ showModal, setShowModal, friend, onChange }) => {
  const { setMessage } = useStorage();
  const { axios } = useAxios();
  const { t } = useTranslation();
  const { err_send_report } = useTranlatedMsg();

  // const [selectedUser, setSelectedUser] = useState<Friend | undefined>(
  //   undefined
  // );

  // const [showUsersModal, setShowUsersModal] = useState(false);

  const { mutate: submit } = useMutation<
    InferType<typeof validationRules>,
    AxiosResponse<unknown>,
    AxiosError
  >((payload) => axios.post("/profile/report_abuse", payload));

  const closeModal = () => {
    return setShowModal(false);
  };

  const sendReport = (payload: any) => {
    submit(
      {
        ...payload,
        userId: friend?.userId || payload.userId || null,
      },

      {
        onSuccess: () => {
          if (onChange) onChange();
          closeModal();
          // setSelectedUser(undefined);
        },
        onError: (err) => {
          setMessage("danger", err_send_report, err);
        },
      }
    );
  };

  return (
    <IonModal isOpen={showModal} onDidDismiss={closeModal}>
      <Formik
        validationSchema={validationRules}
        initialValues={{ userId: "", text: "" }}
        onSubmit={(values) => {
          sendReport(values);
        }}
        onReset={() => {
          setShowModal(false);
        }}
      >
        {({
          dirty,
          values,
          errors,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <>
            <ModalHeader
              title={t("setting.report_abuse")}
              onClick={() => {
                // setSelectedUser(undefined);
                closeModal();
              }}
              isSubmitting={isSubmitting}
            />

            <IonContent className="form">
              <div className="content-padded">
                <h3>{t("setting.report_abuse_title")}</h3>
                <div className="text-14 margin-bottom">
                  {t("setting.report_abuse_subtitle")}
                </div>

                {/* <IonRow>
                  <IonCol className="label">
                    <IonIcon className="label_icon" icon={person} />
                    Report user:
                  </IonCol>
                  <IonCol className={friend || selectedUser ? "label" : ""}>
                    {friend ? (
                      friend.name
                    ) : (
                      <>
                        {selectedUser ? (
                          <span onClick={() => setShowUsersModal(true)}>
                            {selectedUser.name}
                          </span>
                        ) : (
                          <IonInput
                            aria-label="userId"
                            name="userId"
                            onIonInput={handleChange}
                            onIonBlur={handleBlur}
                            value={values.userId}
                            placeholder="Select user"
                            onClick={() => setShowUsersModal(true)}
                          />
                        )}
                      </>
                    )}
                  </IonCol>
                </IonRow>
                <AddMembersModal
                  presentUsers={[]}
                  showModal={showUsersModal}
                  setShowModal={setShowUsersModal}
                  keepState={false}
                  pickSingle={true}
                  onChange={(selectedUsers: number[]) => {
                    if (selectedUsers.length) {
                      setFieldValue("userId", selectedUsers[0]);
                      get_user(selectedUsers[0], axios).then(
                        (result) => {
                          setSelectedUser(result);
                          //setUsers(result);
                        },
                        (err) =>
                          setMessage("danger", "Error in fetching friends", err)
                      );
                    }
                  }}
                /> */}

                <IonTextarea
                  aria-label="reportAbuse"
                  name="text"
                  auto-grow="true"
                  rows={5}
                  onIonInput={handleChange}
                  onIonBlur={handleBlur}
                  value={values.text}
                  placeholder={t("setting.report_abuse_placeholder")}
                  autocapitalize="true"
                />
                {touched.text && errors.text ? (
                  <div className="error">{errors.text}</div>
                ) : null}
              </div>
            </IonContent>

            <IonButton
              type="submit"
              className="modal_big_button"
              disabled={isSubmitting || !dirty || !values.text}
              onClick={() => handleSubmit()}
            >
              {t("setting.report_abuse")}
            </IonButton>
          </>
        )}
      </Formik>
    </IonModal>
  );
};

export default ReportAbuse;
