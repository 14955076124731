import React, { useState, useEffect, useMemo } from "react";
import { IonContent, IonModal, IonButton, IonGrid } from "@ionic/react";
import "./CUEvent.scss";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { Location, Event, DateOption, SimpleDateOption } from "../models/Event";
import {
  EventCategoryFormRow,
  EventDatetimeFormRows,
  EventDescriptionFormRow,
  EventFeeFormRow,
  EventLocationFormRows,
  EventTagsFormRow,
  EventTicketsFormRow,
  EventTitleFormRow,
  EventVisibilityFormRow,
  EventWebFormRow,
} from "./CUEvent";
import { ModalHeader } from "./Headers";
import { calc_end_date, get_primary_tag_slug } from "../helpers/event";
import { useStorage } from "../hooks";
import { TITLE_LENGTH_LIMIT, URL_REGEX } from "../constants/events";
import { InferType, Schema } from "yup";
import { useMutation } from "react-query";
import useAxios from "../hooks/useAxios";
import { AxiosError, AxiosResponse } from "axios";
import { get_region } from "../cache/coordinates";
import currencies from "../constants/currencies";
import useTranlatedMsg from "../hooks/useTranslatedMsg";
import { useTranslation } from "react-i18next";

export function simplify_date_options(
  dateOptions: DateOption[]
): SimpleDateOption[] {
  let converted_options: SimpleDateOption[] = [];

  for (let i = 0; i < dateOptions.length; i++) {
    converted_options.push({
      start: moment(dateOptions[i].start).toDate(),
      end: moment(dateOptions[i].end).toDate(),
    });
  }

  return converted_options;
}

const validationRules = Yup.object({
  eventTitle: Yup.string()
    .required("Required")
    .max(
      TITLE_LENGTH_LIMIT,
      `Title must be at most ${TITLE_LENGTH_LIMIT} characters`
    ),
  eventDescription: Yup.string(),
  eventStartDatetime: Yup.date().required(),
  eventEndDatetime: Yup.date()
    .when("eventStartDatetime", (eventStartDatetime, schema) => {
      return eventStartDatetime
        ? schema.min(eventStartDatetime, "End time cannot be before start time")
        : schema;
    })
    .required(),
  eventDateOptions: Yup.array()
    .of(
      Yup.object()
        .shape({
          start: Yup.date().required(),
          end: Yup.date().required(),
        })
        .required()
    )
    .required(),
  eventLocationOptions: Yup.array()
    .of(
      Yup.string()
        .required("Required")
        .test(
          "is-unique",
          "Locations must be unique",
          function (value): boolean {
            let duplicates = this.parent.filter(
              (eventLocationId: string | undefined) => {
                return eventLocationId && eventLocationId === value;
              }
            );
            return duplicates.length === 1;
          }
        )
    )
    .required(),
  eventVisibility: Yup.string().oneOf(["private", "public"]),
  eventEntryFeeAmount: Yup.number(),
  eventEntryFeeCurrency: Yup.string().oneOf(currencies),
  eventSourceUrl: Yup.string().matches(URL_REGEX, "Enter correct url"),
  eventTicketsUrl: Yup.string().matches(URL_REGEX, "Enter correct url"),
  eventCategory: Yup.string().when("eventVisibility", {
    is: (eventVisibility: string) => eventVisibility === "public",
    then: (schema: Schema) => schema.required("Category is required"),
  }), // TODO: allow only tags as value
  eventTags: Yup.array().of(Yup.string().required()).required(),
});

type EditEventFormFields = InferType<typeof validationRules>;

export type EditEventProps = FormikProps<EditEventFormFields>;

const EditEvent: React.FC<{
  showModal: boolean;
  setShowModal: CallableFunction;
  map?: google.maps.Map | undefined;
  onEventUpdated?: CallableFunction;
  mapPinLocation?: Location | null;
  setMapPinLocation?: CallableFunction;
  prefetchedEvent: Event;
}> = ({
  showModal,
  setShowModal,
  map,
  onEventUpdated,
  mapPinLocation,
  setMapPinLocation,
  prefetchedEvent,
}) => {
  const { err_while_upload, err_update_event } = useTranlatedMsg();
  const { t } = useTranslation();
  const { setMessage } = useStorage();
  const [prefetchedOptions, setPrefetchedOptions] = useState<
    SimpleDateOption[] | undefined
  >(undefined);
  const [imagePreview, setImagePreview] = useState<any | undefined>(undefined);

  const [placeResults, setPlaceResults] = useState<(Location | undefined)[]>([
    undefined,
  ]);
  const { axios } = useAxios();

  const { mutate: submit } = useMutation(
    ["edited_event_payload"],
    (event_payload) =>
      axios.put<InferType<typeof validationRules>>(
        `/events/${prefetchedEvent.eventId}`,
        event_payload
      )
  );

  const { mutate: uploadPhoto } = useMutation<
    AxiosResponse<unknown>,
    AxiosError,
    FormData
  >(
    ["uploaded_photo"],
    (formData) => {
      return axios.post(`/events/${prefetchedEvent.eventId}/photo`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: () => {
        setImagePreview(undefined);
        if (onEventUpdated) onEventUpdated();
      },
      onError: (err, formData) => {
        setMessage("danger", err_while_upload, err);
      },
    }
  );

  useEffect(() => {
    if (prefetchedEvent) setPlaceResults([prefetchedEvent.location]);
    if (prefetchedEvent.start && prefetchedEvent.dateOptions)
      setPrefetchedOptions(simplify_date_options(prefetchedEvent.dateOptions));
  }, [prefetchedEvent]);

  const initial_values: EditEventFormFields = useMemo(() => {
    const eventEndDatetime = prefetchedEvent?.end
      ? moment(prefetchedEvent.end).toDate()
      : prefetchedEvent.dateOptions.length
      ? prefetchedEvent.dateOptions[0].end
      : calc_end_date(prefetchedEvent);

    return {
      eventTitle: prefetchedEvent.title,
      eventDescription: prefetchedEvent.description
        ? prefetchedEvent.description
        : "",
      eventVisibility: prefetchedEvent.public ? "public" : "private",
      eventCategory: get_primary_tag_slug(prefetchedEvent.tags),
      eventTags: prefetchedEvent?.tags
        ? prefetchedEvent.tags
            .filter((tag) => tag.level !== 1)
            .map((tag) => tag.slug)
        : [],
      eventEntryFeeAmount: prefetchedEvent.price
        ? prefetchedEvent.price.amount[0]
        : 0,
      eventEntryFeeCurrency: prefetchedEvent.price
        ? prefetchedEvent.price.currency
        : get_region() === "usa"
        ? "USD"
        : "CZK",
      eventSourceUrl: prefetchedEvent.sourceUrl
        ? prefetchedEvent.sourceUrl
        : "",
      eventTicketsUrl: prefetchedEvent.ticketsUrl
        ? prefetchedEvent.ticketsUrl
        : "",
      eventLocationOptions: prefetchedEvent?.location
        ? [prefetchedEvent?.location.id]
        : prefetchedEvent?.locationOptions.map((location) => location.id),
      eventStartDatetime: prefetchedEvent.start
        ? moment(prefetchedEvent.start).toDate()
        : prefetchedEvent.dateOptions[0].start,
      eventEndDatetime,
      eventDateOptions: prefetchedEvent.start
        ? []
        : simplify_date_options(prefetchedEvent.dateOptions),
    };
  }, [prefetchedEvent]);

  useEffect(() => {
    if (prefetchedEvent.location) {
      setPlaceResults([
        {
          id: prefetchedEvent.location.id,
          name: prefetchedEvent.location.name,
          formattedAddress: prefetchedEvent.location.formattedAddress,
          lat: prefetchedEvent.location.lat,
          lng: prefetchedEvent.location.lng,
        },
      ]);
    } else
      setPlaceResults(
        prefetchedEvent.locationOptions.map((location) => {
          return {
            id: location.id,
            name: location.name,
            formattedAddress: location.formattedAddress,
            lat: location.lat,
            lng: location.lng,
          };
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefetchedEvent?.locationOptions]);

  useEffect(() => {
    if (mapPinLocation && setMapPinLocation) {
      setPlaceResults([mapPinLocation]);
      setMapPinLocation(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapPinLocation]);

  function save(formikActions: any, values: EditEventFormFields) {
    if (placeResults[0] === undefined || placeResults[0].id === undefined) {
      // TODO: show error
      console.log("saving event, error", placeResults[0]);
      return;
    }

    var event_payload: any = {
      title: values.eventTitle,
      description:
        values.eventDescription === "" ? undefined : values.eventDescription,
      public: values.eventVisibility === "public",
      start:
        values.eventDateOptions.length >= 1
          ? undefined
          : values.eventStartDatetime,
      end:
        values.eventDateOptions.length >= 1
          ? undefined
          : values.eventEndDatetime,
      dates:
        values.eventDateOptions.length >= 2
          ? values.eventDateOptions
          : undefined,
      entryFeeAmounts:
        values.eventEntryFeeAmount !== undefined &&
        values.eventVisibility === "public"
          ? [values.eventEntryFeeAmount]
          : undefined,
      entryFeeCurrency: values.eventEntryFeeCurrency
        ? values.eventEntryFeeCurrency
        : undefined,
      ticketsUrl:
        values.eventVisibility === "public" && values.eventTicketsUrl
          ? values.eventTicketsUrl
          : null,
      sourceUrl:
        values.eventVisibility === "public" && values.eventSourceUrl
          ? values.eventSourceUrl
          : null,
      location: placeResults.length === 1 ? placeResults[0] : undefined,
      locations: placeResults.length > 1 ? placeResults : undefined,
      tags:
        values.eventCategory && values.eventVisibility === "public"
          ? [values.eventCategory, ...values.eventTags]
          : undefined,
    };

    submit(event_payload, {
      onSuccess: () => {
        setPlaceResults([undefined]);
        setPrefetchedOptions(undefined);
        formikActions.setSubmitting(false);
        setShowModal(false);
        if (imagePreview) {
          const formData = new FormData();
          formData.append("file", imagePreview, "file");
          uploadPhoto(formData);
        } else if (onEventUpdated) onEventUpdated();
      },
      onError: (err) => {
        setMessage("danger", err_update_event, err);
        formikActions.setSubmitting(false);
      },
    });
  }

  return (
    <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
      <Formik
        initialValues={initial_values}
        validationSchema={validationRules}
        onSubmit={(values, formikActions) => {
          save(formikActions, values);
        }}
        onReset={(values, formikActions) => {
          setShowModal(false);
        }}
      >
        {(props) => (
          <>
            <ModalHeader
              onClick={() => {
                setPlaceResults([undefined]);
                setPrefetchedOptions(undefined);
                props.handleReset();
              }}
              isSubmitting={props.isSubmitting}
              title={t("edit_event.editing")}
            />

            <IonContent className="form add_event_table with_icons">
              <IonGrid>
                <EventVisibilityFormRow
                  props={props}
                  // disabled={!prefetchedEvent.public}
                  disabled={true}
                />

                <EventTitleFormRow
                  props={props}
                  imagePreview={imagePreview}
                  setImagePreview={setImagePreview}
                  oldPhoto={prefetchedEvent.photo}
                />

                <EventDescriptionFormRow props={props} />

                <EventCategoryFormRow props={props} />
                <EventTagsFormRow props={props} />

                <EventLocationFormRows
                  props={props}
                  placeResults={placeResults}
                  setPlaceResults={setPlaceResults}
                  map={map}
                  prefetchedLocationOptions={prefetchedEvent.locationOptions}
                />

                <EventDatetimeFormRows
                  props={props}
                  prefetchedOptions={prefetchedOptions}
                  setPrefetchedOptions={setPrefetchedOptions}
                />

                <EventWebFormRow props={props} />

                <EventFeeFormRow props={props} />

                <EventTicketsFormRow props={props} />
              </IonGrid>
            </IonContent>

            <IonButton
              className="modal_big_button"
              disabled={props.isSubmitting}
              onClick={() => {
                if (
                  !props.values.eventLocationOptions[0] &&
                  placeResults.length > 0 &&
                  placeResults[0]
                ) {
                  props.values.eventLocationOptions[0] = placeResults[0].id;
                }
                props.handleSubmit();
              }}
            >
              {t("edit_event.save")}
            </IonButton>
          </>
        )}
      </Formik>
    </IonModal>
  );
};

export default EditEvent;
