import { IonIcon } from "@ionic/react";
import { ReactNode } from "react";
import "./EmptySection.scss";

export const EmptySection: React.FC<{
  icon: string;
  iconColor?: string;
  title: string;
  description: string | ReactNode;
  children?: React.ReactNode;
}> = ({ icon, iconColor = "primary", title, description, children }) => {
  return (
    <div className="empty_section_solid">
      <IonIcon
        className="empty_section_icon"
        icon={icon}
        size="large"
        color={iconColor}
      />
      <h3>{title}</h3>
      <p>{description}</p>
      {children}
    </div>
  );
};
