import React from "react";
import { IonPage, useIonViewWillEnter } from "@ionic/react";
import { hideTabBar } from "../helpers/tabBar";
import { Guidepost } from "../components/Guidepost";

const GuidepostPage: React.FC = () => {
  useIonViewWillEnter(() => {
    hideTabBar();
  });

  return (
    <IonPage className="page-guidepost">
      <Guidepost skippable={true} />
    </IonPage>
  );
};

export default GuidepostPage;
