import { AxiosInstance } from "axios";
import moment from "moment";
import { SCROLL_STEP } from "../constants/global";
import { Event, AggregatedEvent, AmountOfEvents } from "../models/Event";
import { FilterDateType } from "../models/eventFilter";

type BaseSearchParams = {
  source: string;
  a_lat?: number;
  a_lng?: number;
  b_lat?: number;
  b_lng?: number;
  fulltext: string;
  filter_date: FilterDateType;
  tags: string;
  subtags: string;
  date_from: Date | null;
  date_to: Date | null;
  lat?: number;
  lng?: number;
  interests_tags?: string;
  interests_subtags?: string;
  ref_lat?: number;
  ref_lng?: number;
};

type ListSearchParams = BaseSearchParams & {
  offset: number;
  order_by: "smart_extended";
};

type MapSearchParams = BaseSearchParams & {
  aggregation_level: number;
  order_by: "start";
  order_direction: "ASC";
};
type PreviewSearchParams = BaseSearchParams & {
  preview: boolean;
};

const search = async <T extends Event[] | AggregatedEvent[] | AmountOfEvents>(
  axios: AxiosInstance,
  params: ListSearchParams | MapSearchParams | PreviewSearchParams
) => {
  var clean_date_filter = params.filter_date;
  var clean_date_from = undefined;
  var clean_date_to = undefined;
  if (params.filter_date === "range" && params.date_from && params.date_to) {
    clean_date_from = moment(params.date_from).format("YYYY-MM-DD");
    clean_date_to = moment(params.date_to).format("YYYY-MM-DD");
  } else if (params.filter_date === "range") {
    clean_date_filter = "future";
  }

  return await axios.get<T>(`/events`, {
    params: {
      limit: "offset" in params ? SCROLL_STEP : undefined,
      ...params,
      filter_date: clean_date_filter,
      date_from: clean_date_from,
      date_to: clean_date_to,
    },
  });
};

const load_event = async (axios: AxiosInstance, id: Number, hash?: string) => {
  const res = await axios.get<Event>(`/events/${id}`, {
    params: {
      hash: hash,
    },
    validateStatus: (status) => {
      return status >= 200 && status < 300;
    },
  });
  console.log("GET event", res.data);
  return res.data;
};

async function invite_to_event(
  event_id: number,
  invitees: number[],
  axios: AxiosInstance,
  hash?: string
) {
  var payload: any = {
    invitees: invitees,
  };

  return await axios
    .post(`/events/${event_id}/invitation`, payload, {
      params: {
        hash: hash,
      },
      validateStatus: (status: number) => {
        return status >= 200 && status < 300;
      },
    })
    .then((res) => {
      console.log("POST invite to event", res);
      return res.data;
    });
}

export { search, load_event, invite_to_event };
