import { UserRequestTriggerType, UserRequest } from "../models";
import { useState } from "react";
import useAnalytics from "./useAnalytics";
import { RateApp } from "capacitor-rate-app";
import { AxiosResponse, AxiosError } from "axios";
import { useMutation } from "react-query";
import useAxios from "./useAxios";
import { logEvent } from "../helpers/logging";
import useStorage from "./useStorage";
import useProfile from "./useProfile";
import useTranlatedMsg from "./useTranslatedMsg";

const useUserRequests = () => {
  const { sendMetric } = useAnalytics();
  const { axios } = useAxios();
  const { setMessage, currentUser: user } = useStorage();
  const { getProfile } = useProfile();
  const [requested, setRequested] = useState<boolean>(false);
  const {went_wrong}= useTranlatedMsg()

  const { mutate: submit } = useMutation<
    AxiosResponse<unknown>,
    AxiosError,
    { requestId: number; payload: { state: string } }
  >((req) => axios.put(`/profile/requests/${req.requestId}`, req.payload));

  const trigger = async (name: UserRequestTriggerType) => {
    if (name === "event_join") {
      const request: UserRequest | undefined = user?.requests?.find(
        (req) => req.action === "app_rating"
      );

      if (request && request.state === "open" && !requested) {
        RateApp.requestReview();
        setRequested(true);
        sendMetric({
          name: "apprating_shown",
          data: {},
        });
        logEvent("apprating_shown", {});
        submit(
          {
            requestId: request.userRequestsId,
            payload: {
              state: "closed",
            },
          },
          {
            onSuccess: () => {
              getProfile.refetch();
            },
            onError: (err) => setMessage("danger", went_wrong, err),
          }
        );
      }
    }
  };

  return trigger;
};

export default useUserRequests;
