import React from "react";
import { IonContent, IonIcon, isPlatform, IonButton } from "@ionic/react";
import "./Guidepost.scss";
import { useNavigation } from "../hooks";
import { Auth0GoogleLoginButton, Auth0AppleLoginButton } from "./auth0";

const Guidepost: React.FC<{ skippable?: boolean }> = ({
  skippable = false,
}) => {
  const { navigate } = useNavigation();

  return (
    <IonContent fullscreen>
      <div className="guidepost-container">
        <div className="guidepost-header">
          {skippable && (
            <IonButton
              color="light"
              className="button-skip"
              onClick={() => navigate("/onboarding")}
            >
              Skip
            </IonButton>
          )}
        </div>

        <div className="content-padded container">
          <IonIcon className="logo" icon="/assets/icon/new/logo.svg" />

          <h1>Explore thousands of events on Manyways</h1>

          {/* {isNative && ( */}
          <div className="buttons">
            <IonButton
              className="btn-social btn-social-primary"
              onClick={() => navigate("/registration")}
            >
              Sign up
            </IonButton>

            <Auth0GoogleLoginButton type="register" />

            {isPlatform("ios") && <Auth0AppleLoginButton type="register" />}
          </div>
          {/* )} */}

          <div className="button-small" onClick={() => navigate("/login")}>
            Log in
          </div>
        </div>
      </div>
    </IonContent>
  );
};

export { Guidepost };
