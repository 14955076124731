import React, { useEffect, useState } from "react";
import { IonToggle, ToggleChangeEventDetail } from "@ionic/react";
import ImageSlides from "react-imageslides";

import { Setting } from "../models/User";
import { takePhotoWithAction } from "../helpers/camera";
import { api_url } from "../config";
import { ImageSlidesClose } from "./Discussion";
import { ImageCropper, imageToHtmlTag, resize_image } from "./Cropper";
import { useStorage, useProfile } from "../hooks";
import { useSmartlookSensitive } from "../hooks/useSmartlook";
import { Diagnostic } from "@ionic-native/diagnostic";
import { PopoverActionButtons } from "./Unregistered";
import useTranlatedMsg from "../hooks/useTranslatedMsg";

export const SettingGridRow: React.FC<{
  module: Setting;
  isToggled?: boolean;
  onChange?: () => void;
}> = ({ module, isToggled, onChange }) => {
  const { currentUser: user } = useStorage();
  const { updateSettings } = useProfile();
  // const [popoverState, setShowPopover] = useState({
  //   showPopover: false,
  //   event: undefined,
  // });

  const [popoverStateNoUser, setShowPopoverNoUser] = useState({
    showPopover: false,
    event: undefined,
  });

  const onToggleChange = (e: CustomEvent<ToggleChangeEventDetail<any>>) => {
    e.preventDefault();
    if (onChange) {
      onChange();
      return;
    }
    if (user && !updateSettings.isLoading) {
      updateSettings.mutate({
        ...user.settings,
        [module.name]: !user.settings[module.name],
      });
    }
  };

  return (
    <div className="profile_row">
      <PopoverActionButtons
        popoverState={popoverStateNoUser}
        setShowPopover={setShowPopoverNoUser}
      />
      {/* <IonPopover
        isOpen={popoverState.showPopover}
        event={popoverState.event}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
      >
        <IonContent className="ion-padding" scrollY={false}>
          {module.description}
        </IonContent>
      </IonPopover> */}
      <div className="profile_row_first">
        <h3>{module.title}</h3>
        <IonToggle
          aria-label={module.title}
          checked={isToggled}
          onIonChange={(e: any) => {
            if (user) onToggleChange(e);
          }}
          onClick={(e: any) => {
            if (!user && !module.is_critical)
              setShowPopoverNoUser({ showPopover: true, event: e });
            else if (!user && module.is_critical) Diagnostic.switchToSettings();
          }}
        ></IonToggle>
      </div>

      {/* {!isToggled && module.is_critical && (
        <IonText className="warn_text" color="danger">
          Important for Manyways to work properly
        </IonText>
      )} */}
      <div className="warn_text">{module.description}</div>
      {/* <IonButton
            fill="clear"
            size="small"
            onClick={(e: any) => {
              e.persist();
              setShowPopover({ showPopover: true, event: e });
            }}
          >
            <IonIcon icon={help} />
          </IonButton> */}
    </div>
  );
};

async function prepareImgToCrop(
  image: string,
  setImageToCrop: React.Dispatch<React.SetStateAction<string | undefined>>
) {
  const imageTag = await imageToHtmlTag(image);
  if (imageTag.width > 1500 || imageTag.height > 1500) {
    let resized_image = await resize_image(imageTag, 1500);
    setImageToCrop(resized_image.toDataURL());
  }
  setImageToCrop(image);
}

export const ProfilePhoto: React.FC<{
  photo?: string;
  editEnabled?: boolean;
  group?: boolean;
  onDone?: (blob: any, imageToCrop: string) => void;
}> = ({ photo, editEnabled = false, group = false, onDone }) => {
  const [imageToCrop, setImageToCrop] = useState<string | undefined>(undefined);
  const [photoUrl, setPhotoUrl] = useState("");
  const [slideIsOpen, setSlideIsOpen] = useState(false);
  const { currentUser: user } = useStorage();
  const [popoverStateNoUser, setShowPopoverNoUser] = useState({
    showPopover: false,
    event: undefined,
  });
  useSmartlookSensitive(slideIsOpen);

  const { uploadPhoto } = useProfile();
  const { setMessage } = useStorage();
  const { access_denied, failed_photo } = useTranlatedMsg();

  useEffect(() => {
    if (photo) {
      if (photo.includes("base64")) {
        setPhotoUrl(photo);
      } else {
        setPhotoUrl(
          `${api_url}/files/${group ? "groups" : "profiles"}/${photo}`
        );
      }
    } else
      setPhotoUrl(
        process.env.PUBLIC_URL +
          `/assets/profile${group ? "_group" : ""}_default.png`
      );
  }, [photo, group]);

  return (
    <>
      <PopoverActionButtons
        popoverState={popoverStateNoUser}
        setShowPopover={setShowPopoverNoUser}
      />
      <div
        className="profile_photo_wrapper ion-text-center smartlook-hide"
        data-sl="exclude"
      >
        {editEnabled ? (
          <div
            className="profile_photo ion-text-center"
            style={{ backgroundImage: `url('${photoUrl}')` }}
            onClick={async (e: any) =>
              user
                ? takePhotoWithAction(
                    async (img) => prepareImgToCrop(img, setImageToCrop),
                    setMessage,
                    access_denied,
                    failed_photo
                  )
                : setShowPopoverNoUser({ showPopover: true, event: e })
            }
          >
            {/* <IonIcon size="large" color="medium" icon={photoCameraFill} /> */}
          </div>
        ) : (
          <div
            className="profile_photo ion-text-center smartlook-hide"
            data-sl="exclude"
            style={{ backgroundImage: `url('${photoUrl}')` }}
            onClick={editEnabled ? undefined : () => setSlideIsOpen(true)}
          />
        )}
      </div>
      <ImageCropper
        imageToCrop={imageToCrop}
        aspect={1}
        cropShape="round"
        maxDimensions={200}
        onResizeDone={(blob: any) => {
          if (onDone && imageToCrop) {
            setPhotoUrl(URL.createObjectURL(blob));
            onDone(blob, imageToCrop);
            setImageToCrop(undefined);
          } else {
            uploadPhoto(blob);
            setImageToCrop(undefined);
          }
        }}
        onResizeCancel={() => {
          setImageToCrop(undefined);
        }}
      />
      {!editEnabled && (
        <>
          <ImageSlides
            images={[photoUrl]}
            isOpen={slideIsOpen}
            index={0}
            onChange={() => {}}
          />
          <ImageSlidesClose isOpen={slideIsOpen} setIsOpen={setSlideIsOpen} />
        </>
      )}
    </>
  );
};
