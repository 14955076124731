import React from "react";
import { Formik } from "formik";
import {
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonContent,
  IonIcon,
} from "@ionic/react";
import { useNavigation, useStorage } from "../hooks";
import { verificationCodeSchema } from "../models";
import "./Verification.scss";

import type { VerificationCode } from "../models";
import {
  verificationCodeError,
  VerificationCodeError,
} from "../constants/responseErrors";
import useAxios from "../hooks/useAxios";
import { AxiosError } from "axios";
import { useMutation } from "react-query";

const ResetPasswordVerification: React.FC<{
  phonePrefix: string;
  phone: string;
  password: string;
  userId: number;
  isRegistration?: boolean;
}> = ({ phonePrefix, phone, password, userId, isRegistration = false }) => {
  const { setMessage, postLogin } = useStorage();
  const { axios } = useAxios();
  const { navigate } = useNavigation();

  const { mutate: verifyPassword } = useMutation<
    never,
    AxiosError,
    VerificationCode
  >((payload) =>
    axios.post(
      `/${isRegistration ? "register" : "password"}/verify/${userId}`,
      payload
    )
  );

  const verPass = (
    formData: any,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    verifyPassword(formData, {
      onSuccess: async () => {
        postLogin
          .mutateAsync({ phonePrefix, phone, password })
          .then(() => navigate("/home"));
        setSubmitting(false);
      },
      onError: ({ response }) => {
        const status = response?.status as VerificationCodeError;
        const error = new Error(
          verificationCodeError[status] || "Unknown error"
        );
        setSubmitting(false);
        return setMessage("danger", error.message, error);
      },
    });
  };

  return (
    <IonContent fullscreen className="form verify">
      <IonIcon className="logo" icon="/assets/icon/new/logo.svg" />
      <Formik
        initialValues={{ code: "" }}
        validationSchema={verificationCodeSchema}
        onSubmit={(data, { setSubmitting }) => {
          verPass(data, setSubmitting);
        }}
      >
        {({ values, isSubmitting, handleChange, handleSubmit }) => (
          <IonGrid className="big_form">
            <IonRow className="header_verify no_border">
              <IonCol>Verify password</IonCol>
            </IonRow>
            <IonRow className="no_border centered">
              <IonCol data-sl="mask" className="smartlook-hide">
                A verification code was sent to {phonePrefix}
                {phone}
              </IonCol>
            </IonRow>
            <IonRow className="code no_border">
              <IonCol>
                <IonInput
                  aria-label="code"
                  name="code"
                  inputMode="numeric"
                  value={values.code}
                  // code has 6 digits, but giving here 7. On some phone user could have filled only 5 for some reason..
                  maxlength={7}
                  type="tel"
                  placeholder="CODE"
                  onIonInput={handleChange}
                />
              </IonCol>
            </IonRow>
            <IonRow className="no_border code_message">
              <IonCol>Enter the code you have received via SMS</IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="label" style={{ textAlign: "center" }}>
                <IonButton
                  type="submit"
                  className="btn-social btn-social-primary"
                  disabled={isSubmitting}
                  onClick={() => handleSubmit()}
                >
                  Verify
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </Formik>
    </IonContent>
  );
};

export default ResetPasswordVerification;
