import { useState, useEffect } from "react";
import { Coordinates } from "../models";

import useStorage from "./useStorage";

function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

const useImpreciseGps = () => {
  const { gpsCoordinates } = useStorage();
  const [currentLoc, setCurrentLoc] = useState<Coordinates>();

  useEffect(() => {
    // this is for sorting results by distance on backend
    // let's not send precise gps to backend, so adding random +/- 333m
    // lets remember gps used for consistent results during pagination
    let currentLocTmp: Coordinates | undefined = undefined;
    if (
      gpsCoordinates &&
      (!currentLoc ||
        Math.abs(currentLoc.lat - gpsCoordinates.lat) > 0.005 ||
        Math.abs(currentLoc.lng - gpsCoordinates.lng) > 0.005)
    ) {
      currentLocTmp = {
        lat: gpsCoordinates.lat + getRandomArbitrary(-0.003, 0.003),
        lng: gpsCoordinates.lng + getRandomArbitrary(-0.003, 0.003),
      };
    }

    if (currentLocTmp) setCurrentLoc(currentLocTmp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gpsCoordinates]);

  return currentLoc;
};

export { useImpreciseGps };
