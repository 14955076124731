import React, { useState } from "react";
import { Formik } from "formik";
import { string, object } from "yup";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonModal,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonToggle,
  useIonViewWillEnter,
  IonPage,
  IonTitle,
  IonIcon,
  IonBackButton,
  IonButtons,
} from "@ionic/react";
import "./Profile.scss";
import { ProfilePhoto } from "../components/Profile";
import { ModalHeader } from "../components/Headers";
import { hideTabBar } from "../helpers/tabBar";
import { useStorage, useProfile, useNavigation } from "../hooks";
import { useMutation } from "react-query";

import type { Auth } from "../models";
import useAxios from "../hooks/useAxios";
import { DeactivationPayload } from "../models/deactivations";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

const DeleteAccount: React.FC<{
  isOpen: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isOpen, setShowModal }) => {
  const [deleteData, setDeleteData] = useState(false);
  const { setAuth } = useStorage();
  const { axios } = useAxios();
  const { setMessage } = useStorage();
  const { t } = useTranslation();

  const { mutate: deleteAccount } = useMutation<
    never,
    AxiosError,
    DeactivationPayload
  >(
    (deactivationPayload) => axios.post("/profile/delete", deactivationPayload),
    {
      onSuccess: () => {
        setAuth(null);
        window.location.reload();
      },
      onError: (err) => setMessage("danger", "Something went wrong", err),
    }
  );

  // const { data: deactivationSetting } = useQuery(
  //   ["deactivation_settings"],
  //   () => axios.get<DeactivationDescription>(`/deactivation_form`),
  //   { select: (res) => res.data, enabled: !!user }
  // );
  // const { data: deactivationReason } = useQuery(
  //   ["deactivation_reasons"],
  //   () => axios.get<DeactivationReason[]>(`/deactivation_form/options`),
  //   { select: (res) => res.data, enabled: !!user }
  // );
  // const [checkedAnswer, setCheckedAnswer] = useState<string | undefined>();
  // const [ownReason, setOwnReason] = useState<string | undefined>();

  const onCancel = () => {
    setShowModal(false);
  };

  return (
    <IonModal isOpen={isOpen} className="delete_account_modal">
      <ModalHeader title="Delete account" onClick={onCancel} />
      <IonContent className="form">
        <div className="content-padded">
          <IonGrid>
            <IonRow>
              <IonCol>
                <p>
                  If you delete account, you will be able to register again and
                  your data will be restored.
                </p>

                <p>
                  You have also option to delete all your data. All the events
                  you have created, friendships, messages posted in discussions,
                  etc. will be deleted as well. This process may take several
                  minutes.
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="10" className="label">
                Delete all the data
              </IonCol>

              <IonCol size="2" className="padding-top-s">
                <IonToggle
                  aria-label="Delete all the data"
                  value="delete"
                  checked={deleteData}
                  onIonChange={() => {
                    setDeleteData(!deleteData);
                  }}
                ></IonToggle>
              </IonCol>
            </IonRow>
            {deleteData && (
              <IonRow>
                <IonCol>
                  <div className="warn_text" color="danger">
                    All your events, friend connections, messages, etc. will be
                    permanently deleted!
                  </div>
                </IonCol>
              </IonRow>
            )}
            {/* {deactivationSetting?.active && (
            <DeactivationSettings
              dataSettings={deactivationSetting}
              dataReason={deactivationReason}
              checkedAnswer={checkedAnswer}
              setCheckedAnswer={setCheckedAnswer}
              setOwnReason={setOwnReason}
            />
          )} */}
          </IonGrid>
        </div>
      </IonContent>
      <IonButton
        type="submit"
        className="modal_big_button"
        disabled={false}
        onClick={() => {
          deleteAccount({
            deleteData: deleteData,
            reason: undefined,
            // reason: checkedAnswer
            //   ? {
            //       slug: checkedAnswer,
            //       text: ownReason || null,
            //     }
            //   : undefined,
          });
        }}
      >
        Delete account
      </IonButton>
    </IonModal>
  );
};

// type PopEvent = SyntheticEvent<HTMLIonButtonElement>;

const ProfileEdit: React.FC = () => {
  // const { navigate } = useNavigation();
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const { currentUser: user } = useStorage();
  const [blob, setBlob] = useState<Blob>();
  const { setMessage, auth, setAuth } = useStorage();
  const { updateName, uploadPhoto } = useProfile();
  const { axios } = useAxios();
  const { navigate } = useNavigation();
  const { t } = useTranslation();

  const { mutate: logout } = useMutation<
    never,
    AxiosError,
    Pick<Auth, "refreshToken">
  >((refreshToken) => axios.post("/logout", refreshToken), {
    onSuccess: () => {
      // setPopEvent(null);
      // fuck it, why not
      // When we logout, all private routes are unmounted in router. We would have to make sure there is no async code being executed at the time to avoid memory leaks, lets just reload it for now..
      window.location.reload();
    },
    onError: (err) => setMessage("danger", "Something went wrong", err),
  });

  const formSchema = object({
    name: string().min(2).required("Required"),
    surname: string().min(2).required("Required"),
  }).required();

  const onPhotoDone = (blob: Blob, imageToCrop: string) => {
    setBlob(blob);
    console.log(blob);
  };

  const profSub = (payload: any) => {
    if (blob) {
      uploadPhoto(blob);
    }

    return updateName.mutate(payload, {
      onSuccess: () => {
        // setMessage("light", "Profile updated");
        // closeModal();
      },
      onError: (err) => setMessage("danger", "Failed to update name", err),
    });
  };

  // const onCancel = () => {
  //   closeModal();
  //   setBlob(undefined);
  // };

  useIonViewWillEnter(() => {
    hideTabBar();
  });

  return (
    <IonPage className="page-profile-edit">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/profile"
              text=""
              className="back"
              icon={"/assets/icon/new/arrow-left.svg"}
            />
          </IonButtons>

          <IonTitle>{t("setting.edit_profile")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="form">
        <Formik
          initialValues={{
            name: user?.name.replace(` ${user?.surname}`, "") || "",
            surname: user?.surname || "",
          }}
          validationSchema={formSchema}
          onSubmit={(data) => {
            profSub(data);
          }}
          enableReinitialize={true}
        >
          {({
            values,
            dirty,
            touched,
            errors,
            isValid,
            isSubmitting,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <>
              <div className="content-padded content-button">
                <ProfilePhoto
                  photo={user?.photo}
                  editEnabled={true}
                  onDone={onPhotoDone}
                />

                <IonGrid className="profile-edit-form">
                  <IonRow>
                    <IonCol>
                      {/* <div>Name</div> */}
                      <IonInput
                        aria-label="name"
                        name="name"
                        value={values.name}
                        placeholder="name"
                        autocapitalize="true"
                        className={touched.name && errors.name ? "error" : ""}
                        onIonInput={handleChange}
                        onIonBlur={handleBlur}
                      />
                      {touched.name && errors.name ? (
                        <div className="error">{errors.name}</div>
                      ) : null}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      {/* <div>Surname</div> */}
                      <IonInput
                        aria-label="surname"
                        name="surname"
                        value={values.surname}
                        autocapitalize="true"
                        placeholder="surname"
                        className={
                          touched.surname && errors.surname ? "error" : ""
                        }
                        onIonInput={handleChange}
                        onIonBlur={handleBlur}
                      />
                      {touched.surname && errors.surname ? (
                        <div className="error">{errors.surname}</div>
                      ) : null}
                    </IonCol>
                  </IonRow>
                </IonGrid>

                <div className="profile_row">
                  <div
                    className="profile_row_first"
                    onClick={() => {
                      const { refreshToken } = auth;
                      setAuth(null);
                      logout({ refreshToken });
                      navigate("/");
                    }}
                  >
                    <span>{t("setting.logout")}</span>
                    <IonIcon icon={"/assets/icon/new/arrow-right.svg"} />
                  </div>
                </div>
                <DeleteAccount
                  isOpen={showDeleteAccountModal}
                  setShowModal={setShowDeleteAccountModal}
                />
                <div className="profile_row">
                  <div
                    className="profile_row_first"
                    onClick={() => {
                      setShowDeleteAccountModal(true);
                    }}
                  >
                    <span>{t("setting.delete_account")}</span>
                    <IonIcon icon={"/assets/icon/new/arrow-right.svg"} />
                  </div>
                </div>
              </div>

              <div className="big_button_wrapper">
                <IonButton
                  type="submit"
                  className="modal_big_button"
                  disabled={isSubmitting || !isValid || !(blob || dirty)}
                  onClick={() => handleSubmit()}
                >
                  {t("setting.save")}
                </IonButton>
              </div>
            </>
          )}
        </Formik>
      </IonContent>
    </IonPage>
  );
};

export default ProfileEdit;
