import React, { useState } from "react";
import {
  IonContent,
  IonHeader,
  IonButtons,
  IonIcon,
  IonToolbar,
  IonButton,
  useIonViewWillEnter,
  IonPage,
} from "@ionic/react";
import "./Profile.scss";
import ReportAbuse from "../components/ReportAbuse";
import { ShowPrivacy, ShowTerms } from "../components/TaC";
import {
  useTranslatedNotificationModule,
  useTranslatedTipModule,
  useTranslatedGPSModule,
} from "../components/Settings";
import { ProfilePhoto, SettingGridRow } from "../components/Profile";
import { showTabBar } from "../helpers/tabBar";
import { useStorage, useNavigation } from "../hooks";

import { czFlag, enFlag } from "../icons/iconsFill";
import { warning } from "../icons/iconsOutline";
import { PopoverActionButtons } from "../components/Unregistered";
import { useTranslation } from "react-i18next";
import useTranlatedMsg from "../hooks/useTranslatedMsg";
import LanguageChange from "../components/LanguageChange";

// const EditProfile: React.FC<{
//   user: User | null;
//   isOpen: boolean;
//   onClose: () => void;
//   updateName: UseMutationResult<never, AxiosError, UserName>;
//   uploadPhoto: (file: Blob) => void;
// }> = ({ user, isOpen, onClose: closeModal, updateName, uploadPhoto }) => {
//   const [blob, setBlob] = useState<Blob>();
//   const { setMessage } = useStorage();

//   const formSchema = object({
//     name: string().min(2).required("Required"),
//     surname: string().min(2).required("Required"),
//   }).required();

//   const onPhotoDone = (blob: Blob, imageToCrop: string) => {
//     setBlob(blob);
//     console.log(blob);
//   };

//   const profSub = (payload: any) => {
//     if (blob) {
//       uploadPhoto(blob);
//     }

//     return updateName.mutate(payload, {
//       onSuccess: () => {
//         // setMessage("light", "Profile updated");
//         closeModal();
//       },
//       onError: (err) => setMessage("danger", "Failed to update name", err),
//     });
//   };

//   const onCancel = () => {
//     closeModal();
//     setBlob(undefined);
//   };

//   return (
//     <IonModal isOpen={isOpen} onDidDismiss={onCancel}>
//       <Formik
//         initialValues={{
//           name: user?.name.replace(` ${user?.surname}`, "") || "",
//           surname: user?.surname || "",
//         }}
//         validationSchema={formSchema}
//         onSubmit={(data) => {
//           profSub(data);
//         }}
//         enableReinitialize={true}
//       >
//         {({
//           values,
//           dirty,
//           touched,
//           errors,
//           isValid,
//           isSubmitting,
//           handleBlur,
//           handleChange,
//           handleSubmit,
//         }) => (
//           <>
//             <ModalHeader
//               title="Edit profile"
//               onClick={onCancel}
//               isSubmitting={isSubmitting}
//             />
//             <ProfilePhoto
//               photo={user?.photo}
//               editEnabled={true}
//               onDone={onPhotoDone}
//             />
//             <IonContent className="form">
//               <IonGrid>
//                 <IonRow>
//                   <IonCol>
//                     <div>Name</div>
//                     <IonInput
//                       aria-label="name"
//                       name="name"
//                       value={values.name}
//                       placeholder="name"
//                       autocapitalize="true"
//                       className={touched.name && errors.name ? "error" : ""}
//                       onIonInput={handleChange}
//                       onIonBlur={handleBlur}
//                     />
//                     {touched.name && errors.name ? (
//                       <div className="error">{errors.name}</div>
//                     ) : null}
//                   </IonCol>
//                 </IonRow>
//                 <IonRow>
//                   <IonCol>
//                     <div>Surname</div>
//                     <IonInput
//                       aria-label="surname"
//                       name="surname"
//                       value={values.surname}
//                       autocapitalize="true"
//                       placeholder="surname"
//                       className={
//                         touched.surname && errors.surname ? "error" : ""
//                       }
//                       onIonInput={handleChange}
//                       onIonBlur={handleBlur}
//                     />
//                     {touched.surname && errors.surname ? (
//                       <div className="error">{errors.surname}</div>
//                     ) : null}
//                   </IonCol>
//                 </IonRow>
//               </IonGrid>
//             </IonContent>
//             <IonButton
//               type="submit"
//               className="modal_big_button"
//               disabled={isSubmitting || !isValid || !(blob || dirty)}
//               onClick={() => handleSubmit()}
//             >
//               Save
//             </IonButton>
//           </>
//         )}
//       </Formik>
//     </IonModal>
//   );
// };

// const DeleteAccount: React.FC<{
//   isOpen: boolean;
//   setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
// }> = ({ isOpen, setShowModal }) => {
//   const [deleteData, setDeleteData] = useState(false);
//   const { setAuth } = useStorage();
//   const { axios } = useAxios();
//   const { setMessage, currentUser: user } = useStorage();

//   const { mutate: deleteAccount } = useMutation<
//     never,
//     AxiosError,
//     DeactivationPayload
//   >(
//     (deactivationPayload) => axios.post("/profile/delete", deactivationPayload),
//     {
//       onSuccess: () => {
//         setAuth(null);
//         window.location.reload();
//       },
//       onError: (err) => setMessage("danger", "Something went wrong", err),
//     }
//   );

//   const { data: deactivationSetting } = useQuery(
//     ["deactivation_settings"],
//     () => axios.get<DeactivationDescription>(`/deactivation_form`),
//     { select: (res) => res.data, enabled: !!user }
//   );
//   const { data: deactivationReason } = useQuery(
//     ["deactivation_reasons"],
//     () => axios.get<DeactivationReason[]>(`/deactivation_form/options`),
//     { select: (res) => res.data, enabled: !!user }
//   );
//   const [checkedAnswer, setCheckedAnswer] = useState<string | undefined>();
//   const [ownReason, setOwnReason] = useState<string | undefined>();

//   const onCancel = () => {
//     setShowModal(false);
//   };

//   return (
//     <IonModal isOpen={isOpen} className="delete_account_modal">
//       <ModalHeader title="Delete account" onClick={onCancel} />
//       <IonContent className="form">
//         <IonGrid>
//           <IonRow>
//             <IonCol>
//               <p>
//                 If you delete account, you will be able to register again and
//                 your data will be restored.
//               </p>

//               <p>
//                 You have also option to delete all your data. All the events you
//                 have created, friendships, messages posted in discussions, etc.
//                 will be deleted as well. This process may take several minutes.
//               </p>
//             </IonCol>
//           </IonRow>
//           <IonRow>
//             <IonCol size="10" className="label">
//               Delete all the data
//             </IonCol>

//             <IonCol size="2">
//               <IonToggle
//                 aria-label="Delete all the data"
//                 value="delete"
//                 checked={deleteData}
//                 onIonChange={() => {
//                   setDeleteData(!deleteData);
//                 }}
//               ></IonToggle>
//             </IonCol>
//           </IonRow>
//           {deleteData && (
//             <IonRow>
//               <IonCol>
//                 <div className="warn_text" color="danger">
//                   All your events, friend connections, messages, etc. will be
//                   permanently deleted!
//                 </div>
//               </IonCol>
//             </IonRow>
//           )}
//           {deactivationSetting?.active && (
//             <DeactivationSettings
//               dataSettings={deactivationSetting}
//               dataReason={deactivationReason}
//               checkedAnswer={checkedAnswer}
//               setCheckedAnswer={setCheckedAnswer}
//               setOwnReason={setOwnReason}
//             />
//           )}
//         </IonGrid>
//       </IonContent>
//       <IonButton
//         type="submit"
//         className="modal_big_button"
//         disabled={false}
//         onClick={() => {
//           deleteAccount({
//             deleteData: deleteData,
//             reason: checkedAnswer
//               ? {
//                   slug: checkedAnswer,
//                   text: ownReason || null,
//                 }
//               : undefined,
//           });
//         }}
//       >
//         Delete account
//       </IonButton>
//     </IonModal>
//   );
// };

// type PopEvent = SyntheticEvent<HTMLIonButtonElement>;

const Profile: React.FC = () => {
  // const [popEvent, setPopEvent] = useState<PopEvent | null>(null);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showLanguageModal, setshowLanguageModal] = useState(false);
  // const [showEditProfileModal, setShowEditProfileMo;dal] = useState(false);
  // const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const { currentUser: user, setMessage, currLanguage } = useStorage();
  // const { updateName, uploadPhoto } = useProfile();
  // const { axios } = useAxios();
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const gps_module = useTranslatedGPSModule();
  const notifications_module = useTranslatedNotificationModule();
  const tips_module = useTranslatedTipModule();
  const { t } = useTranslation();
  const { alert_app_cls_op, user_reported } = useTranlatedMsg();

  const { navigate } = useNavigation();
  const flagMap: Record<string, string> = {
    cs: czFlag,
    en: enFlag,
  };

  useIonViewWillEnter(() => {
    showTabBar();
  });

  // const { mutate: logout } = useMutation<
  //   never,
  //   AxiosError,
  //   Pick<Auth, "refreshToken">
  // >((refreshToken) => axios.post("/logout", refreshToken), {
  //   onSuccess: () => {
  //     // setPopEvent(null);
  //     // fuck it, why not
  //     // When we logout, all private routes are unmounted in router. We would have to make sure there is no async code being executed at the time to avoid memory leaks, lets just reload it for now..
  //     window.location.reload();
  //   },
  //   onError: (err) => setMessage("danger", "Something went wrong", err),
  // });

  // const handleLogout = () => {
  //   const { refreshToken } = auth;
  //   setAuth(null);
  //   logout({ refreshToken });
  // };

  // const openMenu = (e: PopEvent) => {
  //   return setPopEvent(e);
  // };

  // const closeModal = (value = false) => {
  //   // setPopEvent(null);
  //   setShowEditProfileModal(value);
  // };

  return (
    <IonPage className="page-profile">
      <IonHeader>
        <IonToolbar>
          <>
            <IonButtons slot="end">
              {user && (
                <IonButton
                  color="dark"
                  onClick={() => navigate("/profile/edit")}
                >
                  <IonIcon icon="/assets/icon/new/menu-extra.svg" />
                </IonButton>
              )}
              {/* <IonPopover
                className="profile-menu"
                event={popEvent}
                isOpen={popEvent !== null}
                onDidDismiss={() => setPopEvent(null)}
              >
                <IonList>
                  <IonItem
                    className="menu-button"
                    lines="none"
                    onClick={() => {
                      setPopEvent(null);
                      setShowEditProfileModal(true);
                    }}
                  >
                    <IonIcon size="small" icon={editSquare} /> Edit profile
                  </IonItem>
                  <IonItem
                    className="menu-button"
                    lines="none"
                    onClick={handleLogout}
                  >
                    <IonIcon size="small" icon={logoutIcon} /> Log out
                  </IonItem>
                  <IonItem
                    className="menu-button"
                    lines="none"
                    onClick={() => {
                      setPopEvent(null);
                      setShowDeleteAccountModal(true);
                    }}
                  >
                    <IonIcon size="small" icon={trash} /> Delete account
                  </IonItem>
                </IonList>
              </IonPopover> */}
            </IonButtons>
          </>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="content-padded">
          <ProfilePhoto photo={user?.photo} editEnabled={!user?.photo} />
          <h2
            className="profile-title ion-text-center smartlook-hide"
            data-sl="mask"
          >
            {user?.name}
          </h2>

          <PopoverActionButtons
            popoverState={popoverState}
            setShowPopover={setShowPopover}
          />
          {/* <div className="profile_row">
            <a
              onClick={(e) => {
                // TODO: export this to functions.. its used also elsewhere
                SocialSharing.share(INVITE_MESSAGE);
                e.preventDefault();
                logEvent("profile_app_invite", {});
              }}
              href="#/profile"
              className="profile_section_link"
            >
              <IonIcon icon={shareIos} className="share-icon-profile-section" />{" "}
              Invite your friends to Manyways
            </a>
          </div> */}

          <div className="profile_row">
            <div
              className="profile_row_first"
              onClick={(e: any) => {
                if (user) navigate("/profiles/feedbacks");
                else setShowPopover({ showPopover: true, event: e });
              }}
            >
              <h3
              // href={user ? "#/profiles/feedbacks" : undefined}
              >
                {t("setting.feedback")}
              </h3>
              <IonIcon icon={"/assets/icon/new/arrow-right.svg"} />
            </div>
          </div>
          <div className="profile_row">
            <div
              className="profile_row_first"
              onClick={() => navigate("/profiles/onboarding")}
            >
              <h3

              // href={"#/profiles/onboarding"}
              >
                {t("setting.my_interests")}
              </h3>
              <IonIcon icon={"/assets/icon/new/arrow-right.svg"} />
            </div>
          </div>

          <div className="profile_row">
            <div
              className="profile_row_first"
              onClick={(e: any) => {
                if (user) setShowReportModal(true);
                else setShowPopover({ showPopover: true, event: e });
                e.preventDefault();
              }}
            >
              <h3
              // href="#/profile"
              // className="profile_section_link profile_section_link_important"
              >
                {/* <IonIcon icon={warning} />  */}
                {t("setting.report_abuse")}
              </h3>
              <IonIcon icon={"/assets/icon/new/arrow-right.svg"} />
            </div>
          </div>
          <div className="profile_row">
            <div
              className="profile_row_first"
              onClick={(e: any) => {
                if (user) setshowLanguageModal(true);
                else setShowPopover({ showPopover: true, event: e });
                e.preventDefault();
              }}
            >
              <h3>{t("setting.language")}</h3>
              <IonIcon
                icon={flagMap[currLanguage]}
                className="icon_medium"
                size={"large"}
              />
            </div>
          </div>

          {user?.settingsGeneral.admin && (
            <div className="profile_row">
              <span
                onClick={() => {
                  localStorage.removeItem("seenOnboardnig");
                  localStorage.removeItem("interestsCategories");
                  localStorage.removeItem("interestsTags");
                  alert(alert_app_cls_op);
                }}
                className="profile_section_link profile_section_link_important"
              >
                <IonIcon icon={warning} /> {t("profile.reset_onboarding")}
                <br />({t("profile.info")})
              </span>
            </div>
          )}

          {/* <h4 className="profile_section_header">Settings</h4> */}
          {/* <div className="profile_row"> */}
          <SettingGridRow
            module={gps_module}
            isToggled={user?.settings.gps || gps_module.is_initialized}
          />
          <SettingGridRow
            module={notifications_module}
            isToggled={user?.settings.notifications}
          />
          <SettingGridRow
            module={tips_module}
            isToggled={user?.settings.tips}
          />
          {/* </div> */}

          {/* <h4 className="profile_section_header">Legal and policies</h4> */}
          <div className="profile_section_light">
            <ShowTerms
              showModal={showTermsModal}
              setShowModal={setShowTermsModal}
            />
            <div
              className="link profile_section_link"
              onClick={() => setShowTermsModal(true)}
            >
              {t("setting.terms")}
            </div>
            {/* </div>

          <div className="profile_row"> */}
            <ShowPrivacy
              showModal={showPrivacyModal}
              setShowModal={setShowPrivacyModal}
            />
            <div
              className="link profile_section_link"
              onClick={() => setShowPrivacyModal(true)}
            >
              {t("setting.privacy_policy")}
            </div>
          </div>

          <ReportAbuse
            showModal={showReportModal}
            setShowModal={setShowReportModal}
            onChange={() => setMessage("primary", user_reported)}
          />
          <LanguageChange
            showModal={showLanguageModal}
            setShowModal={setshowLanguageModal}
          />
          {/* <EditProfile
            user={user}
            isOpen={showEditProfileModal}
            onClose={closeModal}
            updateName={updateName}
            uploadPhoto={uploadPhoto}
          />
          <DeleteAccount
            isOpen={showDeleteAccountModal}
            setShowModal={setShowDeleteAccountModal}
          /> */}
        </div>

        {!user && (
          <div className="big_button_wrapper low">
            <IonButton
              className="modal_big_button"
              onClick={() => navigate("/registration")}
            >
              {t("profile.sign_up")}
            </IonButton>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Profile;
