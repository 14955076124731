import React from "react";
import { IonContent, IonGrid, IonRow, IonCol } from "@ionic/react";
import "./MissingInvitationError.scss";

const MissingInvitationError: React.FC<{
  numberPrefix: string;
  number: string;
}> = ({ numberPrefix, number }) => {
  return (
    <IonContent fullscreen className="form verify">
      <img
        src={process.env.PUBLIC_URL + "/assets/logomark.png"}
        className="logo"
        alt="logo"
      />

      <IonGrid className="big_form">
        <IonRow className="header_error no_border">
          <IonCol>Missing invitation</IonCol>
        </IonRow>

        <IonRow className="no_border code_message">
          <IonCol>
            <p>
              We are sorry, Manyways is currently available for{" "}
              <em>invited users only</em>.
            </p>

            <p>
              To be able to register, your phone number{" "}
              <em>
                {numberPrefix} {number}
              </em>{" "}
              has to receive an <em>invitation from already registered user</em>
              .
            </p>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default MissingInvitationError;
