import React from "react";
import copy from "copy-to-clipboard";
import { SetMessage } from "../contexts/StateStorage";

type ConfigType = {
  regex: RegExp;
  fn: (key: any, result: any) => JSX.Element;
};

// sligtly modified from here https://stackoverflow.com/questions/33235890/react-replace-links-in-a-text
const link_replace_config = (setMessage: SetMessage): ConfigType[] => [
  {
    regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
    fn: (key: any, result: any) => (
      <span key={key}>
        <a
          rel="noreferrer"
          target="_blank"
          href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}
        >
          {result[1]}://{result[2]}.{result[3]}
          {result[4]}
        </a>
        {result[5]}
      </span>
    ),
  },
  {
    regex:
      /(([a-zA-Z0-9])([a-zA-Z0-9!#$%&'*_.+/=?^`{|-]{0,63})@(([a-zA-Z0-9_]{0,}\.?)([a-zA-Z0-9]+){2,255}){3,256})( |,|$|\.)/gim,
    fn: (key: any, result: any) => (
      <span key={key}>
        <button
          className="button-link"
          onClick={() => {
            copy(result[1]);
            setMessage("primary", "Text copied");
          }}
        >
          {result[1]}
        </button>
        {result[result.length - 1]}
      </span>
    ),
  },
  {
    regex:
      /( |,|^)(([a-zA-Z]{1,3}\.)?([a-z]+)\.([a-z]{2,}?)(.*?))( |,|$|\.)/gim,
    fn: (key: any, result: any) => (
      <span key={key}>
        {result[1]}
        <a rel="noreferrer" target="_blank" href={`http://${result[2]}`}>
          {result[2]}
        </a>
        {result[result.length - 1]}
      </span>
    ),
  },
];

export { link_replace_config };
