// TODO: shouldn't this be in models?

import { favoriteFill } from "../icons/iconsFill";
import { done, close, favorite, questionMark } from "../icons/iconsOutline";

export const attendanceStateLabels = {
  INVITED: "Invited",
  GOING: "Going",
  DECLINED: "Declined",
  MAYBE: "Maybe",
};

export const attendanceStateColors = {
  INVITED: "warning",
  GOING: "primary",
  DECLINED: "medium",
  MAYBE: "secondary",
};

export const attendanceStateIcons = {
  INVITED: questionMark,
  GOING: done,
  DECLINED: close,
  MAYBE: favorite,
};
export const attendanceSlideIcons = {
  INVITED: questionMark,
  GOING: done,
  DECLINED: close,
  MAYBE: favoriteFill,
};

export const TITLE_LENGTH_LIMIT = 100;

export const URL_REGEX =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
