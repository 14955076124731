import { useRef, useState, useEffect } from "react";
import { logEvent } from "../helpers/logging";
import { Tag } from "../models/Event";
import {
  FilterDateType,
  FilterMembershipType,
  ifilter_dict,
} from "../models/eventFilter";
import { calcAggregationLevel } from "../helpers/map";
import { DEFAULT_ZOOM, MAP_REFRESH_LOCK } from "../constants/map";
import { load } from "../cache/engine";
import { useRememberValue } from "./useLocalStorage";

function uncheck_inappropriate_subtags(
  tags: Tag[] | undefined,
  chosenTags: Set<string>,
  chosenSubtags: Set<string>
) {
  const checkedTags = tags?.filter((category) => {
    return chosenTags.has(category.slug);
  });

  const checkedSubtags = checkedTags?.map((category) =>
    category.subtags.filter((subtag) => {
      return chosenSubtags.has(subtag.slug);
    })
  );
  return new Set(checkedSubtags?.flat().map((subtag) => subtag.slug));
}

const useBaseFilters = (tags: Tag[] | undefined) => {
  const [rememberFilters, setRememberFilters] = useState<boolean>(
    load<boolean>("filterRemember", false)
  );
  const [tagsFilter, setTagsFilter] = useState<Set<string>>(
    load<Set<string>>("filterTags", new Set())
  );
  const [subtagsFilter, setSubtagsFilter] = useState<Set<string>>(
    load<Set<string>>("filterSubtags", new Set())
  );

  const [dayFilter, setDayFilter] = useState<FilterDateType>("30days");
  const [mapSearchText, setMapSearchText] = useState<string>("");
  const [membershipFilter, setMembershipFilter] =
    useState<FilterMembershipType>("all");
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);

  useRememberValue("filterRemember", rememberFilters);
  useRememberValue("filterTags", tagsFilter, rememberFilters);
  useRememberValue("filterSubtags", subtagsFilter, rememberFilters);

  const filters: ifilter_dict = {
    tags: {
      value: tagsFilter,
      setter: setTagsFilter,
    },
    subtags: {
      value: subtagsFilter,
      setter: setSubtagsFilter,
    },
    day: {
      value: dayFilter,
      setter: setDayFilter,
    },
    search: {
      value: mapSearchText,
      setter: setMapSearchText,
    },
    membership: {
      value: membershipFilter,
      setter: setMembershipFilter,
    },
    dateFrom: {
      value: dateFrom,
      setter: setDateFrom,
    },
    dateTo: {
      value: dateTo,
      setter: setDateTo,
    },
    remember: {
      value: rememberFilters,
      setter: setRememberFilters,
    },
  };

  useEffect(() => {
    if (!tags) return;
    filters.subtags.setter(
      uncheck_inappropriate_subtags(
        tags,
        filters.tags.value,
        filters.subtags.value
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.tags.value, tags]);

  useEffect(() => {
    logEvent("search_filter", {
      param: "day",
      state: filters.day.value,
    });
  }, [filters.day.value]);

  useEffect(() => {
    logEvent("search_filter", {
      param: "tags",
      state: Array.from(filters.tags.value).join(","),
    });
  }, [filters.tags.value]);

  useEffect(() => {
    logEvent("search_filter", {
      param: "search",
      state: filters.search.value,
    });
  }, [filters.search.value]);

  return filters;
};

const useOptimizedLocationFilter = (
  map: google.maps.Map | undefined,
  enabled: boolean
) => {
  const map_draw_lock = useRef<number | undefined>(undefined);
  const [north, setNorth] = useState<number>();
  const [south, setSouth] = useState<number>();
  const [east, setEast] = useState<number>();
  const [west, setWest] = useState<number>();
  const [aggLevel, setAggLevel] = useState<number>(DEFAULT_ZOOM);
  const [refreshEvents, setRefreshEvents] = useState<number>(Math.random());
  const [gpsChangedSignal, setGpsChangedSignal] = useState<number>(
    Math.random()
  );

  async function recalculateMapAttributes() {
    const zoomLevel = map?.getZoom();
    if (!zoomLevel) return;
    const b = map?.getBounds()?.toJSON();
    if (!b) return;

    const north_new = b.north;
    const south_new = b.south;
    const east_new = b.east;
    const west_new = b.west;

    const aggregationLevel = calcAggregationLevel(zoomLevel);

    const north_adjusted = north_new + (north_new - south_new) / 2;
    const south_adjusted = south_new - (north_new - south_new) / 2;
    const east_adjusted = east_new + (east_new - west_new) / 2;
    const west_adjusted = west_new - (east_new - west_new) / 2;

    if (
      !north ||
      north < north_new ||
      !south ||
      south > south_new ||
      !east ||
      east < east_new ||
      !west ||
      west > west_new ||
      aggregationLevel !== aggLevel
    ) {
      setNorth(north_adjusted);
      setSouth(south_adjusted);
      setEast(east_adjusted);
      setWest(west_adjusted);
    }

    if (aggregationLevel !== aggLevel) setAggLevel(aggregationLevel);

    setGpsChangedSignal(Math.random());
  }

  useEffect(() => {
    if (map) {
      map.addListener("bounds_changed", () => {
        if (map) {
          if (map_draw_lock.current === undefined) {
            map_draw_lock.current = window.setTimeout(
              setRefreshEvents,
              MAP_REFRESH_LOCK,
              Math.random()
            );
          }
        }
      });
    }
  }, [map]);

  useEffect(() => {
    if (enabled && map) recalculateMapAttributes();
    map_draw_lock.current = undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshEvents, map, enabled]);

  return {
    north,
    south,
    east,
    west,
    aggLevel,
    gpsChangedSignal, // TODO: remove this, use values instead
  };
};

export {
  useBaseFilters,
  useOptimizedLocationFilter,
  uncheck_inappropriate_subtags,
};
