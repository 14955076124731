import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useState } from "react";
import { hideTabBar } from "../helpers/tabBar";
import { done } from "../icons/iconsOutline";
import "./OnBoarding.scss";
import { useRememberValue } from "../hooks/useLocalStorage";
import { useNavigation, useProfile, useStorage } from "../hooks";
import useAnalytics from "../hooks/useAnalytics";
import { sets_to_array } from "../helpers/sets";
import { useTranslation } from "react-i18next";

const OnBoarding: React.FC<{
  start: 2 | 3;
  type: "init" | "change";
  destination: string;
}> = ({ start, type, destination }) => {
  const { syncInterests } = useProfile();
  const {
    currentUser: user,
    interestsCategories,
    setInterestsCategories,
    interestsTags,
    setInterestsTags,
    tags,
    seenOnboardnig,
    setSeenOnboarding,
  } = useStorage();
  const [page, setPage] = useState<1 | 2 | 3>(start);
  // const [showTermsModal, setShowTermsModal] = useState(false);
  // const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const { sendMetric } = useAnalytics();
  const { navigate } = useNavigation();
  const { t } = useTranslation();

  useIonViewWillEnter(() => {
    if (page !== start) setPage(start);

    hideTabBar();
    sendMetric({
      name: "onboarding_start",
      data: { step: start },
    });
  }, [page, start]);

  useRememberValue("interestsCategories", interestsCategories, true);
  useRememberValue("interestsTags", interestsTags, true);

  return (
    <IonPage>
      {/* {page !== 1 && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              {page !== 2 && (
                <IonButton
                  onClick={() => {
                    setPage(2);
                  }}
                  className="onboard-back-btn"
                >
                  <IonIcon icon={arrowBackIos} />
                </IonButton>
              )}
              {page === 2 && start === 2 && (
                <IonBackButton
                  defaultHref="/profile"
                  text=""
                  className="back"
                />
              )}
            </IonButtons>
            <IonTitle>Preferences</IonTitle>
          </IonToolbar>
        </IonHeader>
      )} */}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {/* <div className="controls"> */}
            {page === 2 && (
              <IonBackButton
                defaultHref="/profile"
                text=""
                className="back"
                icon={"/assets/icon/new/arrow-left.svg"}
              />
            )}
            {page === 3 && (
              <IonButton
                onClick={() => {
                  setPage(2);
                }}
              >
                <IonIcon icon={"/assets/icon/new/arrow-left.svg"} />
              </IonButton>
            )}
            {/* </div> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        className={`onboard-content-page ${
          page === 1 && "onboard-content-page-photo"
        }`}
      >
        {/* {page === 1 && (
          <div className="page-one-body">
            <div className="page-one-upper-body-box">
              <div>
                <h1>Explore the best events</h1>
                <h4>
                  We are handpicking the most interesting events.
                  <br />
                  Get inspired and live to the fullest.
                </h4>
                <div className="cats-words-onboard">
                  <img src="/assets/new_words.png" alt="words" />
                </div>
              </div>
              <div className="page-one-logo_btn-box">
                <img
                  src="/assets/logo_logotype.svg"
                  alt="cover"
                  className="page-one-logo"
                />
                <IonButton
                  color="light"
                  type="submit"
                  fill="outline"
                  className="page-one-login-btn"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Login
                </IonButton>
              </div>
            </div>
            <div className="page-one-lower-body-box">
              <div className="page-one-btn_tac-box">
                <TermsAndPrivacy
                  showTermsModal={showTermsModal}
                  setShowTermsModal={setShowTermsModal}
                  showPrivacyModal={showPrivacyModal}
                  setShowPrivacyModal={setShowPrivacyModal}
                  onboarding={true}
                />
                <IonButton
                  type="submit"
                  className="page-one-btn"
                  onClick={() => {
                    setPage(2);
                    sendMetric({
                      name: "onboarding_1_finish",
                      data: {},
                    });
                  }}
                >
                  Continue
                </IonButton>
              </div>
            </div>
          </div>
        )} */}

        {/* <div className="onboard-header-box">
          {page === 2 && (
            <>
              {/* <h1>What you love?</h1> */}
        {/* <h4>So we can help you find the best events for you</h4> */}
        {/* </> */}
        {/* )}
          {page === 3 && <h1>You may specify your taste</h1>}
        </div> */}

        {page === 2 && (
          <div className="content-padded">
            <h1>{t("onBoarding.title")}</h1>
            <div className="events-boxes page-box">
              {tags
                ?.filter((v) => v.slug !== "other")
                .map((tag) => {
                  return (
                    <div
                      key={tag.slug}
                      className="img-gradient-box"
                      onClick={() => {
                        if (
                          interestsCategories &&
                          typeof interestsCategories !== "string"
                        ) {
                          var arr = Array.from(interestsCategories.values());
                          interestsCategories.has(tag.slug)
                            ? setInterestsCategories(
                                new Set(arr.filter((v) => v !== tag.slug))
                              )
                            : setInterestsCategories(
                                new Set([...arr, tag.slug])
                              );
                        }
                      }}
                    >
                      <div
                        className={
                          interestsCategories.has(tag.slug)
                            ? "gradient-color onboard-checked-cat"
                            : "gradient-color"
                        }
                      />
                      {
                        <div
                          className={`onboard-icon-bg ${
                            interestsCategories.has(tag.slug) && "checked"
                          }`}
                        >
                          <IonIcon
                            color="light"
                            icon={done}
                            className="onboard-icon"
                          ></IonIcon>
                        </div>
                      }
                      <div
                        className="image"
                        style={{
                          backgroundImage: `url('/assets/categories/low/${tag.slug}.jpg')`,
                        }}
                        // alt={tag.slug}
                      />

                      <div className="onboard-tag-name">{tag.name}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        {page === 3 && (
          <div className="content-padded">
            <h2> {t("onBoarding.tell_us")}</h2>
            <div className="text-important">
              {t("onBoarding.tell_us_subtitle")}
            </div>

            <div className="page-box">
              {tags?.map(
                (tag) =>
                  interestsCategories.has(tag.slug) && (
                    <div key={tag.slug}>
                      <h4>{tag.name}</h4>

                      <div className="container-tags">
                        {tag.subtags.map((t) => {
                          return (
                            <IonButton
                              key={t.slug}
                              size="small"
                              fill={"solid"}
                              color={
                                interestsTags?.has(t.slug) ? "primary" : "light"
                              }
                              onClick={() => {
                                if (
                                  interestsTags &&
                                  typeof interestsTags !== "string"
                                ) {
                                  var arr = Array.from(interestsTags.values());
                                  interestsTags.has(t.slug)
                                    ? setInterestsTags(
                                        new Set(arr.filter((v) => v !== t.slug))
                                      )
                                    : setInterestsTags(
                                        new Set([...arr, t.slug])
                                      );
                                }
                              }}
                            >
                              {t.name}
                            </IonButton>
                          );
                        })}
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        )}

        <div className={page !== 1 ? "onboard-btn-box" : ""}>
          {page === 2 && (
            <IonButton
              type="submit"
              className="onboard_big_button"
              disabled={interestsCategories.size === 0}
              onClick={() => {
                setPage(3);
                sendMetric({
                  name: "onboarding_2_finish",
                  data: { categories: Array.from(interestsCategories) },
                });
              }}
            >
              {t("onBoarding.continue")}
            </IonButton>
          )}
          {page === 3 && (
            <IonButton
              type="submit"
              className="onboard_big_button"
              onClick={() => {
                if (!seenOnboardnig) setSeenOnboarding(true);
                navigate(destination);

                sendMetric({
                  name: "onboarding_3_finish",
                  data: {
                    categories: Array.from(interestsCategories),
                    tags: Array.from(interestsTags),
                  },
                });

                if (user)
                  syncInterests({
                    tags: sets_to_array(interestsCategories, interestsTags),
                  });
              }}
            >
              {t("onBoarding.save")}
            </IonButton>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default OnBoarding;
