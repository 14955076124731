import { IonButton, useIonViewWillEnter } from "@ionic/react";
import { showTabBar } from "../helpers/tabBar";
import { RouteComponentProps } from "react-router";
import useAxios from "../hooks/useAxios";
import { Order } from "../models/orders";
import { useQuery } from "react-query";
import "./Order.scss";

interface OrderDetailProps
  extends RouteComponentProps<{ id: string; code: string }> {}

const OrderCancelled: React.FC<OrderDetailProps> = ({ match }) => {
  const { axios } = useAxios();
  const orderId = Number(match.params.id);

  useIonViewWillEnter(() => {
    showTabBar();
  });

  const { data: order } = useQuery(
    ["order", orderId, match.params.code],
    () =>
      axios.get<Order>(`/orders/${orderId}`, {
        params: {
          code: match.params.code,
        },
      }),
    {
      select: (res) => res.data,
    }
  );

  return (
    <div className="container">
      <h1>Order details</h1>
      <p>Order has been cancelled</p>
      {order && (
        <div className="center button-back">
          <IonButton href={`#/events/${order.items[0].eventId}/about`}>
            Back to event
          </IonButton>
        </div>
      )}
    </div>
  );
};

export default OrderCancelled;
