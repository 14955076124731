import React, { useState, useCallback } from "react";
import { IonModal, IonContent, IonButton } from "@ionic/react";
import Cropper, { Area } from "react-easy-crop";
import "./Cropper.scss";
import getCroppedImg from "../helpers/cropImage";
import { useStorage } from "../hooks";
import pica from "pica";
import useTranlatedMsg from "../hooks/useTranslatedMsg";
import { useTranslation } from "react-i18next";

export async function imageToHtmlTag(image: string): Promise<HTMLImageElement> {
  return await new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = image;
  });
}

export async function canvasToBlob(
  canvas: HTMLCanvasElement,
  mime: string = "image/png",
  quality: number = 1
): Promise<Blob> {
  return new Promise((resolve) => {
    canvas.toBlob(
      (blob: any) => {
        resolve(blob);
      },
      mime,
      quality
    );
  });
}

async function resize_image(
  image_to_resize: string | HTMLImageElement | HTMLCanvasElement,
  maxDimensions: number
  //callback: CallableFunction,
): Promise<HTMLCanvasElement> {
  let image =
    typeof image_to_resize === "string"
      ? await imageToHtmlTag(image_to_resize)
      : image_to_resize;

  var canvas = document.createElement("canvas");

  if (image.width > image.height) {
    canvas.width = (maxDimensions * image.width) / image.height;
    canvas.height = maxDimensions;
  } else {
    canvas.width = maxDimensions;
    canvas.height = (maxDimensions * image.height) / image.width;
  }

  return await pica().resize(image, canvas);
}

const ImageCropper: React.FC<{
  imageToCrop: string | undefined;
  aspect: number;
  cropShape?: "rect" | "round";
  maxDimensions: number;
  onResizeDone: CallableFunction;
  onResizeCancel: CallableFunction;
}> = ({
  imageToCrop,
  aspect,
  cropShape = "rect",
  maxDimensions,
  onResizeDone,
  onResizeCancel,
}) => {
  const [isModalRendered, setIsModalRendered] = useState<boolean>(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const { fail_picture, fail_size_picture } = useTranlatedMsg();
  const { t } = useTranslation();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<{
    width: number;
    height: number;
    x: number;
    y: number;
  } | null>(null);
  const { setMessage } = useStorage();

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedArea);
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const reset = async () => {
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    setCroppedAreaPixels(null);
  };

  const processCroppedImage = useCallback(async () => {
    if (imageToCrop && croppedAreaPixels) {
      const croppedImage = await getCroppedImg(
        imageToCrop,
        croppedAreaPixels,
        0
      ).catch((err) => setMessage("danger", fail_picture, err));

      if (!croppedImage) return;

      resize_image(croppedImage, maxDimensions)
        .then((canvas: HTMLCanvasElement) => {
          canvasToBlob(canvas).then((blob) => {
            onResizeDone(blob);
            reset();
          });
        })
        .catch((err) => setMessage("danger", fail_size_picture, err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedAreaPixels]);

  const abandon = async () => {
    onResizeCancel();
    reset();
  };

  return (
    <IonModal
      isOpen={imageToCrop !== undefined}
      animated={false}
      onDidPresent={() => {
        setIsModalRendered(true);
      }}
      onDidDismiss={() => {
        setIsModalRendered(false);
      }}
      className="modal-cropper"
    >
      <IonContent>
        <div className="cropper-content smartlook-hide" data-sl="exclude">
          {isModalRendered && (
            <Cropper
              image={imageToCrop}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              cropShape={cropShape}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          )}
        </div>
        <IonButton color="dark" className="button-cancel" onClick={abandon}>
          {t("crooper.cancel")}
        </IonButton>
        <IonButton
          color="dark"
          className="button-use"
          onClick={processCroppedImage}
        >
          {t("crooper.use_photo")}
        </IonButton>
      </IonContent>
    </IonModal>
  );
};

export { resize_image, ImageCropper };
