import { object, string, ref } from "yup";

import type { InferType } from "yup";

export const name = {
  name: string().min(2).required("Required"),
  surname: string().min(2).required("Required"),
};

export const phone = {
  phonePrefix: string().required("Required"),
  phone: string()
    .matches(/^[0-9]{8,10}$/)
    .required("Required"),
};

export const password = {
  password: string().min(3).required("Required"),
};

export const login = {
  ...phone,
  ...password,
};

export const registerBase = {
  ...phone,
  ...password,
  confirmPassword: string()
    .required("Required")
    .oneOf([ref("password")]),
};

export const register = {
  ...name,
  ...registerBase,
};

export const verificationCode = {
  code: string()
    .matches(/^[0-9]{6}$/)
    .required(),
};

export const loginSchema = object(login).required();
export const registerSchema = object(register).required();
export const resetPasswordSchema = object(registerBase).required();
export const verificationCodeSchema = object(verificationCode).required();

export type Auth = {
  accessToken: string | null;
  refreshToken: string | null;
};
export type VerificationCode = InferType<typeof verificationCodeSchema>;
export type LoginData = Omit<InferType<typeof loginSchema>, "confirmPassword">;
export type RegisterData = Omit<
  InferType<typeof registerSchema>,
  "confirmPassword"
>;
export type ResetPasswordData = Omit<
  InferType<typeof resetPasswordSchema>,
  "confirmPassword"
>;
