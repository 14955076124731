import { IonBackButton } from "@ionic/react";
import { useRef } from "react";
import { MainImage } from "./Event";
import { device } from "../config";

// const MapEvDet: React.FC<{
//   coordinates: Coordinates | undefined;
//   pin: string;
// }> = ({ coordinates, pin }) => {
//   const { mapApiLoaded } = useStorage();

//   return mapApiLoaded ? (
//     <GoogleMap
//       mapContainerClassName="map-ev_det"
//       center={coordinates}
//       zoom={DEFAULT_ZOOM}
//       options={{
//         disableDefaultUI: true,
//         clickableIcons: false,
//         minZoom: MIN_ZOOM,
//         styles: MAP_STYLES,
//         keyboardShortcuts: false,
//       }}
//     >
//       {coordinates && (
//         <Marker
//           position={{ lat: coordinates.lat, lng: coordinates.lng }}
//           options={{ icon: pin }}
//         />
//       )}
//     </GoogleMap>
//   ) : (
//     <></>
//   );
// };

export const AboutHeader: React.FC<{
  photo: string;
  // coordinates: Coordinates | undefined;
  mask: boolean;
  isPast?: boolean;
  end?: React.ReactNode;
  // showMap: boolean;
  // setShowMap: React.Dispatch<React.SetStateAction<boolean>>;
  // pin: string;
}> = ({ photo, mask, isPast, end }) => {
  // const [paddingTop, setPaddingTop] = useState("60%");
  // const [mapTouched, setMapTouched] = useState(false);
  // const [coordinatesCached, setCoordinatesCached] = useState<Coordinates>();

  const imgElement = useRef<HTMLImageElement>(null);

  // useEffect(() => {
  //   if (imgElement?.current?.clientHeight) {
  //     imgElement.current.clientHeight < 240
  //       ? setPaddingTop(`${imgElement.current.clientHeight}px`)
  //       : setPaddingTop("240px");
  //   }
  // }, [imgElement?.current?.clientHeight]);

  // useEffect(() => {
  //   if (showMap) setMapTouched(true);
  // }, [showMap]);

  // useEffect(() => {
  //   if (coordinates) setCoordinatesCached(coordinates);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [coordinates?.lat, coordinates?.lng]);

  return (
    <>
      {/* {coordinatesCached && (
        <IonButton
          className="btn-map"
          fill="solid"
          color={isPast ? "medium" : "primary"}
          size="small"
          onClick={() => setShowMap(!showMap)}
        >
          <IonIcon
            icon={showMap ? image : mapFill}
            color={isPast ? "dark" : "light"}
          />
        </IonButton>
      )} */}

      <div className="visual_container" /*style={{ paddingTop }}*/>
        <div className="header-menu-floating">
          <div>
            {device !== "desktop" && (
              <IonBackButton
                defaultHref="/events"
                text=""
                className="button back"
                icon={"/assets/icon/new/arrow-left.svg"}
              />
            )}
          </div>

          <div>{end}</div>
        </div>

        {/* {(showMap || mapTouched) && coordinatesCached && (
          <div
            className="visual_content"
            style={{ display: showMap ? "block" : "none" }}
          >
            <MapEvDet coordinates={coordinatesCached} pin={pin} />
          </div>
        )} */}
        <MainImage
          src={photo}
          // showMap={showMap}
          imgRef={imgElement}
          mask={mask}
        />
      </div>
    </>
  );
};
