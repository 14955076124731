import { Subscription } from "rxjs";
import { init_gps, destroy_gps } from "../helpers/gps";
import {
  ProfileTokenPayload,
  Setting,
  SettingName,
  User,
} from "../models/User";
import { handle_open_notification } from "./Notifications";
import { one_signal_app_id } from "../config";
import { useProfile, useStorage } from "../hooks";
import OneSignal from "onesignal-cordova-plugin";
import { OneSignalNotificationAdditionalData } from "../models/notifications";
import { AxiosError, AxiosResponse } from "axios";
import { UseMutateFunction } from "react-query";
import { useTranslation } from "react-i18next";

class SettingModule implements Setting {
  name: SettingName;
  title: string;
  description: string;
  is_critical: boolean;
  is_initialized: boolean;
  subscription: Subscription | null;
  init_func: (...args: any[]) => void;
  destroy_func: (...args: any[]) => void;

  constructor(
    name: SettingName,
    title: string,
    desc: string,
    is_critical: boolean,
    init_func: (...args: any[]) => void,
    destroy_func: (...args: any[]) => void
  ) {
    this.name = name;
    this.title = title;
    this.description = desc;
    this.is_critical = is_critical;
    this.init_func = init_func;
    this.destroy_func = destroy_func;

    this.is_initialized = false;
    this.subscription = null;

    this.setInitialized = this.setInitialized.bind(this);
    this.setSubscription = this.setSubscription.bind(this);
    this.init = this.init.bind(this);
    this.destroy = this.destroy.bind(this);
  }

  setInitialized(value: boolean) {
    this.is_initialized = value;
  }

  setSubscription(value: Subscription | null) {
    this.subscription = value;
  }

  init(...args: any) {
    if (this.name === "gps") {
      this.init_func(...args, this.setInitialized, this.setSubscription);
    } else {
      this.init_func(...args, this.setInitialized);
    }
  }

  destroy(...args: any) {
    if (this.name === "gps") {
      this.destroy_func(
        this.subscription,
        args[0],
        this.setSubscription,
        this.setInitialized
      );
    } else {
      this.destroy_func(...args);
    }
    this.is_initialized = false;
  }
}
export const useTranslatedGPSModule = () => {
  const { t } = useTranslation();

  const title = t("setting.allow_gps");
  const desc = t("setting.allow_gps_subtitle");

  return gps_module(title, desc);
};

const gps_module = (title: string, desc: string) => {
  return new SettingModule(
    "gps",
    title,
    desc,
    // useTranslation().t("allow_gps"),
    // "Needed for event search, map positioning, event navigation, ...",
    true,
    (
      setGpsCoordinates: CallableFunction,
      turnOffGps: () => void,
      setInitialized: (v: boolean) => void,
      setSubscription: (v: Subscription | null) => void
    ) => {
      return init_gps(
        setGpsCoordinates,
        turnOffGps,
        setInitialized,
        setSubscription
      );
    },
    (
      subscription: Subscription,
      setGpsCoordinates: CallableFunction,
      setSubscription: (v: Subscription | null) => void,
      setInitialized: (v: boolean) => void
    ): void =>
      destroy_gps(
        subscription,
        setGpsCoordinates,
        setSubscription,
        setInitialized
      )
  );
};

function syncOneSignalToken(
  user: User,
  uploadNotificationTokens: UseMutateFunction<
    AxiosResponse<unknown, any>,
    AxiosError<any, any>,
    ProfileTokenPayload,
    unknown
  >
) {
  OneSignal.getDeviceState((identity) => {
    if (user.PushNotificationToken) {
      for (let i = 0; i < user.PushNotificationToken.length; i++) {
        const token = user.PushNotificationToken[i];
        if (identity.userId === token.token) return true;
      }
    }

    if (identity.userId) uploadNotificationTokens({ token: identity.userId });
    else setTimeout(syncOneSignalToken, 1000, user, uploadNotificationTokens);
  });
}
// "notifications",
// "Allow notifications",
// "Needed for sending push notification to your mobile device",

export const useTranslatedNotificationModule = () => {
  const { t } = useTranslation();
  const title = t("setting.allow_notification");
  const desc = t("setting.allow_notifications_subtitle");

  return notifications_module(title, desc);
};

const notifications_module = (title: string, desc: string) => {
  return new SettingModule(
    "notifications",
    title,
    desc,
    false,
    (
      user: User | null,
      refresh_notifications: () => void,
      routerRef: any,
      uploadNotificationTokens: UseMutateFunction<
        AxiosResponse<unknown, any>,
        AxiosError<any, any>,
        ProfileTokenPayload,
        unknown
      >,
      setInitialized: (v: boolean) => void
    ) => {
      if (user) {
        // NOTE: Update the setAppId value below with your OneSignal AppId.
        OneSignal.setAppId(one_signal_app_id);
        OneSignal.setNotificationOpenedHandler((n) => {
          console.log("opened notification", n);
          handle_open_notification(
            n.notification
              .additionalData as OneSignalNotificationAdditionalData,
            routerRef
          );
        });

        // Send external our userID to Onesignal
        OneSignal.setExternalUserId(user.userId.toString());

        // Prompts the user for notification permissions.
        //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
        OneSignal.promptForPushNotificationsWithUserResponse(function (
          accepted
        ) {
          console.log("User accepted notifications: " + accepted);
        });

        syncOneSignalToken(user, uploadNotificationTokens);

        setInitialized(true);
      }
    },
    (deleteNotificationTokens: UseMutateFunction<AxiosResponse>) =>
      deleteNotificationTokens()
  );
};

export const useTranslatedTipModule = () => {
  const { t } = useTranslation();
  const title = t("setting.allow_tips");
  const desc = t("setting.allow_tips_subtitle");

  return tips_module(title, desc);
};

const tips_module = (title: string, desc: string) => {
  return new SettingModule(
    "tips",
    title,
    desc,
    false,
    () => {},
    () => {}
  );
};

export function useSetting(name: SettingName) {
  const { currentUser: user } = useStorage();
  const { updateSettings } = useProfile();

  const state = user?.settings[name];

  const toggle = async () => {
    if (!user || updateSettings.isLoading) return;

    updateSettings.mutate({
      ...user.settings,
      [name]: !user.settings[name],
    });
  };

  return { state, toggle };
}
