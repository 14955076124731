import useAxios from "./useAxios";
import useStorage from "./useStorage";
import { useMutation } from "react-query";
import { logEvent } from "../helpers/logging";
import { AxiosError } from "axios";
import useUserRequests from "./useUserRequests";
import { useInvalidateQueries } from "./useInvalidateQueries";
import { useTranslation } from "react-i18next";

const useJoinEvent = (eventId: number) => {
  const { axios } = useAxios();
  const { setMessage } = useStorage();
  const trigger = useUserRequests();
  const invalidateQueries = useInvalidateQueries();
  const {t} = useTranslation()


  const { mutate: joinEvent } = useMutation<never, AxiosError, {}>(
    (payload_state) => axios.post(`/events/${eventId}/users`, payload_state),
    {
      onSuccess: () => {
        logEvent("event_join", {});
        invalidateQueries('hp_events');
        trigger("event_join");
        setMessage("primary", t("messages.joined_event"));
      },
      onError: (err) => setMessage("danger", t("messages.went_wrong"), err),
    }
  );
  return { joinEvent };
};
export default useJoinEvent;
