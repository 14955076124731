import { default_locations } from "../config";
import { Coordinates } from "../models/Event";

function get_region() {
  if (new Date().getTimezoneOffset() > 120) return "usa";
  else return "czechia";
}

function get_default_gps() {
  if (new Date().getTimezoneOffset() > 120) return default_locations.new_york;
  else return default_locations.praha;
}

function load_coordinates(): Coordinates {
  console.log("loading coordinates");
  var coords = localStorage.getItem("coordinates");
  if (coords) {
    console.log("coordinates loaded from cache");
    return JSON.parse(coords);
  } else {
    console.log("using default coordinates");

    const defGps = get_default_gps();
    return {
      lat: defGps.lat,
      lng: defGps.lng,
    };
  }
}

function save_coordinates(lat: Number, lng: Number) {
  console.log("storing coordinates");
  localStorage.setItem("coordinates", JSON.stringify({ lat: lat, lng: lng }));
}

export { get_default_gps, load_coordinates, save_coordinates, get_region };
