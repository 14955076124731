import React, { useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonBackButton,
} from "@ionic/react";
import "./Login.scss";
import LoginForm from "../components/Login";
import { hideTabBar } from "../helpers/tabBar";

const Login: React.FC = () => {
  useEffect(() => hideTabBar(), []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/search"
              text=""
              className="back"
              icon={"/assets/icon/new/arrow-left.svg"}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="form login">
        <div className="container">
          <LoginForm />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
