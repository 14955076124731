import { InferType, object, string } from "yup";

export type Feedback = {
  callback: CallableFunction;
  feedbackId: number;
  text: string;
  createdAt: Date;
  authorId: number | null;
  replies: Feedback[];
};

export const feedback = object({
  text: string().required("Required"),
}).required();

export type FeedbackFormData = InferType<typeof feedback>;
export type feedbackFormReply = {
  text: string;
  feedbackId: number;
};
