import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { isCapacitor } from "../config";

// TODO: DRY IT
const isDevelopment = process.env.NODE_ENV === "development";

export function logEvent(
  evemt: string,
  tags: {
    [key: string]: string;
  }
) {
  if (isDevelopment) console.log("Firebase | event", evemt, tags);
  if (isCapacitor)
    FirebaseAnalytics.logEvent({
      name: evemt,
      params: tags,
    });
}
