import React from "react";

const ImagePreview: React.FC<{
  blob: any;
}> = ({ blob }) => {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={URL.createObjectURL(blob)} />;
};

export { ImagePreview };
