import React, { useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
} from "@ionic/react";
import { hideTabBar } from "../helpers/tabBar";

const Loading: React.FC = () => {
  useEffect(() => hideTabBar(), []);

  return (
    <IonPage className="page-loading">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Manyways</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className="loading"></IonContent>
    </IonPage>
  );
};

export default Loading;
