// your name, so you can see in Rollbar who generated error
export const DEVELOPER = "benes";
// you can override local API url
export const API_URL = undefined;
// export const API_URL = `https://api.manyways.info`;
// export const API_URL = `https://api-beta.manyways.info`;
// export const DEVICE = 'default';
// export const DEVICE = 'desktop';
export const WEB_URL = `https://m-beta.manyways.info`;
export const WEB_URL_TICKETS = `https://m-beta.manyways.info`;
// export const DEVICE = "mobile";
export const DEVICE = "desktop";
export const SLACK_WEBHOOK_URL_RELEASES =
  "https://hooks.slack.com/services/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";
