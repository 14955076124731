import React, { useEffect } from "react";
import { IonContent, IonPage } from "@ionic/react";
import { hideTabBar } from "../helpers/tabBar";

const NoInternet: React.FC = () => {
  useEffect(() => hideTabBar(), []);

  return (
    <IonPage>
      <IonContent fullscreen className="no-internet">
          <p>Cannot join the server. Please, try again in several minutes. Make sure you are connected to the internet.</p>
      </IonContent>
    </IonPage>
  );
};

export default NoInternet;
