import React from "react";
import { Formik } from "formik";
import { IonButton, IonTextarea } from "@ionic/react";
import "./FeedbackForm.scss";
import "./Feedback.scss";
import { useNavigation, useStorage } from "../hooks";
import { feedback } from "../models";
import type { FeedbackFormData } from "../models";
import { logEvent } from "../helpers/logging";
import { useMutation } from "react-query";
import useAxios from "../hooks/useAxios";
import { AxiosError, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import useTranlatedMsg from "../hooks/useTranslatedMsg";

const FeedbackForm: React.FC<{
  callback?: CallableFunction;
}> = ({ callback }) => {
  const { setMessage } = useStorage();
  const { axios } = useAxios();
  const { navigate } = useNavigation();
  const { t } = useTranslation();
  const { feedback_sent, err_feedback } = useTranlatedMsg();

  const { mutate: submit, isLoading } = useMutation<
    AxiosResponse<unknown>,
    AxiosError,
    FeedbackFormData
  >(["feedback"], (feedbackFormData) =>
    axios.post("/feedback", feedbackFormData)
  );

  function save(payload: FeedbackFormData) {
    logEvent("feedback_sent", {});

    submit(payload, {
      onSuccess: () => {
        setMessage("primary", feedback_sent);
        navigate("/profile");
        if (callback) callback();
      },
      onError: () => {
        setMessage("danger", err_feedback);
      },
    });
  }

  return (
    <Formik
      initialValues={{ text: "" }}
      validationSchema={feedback}
      onSubmit={(values, formikActions) => {
        save(values);
        formikActions.resetForm();
      }}
    >
      {({
        dirty,
        values,
        errors,
        isValid,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <>
          <div className="content-padded content-button feedback">
            <h3>{t("setting.feedback_title")}</h3>
            <div className="text-14">{t("setting.feedback_subtitle")}</div>
            <div className="textarea-fb-box">
              <IonTextarea
                aria-label="feedbackForm"
                autoGrow={true}
                rows={5}
                name="text"
                value={values.text}
                placeholder={t("setting.feedback_placeholder")}
                className="custom-area-fb"
                autocapitalize="true"
                onIonInput={handleChange}
                onIonBlur={handleBlur}
              />
            </div>
          </div>
          <div className="big_button_wrapper">
            <IonButton
              type="submit"
              className="modal_big_button"
              disabled={isLoading || !isValid || !dirty}
              onClick={() => handleSubmit()}
            >
              {t("setting.send")}
            </IonButton>
          </div>
        </>
      )}
    </Formik>
  );
};

export default FeedbackForm;
