import React, { useEffect, useState, useRef } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { Redirect } from "react-router";
import {
  IonApp,
  IonButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonToast,
  isPlatform,
  setupIonicReact,
  useIonRouter,
} from "@ionic/react";
import { IonReactHashRouter } from "@ionic/react-router";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Network } from "@capacitor/network";

import Login from "./pages/Login";
import Registration from "./pages/Registration";
import ResetPassword from "./pages/ResetPassword";
import Events, { PastEventsWrapper } from "./pages/Events";
import Profile from "./pages/Profile";
import ProfileEdit from "./pages/ProfileEdit";
import Feedbacks from "./pages/Feedbacks";
import EventDetail from "./pages/EventDetail";
import Notifications from "./pages/Notifications";
import Tips from "./pages/Tips";
import Announcement from "./pages/Announcement";
import Loading from "./pages/Loading";
import NoInternet from "./pages/NoInternet";
import Guidepost from "./pages/Guidepost";
import Intro from "./pages/Intro";
import { AppTrackingTransparency } from "capacitor-plugin-app-tracking-transparency";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
// import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";
import "./theme/layout.scss";
import "./theme/forms.scss";
import "./theme/common.scss";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { useTranslation } from "react-i18next";
import {
  useTranslatedNotificationModule,
  useTranslatedGPSModule,
} from "./components/Settings";
import { App as CApp, URLOpenListenerEvent } from "@capacitor/app";
import { useStorage, useProfile } from "./hooks";
import { ProfileTokenPayload } from "./models";
import { logEvent } from "./helpers/logging";
import { useSmartlook } from "./hooks/useSmartlook";
import { device, isMobileDevelopment } from "./config";
import OnBoardng from "./pages/OnBoarding";
import HomePage from "./pages/HomePage";
import Search from "./pages/Search";
import useAnalytics from "./hooks/useAnalytics";
import { Auth0Listener } from "./components/auth0";
import { useMutation } from "react-query";
import useAxios from "./hooks/useAxios";
import { AxiosResponse, AxiosError } from "axios";
import { AppsFlyerInitialization } from "./components/Appsflyer";
import PlaceDetailInfo from "./pages/PlaceDetailInfo";
import FollowingPlacesEvents from "./pages/FollowingPlacesEvents";
import OrderDetail from "./pages/Order";
import OrderCancelled from "./pages/OrderCancelled";
import translations from "./translations";
import useTranlatedMsg from "./hooks/useTranslatedMsg";

i18n.use(initReactI18next).init({
  resources: translations,
  lng: "en", // if you're using a language detector, do not define the lng option
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});

const AppUrlListener: React.FC<{
  loggedUserId: React.MutableRefObject<number | undefined>;
}> = ({ loggedUserId }) => {
  const history = useHistory();
  const location = useLocation();
  const { sendMetric } = useAnalytics();
  const { currentUser: user } = useStorage();

  useEffect(() => {
    CApp.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      const url = new URL(event.url);
      const path = url.hash ? url.hash.replace("#", "") : url.pathname;

      if (isPlatform("hybrid")) {
        //"/events/:id/:hash/:section"
        // const path_parts = path.split("/");
        // if (path_parts.length >= 4 && path_parts[1] === "events") {
        //   if (loggedUserId.current) {
        //     history.push(`/events/${path_parts[2]}/${path_parts[3]}/about`);
        //   } else {
        //     remember_event_invitation(parseInt(path_parts[2]), path_parts[3]);
        //   }
        // }
        history.push(path);
      } else {
        history.push(path);
      }
    });

    return () => {
      CApp.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    logEvent("page_view", {
      path: location.pathname,
    });

    sendMetric({
      name: "page_view",
      data: {
        path: location.pathname,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (
      user &&
      ["/login", "/registration", "/reset_password"].includes(location.pathname)
    )
      history.push("/home");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.userId, location]);

  return null;
};

const AppBackButtonListener: React.FC = () => {
  const ionRouter = useIonRouter();

  document.addEventListener("ionBackButton", (ev: any) => {
    ev.detail.register(-1, () => {
      if (!ionRouter.canGoBack()) {
        CApp.exitApp();
      }
    });
  });

  return null;
};

setupIonicReact({
  mode: "ios",
  swipeBackEnabled: false, // also prevent swiping back on either platform
  hardwareBackButton: true, // android only
});

if (isPlatform("ios")) {
  StatusBar.setStyle({ style: Style.Light }).catch(() => {
    console.log("StatusBar not initialised");
  });
}

ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT).catch(
  function () {
    console.log("Cant lock screen on this device");
  }
);

declare global {
  interface Window {
    accessToken: string;
  }
}

const App: React.FC = () => {
  // const rollbar = useRollbar();
  const routerRef = useRef<IonReactHashRouter>(null);
  const [hasInternet, setHasInternet] = useState(true);
  const loggedUserId = useRef<number>();
  // TODO: hide this var to auth0 context and wrap
  // NOTE: Auth0 is broken on mobile dev env because of https => we ignore Auth0 here
  const [isLoadingAuth0, setIsLoadingAuth0] = useState(!isMobileDevelopment);
  const { axios } = useAxios();
  const { sendMetric } = useAnalytics();
  const { t } = useTranslation();
  const gps_module = useTranslatedGPSModule();
  const notifications_module = useTranslatedNotificationModule();
  const { gps_reject } = useTranlatedMsg();

  const {
    auth,
    toast,
    currentUser,
    setMessage,
    setGpsCoordinates,
    setToast,
    dynamicLink,
    seenOnboardnig,
    settings,
  } = useStorage();

  useSmartlook(settings);

  // const { navigate } = useNavigation();
  const [bottomMenuSignal, setBottomMenuSignal] = useState<Number>(
    Math.random()
  );
  const { getProfile, updateSettings } = useProfile();
  const { mutate: uploadNotificationTokens } = useMutation<
    AxiosResponse<unknown>,
    AxiosError,
    ProfileTokenPayload
  >(["upload_notification_Tokens"], (data) =>
    axios.post("/profile/push_notification_tokens", data)
  );
  const { mutate: deleteNotificationTokens } = useMutation(
    ["delete_notification_Tokens"],
    () => axios.delete("/profile/push_notification_tokens")
  );

  useEffect(() => {
    setTimeout(() => {
      if (auth.accessToken) getProfile.refetch();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUser) {
      loggedUserId.current = currentUser.userId;
      // const ei = load_event_invitation();
      // if (ei) {
      //   flush_event_invitation();
      //   navigate(`/events/${ei["event_id"]}/${ei["hash"]}/about`);
      // }
    } else {
      loggedUserId.current = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.userId]);

  useEffect(() => {
    Network.addListener("networkStatusChange", (status) => {
      if (status.connected === false) {
        setHasInternet(false);
      } else {
        setHasInternet(true);
      }
    });

    return () => {
      Network.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      // setting gps...
      if (
        (currentUser?.settings.gps || !auth.refreshToken) &&
        !gps_module.is_initialized
      ) {
        const turnOffGps = () => {
          if (!currentUser) return;
          updateSettings.mutate({ ...currentUser.settings, gps: false });
          setMessage("danger", gps_reject);
        };
        gps_module.init(setGpsCoordinates, turnOffGps);
      } else if (
        currentUser &&
        !currentUser.settings.gps &&
        gps_module.is_initialized
      ) {
        gps_module.destroy(setGpsCoordinates);
      }

      if (currentUser) {
        // setting notifications
        if (
          currentUser.settings.notifications &&
          !notifications_module.is_initialized
        ) {
          // notifications_module.init(currentUser, refreshNotifications, routerRef);
          notifications_module.init(
            currentUser,
            null,
            routerRef,
            uploadNotificationTokens
          );
        } else if (
          !currentUser.settings.notifications &&
          notifications_module.is_initialized
        ) {
          notifications_module.destroy(deleteNotificationTokens);
        }
      }

      if (isPlatform("ios"))
        AppTrackingTransparency.getStatus().then((s) => {
          sendMetric({
            name: "att_status",
            data: { status: s.status },
          });
          if (s.status === "notDetermined")
            AppTrackingTransparency.requestPermission().then((s) =>
              sendMetric({
                name: "att_request",
                data: { response: s.status },
              })
            );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.settings, auth.refreshToken]);

  if (!hasInternet) {
    return <NoInternet />;
  }

  return (
    <>
      {
        // auth0 login
        (isLoadingAuth0 ||
          // login
          // (postLogin.isSuccess && auth.accessToken) ||
          // loading logged user
          // (auth.accessToken && !getProfile.isFetchedAfterMount) ||
          // waiting to sync users interests to local storage
          (currentUser?.interests?.length && !seenOnboardnig)) && <Loading />
      }
      <IonApp>
        <IonReactHashRouter ref={routerRef}>
          <AppUrlListener loggedUserId={loggedUserId} />
          <AppBackButtonListener />
          {/* TODO: load listener only if !isMobileDevelopment */}
          {Capacitor.isNativePlatform() && (
            <AppsFlyerInitialization currentUser={currentUser} />
          )}
          <Auth0Listener isLoadingCallback={setIsLoadingAuth0} />
          <IonTabs>
            <IonRouterOutlet>
              <Route path="/intro" exact={true} render={() => <Intro />} />
              <Route
                path="/order/:id/:code/cancelled"
                exact
                render={(props) => <OrderCancelled {...props} />}
              />
              <Route
                path="/order/:id/:code"
                exact
                render={(props) => <OrderDetail {...props} />}
              />
              <Route
                path="/onboarding"
                exact={true}
                render={() => {
                  return (
                    <OnBoardng start={2} type="init" destination="/home" />
                  );
                }}
              />
              <Route
                path="/login"
                exact={true}
                render={() => {
                  if (currentUser) return <Redirect to="/home" />;
                  else return <Login />;
                }}
              />
              <Route
                path="/guidepost"
                exact={true}
                render={() => {
                  if (currentUser) return <Redirect to="/home" />;
                  else return <Guidepost />;
                }}
              />
              <Route
                path="/registration"
                exact={true}
                render={() => {
                  if (currentUser) return <Redirect to="/home" />;
                  else return <Registration />;
                }}
              />
              <Route
                path="/reset_password"
                exact={true}
                render={() => {
                  if (currentUser) return <Redirect to="/home" />;
                  else return <ResetPassword />;
                }}
              />
              <Route
                path="/home"
                exact={true}
                render={() => <HomePage bottomMenuSignal={bottomMenuSignal} />}
              />
              <Route path="/search" exact={true} render={() => <Search />} />
              <Route
                path="/search/following_places_events"
                exact={true}
                render={() => <FollowingPlacesEvents />}
              />
              <Route
                path="/events"
                exact={true}
                render={() => <Events bottomMenuSignal={bottomMenuSignal} />}
              />
              {/* <Route
                path="/friends"
                exact={true}
                render={() => <Friends bottomMenuSignal={bottomMenuSignal} />}
              />
              <Route
                path="/friends/friend/:id"
                render={(props) => {
                  if (currentUser) return <FriendDetail {...props} />;
                  else return <Redirect to="/login" />;
                }}
              />
              <Route
                path="/friends/group/:id"
                render={(props) => {
                  if (currentUser) return <GroupDetail {...props} />;
                  else return <Redirect to="/login" />;
                }}
              /> */}
              <Route
                path="/profile/edit"
                exact={true}
                render={() => {
                  if (currentUser) return <ProfileEdit />;
                  else return <Redirect to="/profile" />;
                }}
              />
              <Route
                path="/profile"
                exact={true}
                render={() => {
                  return <Profile />;
                }}
              />
              <Route
                // I put there "profiles" and not "profile", because IonRouterLink in Profile wasn't redirecting properly
                path="/profiles/feedbacks"
                render={() => {
                  if (currentUser) return <Feedbacks />;
                  else return <Redirect to="/login" />;
                }}
              />
              <Route
                path={"/profiles/onboarding"}
                render={() => {
                  return (
                    <OnBoardng start={2} type="change" destination="/profile" />
                  );
                }}
              />
              <Route
                path="/events/notifications"
                exact={true}
                render={() => (
                  <Notifications bottomMenuSignal={bottomMenuSignal} />
                )}
              />
              <Route
                path="/events/past"
                exact={true}
                render={() => (
                  <PastEventsWrapper
                    section="past"
                    bottomMenuSignal={bottomMenuSignal}
                  />
                )}
              />
              <Route
                path="/events/interested"
                exact={true}
                render={() => (
                  <PastEventsWrapper
                    section="interested"
                    bottomMenuSignal={bottomMenuSignal}
                  />
                )}
              />
              <Route
                path="/events/organising"
                exact={true}
                render={() => (
                  <PastEventsWrapper
                    section="organising"
                    bottomMenuSignal={bottomMenuSignal}
                  />
                )}
              />
              <Route
                path="/notifications/tips/:id"
                exact={true}
                render={(props) => <Tips {...props} />}
              />
              <Route
                path="/events/:id/:section"
                render={(props) => <EventDetail {...props} />}
              />
              <Route
                path="/place/:id"
                render={(props) => <PlaceDetailInfo {...props} />}
              />
              <Route
                path="/events/:id/:hash/:section"
                render={(props) => {
                  return <EventDetail {...props} />;
                }}
              />
              <Route
                path="/announcements/:id"
                render={(props) => {
                  if (currentUser) return <Announcement {...props} />;
                  else return <Redirect to="/login" />;
                }}
              />
              <Route
                path="/"
                render={() =>
                  seenOnboardnig ? (
                    <Redirect to="/home" />
                  ) : (
                    <Redirect to="/intro" />
                  )
                }
                exact={true}
              />
            </IonRouterOutlet>

            {!(isPlatform("desktop") || isPlatform("mobileweb")) ||
            currentUser ||
            device === "mobile" ? (
              <IonTabBar
                slot="bottom"
                onClick={() => {
                  setBottomMenuSignal(Math.random());
                }}
              >
                <IonTabButton tab="home" href="/home">
                  <IonIcon icon="/assets/icon/new/menu-events.svg" />
                  <IonLabel>{t("menu.events")}</IonLabel>
                </IonTabButton>
                <IonTabButton tab="search" href="/search">
                  <IonIcon icon="/assets/icon/new/menu-map.svg" />
                  <IonLabel>{t("menu.map")}</IonLabel>
                </IonTabButton>
                <IonTabButton tab="events" href="/events">
                  <IonIcon icon="/assets/icon/new/menu-feed.svg" />
                  <IonLabel>{t("menu.activities")}</IonLabel>
                </IonTabButton>
                {/* <IonTabButton tab="friends" href="/friends">
                  <IonIcon icon={group} />
                  <IonLabel>Social</IonLabel>
                </IonTabButton> */}
                {/* <IonTabButton tab="notifications" href="/notifications">
                  <IonIcon icon={notification} />
                  <IonLabel>Notifications</IonLabel>
                  {has_unnoticed_notifications(notifications) && (
                    <span className="notification_dot" />
                  )}
                </IonTabButton> */}
                <IonTabButton tab="profile" href="/profile">
                  <IonIcon icon="/assets/icon/new/menu-profile.svg" />
                  <IonLabel>{t("menu.profile")}</IonLabel>
                </IonTabButton>
              </IonTabBar>
            ) : (
              <IonTabBar slot="bottom"></IonTabBar>
            )}
          </IonTabs>
        </IonReactHashRouter>

        <IonToast
          color={toast?.type}
          isOpen={toast !== undefined}
          onDidDismiss={() => setToast(undefined)}
          message={toast?.text}
          duration={3000}
        />
        {(isPlatform("desktop") || isPlatform("mobileweb")) &&
          !currentUser &&
          device !== "mobile" && (
            <div className="stores">
              {(isPlatform("desktop") ||
                (isPlatform("mobileweb") && isPlatform("ios"))) && (
                <IonButton
                  className="store"
                  fill="clear"
                  target="_blank"
                  href={
                    isPlatform("mobileweb") && dynamicLink
                      ? // TODO: change to dynamicLink when ios is released to prod
                        dynamicLink
                      : "https://apps.apple.com/cz/app/manywaysapp/id1569619891"
                  }
                >
                  <img src="/assets/icon/stores/appstore.svg" alt="App Store" />
                </IonButton>
              )}
              {(isPlatform("desktop") ||
                (isPlatform("mobileweb") && isPlatform("android"))) && (
                <IonButton
                  className="store"
                  fill="clear"
                  target="_blank"
                  // @ts-ignore
                  href={
                    isPlatform("mobileweb") && dynamicLink
                      ? dynamicLink
                      : "https://play.google.com/store/apps/details?id=info.manyways.main"
                  }
                >
                  <img
                    src="/assets/icon/stores/googleplay.svg"
                    alt="Google Play"
                  />
                </IonButton>
              )}
            </div>
          )}
      </IonApp>
    </>
  );
};

export default App;
