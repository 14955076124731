import React, { useEffect, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonIcon,
  IonButton,
  useIonViewWillEnter,
  IonItem,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  ScrollDetail,
  useIonViewWillLeave,
  IonAvatar,
  IonBackButton,
  IonButtons,
  IonFab,
  IonFabButton,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";

import "./Events.scss";
import "./Activities.scss";
import { Event } from "../models/Event";
import { EventItem } from "../components/Event";
import { showTabBar } from "../helpers/tabBar";
import { useStorage } from "../hooks";
import { EmptySection } from "../components/EmptySection";
import { axios } from "../helpers/axios";
import { SkeletonEventList } from "../components/Skeleton";
import { SCROLL_STEP } from "../constants/global";
import { has_notification_path } from "../components/Notifications";
import useNotifications from "../hooks/useNotifications";
import { add, search } from "../icons/iconsOutline";
import { Guidepost } from "../components/Guidepost";
import AddEvent from "../components/AddEvent";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import useTranlatedMsg from "../hooks/useTranslatedMsg";

// const NiceDate: React.FC<{
//   date: Date | null;
// }> = ({ date }) => {
//   const mdate = moment(date);
//   if (date === null) return <>Vote on the date</>;
//   else if (mdate.isSame(moment(), "day")) return <>Today</>;
//   else if (mdate.isSame(moment().add(1, "day"), "day")) return <>Tomorrow</>;
//   else if (mdate.isSame(moment(), "month")) return <>Later this month</>;
//   else if (moment(date).isSame(moment(), "year"))
//     return <>{mdate.format(" MMMM ")}</>;
//   else return <>{mdate.format(" MMMM YYYY")}</>;
// };

const EventsList: React.FC<{
  events: Event[];
  // duplicate_event: CallableFunction;
  source: string;
}> = ({ events, source }) => {
  return (
    <>
      {events.map((event, i) => (
        <EventItem
          event={event}
          // duplicate_event={duplicate_event}
          key={event.eventId}
          order={i + 1}
          source={source}
        />
      ))}
    </>
  );
};

const FutureEventsTab: React.FC<{
  // setShowAddEventModal: React.Dispatch<React.SetStateAction<boolean>>;
  // setPrefetchedEvent: React.Dispatch<React.SetStateAction<Event | undefined>>;
  // duplicate_event: CallableFunction;
  // tab: boolean;
  // setOnTop: React.Dispatch<React.SetStateAction<Number>>;
  section: string;
}> = ({ section }) =>
  // {
  // setShowAddEventModal,
  // setPrefetchedEvent,
  // duplicate_event,
  // tab,
  // setOnTop,
  // }
  {
    const { currentUser: user, setMessage } = useStorage();
    const { t } = useTranslation();
    const { err_load_fut_events } = useTranlatedMsg();

    const {
      data: futureEvents,
      refetch: refetchFuture,
      isLoading: futureEventsAreLoading,
      fetchNextPage: fetchFutureEventsNextPage,
    } = useInfiniteQuery(
      ["myEventsFutureEvents", section],
      ({ pageParam = 0 }) => {
        return axios.get<Event[]>("/events", {
          params:
            section === "past"
              ? {
                  source: "myevents",
                  membership: "mine",
                  filter_date: "past",
                  // attends: "INVITED,GOING,MAYBE",
                  attends: "GOING",
                  order_by: "start",
                  order_direction: "DESC",
                  offset: pageParam,
                  limit: SCROLL_STEP,
                }
              : section === "maybe"
              ? {
                  source: "myevents",
                  membership: "mine",
                  filter_date: "future",
                  // attends: "INVITED,GOING,MAYBE",
                  attends: "MAYBE",
                  order_by: "start",
                  order_direction: "ASC",
                  offset: pageParam,
                  limit: SCROLL_STEP,
                }
              : {
                  source: "myevents",
                  membership: "mine",
                  filter_date: "future",
                  // attends: "INVITED,GOING,MAYBE",
                  attends: "GOING",
                  order_by: "start",
                  order_direction: "ASC",
                  offset: pageParam,
                  limit: SCROLL_STEP,
                },
        });
      },
      {
        getNextPageParam: (lastPage, pages) => {
          if (lastPage.data.length < SCROLL_STEP) return;
          else return pages.flatMap((page) => page.data).length;
        },
        onError: (error) => {
          setMessage("danger", err_load_fut_events, error);
        },
      }
    );

    const futureEventsFlat: Event[] = [];

    futureEvents?.pages.map((page) =>
      page.data.map((ev) => futureEventsFlat.push(ev))
    );

    useIonViewWillEnter(() => {
      refetchFuture();
    });

    const loadMore = async (e: any) => {
      await fetchFutureEventsNextPage();
      e.target.complete();
    };

    // function showCurrentMonthTitle(i: number, event: Event): boolean {
    //   if (i === 0) return true;
    //   const today = moment(get_start(futureEventsFlat[i - 1])).isSame(
    //     moment(),
    //     "day"
    //   );

    //   const tomorrow = moment(get_start(futureEventsFlat[i - 1])).isSame(
    //     moment().add(1, "day"),
    //     "day"
    //   );

    //   const checkedEvents = !(
    //     get_start(event) === null && get_start(futureEventsFlat[i - 1]) === null
    //   );
    //   const sortedEvents = !moment(get_start(futureEventsFlat[i - 1])).isSame(
    //     moment(get_start(event)),
    //     today || tomorrow ? "day" : "month"
    //   );

    //   return checkedEvents && sortedEvents;
    // }

    return (
      <>
        {futureEventsAreLoading ? (
          <IonList lines="none">
            <SkeletonEventList size="big" />
          </IonList>
        ) : (
          user && (
            <>
              <IonList style={{ marginTop: "10px" }} className="events_list">
                {futureEventsFlat.length ? (
                  futureEventsFlat.map((event, i) => (
                    <React.Fragment key={event.eventId}>
                      {/* {showCurrentMonthTitle(i, event) && (
                      <IonItem lines="none" className="list_date">
                        <NiceDate date={get_start(event)} />
                      </IonItem>
                    )} */}
                      <EventItem
                        event={event}
                        // duplicate_event={duplicate_event}
                        // overview={tab}
                        order={i + 1}
                        source="myevents_future"
                      />
                    </React.Fragment>
                  ))
                ) : (
                  <EmptySectionMyEvents
                    // setPrefetchedEvent={setPrefetchedEvent}
                    // setShowAddEventModal={setShowAddEventModal}
                    description={t("activities.no_events_plan")}
                  />
                )}
              </IonList>
              {/* <IonFab vertical="bottom" horizontal="end" slot="fixed">
              <IonFabButton
                onClick={() => {
                  setPrefetchedEvent(undefined);
                  setShowAddEventModal(true);
                }}
              >
                <IonIcon icon={add} />
              </IonFabButton>
            </IonFab> */}
              <IonInfiniteScroll onIonInfinite={loadMore} threshold="200px">
                <IonInfiniteScrollContent loadingSpinner="dots" />
              </IonInfiniteScroll>
            </>
          )
        )}
      </>
    );
  };

const PastAndDeclinedEventsTab: React.FC<{
  // setShowAddEventModal: React.Dispatch<React.SetStateAction<boolean>>;
  // setPrefetchedEvent: React.Dispatch<React.SetStateAction<Event | undefined>>;
  // duplicate_event: CallableFunction;
  // setOnTop: React.Dispatch<React.SetStateAction<Number>>;
  section: "past" | "interested" | "organising";
}> = ({ section }) =>
  // {
  // setShowAddEventModal,
  // setPrefetchedEvent,
  // duplicate_event,
  // setOnTop,
  // }
  {
    const { currentUser: user, setMessage } = useStorage();
    const { err_load_events } = useTranlatedMsg();

    // const {
    //   data: futureEventsDeclinedFlat,
    //   refetch: refetchDeclined,
    //   isLoading: futureEventsDeclinedAreLoading,
    // } = useQuery(
    //   ["myEventsFutureEventsDeclined"],
    //   () => {
    //     return axios.get<Event[]>("/events", {
    //       params: {
    //         source: "myevents",
    //         membership: "mine",
    //         filter_date: "future",
    //         attends: "DECLINED",
    //         order_by: "start",
    //         order_direction: "ASC",
    //       },
    //     });
    //   },
    //   { select: (rawData) => rawData?.data }
    // );

    const {
      data: pastEvents,
      refetch: refetchPast,
      isLoading: pastEventsAreLoading,
      fetchNextPage: fetchPastEventsNextPage,
    } = useInfiniteQuery(
      ["myEventsPastEvents", section],
      ({ pageParam = 0 }) => {
        return axios.get<Event[]>("/events", {
          params:
            section === "past"
              ? {
                  source: "myevents",
                  membership: "mine",
                  filter_date: "past",
                  order_by: "start",
                  order_direction: "DESC",
                  offset: pageParam,
                  limit: SCROLL_STEP,
                }
              : {
                  source: "myevents",
                  is_host: true,
                  order_by: "start",
                  order_direction: "ASC",
                  offset: pageParam,
                  limit: SCROLL_STEP,
                },
        });
      },
      {
        getNextPageParam: (lastPage, pages) => {
          if (lastPage.data.length < SCROLL_STEP) return;
          else return pages.flatMap((page) => page.data).length;
        },
        onError: (error) => {
          setMessage("danger", err_load_events, error);
        },
      }
    );
    const pastEventsFlat: Event[] = [];

    pastEvents?.pages.map((page) =>
      page.data.map((ev) => pastEventsFlat.push(ev))
    );

    useIonViewWillEnter(() => {
      // refetchDeclined();
      refetchPast();
    });

    const loadMore = async (e: any) => {
      await fetchPastEventsNextPage();
      e.target.complete();
    };

    return (
      <>
        {/* {futureEventsDeclinedAreLoading || pastEventsAreLoading ? ( */}
        {pastEventsAreLoading ? (
          <IonList lines="none">
            <SkeletonEventList size="big" />
          </IonList>
        ) : user &&
          // (futureEventsDeclinedFlat?.length || pastEventsFlat?.length) ? (
          pastEventsFlat?.length ? (
          <IonList className="events_list">
            {/* <IonItem lines="none" className="list_date">
              Declined Events
            </IonItem>
            {futureEventsDeclinedFlat && (
              <EventsList
                events={futureEventsDeclinedFlat}
                // duplicate_event={duplicate_event}
                source="myevents_future_declined"
              />
            )} */}
            {/* <IonItem lines="none" className="list_date">
              Past Events
            </IonItem> */}
            <EventsList
              events={pastEventsFlat}
              // duplicate_event={duplicate_event}
              source="myevents_past"
            />
            <IonInfiniteScroll onIonInfinite={loadMore} threshold="200px">
              <IonInfiniteScrollContent loadingSpinner="dots" />
            </IonInfiniteScroll>
          </IonList>
        ) : (
          <EmptySectionMyEvents
            // setPrefetchedEvent={setPrefetchedEvent}
            // setShowAddEventModal={setShowAddEventModal}
            description={
              section === "past"
                ? t("activities.no_past_events")
                : t("activities.no_events_yet")
            }
          />
        )}
      </>
    );
  };

const EmptySectionMyEvents: React.FC<{
  // setPrefetchedEvent: React.Dispatch<React.SetStateAction<Event | undefined>>;
  // setShowAddEventModal: React.Dispatch<React.SetStateAction<boolean>>;
  description: string;
}> = ({ description }) => {
  return (
    <EmptySection
      icon={"/assets/icon/new/menu-events.svg"}
      title={t("activities.no_events")}
      description={description}
    >
      {/* <IonButton
        className="button-primary-light"
        fill="clear"
        size="small"
        onClick={() => {
          setPrefetchedEvent(undefined);
          setShowAddEventModal(true);
        }}
      >
        <IonIcon icon={calendarAdd} /> Create an event
      </IonButton> */}
      <IonButton
        className="button-primary-light"
        fill="clear"
        size="small"
        href="#/search"
      >
        <IonIcon icon={search} /> {t("activities.search_events")}
      </IonButton>
    </EmptySection>
  );
};

// type Tab = "overview" | "past";

const Events: React.FC<{
  bottomMenuSignal: Number;
  section: "past" | "interested" | "organising";
}> = ({ bottomMenuSignal, section }) => {
  // const [tab, setTab] = useState<Tab>("overview");
  const [lastNidsUpdate, setLastNidsUpdate] = useState<number[]>([]);
  // const [prefetchedEvent, setPrefetchedEvent] = useState<Event>();
  // const [showAddEventModal, setShowAddEventModal] = useState(false);
  const { notifications } = useStorage();
  const { submit } = useNotifications();
  const [onTop, setOnTop] = useState<Number>(0);
  const contentEle = useRef<HTMLIonContentElement>(null);
  const isActive = useRef<boolean>(false);
  // const { axios } = useAxios();

  // const duplicate_event = (eventId: number) => {
  //   load_event(axios, eventId)
  //     .then((event) => {
  //       setPrefetchedEvent(event);
  //       setShowAddEventModal(true);
  //     })
  //     .catch((err) => setMessage("danger", undefined, err));
  // };

  useIonViewWillEnter(() => {
    isActive.current = true;
    showTabBar();
  });

  useIonViewWillLeave(() => {
    isActive.current = false;
  });

  function handleScroll(ev: CustomEvent<ScrollDetail>) {
    setOnTop(ev.detail.scrollTop);
  }

  function scrollOrSwitch() {
    if (!isActive.current) return;
    if (onTop !== 0) contentEle.current?.scrollToTop(400);
    // else setTab("overview");
  }

  useEffect(() => {
    if (isActive.current) setTimeout(scrollOrSwitch, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomMenuSignal]);

  useEffect(() => {
    let nids = has_notification_path(notifications, ["events"]);
    nids = nids.concat(has_notification_path(notifications, ["events"]));

    const allNidsUpdated = nids.every((val) => lastNidsUpdate.includes(val));

    if (nids.length !== 0 && !allNidsUpdated) {
      setLastNidsUpdate([...lastNidsUpdate, ...nids]);
      submit({ notificationIds: nids });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/events"
              text=""
              className="back"
              icon={"/assets/icon/new/arrow-left.svg"}
            />
          </IonButtons>
          <IonTitle>
            {section === "organising"
              ? t("activities.organising")
              : "Past events"}
          </IonTitle>
        </IonToolbar>
        {/* <IonSegment
          value={tab}
          onIonChange={(e) => setTab(e.detail.value as Tab)}
          className="simple original"
          color="primary"
        >
          <IonSegmentButton value="overview">
            <IonLabel>Overview</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="past">
            <IonLabel>Declined &amp; past</IonLabel>
          </IonSegmentButton>
        </IonSegment> */}
      </IonHeader>

      {/* <AddEvent
        showModal={showAddEventModal}
        setShowModal={setShowAddEventModal}
        type="default"
        prefetchedEvent={prefetchedEvent}
      /> */}
      <IonContent
        scrollEvents={true}
        onIonScroll={handleScroll}
        ref={contentEle}
      >
        {/* {tab === "overview" ? (
          <FutureEventsTab
          // setShowAddEventModal={setShowAddEventModal}
          // setPrefetchedEvent={setPrefetchedEvent}
          // duplicate_event={duplicate_event}
          // tab={tab === "overview"}
          // setOnTop={setOnTop}
          />
        ) : ( */}
        <PastAndDeclinedEventsTab
          section={section}
          // setShowAddEventModal={setShowAddEventModal}
          // setPrefetchedEvent={setPrefetchedEvent}
          // duplicate_event={duplicate_event}
          // setOnTop={setOnTop}
        />
        {/* )} */}
      </IonContent>
    </>
  );
};

export const PastEventsWrapper: React.FC<{
  bottomMenuSignal: Number;
  section: "past" | "interested" | "organising";
}> = ({ bottomMenuSignal, section }) => {
  const { currentUser: user } = useStorage();

  return (
    <IonPage className="page-events">
      {
        user && <Events bottomMenuSignal={bottomMenuSignal} section={section} /> //: (
        // <DisabledSection section={"events"} />
        //)
      }
    </IonPage>
  );
};

const Activities: React.FC<{
  bottomMenuSignal: Number;
}> = ({ bottomMenuSignal }) => {
  const { currentUser: user } = useStorage();
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [section, setSection] = useState("going");
  const { t } = useTranslation();
  useIonViewWillEnter(() => {
    showTabBar();
  });

  return (
    <IonPage className={user ? "page-activities" : "page-guidepost"}>
      {user ? (
        <IonContent className="padding-base_notch-top">
          <IonList>
            {/* <IonItem
              lines="none"
              className="activity"
              href="#/events/interested"
            >
              <IonAvatar>
                <IonIcon icon="/assets/icon/new/bookmark.svg" />
              </IonAvatar>
              Interested
            </IonItem> */}
            <IonItem
              lines="none"
              className="activity"
              href="#/events/notifications"
            >
              <IonAvatar>
                <IonIcon icon="/assets/icon/new/noti.svg" />
              </IonAvatar>
              {t("activities.notifications")}
            </IonItem>
            <IonItem
              lines="none"
              className="activity"
              href="#/events/organising"
            >
              <IonAvatar>
                <IonIcon icon="/assets/icon/new/tick.svg" />
              </IonAvatar>
              {t("activities.organising")}
            </IonItem>
          </IonList>

          <IonSegment
            value={section}
            onIonChange={(e: any) => {
              setSection(e.detail.value);
            }}
            className="simple original spacing"
            // color="primary"
          >
            <IonSegmentButton value="going">
              <IonLabel> {t("activities.going")}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="maybe">
              <IonLabel> {t("activities.interested")}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="past">
              <IonLabel> {t("activities.past")}</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          <FutureEventsTab section={section} />

          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton
              onClick={() => {
                // setPrefetchedEvent(undefined);
                setShowAddEventModal(true);
              }}
            >
              <IonIcon icon={add} />
            </IonFabButton>

            <AddEvent
              showModal={showAddEventModal}
              setShowModal={setShowAddEventModal}
              type="default"
              // prefetchedEvent={prefetchedEvent}
            />
          </IonFab>
        </IonContent>
      ) : (
        <Guidepost skippable={false} />
      )}
    </IonPage>
  );
};

export default Activities;
