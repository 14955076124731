import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonBackButton,
} from "@ionic/react";
import "./Login.scss";
import {
  ResetPassword as ResetPasswordForm,
  Verification,
} from "../components";
import { hideTabBar } from "../helpers/tabBar";

const ResetPassword: React.FC = () => {
  const [phonePrefix, setPhonePrefix] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [userId, setUserId] = useState<number | null>(null);

  useEffect(() => hideTabBar(), []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {userId ? (
              <IonButton
                onClick={() => {
                  setUserId(null);
                }}
              >
                <IonIcon icon={"/assets/icon/new/arrow-left.svg"} />
              </IonButton>
            ) : (
              <IonBackButton
                defaultHref="/search"
                text=""
                className="back"
                icon={"/assets/icon/new/arrow-left.svg"}
              />
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className="form reset_password">
        <div className="container">
          {userId === null ? (
            <ResetPasswordForm
              setPhonePrefix={setPhonePrefix}
              setPhone={setPhone}
              setPassword={setPassword}
              setUserId={setUserId}
            />
          ) : (
            <Verification
              phonePrefix={phonePrefix}
              phone={phone}
              password={password}
              userId={userId}
            />
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
