import {
  IonModal,
  IonContent,
  IonRow,
  IonButton,
  IonIcon,
  IonCol,
  IonList,
  IonItemGroup,
  IonItem,
  IonLabel,
  useIonViewWillEnter,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";
import { showTabBar } from "../helpers/tabBar";
import { useNavigation } from "../hooks";
import {
  calendarMonthFill,
  barChartFill,
  groupFill,
  nearMeFill,
  notificationsFill,
  personFill,
} from "../icons/iconsFill";
import { chat } from "../icons/iconsOutline";

import { DisabledSections } from "../models/DisabledSections";
import { ModalHeader } from "./Headers";
import "./UnsignedUser.scss";

export const AccountBenefits: React.FC<{
  showBenefits: boolean;
  setShowBenefits: CallableFunction;
}> = ({ showBenefits, setShowBenefits }) => {
  const { navigate: nav } = useNavigation();

  return (
    <IonModal
      isOpen={showBenefits}
      onDidDismiss={() => setShowBenefits(false)}
      className="filters"
    >
      <ModalHeader
        onClick={() => {
          setShowBenefits(false);
        }}
        title="Account benefits"
      />
      <IonContent className="benefits-modal">
        <IonRow>
          <IonCol className="label">Account will enable you</IonCol>
        </IonRow>
        <IonList lines="none" className="benefits-list">
          <IonItemGroup>
            <IonItem>
              <IonIcon slot="start" icon={calendarMonthFill} color="primary" />
              <IonLabel>Join events and keep track of them</IonLabel>
            </IonItem>
            <IonItem>
              <IonIcon slot="start" icon={notificationsFill} color="primary" />
              Get reminders about your events
            </IonItem>
            <IonItem>
              <IonIcon slot="start" icon={personFill} color="primary" />
              Connect and chat with your friends
            </IonItem>
            <IonItem>
              <IonIcon slot="start" icon={groupFill} color="primary" />
              Create groups with your friends
            </IonItem>
            <IonItem>
              <IonIcon slot="start" icon={chat} color="primary" />
              Chat in events
            </IonItem>
            <IonItem>
              <IonIcon slot="start" icon={barChartFill} color="primary" />
              Vote with your friends where and when to go
            </IonItem>
            <IonItem>
              <IonIcon slot="start" icon={nearMeFill} color="primary" />
              Get directions to your events
            </IonItem>
          </IonItemGroup>
        </IonList>
        <div className="action-btns">
          <IonButton
            className="button-primary-light"
            fill="clear"
            size="small"
            onClick={() => {
              setShowBenefits(false);
              nav("/registration");
            }}
          >
            Register
          </IonButton>
          <IonButton
            className="button-primary-light"
            fill="clear"
            size="small"
            onClick={() => {
              setShowBenefits(false);
              nav("/login");
            }}
          >
            Login
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};
const DisabledSection: React.FC<{ section: string }> = ({ section }) => {
  const [showBenefits, setShowBenefits] = useState<boolean>(false);
  const { navigate } = useNavigation();

  var pages: DisabledSections = {
    events: {
      title: "My events",
      src: "/assets/disabled_sections/events.svg",
      titleText: "Create account to view your events",
      benefits: [
        "Join events you're interested in",
        "Keep track of events you attend",
        "Organise your own events",
      ],
    },
    friends: {
      title: "Friends",
      src: "/assets/disabled_sections/friends.svg",
      titleText: "Create account to connect with your friends",
      benefits: [
        "Add friends and create groups",
        "Share events you find interesting",
        "Plan meeting in few simple steps",
      ],
    },
    profile: {
      title: "Profile",
      src: "/assets/disabled_sections/profile.svg",
      titleText: "Create account to see your profile",
      benefits: [
        "Let your friends join you in the app",
        "Use more features Manyways offers",
        "Set up important personal settings",
      ],
    },
    notifications: {
      title: "Notifications",
      src: "/assets/disabled_sections/notifications.svg",
      titleText: "Create account to get notifications",
      benefits: [
        "Be reminded of upcoming events",
        "Find out about events you might like",
        "Weekly summary of your plans and more",
      ],
    },
  };

  useIonViewWillEnter(() => {
    showTabBar();
  });

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{pages[section].title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="disabled_component">
          <img
            src={pages[section].src}
            alt={"disabled " + section}
            className={`potato potato-${section}`}
          />
          <h3>{pages[section].titleText}</h3>

          <ul>
            {pages[section].benefits.map((benefit) => (
              <li key={benefit}>{benefit}</li>
            ))}
          </ul>
          <IonRow className="action-btns">
            <IonButton
              className="button-primary-light"
              fill="clear"
              size="small"
              onClick={() => setShowBenefits(true)}
            >
              See all benefits
            </IonButton>
            <IonButton
              className="button-primary-light"
              fill="clear"
              size="small"
              onClick={() => {
                navigate("/registration");
              }}
            >
              Register
            </IonButton>
            <IonButton
              className="button-primary-light"
              fill="clear"
              size="small"
              onClick={() => {
                navigate("/login");
              }}
            >
              Log in
            </IonButton>
          </IonRow>
          <AccountBenefits
            showBenefits={showBenefits}
            setShowBenefits={setShowBenefits}
          />
        </div>
      </IonContent>
    </>
  );
};
export default DisabledSection;
